import { prot } from "@grenton/gm-common"
import { ToggleOn, ToggleOff } from "@mui/icons-material"
import { ButtonBase, Box, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { AppWidgetBox } from "./WidgetBox"
import { WidgetBasicObject } from "./basic"

export namespace WidgetGenericOUT {

    export const Type = "GenericDigitalOUT"

    export const Factory = WidgetBasicObject.factory(Type,{api:prot.DigitalOUT})

    export const Renderer = WidgetBasicObject.renderer(Widget)

    function Widget(props: WidgetBasicObject.BasicProps) {
        const [value, setValue] = useState(false)
        return <AppWidgetBox padding={0}>
            <ButtonBase sx={{flexGrow:2,pt:1,pb:1,pr:2,pl:2}} onClick={()=>setValue(!value)}>
            <Box pr={2}>{value ? <ToggleOn color="primary"/> : <ToggleOff color="disabled"/>}</Box>
                <Stack alignItems="start" direction="column" flexGrow={2} alignContent={"center"}>
                <Typography variant="body1" flexGrow={2}>{props.widget.title}</Typography>
                <Typography variant="caption" fontWeight={800} flexGrow={2} color={value?"primary":"#aaa"}>{value ? 'On' : 'Off'}</Typography>
                </Stack>
            </ButtonBase>
        </AppWidgetBox>
    }
}
