import { fabric, isAnonymousController, isApiExtending, ObjectApi, ObjectID, ObjectSelector, parseTag, TAG_SEPARATOR } from '@grenton/gm-common';

export const OWNER_TAG_MASK = '{self}';
export const TAG_WILDCARD_SUFFIX = '.*';
const OWNER_TAG_MASK_WITH_WILDCARD = `${OWNER_TAG_MASK}${TAG_WILDCARD_SUFFIX}`;

/**
 * returns list of (dedup) objects' ids that matches given outlet based on an optional outlet selector.
 * Objects are sorted by name+optional index (IMPORTANT), since this is the natural order of objects in a selector.
 * Outlet always has a protocol defined, so all returned objects MUST comply with this protocol.
 *
 * Additional filtering is done based on a selector:
 * Outlet Selector may include:
 * - explicit object ids
 * - tags (regular or relative ones)
 * - functional types
 *
 * Filtering logic is as follows:
 * - include objects explicitly selected by id if they comply with outlet protocol
 * - include objects that met all of following criteria
 *      - if selector defines tags, objects must have all tags from selector
 *      - if selector defines types, objects must have at least one of the types from selector
 *
 * Selector tags may include wildcards to match multiple values, e.g. 'loc:floor1.*'
 * Selector tags may include {ctrl} mask that will be substituted with owner tag of matching category, e.g.
 *      - assuming that controller has 'loc:floor1' tag, selector 'loc:{ctrl}' will be replaced with 'loc:floor1'
 *      - assuming that controller has 'loc:floor1' tag, selector 'loc:{ctrl}.*' will be replaced with 'loc:floor1.*'
 *      - assuming that controller has 'lights:primary' and 'lights:secondary' tags, selector 'lights:{ctrl}.*' will be expanded to 'lights:primary' and 'lights:secondary'
 *      - assuming that controller has no 'loc:' tags, selector 'loc:{ctrl}' will be ignored'
 */

export type CandidateObject = { uuid: string; api: ObjectApi; config: Pick<fabric.ObjectConfig, 'name' | 'tags' | 'device'> };

export type CandidatesProvider = {
    all: () => CandidateObject[];
    byId: (id: string) => CandidateObject | undefined;
};

type Props = {
    objectProvider: CandidatesProvider;
    ownerTags: string[];
    outletProtocol: string;
    selector: ObjectSelector;
};

export function evaluateOutletSelector({ objectProvider, ownerTags, outletProtocol, selector }: Props): ObjectID[] {
    const withCompatibleProtocol = (obj: CandidateObject) => !isAnonymousController(obj.uuid) && isApiExtending(obj.api, outletProtocol);

    // use AND operator to filter objects by tags
    const selectorHasTags = Boolean(selector.tags?.length);
    const selectorHasTypes = Boolean(selector.types?.length);

    let objectsSelectedByTagsAndType: CandidateObject[] = selectorHasTags || selectorHasTypes ? objectProvider.all().filter(withCompatibleProtocol) : [];

    if (selectorHasTags && objectsSelectedByTagsAndType.length) {
        // selected object must have all tags from selector (AND)
        // allow wildcards (.*) and owner tag substitution ({ctrl})

        // go through all tags and substitute with owner tag if needed
        const parsedOwnerTags = ownerTags.map(parseTag);

        selector
            .tags!.map((tag) => {
                // check if the value part is {ctrl} or {ctrl.*};
                // if owner has multiple tags from this category, expand selector tag to multiple tags to cover all values
                const parsed = parseTag(tag);
                if (parsed.value === OWNER_TAG_MASK || parsed.value === OWNER_TAG_MASK_WITH_WILDCARD) {
                    return parsedOwnerTags
                        .filter((ownerTag) => ownerTag.category === parsed.category)
                        .map((ownerTag) => parsed.category + TAG_SEPARATOR + parsed.value.replace(OWNER_TAG_MASK, ownerTag.value));
                } else {
                    return tag;
                }
            })
            .flat()
            .forEach((selectorTag) => {
                const wildcard = selectorTag.endsWith(TAG_WILDCARD_SUFFIX)
                    ? selectorTag.substring(0, selectorTag.length - TAG_WILDCARD_SUFFIX.length)
                    : undefined;
                objectsSelectedByTagsAndType = objectsSelectedByTagsAndType.filter((obj) =>
                    wildcard ? Boolean(obj.config.tags.find((objectTag) => objectTag.startsWith(wildcard))) : obj.config.tags.includes(selectorTag),
                );
            });
    }

    // must has at least on type (OR)
    if (selectorHasTypes && objectsSelectedByTagsAndType.length) {
        objectsSelectedByTagsAndType = objectsSelectedByTagsAndType.filter((obj) => obj.config.device && selector.types?.includes(obj.config.device));
    }

    // include all named objects if compatible
    const objectsSelectedExplicitly: CandidateObject[] = [];

    selector.ids?.forEach((id) => {
        if (objectsSelectedByTagsAndType.find((obj) => obj.uuid === id)) return; //dedup
        const obj = objectProvider.byId(id);
        if (obj && withCompatibleProtocol(obj)) objectsSelectedExplicitly.push(obj);
    });

    return [...objectsSelectedExplicitly, ...objectsSelectedByTagsAndType]
        .filter((obj) => !selector.exclude?.includes(obj.uuid))
        .toSorted((obj1, obj2) => sortAlphabeticallyConsideringIndices(obj1.config.name, obj2.config.name))
        .map((obj) => obj.uuid);
}

function sortAlphabeticallyConsideringIndices(a: string, b: string): number {
    // Regular expression to match strings with brackets and numbers at the end
    const regex = /^(.*?)(\[(\d+)\])?$/;

    const matchA = a.match(regex);
    const matchB = b.match(regex);

    if (!matchA || !matchB) {
        return a.localeCompare(b); // Default to normal string comparison
    }

    const baseA = matchA[1] || '';
    const baseB = matchB[1] || '';
    const indexA = matchA[3];
    const indexB = matchB[3];

    if (baseA !== baseB) {
        return baseA.localeCompare(baseB); // Compare base strings
    }

    // If base strings are the same, compare indices numerically
    return (parseInt(indexA || '0') || 0) - (parseInt(indexB || '0') || 0);
}
