import { BehaviorSubject, Observable, Observer, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface Emission<T> {
    value: T;
    notify: boolean;
}

export class SilentBehaviorSubject<T> {
    private subject: BehaviorSubject<Emission<T>>;

    constructor(initialValue: T) {
        this.subject = new BehaviorSubject<Emission<T>>({ value: initialValue, notify: true });
    }

    get value(): T {
        return this.subject.value.value;
    }

    next(value: T): void {
        this.subject.next({ value, notify: true });
    }

    silentNext(value: T): void {
        this.subject.next({ value, notify: false });
    }

    asObservable(): Observable<T> {
        return this.subject.asObservable().pipe(
            filter((emission) => emission.notify),
            map((emission) => emission.value),
        );
    }

    subscribe(observer: Partial<Observer<T>>): Subscription {
        return this.asObservable().subscribe(observer);
    }

    complete(): void {
        this.subject.complete();
    }

    error(err: any): void {
        this.subject.error(err);
    }
}
