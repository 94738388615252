import { Subscription, distinctUntilChanged, filter, skip } from 'rxjs';
import { NotificationReceiver } from '../../notifications';
import { StateProvider } from '../../utils/state';
import { CertificateStatus } from './certificateStatusMonitor';
import { installCertificateCommandType } from './installCertificateCommand';

export function certificateViewNotifier(networkViewProvider: StateProvider<CertificateStatus>, notificationReceiver: NotificationReceiver): Subscription {
    return networkViewProvider
        .pipe(
            skip(1),
            distinctUntilChanged(),
            filter((status) => status === 'not-installed'),
        )
        .subscribe((_) => {
            notificationReceiver({
                level: 'warning',
                title: `CLU certificate not installed`,
                details: `In order to connect to CLU, you need to install certificate in your system`,
                commands: [
                    {
                        title: 'Install',
                        command: {
                            type: installCertificateCommandType,
                        },
                    },
                ],
            });
        });
}
