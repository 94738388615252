import { schema } from '@grenton/gm-common';
import { ObjectEventEmitter, MethodInvocation, Scheduler, ObjectStateHolder } from './common';
import { DimmableObjectEmulator } from './dimmable-object-emulator';

export class LedRGB extends DimmableObjectEmulator {
    //filename different from class name?
    constructor(state: ObjectStateHolder, emitter: ObjectEventEmitter, scheduler: Scheduler) {
        super(state, emitter, scheduler);
    }

    async execute(request: MethodInvocation): Promise<schema.PropertyValue> {
        const params = request.params || {};
        let rampTime;
        if (params['ramp'] !== undefined) rampTime = params['ramp'] as number;
        else rampTime = (this.state.get('rampTime') as number) || 0;

        switch (request.method) {
            case 'setValue': {
                this.set('Value', params['value'] as number, rampTime);
                break;
            }
            case 'setHue': {
                this.set('Hue', params['hue'] as number, rampTime);
                break;
            }
            case 'setSaturation': {
                this.set('Saturation', params['saturation'] as number, rampTime);
                break;
            }
            case 'setRedValue': {
                this.set('RedValue', params['redValue'] as number, rampTime);
                break;
            }
            case 'setGreenValue': {
                this.set('GreenValue', params['greenValue'] as number, rampTime);
                break;
            }
            case 'setBlueValue': {
                this.set('BlueValue', params['blueValue'] as number, rampTime);
                break;
            }
            case 'setWhiteValue': {
                this.set('WhiteValue', params['whiteValue'] as number, rampTime);
                break;
            }
            case 'setRGBvalue': {
                //todo typo?
                this.state.set('rgb', params['rgb']!);
                break;
            }
            case 'holdValue': {
                const value = this.state.get('value') as number;
                const step = this.determineStep();
                this.state.set('value', Math.min(Math.max(value + step, 0), 1));
                break;
            }
            case 'holdHue': {
                this.hold('Hue');
                break;
            }
            case 'switchOn': {
                this.switchOn('value', rampTime, params['time'] as number);
                break;
            }
            case 'switchOff': {
                this.switchOff('value', rampTime, params['time'] as number);
                break;
            }
            case 'switch': {
                this.switch('value', rampTime, params['time'] as number);
                break;
            }
            case 'setRampTime': {
                this.state.set('rampTime', params['rampTime']!);
                break;
            }
            case 'setMaxValue': {
                this.state.set('maxValue', params['maxValue']!);
                break;
            }
            case 'setMinValue': {
                this.state.set('minValue', params['minValue']!);
                break;
            }
            case 'holdRedValue': {
                this.hold('redValue');
                break;
            }
            case 'holdGreenValue': {
                this.hold('greenValue');
                break;
            }
            case 'holdBlueValue': {
                this.hold('blueValue');
                break;
            }
            case 'holdWhiteValue': {
                this.hold('whiteValue');
                break;
            }
            case 'switchR': {
                this.switch('redValue', rampTime, params['time'] as number);
                break;
            }
            case 'switchG': {
                this.switch('greenValue', rampTime, params['time'] as number);
                break;
            }
            case 'SwitchB': {
                this.switch('blueValue', rampTime, params['time'] as number);
                break;
            }
            case 'switchW': {
                this.switch('whiteValue', rampTime, params['time'] as number);
                break;
            }
        }
        return null;
    }
}
