import { Components, Theme } from '@mui/material';

export const MuiCheckbox: Components<Omit<Theme, 'components'>>['MuiCheckbox'] = {
    defaultProps: {
        // color: 'secondary',
    },
    styleOverrides: {
        //@ts-ignore
        root: ({ ownerState, theme }) => ({
            '& .MuiSvgIcon-root': {
                // color: ownerState.disabled ? 'inherit' : theme.palette.secondary.main,
            },
        }),
    },
};
