import { ProjectImpl, StateUpdater, Command, CommandHandler } from '@grenton/gm-logic';
import { I18nLang } from '@grenton/gm-common';

export class I18nLangEditCommand implements Command {
    readonly type = 'i18nLangEditCommand';

    constructor(readonly lang: I18nLang) {}
}

export class I18nLangEditCommandHandler implements CommandHandler<I18nLangEditCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd instanceof I18nLangEditCommand;
    }

    execute(cmd: I18nLangEditCommand) {
        return this.update((p) => p.editI18nLang(cmd.lang));
    }
}
