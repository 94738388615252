import { EventAvailable } from "@mui/icons-material"
import { ButtonBase, Box, Stack, Typography } from "@mui/material"
import { AppWidgetBox } from "./WidgetBox"
import { WidgetBasicObject } from "./basic"
import { prot } from "@grenton/gm-common"

export namespace WidgetScheduler {

    export const Type = "WidgetScheduler"

    export const Factory = WidgetBasicObject.factory(Type,{api:prot.Scheduler})

    export const Renderer = WidgetBasicObject.renderer(Widget)

    function Widget(props: WidgetBasicObject.BasicProps) {
        return <AppWidgetBox padding={0}>
            <ButtonBase sx={{ overflow: 'hidden', flexGrow: 2, pt: 1, pb: 1, pr: 2, pl: 2 }} onClick={() => { }}>
                <Box pr={2}><EventAvailable color="primary" /></Box>
                <Stack alignItems="start" direction="column" flexGrow={2} alignContent={"center"}>
                    <Typography variant="body1" textOverflow={"ellipsis"} flexGrow={2}>{props.object.config.name}</Typography>
                </Stack>
                <Box color="primary" pl={2}>0.65</Box>
            </ButtonBase>
        </AppWidgetBox>
    }

}
