import { Components, Theme } from '@mui/material';

export const MuiSwitch: Components<Omit<Theme, 'components'>>['MuiSwitch'] = {
    defaultProps: {
        color: 'secondary',
    },
    styleOverrides: {
        root: ({ theme }) => ({
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
            '&:active': {
                '& .MuiSwitch-thumb': {
                    width: 15,
                },
                '& .MuiSwitch-switchBase.Mui-checked': {
                    transform: 'translateX(9px)',
                },
            },
            '& .MuiSwitch-switchBase': {
                padding: 4,
                '&.Mui-checked': {
                    transform: 'translateX(12px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        opacity: 1,
                        //  backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
                    },
                },
                '&.Mui-disabled': {
                    '& + .MuiSwitch-track': {
                        opacity: 1,
                        //  backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
                    },
                    '& .MuiSwitch-thumb': {
                        opacity: 1,
                        backgroundColor: '#22222226',
                    },
                },
            },
            '& .MuiSwitch-thumb': {
                boxShadow: 'none',
                width: 8,
                height: 8,
                borderRadius: 4,
                transition: theme.transitions.create(['width'], {
                    duration: 200,
                }),
            },
            '& .MuiSwitch-track': {
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#e5e5e5' : '#e5e5e5',
                boxSizing: 'border-box',
            },
        }),
    },
};
