import { Block, Blocks, FieldCheckbox, FieldLabel, MenuOption } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, createDelegate, emptyOption, FieldDropdownModel, STATEMENT_BLOCK_STYLE } from './common';
import { FieldDropdownEx } from './field-dropdown-ex';
import { Lists } from '@grenton/gm-common';

export namespace TagBlock {
    export const Type = 'tag';

    export const TAGS = 'tags';

    export const CATEGORY_FIELD = 'cat';
    export const VALUE_FIELD = 'val';
    export const INCLUDE_CHILDREN_FIELD = '*';

    const CATEGORY_EMPTY_OPTION = emptyOption('{category}');
    const VALUE_EMPTY_OPTION = emptyOption('{value}');

    export class Delegate implements BlockDelegate {
        inputIsSelection = false;

        categoryDropdownModel: FieldDropdownModel;
        valuesDropdownModel: FieldDropdownModel;
        tree: { [category: string]: string[] } = {};

        constructor(private block: Block) {
            block.setOutput(true, 'string');
            this.categoryDropdownModel = new FieldDropdownModel(block, CATEGORY_FIELD, [CATEGORY_EMPTY_OPTION]);
            this.valuesDropdownModel = new FieldDropdownModel(block, VALUE_FIELD, [VALUE_EMPTY_OPTION]);

            block
                .appendDummyInput()
                .appendField(new FieldLabel('tag'))
                .appendField(new FieldDropdownEx(this.categoryDropdownModel.generator), CATEGORY_FIELD)
                .appendField(new FieldLabel(':'))
                .appendField(new FieldDropdownEx(this.valuesDropdownModel.generator), VALUE_FIELD)
                .appendField(new FieldCheckbox(false, undefined, { checkCharacter: '\u2731' }), INCLUDE_CHILDREN_FIELD);

            block.setInputsInline(false);
            block.setStyle(STATEMENT_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        updateValuesDropdown(selectedCategory?: string) {
            const values: MenuOption[] = (this.tree[selectedCategory || ''] || []).map((tag) => [tag, tag]);
            values.unshift(VALUE_EMPTY_OPTION);
            const selectedValue = this.block.getFieldValue(VALUE_FIELD);
            const hasSelected = Boolean(values.find((v) => v[1] === selectedValue));
            this.valuesDropdownModel.setOptions(values, hasSelected ? selectedValue : '');
        }

        onUpdate(context: BlockContext) {
            const selectedCategory = this.block.getFieldValue(CATEGORY_FIELD);
            this.tree = Lists.reduce(context.project.tags.categories, (cat) => [cat.name, cat.tags]);

            const categories: MenuOption[] = Object.keys(this.tree).map((cat) => [cat, cat]);
            categories.unshift(CATEGORY_EMPTY_OPTION);
            this.categoryDropdownModel.setOptions(categories, selectedCategory);
            this.updateValuesDropdown(selectedCategory);
        }
    }
}

Blocks[TagBlock.Type] = createDelegate((block) => new TagBlock.Delegate(block));

luaGenerator.forBlock[TagBlock.Type] = (block) => {
    //const _block = block as BlockWithDelegate<TagBlock.Delegate
    const code = `"${block.getFieldValue(TagBlock.CATEGORY_FIELD) || ''}:${block.getFieldValue(TagBlock.VALUE_FIELD) || ''}${
        block.getFieldValue(TagBlock.INCLUDE_CHILDREN_FIELD) ? '.*' : ''
    }"`;
    return [code, Order.ATOMIC];
};
