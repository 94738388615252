import { ProjectComponentType } from '@grenton/gm-common/src';
import { newComponentId } from '../id';
import { ProjectDeviceModuleInstanceImpl } from '../model/module';

export function createComponentInstanceImpl(cmpSpecRef: string, type: ProjectComponentType) {
    return ProjectDeviceModuleInstanceImpl.from({
        id: newComponentId(),
        ref: cmpSpecRef,
        type,
    });
}
