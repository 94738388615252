import { BlindsClosed, Blinds, StopOutlined, KeyboardArrowUp, KeyboardArrowDown, Tune } from "@mui/icons-material"
import { Box, Stack, Typography, Button, IconButton } from "@mui/material"
import { useState, useRef } from "react"
import { AppWidgetBox } from "./WidgetBox"
import { WidgetBasicObject } from "./basic"
import { prot } from "@grenton/gm-common"


function roundToRange(value: number, range: [number, number]) {
    if (value < range[0]) {
        return range[0];
    }
    if (value > range[1]) {
        return range[1];
    }
    return value;
}

export namespace WidgetRollerShutter {
    export const Type = "WidgetRollerShutter"

    export const Factory = WidgetBasicObject.factory(Type,{api:prot.RollerShutter})

    export const Renderer = WidgetBasicObject.renderer(Widget)

    function Widget(props:WidgetBasicObject.BasicProps) {
        const [value, setValue] = useState(0.5)
        const [dir, setDir] = useState<-1|0|1>(0)
    
        const timer = useRef<any>();
    
        function move(d:-1|1|0) { 
            setDir(d)
            clearInterval(timer.current)
            if (d !== 0) {
                let v = value
                timer.current = setInterval(()=>{
                    v = roundToRange(Math.round((v + d * 0.1)*100)/100, [0,1])
                    setValue(v)
                    if (v ===0 || v === 1) {
                        clearInterval(timer.current)
                        setDir(0)
                    }
                }, 1500)
            }
        }
    
        return <AppWidgetBox>
            <Box pr={2} pl={1}>{value === 0 ? <BlindsClosed color="primary"/> : <Blinds color="primary"/>}</Box>
                <Stack alignItems="start" direction="column" flexGrow={2} alignContent={"center"}>
                <Typography variant="body1" flexGrow={2}>{props.object.config.name}</Typography>
                <Typography variant="caption" fontWeight={800} flexGrow={2} color={value?"primary":"#aaa"}>{value === 0 ? 'Closed' : `Opened in ${value*100}%`}</Typography>
                </Stack>
                <Stack>
                    <Button sx={{p:0}} disabled={value===1} onClick={()=>move(dir === 1 ? 0 : 1)}>{dir === 1 ? <StopOutlined/> : <KeyboardArrowUp/>}</Button>
                    <Button sx={{p:0}} disabled={value===0} onClick={()=>move(dir === -1 ? 0 : -1)}>{dir === -1 ? <StopOutlined/> :<KeyboardArrowDown/>}</Button>
                </Stack>
                <IconButton color="primary"><Tune/></IconButton>
        </AppWidgetBox>
    }
    
}
