import { HWCluInfo } from '@grenton/gm-common';
import { LookupNetwork, NetworkView, formatNetworkUrlWithServiceIndex } from './networkView';
import { StateProvider, StateUpdater } from '../../utils/state';
import { systemDiscoveryResponseValidator } from '@grenton/contract';
import loglevel from 'loglevel';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { Subject, distinctUntilChanged, map } from 'rxjs';

const INTERVAL_ON_SUCCESS = 5000;
const INTERVAL_ON_ERROR = 3000;
const MAX_MDNS = 4;

// generally, if there's no CLU at pinged url, it should fail quickly
// but if there is, we want to give CLU time to respond
// btw - on MacOS with /etc/hosts mapping, it takes surprisingly long to resolve this address
const CLU_DISCOVERY_ENDPOINT_TIMEOUT = 10000;

type DiscoverResponse = {
    clus: HWCluInfo[];
};

/**
 * handles "CLU discovery"
 * discovery procedure:
 *
 * ping https://grenton{-index}.local addresses and keep increasing index (up to some predefined point) until we get a successful response.
 * by successful response we mean 200 OK and a json payload that corresponding to our discovery protocol.
 * on success, stop increasing the index and stick to this url, keep pinging it in regular longer intervals
 * to ensure it is still there. If it disappears, start the discovery procedure again.
 *
 * on error, use shorter interval to ensure lower latency in discovering new CLU.
 *
 * _ANY_ CLU we discover, should return info about all its network peers, regardless on the cluster they are in.
 * that's why we need to find just one.
 *
 * discovery endpoint does not require authentication as it does not return any sensitive data (CLU+list of modules)
 */
export class NetworkLookup {
    constructor(
        private updateNetworkView: StateUpdater<NetworkView>,
        private networkViewProvider: StateProvider<NetworkView>,
    ) {
        let pinger: NetworkPinger | null = null;

        this.networkViewProvider
            .pipe(
                //skip(1),
                map((nv) => (nv.selectedNetworkId ? nv.networks.find((network) => network.id === nv.selectedNetworkId) : null)),
                distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
            )
            .subscribe((network) => {
                pinger?.cancel();
                pinger = null;
                if (network) {
                    pinger = new NetworkPinger(network);
                    pinger.start((result) => {
                        if (result) {
                            this.updateNetworkView((value) => ({ ...value, discovery: { clus: result.response.clus, connectedClu: result.url } }));
                        } else {
                            this.updateNetworkView((value) => ({ ...value, discovery: { clus: [], connectedClu: null } }));
                        }
                    });
                }
            });
    }
}

type PingerNotification = { response: DiscoverResponse; url: string };

class NetworkPinger {
    private client: AxiosInstance;
    private started = false;
    private mdnsIndex = new MdnsIndexCounter();

    private discoveryResponse = new Subject<PingerNotification | null>();
    static responseValidator = systemDiscoveryResponseValidator();

    constructor(private network: LookupNetwork) {
        this.client = axios.create({
            headers: {
                Accept: 'application/json',
            },
            validateStatus: (status) => status === 200,
        });
    }

    start(callback: (value: PingerNotification | null) => void) {
        loglevel.debug(`discovery [${this.network.id}]: start`);
        this.started = true;
        this.discoveryResponse.subscribe(callback);

        // small delay to avoid delaying app launch
        setTimeout(() => this.ping(), 1000);
    }

    cancel() {
        loglevel.debug(`discovery [${this.network.id}]: cancel`);
        this.started = false;
    }

    private onError(url: string, msg: string) {
        if (!this.started) return;
        this.discoveryResponse.next(null);
        loglevel.debug(`discovery [${this.network.id}]: error fetching ${url}: ${msg}`);
        this.mdnsIndex.inc();
        setTimeout(() => this.ping(), INTERVAL_ON_ERROR);
    }

    private async ping() {
        const url = formatNetworkUrlWithServiceIndex(this.network.urlTemplate, this.mdnsIndex.format());
        try {
            loglevel.debug(`discovery [${this.network.id}]: fetching ${url}`);
            const discoveryResponse = await this.client.get<DiscoverResponse>(url, { timeout: CLU_DISCOVERY_ENDPOINT_TIMEOUT });
            if (!this.started) return;
            const validation = NetworkPinger.responseValidator(discoveryResponse.data);
            if (validation.ok) {
                setTimeout(() => this.ping(), INTERVAL_ON_SUCCESS);
                loglevel.debug(`discovery [${this.network.id}]: success`);
                this.discoveryResponse.next({ response: discoveryResponse.data, url });
            } else {
                throw new Error('CLU responded with invalid response format: ' + validation.errors?.join(', '));
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response) {
                    if (axiosError.response.status === 200 && error.message.includes('JSON')) {
                        this.onError(url, 'malformed JSON response from server');
                    }
                    this.onError(url, `HTTP error, status: ${axiosError.response.status}`);
                } else if (axiosError.request) {
                    this.onError(url, 'No response received from the server');
                } else {
                    this.onError(url, `Error setting up the request: ${axiosError.message}`);
                }
            } else if (error instanceof Error) {
                this.onError(url, error.message);
            } else {
                this.onError(url, 'Unknown error');
            }
        }
    }
}

class MdnsIndexCounter {
    private index = 1;

    inc() {
        this.index++;
        if (this.index > MAX_MDNS) {
            this.index = 0;
        }
    }

    reset() {
        this.index = 1;
    }

    get value() {
        return this.index;
    }

    format() {
        return this.index === 1 ? '' : `-${this.index}`;
    }
}
