import { Components, Theme } from '@mui/material';
import { grentonColors } from '../colors';
import { sizeRem, sizes } from '../size';

export const MuiTextField: Components<Omit<Theme, 'components'>>['MuiTextField'] = {
    defaultProps: {
        variant: 'outlined',
        size: 'small',
        color: 'secondary',
    },
    styleOverrides: {
        root: ({ theme }) => ({
            fontWeight: 600,
            fontSize: sizeRem(sizes.M),
            //color: theme.palette.secondary.main,
            '& .MuiStandardInput-root': {
                borderRadius: 0,
                // '&.Mui-hover': {
                //     borderColor: theme.palette.secondary.main,
                // },
                // '&.Mui-focused fieldset': {
                //     borderColor: theme.palette.secondary.main,
                // },
                '& .MuiOutlinedInput-input': {},
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: 0,
                fontWeight: 600,
                fontSize: sizeRem(sizes.M),
                // '&.Mui-hover': {
                //     borderColor: theme.palette.secondary.main,
                // },
                // '&.Mui-focused fieldset': {
                //     borderColor: theme.palette.secondary.main,
                // },
                '& .MuiOutlinedInput-input': {
                    height: 'auto' /* "auto" makes it a bit taller, but same height as MuiInput.select */,
                    // fontWeight: 600,
                    // fontSize: sizeRem(sizes.M),
                },
            },
            '& fieldset': {
                borderColor: grentonColors.grey[400],
            },
            '& .Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '& .Mui-disabled fieldset': {
                borderColor: grentonColors.grey[400],
            },

            // these are probably redundant and should be handled by MuiInputLabel
            // '& label.Mui-focused': {
            //     color: theme.palette.secondary.main,
            // },
            // '& label.Mui-disabled': {
            //     color: grentonColors.Text_Disabled,
            // },
            // '&:hover label:not(.Mui-error):not(.Mui-disabled)': {
            //     color: theme.palette.secondary.main,
            // }
        }),
    },
};
