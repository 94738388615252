import { useEffect, useState } from 'react';
import { BehaviorSubject, Observable } from 'rxjs';

function useSubscribeEffect<T>(subject: Observable<T>, setState: (value: T) => void) {
    useEffect(() => {
        const sub = subject.subscribe(setState);
        return () => sub.unsubscribe();
        // eslint-disable-next-line
    }, []);
}

export function useSubjectState<T>(subject: BehaviorSubject<T>): [value: T, setter: (value: T) => void] {
    const [getter, setter] = useState<T>(subject.value);
    useSubscribeEffect(subject, setter);
    return [getter, setter];
}

// TODO undefined->null ?
export function useObservable<T>(subject: Observable<T>): [value: T | undefined] {
    const [getter, setter] = useState<T>();
    useSubscribeEffect(subject, setter);
    return [getter];
}

export function useObservableOr<T>(subject: Observable<T>, defaultValue: T): [value: T] {
    const [getter, setter] = useState<T>(defaultValue);
    useSubscribeEffect(subject, setter);
    return [getter];
}
