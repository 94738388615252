import { Components, Theme } from '@mui/material';

export const MuiSlider: Components<Omit<Theme, 'components'>>['MuiSlider'] = {
    defaultProps: {},
    styleOverrides: {
        root: ({ theme }) => ({
            color: theme.palette.secondary.main,
            height: 8,
            '& .MuiSlider-track': {
                border: 'none',
            },
            '& .MuiSlider-thumb': {
                height: 24,
                width: 4,
                borderRadius: 0,
                border: '2px solid white',
                backgroundColor: 'currentColor',
                boxSizing: 'content-box',
                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                    boxShadow: 'none',
                },
                '&::before': {
                    display: 'none',
                },
            },
            '& .MuiSlider-valueLabel': {
                lineHeight: 1.2,
                fontSize: 12,
                background: 'unset',
                padding: '0 4px 0 4px',
                width: 24,
                height: 24,
                borderRadius: '1em',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&::before': { display: 'none' },
            },
        }),
    },
};
