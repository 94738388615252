import { Block, Blocks, FieldImage, MenuOption } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { schema } from '@grenton/gm-common';
import { BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, BlockWithDelegate, VALUE_BLOCK_STYLE, createDelegate, emptyOption, FieldDropdownModel, NOT_SELECTED } from './common';
import { FieldDropdownEx } from './field-dropdown-ex';

export namespace MethodParamBlock {
    export const Type = 'method_param';
    export const PARAM_FIELD = 'PARAM';

    const EMPTY_OPTION = emptyOption('{name}');

    export const SOURCE_OPTION: MenuOption = ['event_source', 'event_source'];

    export class Delegate implements BlockDelegate {
        paramName?: string;

        dropdownModel: FieldDropdownModel;

        constructor(private block: Block) {
            this.dropdownModel = new FieldDropdownModel(block, PARAM_FIELD, [EMPTY_OPTION, SOURCE_OPTION]);

            block
                .appendDummyInput()
                // .appendField("arg")
                .appendField(new FieldImage('icons/parameter.svg', 16, 16))
                .appendField(new FieldDropdownEx(this.dropdownModel.generator), PARAM_FIELD);

            // enable all connections, adjust later
            block.setOutput(true, null);

            block.setInputsInline(false);
            block.setStyle(VALUE_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        onUpdate(context: BlockContext) {
            let options: MenuOption[] = [EMPTY_OPTION];
            const selected = this.block.getFieldValue(PARAM_FIELD);
            this.paramName = selected;
            if (context.editedScript.scriptRef.type === 'method') {
                const method = context.editedScript.getMethod();
                options = method?.spec.params?.map((p: schema.MethodParam) => [p.id, p.id]) || [EMPTY_OPTION];
            } else if (context.editedScript.scriptRef.type === 'event') {
                if (selected === SOURCE_OPTION[1]) {
                    context.setReturnedType({ type: 'object', api: context.editedScript.entity.api.api });
                }
                options = [EMPTY_OPTION, SOURCE_OPTION];
            }
            this.dropdownModel.setOptions(options, selected);
        }
    }
}

Blocks[MethodParamBlock.Type] = createDelegate((block) => new MethodParamBlock.Delegate(block));

luaGenerator.forBlock[MethodParamBlock.Type] = (block) => {
    const _block = block as BlockWithDelegate<MethodParamBlock.Delegate>;
    const param = _block.delegate.paramName;
    return [param || NOT_SELECTED, Order.ATOMIC];
};
