import { Block, Blocks, FieldDropdown, FieldLabel, inputs } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { BlockDelegate, BlockWithDelegate, createDelegate, STATEMENT_BLOCK_STYLE } from './common';
import log from 'loglevel';
import { BlockContext } from '@grenton/gm-logic';

/*
  block loading sequence:
  unfortunately, there's no 'loaded' callback to be used to reconfigure connections.
  extra state is loaded prior to loading children (inputs) and sibling (next)

  const block = workspace.newBlock(state['type'], state['id']);
  block.setShadow(isShadow);
  loadCoords(block, state);
  loadAttributes(block, state);
  loadExtraState(block, state);
  tryToConnectParent(parentConnection, block, state);
  loadIcons(block, state);
  loadFields(block, state);
  loadInputBlocks(block, state);
  loadNextBlocks(block, state);
  initBlock(block, workspace.rendered);


*/

export namespace TestBlock {
    export const Type = 'test_block';

    export interface ExtraState {}

    const LABEL_FIELD = 'label_field';
    const SELECTOR_FIELD = 'selector_field';

    export class Delegate implements BlockDelegate<ExtraState> {
        extraState: ExtraState = {};

        constructor(private block: Block) {
            block.appendDummyInput().appendField(new FieldLabel('dummy + label field'), LABEL_FIELD);

            block
                .appendDummyInput()
                .appendField(new FieldLabel('dummy + dropdown field'))
                .appendField(new FieldDropdown(() => [['option1', 'val1']]), SELECTOR_FIELD);

            // enable all connections, adjust later
            block.setPreviousStatement(true, null);
            block.setNextStatement(true, null);
            block.setOutput(true, null);

            block.appendValueInput('value_input').setAlign(inputs.Align.RIGHT).appendField(new FieldLabel('value input'));

            block.appendStatementInput('statement_input').setAlign(inputs.Align.RIGHT).appendField(new FieldLabel('statement input'));

            block.setInputsInline(false);
            block.setStyle(STATEMENT_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
            log.info(`TEST BLOCK init id=${this.block.id} marker=?`);
        }

        // @ts-ignore
        onUpdate(context: BlockContext) {
            log.info(`TEST BLOCK onUpdate id=${this.block.id} marker=${this.block.isInsertionMarker()}`);
        }

        saveState() {
            log.info(`TEST BLOCK save extra state id=${this.block.id} marker=${this.block.isInsertionMarker()}`);
            return this.extraState;
        }

        loadState(state: ExtraState) {
            this.extraState = state;
            log.info(`TEST BLOCK load extra state id=${this.block.id} marker=${this.block.isInsertionMarker()}`);
        }
    }
}

Blocks[TestBlock.Type] = createDelegate((block) => new TestBlock.Delegate(block));

luaGenerator.forBlock[TestBlock.Type] = (block, _) => {
    //@ts-ignore
    const _block = block as BlockWithDelegate<TestBlock.Delegate>;
    const noOutput = true;
    const code = '-- test block';
    return noOutput ? code : [code, Order.ATOMIC];
};
