import { Project } from '@grenton/gm-common';
import { ProjectImpl, LoadNewProject, StateProvider, StateUpdater } from '@grenton/gm-logic';
import { ImportProjectImpl, ImportSingleCLU } from '@grenton/gm-logic/src/project/builders';

export type importProjectFromCLU = ReturnType<typeof importProjectFromCLU>;

export function importProjectFromCLU(
    projectProvider: StateProvider<ProjectImpl | null>,
    projectUpdater: StateUpdater<ProjectImpl>,
    projectLoader: LoadNewProject,
    importProjectImpl: ImportProjectImpl,
    importProjectImplWithSingleCLU: ImportSingleCLU,
) {
    /*
     * if current project is 'empty', just replace it with a full project imported from CLU
     * if current project has content, we generally have two options:
     * 1) import hardware only, this makes sense if we want "replace" existing CLU, and we already have all virtual modules in place
     * 2) import both virtual and hardware modules connected to this CLU. this is also a valid use-case, and it occurs when user
     *    starts creating a new system (bunch of separated CLUs that needs to be connected into a single cluster)
     */

    return function (cluID: string, project: Project, withModules: boolean) {
        if (projectProvider.value === null || projectProvider.value.empty) {
            projectLoader(importProjectImpl(project));
        } else {
            projectUpdater(async (p) => {
                return p.merge(await importProjectImplWithSingleCLU(project, cluID, withModules));
            });
        }
    };
}
