import { Components, Theme } from '@mui/material';
import { grentonColors } from '../colors';

export const MuiCard: Components<Omit<Theme, 'components'>>['MuiCard'] = {
    defaultProps: {
        variant: 'outlined',
    },
    styleOverrides: {
        root: () => ({
            borderRadius: 0,
            borderColor: grentonColors.Line_Menu,
        }),
    },
};
