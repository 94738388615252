import { MethodInvocation, ObjectStateHolder, Scheduler } from './modules/common';
import { ObjectApi, fabric, schema } from '@grenton/gm-common';
import log from 'loglevel';
import { PartialObject } from '@grenton/gm-logic';
import { SystemClient } from '@grenton/gm-logic';
import { Subscriptions } from '@grenton/gm-common';

interface CandidateObject extends PartialObject {
    readonly config: fabric.ObjectConfig;
    readonly api: ObjectApi;
}

function isCandidate(o?: PartialObject) {
    return Boolean(o && o.config && o.api && o.logic && o.config.impl.type === 'script');
}

export class ControllerEngineEmulator {
    private emulatedObjects: {
        [uuid: string]: {
            api: ObjectApi;
            scheduler: Scheduler;
            state: ObjectStateHolder;
        };
    } = {};

    // @ts-ignore
    private scheduler: Scheduler;

    private readonly subs = new Subscriptions();
    private fabricPublisher?: fabric.FabricPublisher;

    constructor() {
        this.scheduler = new Scheduler();
    }

    connect(fabricObservable: fabric.FabricObservable, fabricPublisher: fabric.FabricPublisher, fabricRpcServer: fabric.FabricRpcServer) {
        this.subs.dispose();
        const systemClient = new SystemClient<CandidateObject>(isCandidate, {
            logic: true,
            state: false,
        });
        this.fabricPublisher = fabricPublisher;
        this.subs.put(fabricObservable.subscribe((msg) => systemClient.process(msg)));

        const handleObjectUpdate = (data: { uuid: string; object?: PartialObject }) => {
            const object = data.object as CandidateObject;
            if (!object) {
                this.remove(data.uuid);
                fabricRpcServer.removeExecutor(data.uuid);
            } else {
                const r = this.onChange(object);
                const executor = r?.executor;
                if (executor) {
                    fabricRpcServer.registerExecutor(object.uuid, (request: fabric.JsonRpcRequest) => {
                        return (
                            executor(request)
                                .then((result) =>
                                    request.id
                                        ? {
                                              id: request.id,
                                              result,
                                          }
                                        : undefined,
                                )

                                // TODO here, besides runtime errors, we can expect some errors specific to invocation,
                                // e.g. invalid parameters etc.
                                // executor has to throw such error and we need to distinguish between runtime/invocation here
                                .catch((e) =>
                                    request.id
                                        ? {
                                              id: request.id,
                                              error: {
                                                  code: 0,
                                                  message: e.toString(),
                                              },
                                          }
                                        : undefined,
                                )
                        );
                    });
                }
            }
        };

        systemClient.system.objects.forEach((object) => handleObjectUpdate({ uuid: object.uuid, object }));

        this.subs.put(
            systemClient.events.subscribe((e) => {
                switch (e.type) {
                    //TODO replace with logic-changed?
                    case 'object-added':
                    case 'object-removed': {
                        handleObjectUpdate(e.data);
                        break;
                    }
                }
            }),
        );
    }

    private onChange(object: CandidateObject):
        | {
              executor?: (invocation: MethodInvocation) => Promise<schema.PropertyValue>;
          }
        | undefined {
        // we may have it or not

        if (!this.emulatedObjects[object.uuid]) {
            const stateHolder = new ObjectStateHolder();
            // fill based on API
            Object.values(object.api.flat.features).forEach((f) => {
                stateHolder.set(f.id, f.default || null);
            });
            // defer to avoid going into recursive loop!
            // TODO move this to fabricPublisher?
            setTimeout(() => {
                stateHolder.objectState.subscribe((state) => {
                    this.fabricPublisher?.publish({ type: 'state', data: { uuid: object.uuid, state } });
                });
            });
            const scheduler = new Scheduler();
            this.emulatedObjects[object.uuid] = {
                api: object.api,
                state: stateHolder,
                //emulator,
                scheduler,
            };
        }

        // TODO sync state
        if (object.state) {
            //this.emulatedObjects[object.uuid].state.setQuietly(object.state)
        }

        let executor: ((i: MethodInvocation) => Promise<schema.PropertyValue>) | undefined = undefined;
        if (object.logic) {
            executor = async (i: MethodInvocation) => {
                //log.info(`TODO controller ${object.uuid} received method invocation`,i)

                // for testing and development we allow to control state from outside
                if (i.method === '_SetState' && i.params?.state) {
                    const update = i.params.state as any;
                    const emulated = this.emulatedObjects[object.uuid];
                    if (emulated) {
                        Object.values(object.api.flat.features)
                            .map((f) => [f.id, update[f.id]])
                            .filter((kv) => kv[1] !== undefined)
                            .forEach((kv) => emulated.state.set(kv[0], kv[1]));
                    }
                    return null;
                }

                const method = object.api.flat.methods[i.method];
                if (!method) {
                    throw new Error(`no such method ${i.method}`);
                }
                const methodInstance = object.logic?.scripts['self.' + method.id];
                if (!methodInstance) {
                    throw new Error(`no such methodInstance ${method.id}`);
                }
                if (methodInstance.type === 'actions') {
                    log.info(methodInstance.script);
                } else {
                    throw new Error('currently only actions can be executed');
                }
                return null;
            };
        }

        return { executor };
    }

    private remove(uuid: string) {
        const o = this.emulatedObjects[uuid];
        if (o) {
            o.scheduler.cancel();
            delete this.emulatedObjects[uuid];
        }
    }
}
