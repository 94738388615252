import { Components, Theme } from '@mui/material';
import { grentonColors } from '../colors';
import { sizeRem, sizes } from '../size';

export const MuiSelect: Components<Omit<Theme, 'components'>>['MuiSelect'] = {
    defaultProps: {
        variant: 'outlined',
        size: 'small',
        color: 'secondary',
    },
    styleOverrides: {
        root: ({ theme }) => ({
            borderRadius: 0,
            fontSize: sizeRem(sizes.M),
            fontWeight: 600,
            '& .MuiFormLabel-root': {
                fontWeight: 600,
                fontSize: sizeRem(sizes.M),
            },
            '& label.MuiInputLabel-shrink': {
                fontWeight: 600,
            },
            // "label" element is used also as a placeholder
            '& label:not(.MuiInputLabel-shrink)': {
                //lineHeight: '1.2em',
                fontWeight: 600,
                color: grentonColors.Font_Placeholder,
            },
            '& .Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '& fieldset': {
                borderColor: grentonColors.grey[400],
            },
            '&.Mui-focused label': {
                color: theme.palette.secondary.main,
            },
            '&:hover label:not(.Mui-error)': {
                color: theme.palette.secondary.main,
            },
        }),
    },
};
