import { Components, Theme } from '@mui/material';
import { icons } from '../size';

export const MuiIconButton: Components<Omit<Theme, 'components'>>['MuiIconButton'] = {
    defaultProps: {
        color: 'secondary',
    },
    styleOverrides: {
        root: {
            '&.MuiIconButton-sizeSmall': {
                fontSize: icons.small,
            },
            '&.MuiIconButton-sizeMedium': {
                fontSize: icons.medium,
            },
            '&.MuiIconButton-sizeLarge': {
                fontSize: icons.large,
            },
        },
    },
};
