import { Component } from '@grenton/gm-common';
import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils/state';
import { ProjectImpl } from '../model';
import { createObjectsFromVirtualComponent } from '../builders';

export class AddVirtualObjectCommand implements Command {
    readonly type = 'AddVirtualObjectCommand';
    constructor(
        readonly data: {
            tag?: string;
            component: Component;
        },
    ) {}
}

export class AddVirtualObjectCommandHandler implements CommandHandler<AddVirtualObjectCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'AddVirtualObjectCommand';
    }

    execute(cmd: AddVirtualObjectCommand) {
        return this.update((project) => {
            return project.withComponentEntry(
                createObjectsFromVirtualComponent(
                    project.firmware,
                    cmd.data.component.id,
                    null,
                    (label) => project.hasObjectWithLabel(label),
                    true,
                    cmd.data.tag ? [cmd.data.tag] : [],
                ),
            );
        });
    }
}
