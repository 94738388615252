import { HWCluShim, ObjectApi, Lists, Component } from '@grenton/gm-common';
import { HWCluImpl } from '../project';
import { createModuleImpl } from './createModuleFromShim';

export function createCluImpl(
    clu: HWCluShim,
    resolverModuleType: (type: string) => Component | undefined,
    resolverObjectApi: (api: { ref: string }) => ObjectApi | undefined,
    imported: boolean,
    online = false,
) {
    const modules = clu.modules.map((m) => createModuleImpl(m, resolverModuleType, resolverObjectApi, online)).filter((m) => Boolean(m));

    return new HWCluImpl({
        id: clu.id,
        name: clu.name,
        imported,
        online,
        modules: Lists.reduce(modules, (m) => [m!.id, m!]),
    });
}
