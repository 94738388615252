import { Components, Theme } from '@mui/material';
import { spacing } from '../size';

export const MuiDialogActions: Components<Omit<Theme, 'components'>>['MuiDialogActions'] = {
    defaultProps: {},
    styleOverrides: {
        //@ts-ignore
        root: ({ _ownerState, _theme }) => ({
            paddingLeft: spacing.XS2,
            paddingRight: spacing.XS2,
            paddingTop: spacing.S,
            paddingBottom: spacing.XS2,
        }),
    },
};
