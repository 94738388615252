import { IdMap, Lists, ProjectPermissionsSet, ProjectRole, ProjectSecurity, ProjectUser } from '@grenton/gm-common';

export class ProjectSecurityImpl {
    static from(security: ProjectSecurity) {
        return new ProjectSecurityImpl({
            users: Lists.reduce(security.users, (u) => [u.name, ProjectUserImpl.from(u)]),
            roles: Lists.reduce(security.roles, (r) => [r.rolename, ProjectRoleImpl.from(r)]),
        });
    }

    constructor(private data: { users: IdMap<ProjectUserImpl>; roles: IdMap<ProjectRoleImpl> }) {}

    withUser(user: ProjectUserImpl) {
        return new ProjectSecurityImpl({ ...this.data, users: { ...this.data.users, [user.name]: user } });
    }

    withoutUser(name: string) {
        const users = { ...this.data.users };
        delete users[name];
        return new ProjectSecurityImpl({ ...this.data, users });
    }

    getUserByName(name: string) {
        return this.data.users[name];
    }

    getRoleByName(name: string) {
        return this.data.roles[name];
    }

    get users() {
        return Object.values(this.data.users);
    }

    get roles() {
        return Object.values(this.data.roles);
    }

    export(): ProjectSecurity {
        return {
            users: Object.values(this.data.users).map((u) => u.export()),
            roles: Object.values(this.data.roles).map((r) => r.export()),
        };
    }
}

export class ProjectUserImpl {
    static from(user: ProjectUser) {
        return new ProjectUserImpl(user);
    }

    constructor(private data: { name: string; pwd: string; disabled: boolean; roles: string[] }) {}

    get name() {
        return this.data.name;
    }

    get pwd() {
        return this.data.pwd;
    }

    get disabled() {
        return this.data.disabled;
    }

    get roles() {
        return this.data.roles;
    }

    withDisabled(disabled: boolean) {
        return new ProjectUserImpl({ ...this.data, disabled });
    }

    withPwd(pwd: string) {
        return new ProjectUserImpl({ ...this.data, pwd });
    }

    export(): ProjectUser {
        return {
            name: this.data.name,
            pwd: this.data.pwd,
            disabled: this.data.disabled,
            roles: this.data.roles,
        };
    }
}

export const TEMPORARY_DEFAULT_USER_ROLES = ['admin'];

export class ProjectRoleImpl {
    static from(role: ProjectRole) {
        return new ProjectRoleImpl(role);
    }

    constructor(private data: { rolename: string; accessLevel: ProjectPermissionsSet }) {}

    export(): ProjectRole {
        return {
            rolename: this.data.rolename,
            accessLevel: this.data.accessLevel,
        };
    }
}
