import { createOwnerOutletInAnonymousController } from '../logic';
import { ANONYMOUS_CONTROLLER_SUFFIX } from '../naming';
import { isApiRef } from '../objects/api';
import { ProjectObject } from './model';

export function createAnonymousCtrl(obj: ProjectObject): ProjectObject {
    const objApi = obj.api;
    if (!isApiRef(objApi)) throw new Error(`cannot create anonymous ctrl for object with inlined API`);
    const anonymousCtrl = createOwnerOutletInAnonymousController(obj.id, objApi.ref);
    const ctrlUUID = `${obj.id}${ANONYMOUS_CONTROLLER_SUFFIX}`;
    return {
        id: ctrlUUID,
        label: ctrlUUID,
        impl: {
            type: 'script',
            componentRef: {
                componentId: obj.impl.componentRef.componentId,
            },
        },
        api: {
            type: 'spec',
            spec: {
                outlets: [anonymousCtrl.outlet],
            },
        },
        init: {
            outlets: [anonymousCtrl.instance],
        },
    };
}
