import { Command } from '../../dispatcher';
import { LoadNewProject } from '../loadNewProject';
import { ImportProjectImpl } from '../builders';
import { ProjectRepository } from '../project-repository';

export class LoadStoredProjectCommand implements Command {
    readonly type = 'LoadStoredProjectCommand';
    constructor(public projectId: string) {}
}

export class LoadStoredProjectCommandHandler {
    constructor(
        private projectRepo: ProjectRepository,
        private projectLoader: LoadNewProject,
        private importProjectImpl: ImportProjectImpl,
    ) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'LoadStoredProjectCommand';
    }

    async execute(cmd: LoadStoredProjectCommand) {
        const json = await this.projectRepo.loadConfiguration(cmd.projectId);
        this.projectLoader(this.importProjectImpl(json));
    }
}
