export type FieldError = string;

export class ValidatorResult {
    static readonly validResult = new ValidatorResult({});

    constructor(public readonly errors: { [field: string]: FieldError[] }) {}

    get valid() {
        return Object.keys(this.errors).length === 0;
    }

    field(field: string): FieldError[] {
        return this.errors[field] || [];
    }

    withError(field: string, error: FieldError) {
        const fieldErrors = this.errors[field] || [];
        fieldErrors.push(error);
        return new ValidatorResult({ ...this.errors, [field]: fieldErrors });
    }
}
