import { Components, Theme } from '@mui/material';
import { grentonColors } from '../colors';
import { getMatchingPaletteColor } from '../palette';
import { icons, px, sizeRem, sizes, spacing } from '../size';

export const MuiToggleButton: Components<Omit<Theme, 'components'>>['MuiToggleButton'] = {
    styleOverrides: {
        root: ({ ownerState, theme }) => {
            const color = getMatchingPaletteColor(theme.palette, ownerState.color);
            return {
                boxShadow: 'none',
                borderRadius: '2em',
                textTransform: 'none',
                color: grentonColors.Font_Black,
                fontWeight: theme.typography.fontWeightRegular,
                fontSize: sizeRem(sizes.M),

                padding: px(spacing.XS2),

                '& .MuiSvgIcon-root': {
                    // it is better to use rem to achieve consistent size across Button/ToggleButton/IconButton and other icons!
                    fontSize: icons.medium,
                },
                '&.MuiToggleButton-sizeSmall': {
                    padding: px(spacing.XS3),
                    '& .MuiSvgIcon-root': {
                        margin: '0.2em',
                        // it is better to use rem to achieve consistent size across Button/ToggleButton/IconButton and other icons!
                        fontSize: icons.small,
                    },
                },
                '&.MuiToggleButtonGroup-groupedHorizontal': {
                    padding: px(spacing.XS2, spacing.XS, spacing.XS2, spacing.XS),
                },
                '&.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButton-sizeSmall': {
                    padding: px(spacing.XS3, spacing.XS2, spacing.XS3, spacing.XS2),
                },
                '&.Mui-selected': {
                    backgroundColor: color.main,
                    color: color.contrastText,
                },
                '&.Mui-selected:hover': {
                    backgroundColor: color.dark,
                },
                '&.MuiToggleButton-greyish': {
                    borderColor: grentonColors.grey[300],
                },
                '&.MuiToggleButton-white': {
                    borderColor: grentonColors.grey[300],
                },
            };
        },
    },
};
