import { Action, ActionItem, ActionOutput, ActionScript, emptyScript, ProjectItemPath, Script, ScriptFormat } from '@grenton/gm-common';
import log from 'loglevel';

export class ActionScriptImpl {
    static empty() {
        return new ActionScriptImpl({ items: [] });
    }

    static from(script: ActionScript) {
        return new ActionScriptImpl({ items: script.items.map((item) => ActionItemImpl.from(item)) });
    }

    constructor(private data: { items: ActionItemImpl[] }) {}

    get items() {
        return this.data.items;
    }

    withItem(item: ActionItemImpl) {
        return new ActionScriptImpl({ ...this.data, items: [...this.data.items, item] });
    }

    withItemUpdate(index: number, fn: (item: ActionItemImpl) => ActionItemImpl) {
        const item = this.data.items[index];
        if (item) {
            const items = [...this.data.items];
            items[index] = fn(item);
            return new ActionScriptImpl({ ...this.data, items });
        } else {
            return this;
        }
    }

    withoutItem(index: number) {
        const items = [...this.data.items];
        items.splice(index, 1);
        return new ActionScriptImpl({ ...this.data, items });
    }

    export(): ActionScript {
        return { items: this.data.items.map((item) => item.export()) };
    }
}

export class ActionItemImpl {
    static from(item: ActionItem) {
        return new ActionItemImpl({ target: item.target, selectedType: item.action.type, output: item.output, actions: { [item.action.type]: item.action } });
    }

    static fromAction(target: ProjectItemPath, action: Action) {
        return new ActionItemImpl({ target, selectedType: action.type, output: { calls: [] }, actions: { [action.type]: action } });
    }

    constructor(
        private data: {
            target: ProjectItemPath;
            selectedType: string;
            output: ActionOutput;
            actions: { [type: string]: Action };
        },
    ) {}

    get target() {
        return this.data.target;
    }

    get action(): Action {
        return this.data.actions[this.data.selectedType]!;
    }

    get selectedType() {
        return this.data.selectedType;
    }

    get output() {
        return this.data.output;
    }

    withSelectedType(type: string, defaults: any) {
        if (this.selectedType === type) return this;
        return this.withAction({ type, model: this.data.actions[type]?.model || defaults });
    }

    withAction(action: Action) {
        return new ActionItemImpl({ ...this.data, selectedType: action.type, actions: { ...this.data.actions, [action.type]: action } });
    }

    withOutput(output: ActionOutput) {
        return new ActionItemImpl({ ...this.data, output });
    }

    export(): ActionItem {
        return {
            target: this.target,
            action: this.action,
            output: this.output,
        };
    }
}

export class ScriptImpl {
    static empty(): ScriptImpl {
        return ScriptImpl.from(emptyScript());
    }

    static from(script: Script) {
        const steps = script.steps;
        if (steps.length === 0) {
            return new ScriptImpl({ format: 'actions' });
        } else {
            if (steps.length > 1) {
                log.warn('ScriptImpl.from: script with multiple steps is not supported');
            }
            return new ScriptImpl({
                format: steps[0]!.format,
                code: steps[0]!.code,
                visual: steps[0]!.visual,
                actions: steps[0]!.actions ? ActionScriptImpl.from(steps[0]!.actions) : undefined,
            });
        }
    }

    constructor(private data: { format: ScriptFormat; visual?: string; code?: string; actions?: ActionScriptImpl }) {
        if (!data) throw new Error('data required');
    }

    // for now we support one-step scripts only!
    export(): Script {
        return {
            steps: [
                {
                    format: this.data.format,
                    visual: this.data.visual,
                    code: this.data.code,
                    actions: this.data.actions?.export(),
                },
            ],
        };
    }

    get code() {
        return this.data.code;
    }

    get visual() {
        return this.data.visual;
    }

    get actions() {
        return this.data.actions;
    }

    get format() {
        return this.data.format;
    }

    get hasContent() {
        return (
            (this.data.format === 'visual' && !!this.data.visual) ||
            (this.data.format === 'lua' && this.data.code !== '') ||
            (this.data.format === 'actions' && !!this.data.actions?.items?.length)
        );
    }

    withVisual(visual: string | null) {
        return new ScriptImpl({ ...this.data, visual: visual || undefined });
    }

    withActions(actions: ActionScriptImpl | null) {
        return new ScriptImpl({ ...this.data, actions: actions || undefined });
    }

    withCode(code: string | null) {
        // do not change format here; code is filled also for visual and action formats
        return new ScriptImpl({ ...this.data, code: code || undefined });
    }

    withEditMode(editMode: ScriptFormat) {
        return new ScriptImpl({ ...this.data, format: editMode });
    }
}
