import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils/state';
import { ProjectImpl } from '../model';

export interface RemoveTagForm {
    categoryName: string;
    tag: string;
}

export class RemoveTagCommand implements Command {
    readonly type = 'RemoveTagCommand';
    constructor(readonly data: { form: RemoveTagForm }) {}
}

export class RemoveTagCommandHandler implements CommandHandler<RemoveTagCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'RemoveTagCommand';
    }

    execute(cmd: RemoveTagCommand) {
        const { tag, categoryName } = cmd?.data?.form;
        return this.update((project) => project.withOutTag(tag, categoryName));
    }
}
