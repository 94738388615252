import { Subscription } from 'rxjs';

export class Subscriptions {
    private subs: Array<Function | Subscription> = [];

    put(sub: Function | Subscription) {
        this.subs.push(sub);
        return this;
    }

    dispose() {
        this.subs.forEach((sub) => {
            if (typeof sub === 'function') {
                sub();
            } else {
                sub.unsubscribe();
            }
        });
        this.subs = [];
    }
}
