import { Project } from '@grenton/gm-common/src';
import { ProjectValidationWarning, ProjectValidationError } from './types';
import { ProjectFirmwareImpl } from '../model';

export type ValidationContext = {
    firmware?: ProjectFirmwareImpl;
};

export type PartialValidator = (project: Project, context: ValidationContext) => PartialValidationResult;

export type PartialValidationResult = {
    warnings?: ProjectValidationWarning[];
    errors?: ProjectValidationError[];
    fatal?: boolean;
};

export class ResultBuilder {
    readonly warnings: ProjectValidationWarning[] = [];
    readonly errors: ProjectValidationError[] = [];
    private _fatal = false;

    get fatal() {
        return this._fatal;
    }

    error(type: ProjectValidationError['type'], message: string, fatal?: boolean) {
        if (fatal) this._fatal = true;
        this.errors.push({ type, message });
        return this;
    }

    fatalError(type: ProjectValidationError['type'], message: string) {
        return this.error(type, message, true);
    }

    warning(type: ProjectValidationWarning['type'], message: string) {
        this.warnings.push({ type, message });
        return this;
    }
}

export const NO_ERRORS = Object.freeze({ errors: [] });
