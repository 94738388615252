import { ObjectEventEmitter, ObjectEmulator, MethodInvocation, Scheduler, ObjectStateHolder } from './common';
import { fabric, schema } from '@grenton/gm-common';

export class DigitalINEmulator implements ObjectEmulator {
    // @ts-ignore
    constructor(
        private state: ObjectStateHolder,
        // @ts-ignore
        private emitter: ObjectEventEmitter,
        // @ts-ignore
        private scheduler: Scheduler,
    ) {}

    // @ts-ignore
    onStateChange(curr: fabric.ObjectState, prev?: fabric.ObjectState) {
        // state of input is usually changing due to external factors, not as a result of calling a method (internal factor)
        // so as events.
        // here all events are generated based on changes of Value feature in time
        // for emulation purposes, all except OnValueChange could be triggered manually, but OnValueChange should
        // be accompanied by Value change - because subscriber will attempt to read it.
        // it may be a common pattern in emulation - need of forcing a particular state when triggering an event.
        // we may allow user either to manipulate state and try to generate a proper event (tricky, unreliable, but close to real thing)
        // or combine triggering event with forcing a state (good ux but may not be enough to simulate all scenarios)
        /*
            { name: 'OnValueChange' },
            { name: 'OnClick', primary: true },
            { name: 'OnLongPress' },
            { name: 'OnShortPress' },
        */
    }

    async execute(request: MethodInvocation): Promise<schema.PropertyValue> {
        const params = request.params || {};
        switch (request.method) {
            case 'setInertion':
                this.state.set('inertion', params['inertion']!);
                break;
        }
        return null;
    }
}
