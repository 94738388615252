import { Project, ProjectUserCredentials } from '@grenton/gm-common';
import axios from 'axios';
import { ProjectValidator } from '../../project';

export type ProjectImporter = ReturnType<typeof projectImporter>;

export function projectImporter(projectValidator: ProjectValidator) {
    return async function (cluURL: string, credentials: ProjectUserCredentials): Promise<Project> {
        const project = await fetchConfiguration(`${cluURL}/configuration`, credentials);
        const validationResult = projectValidator(project);
        if (validationResult.errors?.length) {
            throw new Error(`invalid configuration: ${validationResult.errors.map((e) => e.message).join(', ')}`);
        } else {
            return project;
        }
    };
}

async function fetchConfiguration(url: string, _: ProjectUserCredentials): Promise<Project> {
    const response = await axios.get<Project>(url);
    return response.data;
}
