import { HWCluInfo } from '@grenton/gm-common';

export type NetworkView = {
    discovery: {
        connectedClu: string | null;
        clus: HWCluInfo[];
    };
    networks: LookupNetwork[];
    selectedNetworkId: string | null;
};

export const DISCOVERY_EMPTY = {
    connectedClu: null,
    clus: [],
};

/**
 * GM besides default, local network, can also connect to remote "networks" (simulators)
 */
export type LookupNetwork = {
    id: string;

    /*
     *  url "template" including {index} placeholder to be replaced with MDNS service index:
     *  https://grenton{index}.local
     *  http://127.0.0.1/grenton{index}:3001
     */
    urlTemplate: string;

    /*
     * If the URL template contains {index} placeholder, it means that the network is multi-node
     */
    multi?: boolean;
};

export function parseNetworkDef(envValue: string): LookupNetwork[] {
    // Remove quotes if present and split by comma
    const pairs = envValue.replace(/^"(.*)"$/, '$1').split(',');

    return pairs.map((pair) => {
        // Find the position of the first '='
        const firstEqualIndex = pair.indexOf('=');
        if (firstEqualIndex === -1) {
            throw new Error(`Invalid network format: ${pair}`);
        }

        const id = pair.slice(0, firstEqualIndex).trim();
        const urlTemplate = pair.slice(firstEqualIndex + 1).trim();

        return {
            id,
            urlTemplate,
            multi: urlTemplate.includes('{index}'),
        };
    });
}

export function formatNetworkUrlWithServiceIndex(urlTemplate: string, index: string) {
    return urlTemplate.replace(/{index}/g, index.toString());
}
