import { Block, Connection, Events, FieldDropdown, MenuOption } from 'blockly';
import { ObjectID, schema, REF_SELF } from '@grenton/gm-common';
import { ProjectImpl } from '@grenton/gm-logic';
import { ObjectScriptRef } from '@grenton/gm-logic';
import { BlockContext } from '@grenton/gm-logic';

/**
 * style of blocks is used by theme to apply colors
 */
export const STATEMENT_BLOCK_STYLE = 'grenton_statement_blocks';
export const VALUE_BLOCK_STYLE = 'grenton_value_blocks';

//there are not constants - error in typings
// Blockly.HSV_SATURATION = 0.6; // 0.79 for Gren color // 0 (inclusive) to 1 (exclusive), defaulting to 0.45
// Blockly.HSV_VALUE = 0.8; // 0.89 gor Grne color // 0 (inclusive) to 1 (exclusive), defaulting to 0.65

// TODO simply expose 'EditedScript' here
export interface WorkspaceData {
    getProject(): ProjectImpl;
    readonly editedScriptRef: ObjectScriptRef;
}

export const TYPE_ARRAY = 'Array';
export const SELECTION_OUTPUT_TYPE = 'object-sel';
export const SELECTION_OUTPUT_TYPES = [SELECTION_OUTPUT_TYPE, TYPE_ARRAY];

export const NOT_SELECTED = '{not_selected}';
export const SELF_MARKER = '{self}';
export const SELF = REF_SELF;
export const PARENT = 'parent'; // parent entity (e.g. smart panel)

/**
 * for more complex blocks, this is the best way to encapsulate their logic
 */
export interface BlockDelegate<S = void> {
    loadState?(state: S): void;
    saveState?(): S;

    // this is an artificial event, triggered when a block and its children
    // are fully initialized / rendered.
    // only here it is safe to re-shape a block (e.g. modify output and inputs)
    //onCreate(workspaceData:WorkspaceData) : void

    onUpdate(context: BlockContext): void;
}

export interface BlockWithDelegate<D> extends Block {
    readonly delegate: D;
}

export function createDelegate<T>(creator: (block: Block) => BlockDelegate<T>) {
    return {
        init() {
            this.delegate = creator(this);
        },
        // TODO here we EXPECT EventContext - so why analyzer is using BlockContext
        onupdate(context: [BlockContext]) {
            //TODO hack
            this.delegate.onUpdate(context[0]);
        },
        saveExtraState() {
            if (this.delegate.saveState) {
                return this.delegate.saveState();
            }
        },

        // we need to recreate here all inputs, otherwise drag&drop will fail!
        // in extra state we should store only entityRef+methodId
        // the rest should be populated dynamically
        loadExtraState(state: any) {
            if (this.delegate.loadState) {
                Events.disable();
                try {
                    this.delegate.loadState(state as T);
                } finally {
                    Events.enable();
                }
            }
        },
    } as any;
}

export function safeDisconnect(c: Connection | null | undefined) {
    if (c && c.isConnected()) {
        c.disconnect();
        return true;
    } else {
        return false;
    }
}

export function uuidOrSelf(entityRef?: ObjectID | 'self', editedEntity?: ObjectID) {
    return entityRef === 'self' || editedEntity === entityRef ? 'self' : entityRef || '';
}

export function emptyOption(emptyOptionLabel: string): MenuOption {
    return [emptyOptionLabel, ''];
}

export class FieldDropdownModel {
    constructor(
        private block: Block,
        private fieldName: string,
        private options: MenuOption[] = [['', '']],
    ) {}

    get value() {
        return this.block.getFieldValue(this.fieldName) as string;
    }

    get generator() {
        return () => this.options;
    }

    setOptions(options: MenuOption[], selected?: string, defaultIfMissing?: string) {
        this.options = options;
        (this.block.getField(this.fieldName) as FieldDropdown).getOptions(false);

        // force setting a new value
        if (selected !== undefined) {
            // if selected option is missing, use provided default
            if (defaultIfMissing !== undefined && !this.options.find((o) => o[1] === selected)) {
                selected = defaultIfMissing;
            }

            this.block.setFieldValue(selected, this.fieldName);
            // re-render is required if new and old values are the same, but option labels have changed
            this.block.getField(this.fieldName)?.forceRerender();
        } else {
            //this.block.getField(this.fieldName)?.forceRerender()
        }
    }
}

export function lua_invokeObjectMethod(target: string, method: schema.Method, getArgValue?: (param: schema.MethodParam, index: number) => string | undefined) {
    const args: string[] = [];

    // if there's no value for the parameter, use nil for non-optional ones
    method.params?.forEach((param, index) => {
        let paramCode: string | undefined = getArgValue ? getArgValue(param, index) : undefined;
        if (!paramCode) {
            switch (param.type) {
                case 'boolean':
                    paramCode = 'false';
                    break;
                case 'number':
                    paramCode = '0';
                    break;
                default:
                    if (param.optional !== true) {
                        paramCode = 'nil';
                    }
            }
        }
        if (paramCode) {
            args.push(`${param.label ?? param.id}=${paramCode}`);
        }
    });
    return `${target}.${method.label || method.id}(${args.length ? '{' + args.join(', ') + '}' : ''})`;
}

export function getSortedApis(context: BlockContext) {
    //TODO cache this!
    return [...context.project.firmware.apis].toSorted((a, b) => a.id.localeCompare(b.id));
}
