import {DeleteOutline} from "@mui/icons-material"
import {Stack, Card, CardContent, Typography, FormControl, IconButton, MenuItem} from "@mui/material"
import GMTextField from "../../ui/controls/GMTextField"
import {ActionItemUI, ResolvedActionTarget} from "./action-item-ui"
import {actionRepo} from "./action-repo"
import React from "react"
import {GIconName, icons} from "../../ui/icons/icons"
import {ObjectApi} from "@grenton/gm-common"

const renderActionWidget = (row: ActionItemUI, onUpdate: (model: any) => void) => {
    const action = actionRepo.action(row.action.type);
    if (action) {
        return React.createElement(action.widget, {
            onUpdate,
            api: row.resolvedTarget.api,
            model: row.action.model
        })
    }
    return React.createElement(
        () => <div>Action "{row.action.type}" has not editor</div>,
        {}
    )
}

export function ActionListEditor(props: {
    iconResolver: (target: { device?: string, api: ObjectApi }) => GIconName | null
    onChangeType: (index: number, value: string) => void
    onDeleteAction: (index: number) => void
    onUpdateSpecificAction: (index: number, type: string, model: any) => void,
    rows: ActionItemUI[]
}) {

    const iconRenderer = (target: ResolvedActionTarget) => {
        const iconName = props.iconResolver(target)
        if (iconName) {
            const Icon = icons[iconName]
            return <Icon fontSize="inherit"/>
        }
        return  null
    }

    return (
        <Stack p={2} spacing={2}>

            {props.rows.map((row, index) => {
                return <Card key={index}>
                    <CardContent>
                        <Stack direction="row" alignItems="center">
                            <Stack direction="row" alignItems="center" flexGrow={1}>
                                {iconRenderer(row.resolvedTarget)}
                                <Typography sx={{
                                    ml: 1,
                                    mr: 1,
                                    minWidth: 150,
                                    fontWeight: 'bold'
                                }}>{row.resolvedTarget.name}</Typography>
                                <FormControl sx={{minWidth: 120}}>
                                    <GMTextField
                                        variant="standard"
                                        select
                                        sx={{minWidth: 150}}
                                        size="small"
                                        value={row.action.type}
                                        onChange={(e) => {
                                            props.onChangeType(index, e.target.value)
                                        }}
                                    >
                                        {row.availableTypes.map(a => <MenuItem key={a.type}
                                                                               value={a.type}>{a.label}</MenuItem>)}
                                    </GMTextField>
                                </FormControl>
                            </Stack>

                            <IconButton onClick={() => props.onDeleteAction(index)}><DeleteOutline/></IconButton>
                        </Stack>
                        <Stack padding={0} alignItems={"stretch"}>
                            {renderActionWidget(row, (model: any) =>
                                props.onUpdateSpecificAction(index, row.action.type, model)
                            )}
                        </Stack>
                    </CardContent>
                </Card>
            })}
        </Stack>
    )
}
