import { ToggleOn, ToggleOff } from "@mui/icons-material"
import { ButtonBase, Box, Stack, Typography } from "@mui/material"
import { AppWidgetBox } from "./WidgetBox"
import { WidgetBasicObject } from "./basic"

/**
 * such widget makes sense for monostable digital-in only. 
 * we can simulate temporary change of state and in that way trigger further actions.
 * that would mean we need deviceType "monostable button"
 */
export namespace WidgetMonostableSwitch {

    export const Type = "WidgetMonostableSwitch"

    export const Factory = WidgetBasicObject.factory(Type,{device:"switch"})

    export const Renderer = WidgetBasicObject.renderer(Widget)

    export function Widget(props: WidgetBasicObject.BasicProps) {

        const value = Boolean(props.object.state.features['value'])

        return <AppWidgetBox padding={0}>
            <ButtonBase sx={{ flexGrow: 2, pt: 1, pb: 1, pr: 2, pl: 2 }}>
                <Box pr={2}>{value ? <ToggleOn color="primary" /> : <ToggleOff color="disabled" />}</Box>
                <Stack alignItems="start" direction="column" flexGrow={2} alignContent={"center"}>
                    <Typography variant="body1" flexGrow={2}>{props.widget.title}</Typography>
                    <Typography variant="caption" fontWeight={800} flexGrow={2} color={value ? "primary" : "#aaa"}>{value ? 'On' : 'Off'}</Typography>
                </Stack>
            </ButtonBase>
        </AppWidgetBox>
    }

}

