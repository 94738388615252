import { IdMap, Lists } from '@grenton/gm-common';
import {
    AppLayout,
    AppLayoutOverrides,
    AppLayoutPageOverrides,
    AppLayoutSectionOverrides,
    AppLayoutWidgetOverrides,
    AppPage,
    AppPageSection,
    AppWidget,
} from '@grenton/gm-logic';
import { deepmerge } from 'deepmerge-ts';

export type AppWidgetImplProvider = (id: string) => AppWidget<any> | undefined;

export type AppLayoutItemVisibility = {
    hidden: boolean;
    first: boolean;
    last: boolean;
};

export class AppWidgetInstance<C> {
    constructor(
        readonly widget: AppWidget<C>,
        readonly visibility: AppLayoutItemVisibility,
        private overriddenTitle?: string,
    ) {}

    get id() {
        return this.widget.id;
    }

    get title() {
        return this.overriddenTitle || this.widget.title;
    }
}

export class AppPageSectionImpl {
    static from(
        section: AppPageSection,
        visibility: AppLayoutItemVisibility,
        widgetProvider: AppWidgetImplProvider,
        pageHiddenWidgets: IdMap<boolean> | undefined,
        sectionOverrides: AppLayoutSectionOverrides | undefined,
        widgetOverrides: IdMap<AppLayoutWidgetOverrides> | undefined,
    ) {
        const widgets = section.widgets.map((widgetId, index) => ({ index, widget: widgetProvider(widgetId)! }));

        const widgetsOrder = sectionOverrides?.widgetsOrder || [];

        const calcWidgetOrder = (w: { widget: AppWidget<any>; index: number }) => {
            const i = widgetsOrder.indexOf(w.widget.id);
            return i === -1 ? w.index + 100000 : i;
        };

        const sorted = widgets.sort((w1, w2) => calcWidgetOrder(w1) - calcWidgetOrder(w2)).map((w) => w.widget);

        return new AppPageSectionImpl(
            section,
            visibility,
            sorted.map(
                (w, index) =>
                    new AppWidgetInstance(
                        w,
                        {
                            hidden: Boolean((pageHiddenWidgets || {})[w.id]),
                            first: index === 0,
                            last: index === sorted.length - 1,
                        },
                        (widgetOverrides || {})[w.id]?.title,
                    ),
            ),
            sectionOverrides,
        );
    }

    constructor(
        readonly section: AppPageSection,
        readonly visibility: AppLayoutItemVisibility,
        readonly widgets: AppWidgetInstance<any>[],
        readonly overrides?: AppLayoutSectionOverrides,
    ) {}

    get id() {
        return this.section.id;
    }

    get title() {
        return this.section.title; // TODO consider overrides
    }
}

function sortSections(sections: AppPageSection[], sectionOrder: string[]) {
    const calcSectionOrder = (w: { section: AppPageSection; index: number }) => {
        const i = sectionOrder.indexOf(w.section.id);
        return i === -1 ? w.index + 100000 : i;
    };

    return sections
        .map((section, index) => ({ section, index }))
        .sort((p1, p2) => calcSectionOrder(p1) - calcSectionOrder(p2))
        .map((w) => w.section);
}

export class AppPageImpl {
    static from(
        page: AppPage,
        visibility: AppLayoutItemVisibility,
        widgetProvider: AppWidgetImplProvider,
        pageOverrides?: IdMap<AppLayoutPageOverrides>,
        sectionOverrides?: IdMap<AppLayoutSectionOverrides>,
        widgetOverrides?: IdMap<AppLayoutWidgetOverrides>,
    ) {
        const overrides = (pageOverrides || {})[page.id] || {};
        const sections = sortSections(page.sections, overrides?.sectionOrder || []);
        return new AppPageImpl(
            page,
            visibility,
            sections.map((section, index) => {
                const so = (sectionOverrides || {})[section.id];
                return AppPageSectionImpl.from(
                    section,
                    {
                        hidden: Boolean(so?.hidden),
                        first: index === 0,
                        last: index === sections.length - 1,
                    },
                    widgetProvider,
                    overrides?.hiddenWidgets,
                    so,
                    widgetOverrides,
                );
            }),
            overrides,
        );
    }

    constructor(
        readonly page: AppPage,
        readonly visibility: AppLayoutItemVisibility,
        readonly sections: AppPageSectionImpl[],
        private pageOverrides: AppLayoutPageOverrides,
    ) {}

    get id() {
        return this.page.id;
    }

    get title() {
        return this.pageOverrides?.title || this.page.title;
    }
}

export class AppLayoutOverridesImpl {
    static from(o: AppLayoutOverrides | undefined) {
        return new AppLayoutOverridesImpl(o || { pageOrder: [], allowAutoPages: true, pages: {}, sections: {}, widgets: {} });
    }

    constructor(private data: AppLayoutOverrides) {}

    merge(overlay: AppLayoutOverridesImpl) {
        return new AppLayoutOverridesImpl({
            pageOrder: overlay.data.pageOrder.length ? overlay.data.pageOrder : this.data.pageOrder,
            pages: deepmerge(this.data.pages, overlay.data.pages),
            widgets: deepmerge(this.data.widgets, overlay.data.widgets),
            sections: deepmerge(this.data.sections, overlay.data.sections),
        });
    }

    get widgets() {
        return this.data.widgets;
    }

    get pages() {
        return this.data.pages;
    }

    get sections() {
        return this.data.sections;
    }

    get pageOrder() {
        return this.data.pageOrder;
    }

    withPageOrder(pageOrder: string[]) {
        return new AppLayoutOverridesImpl({ ...this.data, pageOrder });
    }

    isWidgetHiddenOnPage(widgetId: string, pageId: string) {
        return (this.data.pages[pageId]?.hiddenWidgets || {})[widgetId] === true;
    }

    withWidgetHiddenEverywhere(widgetId: string, hidden?: boolean) {
        return new AppLayoutOverridesImpl({ ...this.data, widgets: { ...this.data.widgets, [widgetId]: { ...this.data.widgets[widgetId], hidden } } });
    }

    withWidgetHiddenOnPage(widgetId: string, pageId: string, hidden: boolean) {
        return new AppLayoutOverridesImpl({
            ...this.data,
            pages: {
                ...this.data.pages,
                [pageId]: {
                    ...this.data.pages[pageId],
                    hiddenWidgets: { ...this.data.pages[pageId]?.hiddenWidgets, [widgetId]: Boolean(hidden) },
                },
            },
        });
    }

    withWidgetTitle(widgetId: string, title?: string) {
        return new AppLayoutOverridesImpl({ ...this.data, widgets: { ...this.data.widgets, [widgetId]: { ...this.data.widgets[widgetId], title } } });
    }

    withPageHidden(pageId: string, hidden: boolean) {
        return new AppLayoutOverridesImpl({
            ...this.data,
            pages: {
                ...this.data.pages,
                [pageId]: {
                    ...this.data.pages[pageId],
                    hidden,
                },
            },
        });
    }

    isPageHidden(pageId: string) {
        return Boolean(this.data.pages[pageId]?.hidden);
    }

    withSectionHidden(sectionId: string, hidden: boolean): AppLayoutOverridesImpl {
        return new AppLayoutOverridesImpl({
            ...this.data,
            sections: {
                ...this.data.sections,
                [sectionId]: {
                    ...this.data.sections[sectionId],
                    hidden,
                },
            },
        });
    }

    isSectionHidden(sectionId: string) {
        return Boolean(this.data.sections[sectionId]?.hidden);
    }

    withSectionTitle(sectionId: string, title: string): AppLayoutOverridesImpl {
        return new AppLayoutOverridesImpl({
            ...this.data,
            sections: {
                ...this.data.sections,
                [sectionId]: {
                    ...this.data.sections[sectionId],
                    title,
                },
            },
        });
    }

    getSectionTitle(sectionId: string) {
        return this.data.sections[sectionId]?.title;
    }

    withSectionOrder(pageId: string, sectionOrder: string[]): AppLayoutOverridesImpl {
        return new AppLayoutOverridesImpl({
            ...this.data,
            pages: {
                ...this.data.pages,
                [pageId]: {
                    ...this.data.pages[pageId],
                    sectionOrder,
                },
            },
        });
    }

    withWidgetsOrder(sectionId: string, widgetsOrder: string[]) {
        return new AppLayoutOverridesImpl({
            ...this.data,
            sections: {
                ...this.data.sections,
                [sectionId]: {
                    ...this.data.sections[sectionId],
                    widgetsOrder,
                },
            },
        });
    }
}

function sortPages(pages: AppPage[], pageOrder: string[]) {
    const calcPageOrder = (w: { page: AppPage; index: number }) => {
        const i = pageOrder.indexOf(w.page.id);
        return i === -1 ? w.index + 100000 : i;
    };

    return pages
        .map((page, index) => ({ page, index }))
        .sort((p1, p2) => calcPageOrder(p1) - calcPageOrder(p2))
        .map((w) => w.page);
}

export class AppLayoutImpl {
    private static widgetWithOverrides(widgets: AppWidget<any>[], overrides: AppLayoutOverrides['widgets']) {
        return Lists.reduce(widgets, (widget) => {
            const ov = overrides[widget.id];
            return [
                widget.id,
                {
                    ...widget,
                    title: ov?.title || widget.title,
                    config: ov?.config || widget.config,
                },
            ];
        });
    }

    static from(layout: AppLayout) {
        const overrides = AppLayoutOverridesImpl.from(layout.overrides);
        const widgets = AppLayoutImpl.widgetWithOverrides(layout.widgets, overrides.widgets);
        const pages = sortPages(layout.pages, overrides.pageOrder);

        return new AppLayoutImpl({
            id: layout.id,
            name: layout.name,
            widgets,
            pages: pages.map((page, index) =>
                AppPageImpl.from(
                    page,
                    {
                        hidden: Boolean(overrides.pages[page.id]?.hidden),
                        first: index === 0,
                        last: index === pages.length - 1,
                    },
                    (id) => widgets[id],
                    overrides.pages,
                    overrides.sections,
                    overrides.widgets,
                ),
            ),
            overrides,
        });
    }

    merge(overlay: AppLayoutImpl | null | undefined) {
        if (!overlay) return this;
        const overrides = this.overrides.merge(overlay.overrides);
        const widgets = AppLayoutImpl.widgetWithOverrides([...Object.values(this.data.widgets), ...Object.values(overlay.widgets)], overrides.widgets);

        const pages = sortPages(
            [...this.pages, ...overlay.pages].map((p) => p.page),
            overrides.pageOrder,
        );

        return new AppLayoutImpl({
            id: overlay.id || this.data.id,
            name: overlay.name || this.data.name,
            widgets,
            pages: pages.map((page, index) =>
                AppPageImpl.from(
                    page,
                    {
                        hidden: Boolean(overrides.pages[page.id]?.hidden),
                        first: index === 0,
                        last: index === pages.length - 1,
                    },
                    (id) => widgets[id],
                    overrides.pages,
                    overrides.sections,
                    overrides.widgets,
                ),
            ),
            overrides,
        });
    }

    static readonly empty = new AppLayoutImpl({ id: '', name: '', widgets: {}, pages: [], overrides: AppLayoutOverridesImpl.from(undefined) });

    constructor(
        private data: {
            id: string;
            name: string;
            widgets: IdMap<AppWidget<any>>;
            pages: AppPageImpl[];
            overrides: AppLayoutOverridesImpl;
        },
    ) {}

    get pages() {
        return this.data.pages;
    }

    get id() {
        return this.data.id;
    }

    get name() {
        return this.data.name;
    }

    get widgets() {
        return this.data.widgets;
    }

    readonly widgetProvider: AppWidgetImplProvider = (id) => this.data.widgets[id];

    getPage(id: string) {
        return this.pages.find((p) => p.page.id === id);
    }

    get overrides() {
        return this.data.overrides;
    }

    withOverrides(overrides: AppLayoutOverridesImpl) {
        return new AppLayoutImpl({ ...this.data, overrides });
    }
}
