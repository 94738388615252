import { Command, CommandHandler } from '../../dispatcher';
import { ProjectRepository } from '../project-repository';

export class DeleteProjectCommand implements Command {
    readonly type = 'DeleteProjectCommand';
    constructor(readonly id: string) {}
}

export class DeleteProjectCommandHandler implements CommandHandler<DeleteProjectCommand> {
    constructor(private projectRepo: ProjectRepository) {}
    supports(cmd: Command): boolean {
        return cmd.type === 'DeleteProjectCommand';
    }
    async execute(_cmd: DeleteProjectCommand): Promise<void> {
        await this.projectRepo.delete(_cmd.id);
    }
}
