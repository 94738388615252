import { Box, ButtonBase, Stack, Typography } from "@mui/material"
import { AppWidgetBox } from "./WidgetBox"
import { useContext, useRef } from "react"
import { BrightnessHigh, BrightnessLow, BrightnessMedium } from "@mui/icons-material"
import useLongPress from "../../ui/hooks/useLongPress"
import { WidgetBasicObject } from "./basic"
import { ObjectRPCExecutorContext } from "../object-rpc-executor-context"

export namespace WidgetDimmableLight {
    export const Type = "WidgetDimmableLight"

    export const Factory = WidgetBasicObject.factory(Type,{device:"dimmable"})

    export const Renderer = WidgetBasicObject.renderer(Widget)

    function Widget(props: WidgetBasicObject.BasicProps) {

        const value = Number(props.object.state.features['value'])
        const timeout = useRef<{ timer: any, value: number }>()
        const executor = useContext(ObjectRPCExecutorContext)
        
        function icon() {
            switch (value) {
                case 0: return <BrightnessLow color="disabled" />
                case 1: return <BrightnessHigh color="primary" />
                default:
                    return <BrightnessMedium color="primary" />
            }
        }

        function label() {
            switch (value) {
                case 0: return "Off"
                default:
                    return `${Math.round(value * 100)}%`
            }
        }

        function clear() {
            clearInterval(timeout.current?.timer)
        }

        const holdValue = () => {
            if (timeout.current) {
                executor(props.object.uuid, { method: "holdValue" })
            }
        }

        const hold = useLongPress(() => {
            timeout.current = {
                value,
                timer: setInterval(() => holdValue(), 500)
            }
            },
            () => clear(),
            () => {
                executor(props.object.uuid, { method: "switch", params: { "ramp": 0, "time": 0 } })
                clear()
            }, { delay: 500 })

        return <AppWidgetBox padding={0}>
            <ButtonBase sx={{ flexGrow: 2, pt: 1, pb: 1, pr: 2, pl: 2 }}{...hold as any}>
                <Box style={{ pointerEvents: "none" }} pr={2}>{icon()}</Box>
                <Stack style={{ pointerEvents: "none" }} alignItems="start" direction="column" flexGrow={2} alignContent={"center"}>
                    <Typography variant="body1" flexGrow={2}>{props.widget.title}</Typography>
                    <Typography variant="caption" fontWeight={800} flexGrow={2} color={value ? "primary" : "#aaa"}>{label()}</Typography>
                </Stack>
            </ButtonBase>
        </AppWidgetBox>
    }
}