import { FlashlightOn, FlashlightOff } from "@mui/icons-material"
import { ButtonBase, Box, Stack, Typography } from "@mui/material"
import { AppWidgetBox } from "./WidgetBox"
import { WidgetBasicObject } from "./basic"
import { ObjectRPCExecutorContext } from "../object-rpc-executor-context"
import { useContext } from "react"

export namespace WidgetLight {

    export const Type = "WidgetLight"

    export const Factory = WidgetBasicObject.factory(Type,{device:"lamp"})

    export const Renderer = WidgetBasicObject.renderer(Widget)

    function Widget(props: WidgetBasicObject.BasicProps) {

        const value = Boolean(props.object.state.features['value'])
        const executor = useContext(ObjectRPCExecutorContext)

        return <AppWidgetBox padding={0}>
            <ButtonBase sx={{ flexGrow: 2, pt: 1, pb: 1, pr: 2, pl: 2 }} onClick={() => executor(props.object.uuid, { method: "switch" })}>
                <Box pr={2}>{value ? <FlashlightOn color="primary" /> : <FlashlightOff color="disabled" />}</Box>
                <Stack alignItems="start" direction="column" flexGrow={2} alignContent={"center"}>
                    <Typography variant="body1" flexGrow={2}>{props.widget.title}</Typography>
                    <Typography variant="caption" fontWeight={800} flexGrow={2} color={value ? "primary" : "#aaa"}>{value ? 'On' : 'Off'}</Typography>
                </Stack>
            </ButtonBase>
        </AppWidgetBox>
    }


}

