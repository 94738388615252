import { prot, ObjectApi, isApiExtending } from '@grenton/gm-common';
import { ActionTemplate } from '../../action-template';
import { ActionDigitalOutSetGenerator } from './generator';
import { ActionSetModel } from './model';
import { ActionDigitalOutSetWidget } from './widget';

export const ActionDigitalOutSet: ActionTemplate<ActionSetModel> = {
    type: 'set',
    label: 'Set',
    order: 1,
    defaults: { revertAfter: 0, value: 0 },
    widget: ActionDigitalOutSetWidget,
    generator: ActionDigitalOutSetGenerator,
    isApplicable(api: ObjectApi) {
        return isApiExtending(api, prot.OnOff);
    },
};
