import { layoutGenerator } from "./layout-generator"
import { widgetRenderer } from "./renderer"
import { WidgetLight } from "./widgets/WidgetLight"
import { WidgetScene } from "./widgets/WidgetScene"
import { WidgetDimmableLight } from "./widgets/WidgetDimmableLight"
import {WidgetMonostableSwitch} from "./widgets/WidgetMonostableSwitch";
import { WidgetGenericOUT } from "./widgets/WidgetGenericOUT"
import { WidgetLightController } from "./widgets/WidgetLightController"
import { WidgetRollerShutter } from "./widgets/WidgetRollerShutter"
import { WidgetScheduler } from "./widgets/WidgetScheduler"
import { WidgetGardenController } from "./widgets/WidgetGardenController"
import { WidgetSmartPanel } from "./widgets/WidgetSmartPanel"

widgetRenderer.register(WidgetLight.Type, WidgetLight.Renderer)
layoutGenerator.register(WidgetLight.Factory)

widgetRenderer.register(WidgetScene.Type, WidgetScene.Renderer)
layoutGenerator.register(WidgetScene.Factory)

widgetRenderer.register(WidgetMonostableSwitch.Type, WidgetMonostableSwitch.Renderer)
layoutGenerator.register(WidgetMonostableSwitch.Factory)

widgetRenderer.register(WidgetDimmableLight.Type, WidgetDimmableLight.Renderer)
layoutGenerator.register(WidgetDimmableLight.Factory)

widgetRenderer.register(WidgetGardenController.Type, WidgetGardenController.Renderer)
layoutGenerator.register(WidgetGardenController.Factory)

// layoutGenerator.register((object: SystemObject)=> isApiExtending(object.api, Dimm) ? [createSimpleWidget(WidgetDimmableLight.Type, object)] : [])
// layoutGenerator.register((object: SystemObject)=> isApiExtending(object.api, LedRGB) ? [createSimpleWidget(WidgetDimmableLight.Type, object)] : [])


widgetRenderer.register(WidgetRollerShutter.Type, WidgetRollerShutter.Renderer)
layoutGenerator.register(WidgetRollerShutter.Factory)

widgetRenderer.register(WidgetLightController.Type, WidgetLightController.Renderer)
layoutGenerator.register(WidgetLightController.Factory)

widgetRenderer.register(WidgetScheduler.Type, WidgetScheduler.Renderer)
layoutGenerator.register(WidgetScheduler.Factory)

widgetRenderer.register(WidgetGenericOUT.Type, WidgetGenericOUT.Renderer)
layoutGenerator.register(WidgetGenericOUT.Factory)

widgetRenderer.register(WidgetSmartPanel.Type, WidgetSmartPanel.Renderer)
layoutGenerator.register(WidgetSmartPanel.Factory)

