import { Components, Theme } from '@mui/material';

declare module '@mui/material/ToggleButtonGroup' {
    interface ToggleButtonGroupPropsColorOverrides {
        white: true;
        greyish: true;
    }
}

export const MuiToggleButtonGroup: Components<Omit<Theme, 'components'>>['MuiToggleButtonGroup'] = {};
