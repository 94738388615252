import { I18nLangCodeEnum, I18nLang } from '@grenton/gm-common';

export class ProjectI18nImpl {
    constructor(readonly langs: I18nLang[]) {}

    static from(langs: I18nLang[]) {
        return new ProjectI18nImpl(langs);
    }

    withLanguage(lang: I18nLang): ProjectI18nImpl {
        const exists = this.langs.some((existingLang) => existingLang.code === lang.code);
        if (!exists) {
            const langs: I18nLang[] = [...this.langs, lang];
            return new ProjectI18nImpl(langs);
        }
        return this;
    }

    withoutLanguage(code: string): ProjectI18nImpl {
        if (this.langs.length <= 1) return this;
        const updatedLangs = this.langs.filter((lang) => lang.code !== code);
        if (updatedLangs.length === 1 && !updatedLangs[0]!.isDefault) updatedLangs[0]!.isDefault = true;
        return new ProjectI18nImpl(updatedLangs);
    }

    editLanguage(lang: I18nLang): ProjectI18nImpl {
        const langs: I18nLang[] = [...this.langs];
        const langIndex = langs.findIndex((existingLang) => existingLang.code === lang.code);
        const defaultLanguageExists = this.langs.filter((lang) => lang.isDefault === true)?.length > 1;
        if (langIndex !== -1 && (defaultLanguageExists || lang.isDefault)) {
            langs[langIndex]!.isDefault = lang.isDefault;
            return new ProjectI18nImpl(langs);
        }

        return new ProjectI18nImpl(langs);
    }

    getLanguage(code: I18nLangCodeEnum): I18nLang | undefined {
        return this.langs.find((lang) => lang.code === code);
    }

    export(): ProjectI18nImpl {
        return this;
    }
}
