import { IdMap, ObjectApi, fabric, schema } from '@grenton/gm-common';

export type SystemModelEvents =
    | { type: 'object-added'; data: { uuid: string; object: PartialObject } }
    | { type: 'object-removed'; data: { uuid: string } }
    | { type: 'config-changed'; data: { uuid: string; object: PartialObject } }
    | { type: 'state-changed'; data: { uuid: string; object: PartialObject } }
    | { type: 'logic-changed'; data: { uuid: string; object: PartialObject } };

/*
 * all widgets interacts with AppModel only
 */
export interface SystemModel<T extends PartialObject> {
    getObject(uuid: string): T | undefined;
    objects: T[];
    all: IdMap<{ object: PartialObject; ready: boolean }>;
    clone(): SystemModel<T>;
}

// object can be assembled in any order, only uuid is there at all times
export interface PartialObject {
    uuid: string;
    config?: fabric.ObjectConfig;
    state?: fabric.ObjectState;
    logic?: fabric.ObjectLogic;
    api?: ObjectApi;

    // TODO remove it, PartialObject is used in various contexts, execution is just one of them
    executor?: (method: string, args?: IdMap<schema.PropertyValue>) => Promise<schema.PropertyValue>;
}

// this is not 'regular' rpc client, it allows to choose a target!
export type ObjectRPCExecutor = (uuid: string, request: { method: string; params?: IdMap<schema.PropertyValue> }) => Promise<schema.PropertyValue>;

export const DummyObjectRPCExecutor: ObjectRPCExecutor = (uuid: string, request: { method: string; params?: IdMap<schema.PropertyValue> }) => {
    return Promise.reject(new Error(`RPC call to ${uuid}:${request.method} cannot be handled by dummy executor`));
};

export class PartialObjectImpl implements PartialObject {
    static empty(uuid: string) {
        return new PartialObjectImpl({ uuid, config: undefined, state: undefined, logic: undefined, api: undefined });
    }

    constructor(
        private data: {
            uuid: string;
            config: fabric.ObjectConfig | undefined;
            state: fabric.ObjectState | undefined;
            api: ObjectApi | undefined;
            logic: fabric.ObjectLogic | undefined;
        },
    ) {}

    get uuid() {
        return this.data.uuid;
    }

    get config() {
        return this.data.config;
    }

    get state() {
        return this.data.state;
    }

    get api() {
        return this.data.api;
    }

    get logic() {
        return this.data.logic;
    }

    withState(state: fabric.ObjectState | undefined) {
        return new PartialObjectImpl({ ...this.data, state });
    }

    withLogic(logic: fabric.ObjectLogic | undefined) {
        return new PartialObjectImpl({ ...this.data, logic });
    }

    withConfig(config: fabric.ObjectConfig | undefined) {
        return new PartialObjectImpl({ ...this.data, config, api: config?.api === this.data?.api?.id ? this.data?.api : undefined });
    }

    withApi(api: ObjectApi | undefined) {
        return new PartialObjectImpl({ ...this.data, api });
    }
}

const emptyFn = function () {};

export class SystemModelImpl<T extends PartialObject> implements SystemModel<T> {
    static empty<T extends PartialObject>(filter: (o: PartialObject) => boolean) {
        return new SystemModelImpl<T>(filter, emptyFn, {});
    }

    constructor(
        private filter: (o: PartialObject) => boolean,
        public listener: (event: SystemModelEvents) => void,
        readonly partials: IdMap<{ object: PartialObjectImpl; ready: boolean }>,
    ) {}

    clone() {
        return new SystemModelImpl<T>(this.filter, this.listener, { ...this.partials });
    }

    get objects(): T[] {
        return Object.values(this.partials)
            .filter((e) => e.ready)
            .map((e) => e.object as any);
    }

    get all() {
        return this.partials;
    }

    getObject(uuid: string): T | undefined {
        const o = this.partials[uuid];
        return o?.ready ? (o.object as any) : undefined;
    }

    // we could mark PartialObject as removed and let e.g. UI render a disabled widget.
    // otherwise layout will change and cause unpleasant effect
    withoutObject(uuid: string) {
        const prev = this.partials[uuid];
        if (prev) {
            delete this.partials[uuid];
            if (prev.ready && this.listener) this.listener({ type: 'object-removed', data: { uuid } });
        }
        return this;
    }

    private update(uuid: string, fn: (prev: PartialObjectImpl) => PartialObjectImpl) {
        const prev = this.partials[uuid] || { object: PartialObjectImpl.empty(uuid), ready: false };
        const object = fn(prev.object);

        const ready = this.filter(object);
        this.partials[object.uuid] = { object, ready };

        if (this.listener) {
            if (prev.ready && !ready) {
                this.listener({ type: 'object-removed', data: { uuid: prev.object.uuid } });
            } else if (!prev.ready && ready) {
                this.listener({ type: 'object-added', data: { uuid, object } });
            }
            if (ready && prev.object.state !== object.state) {
                this.listener({ type: 'state-changed', data: { uuid, object } });
            }
            if (ready && prev.object.logic !== object.logic) {
                this.listener({ type: 'logic-changed', data: { uuid, object } });
            }
        }
        return this;
    }

    withObjectConfig(uuid: string, config: fabric.ObjectConfig | undefined) {
        return this.update(uuid, (obj) => obj.withConfig(config));
    }

    withObjectApi(uuid: string, api: ObjectApi | undefined) {
        return this.update(uuid, (obj) => obj.withApi(api));
    }

    withObjectLogic(uuid: string, logic: fabric.ObjectLogic | undefined) {
        return this.update(uuid, (obj) => obj.withLogic(logic));
    }

    withObjectState(uuid: string, state: fabric.ObjectState | undefined) {
        return this.update(uuid, (obj) => obj.withState(state));
    }

    toString() {
        return `SystemModelImpl (partials #${Object.values(this.partials).length}, completed #${Object.values(this.objects).length})`;
    }
}
