import { Project, ProjectHardware } from '@grenton/gm-common';
import loglevel from 'loglevel';
import { BehaviorSubject, Subscription } from 'rxjs';
import { pollEndpoint } from '../../utils';

/**
 * handles communication with a runtime system (deployed configuration).
 * we need to read/write following features:
 *
 * 1. hardware part of current configuration (CLUs+attached modules)
 *    this is required to display current state of connected hardware on mapping screen
 * 2. read current state of objects
 *    we display state on 'runtime' tab and on preparation screen
 * 3. perform RPC calls to objects
 *    this is possible from 'runtime' tab and on preparation screen
 * 4. read logs
 *
 * TBD: HWTeam claims that MQTT will be mirrored on each CLU, so we don't need to connect to leader,
 * but potentially that would ensure lowest latency (leader info must be in discovery)
 *
 * TODO currently to get #1 we poll configuration from /configuration REST endpoint, which is not optimal,
 * we should expose this part of configuration on MQTT
 *
 */
export class ClusterClient {
    public readonly project = new BehaviorSubject<ProjectHardware | null>(null);
    private endpoint?: string;
    private sub: Subscription | null = null;

    constructor() {}

    setCLUEndpoint(endpoint?: string) {
        if (endpoint !== this.endpoint) {
            if (endpoint) {
                loglevel.debug('start monitoring online configuration', endpoint);
            } else {
                loglevel.debug('stop monitoring online configuration');
            }

            this.endpoint = endpoint;
            this.sub?.unsubscribe();
            this.sub = null;
            if (endpoint) {
                this.sub = pollEndpoint<Project>(`${endpoint}/configuration`, 6000).subscribe((result) => {
                    if (result.type === 'data') {
                        this.project.next(result.data.hardware);
                    } else {
                        loglevel.warn('cluster monitor:', result.error.message);
                        this.project.next(null);
                    }
                });
            }
        }
    }
}
