import { WidgetBasicObject } from "./basic"
import { InvertColors, InvertColorsOff } from "@mui/icons-material"
import { ButtonBase, Box, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { AppWidgetBox } from "./WidgetBox"

export namespace WidgetGardenController {
 
    export const Type = "WidgetGardenController"

    export const Factory = WidgetBasicObject.factory(Type,{api:"#garden-controller"})

    export const Renderer = WidgetBasicObject.renderer(Widget)

    function Widget(props:WidgetBasicObject.BasicProps) {
        const [value, setValue] = useState(false)
        return <AppWidgetBox padding={0}>
            <ButtonBase sx={{flexGrow:2,pt:1,pb:1,pr:2,pl:2}} onClick={()=>setValue(!value)}>
            <Box pr={2}>{value ? <InvertColors color="primary"/> : <InvertColorsOff color="disabled"/>}</Box>
                <Stack alignItems="start" direction="column" flexGrow={2} alignContent={"center"}>
                <Typography variant="body1" flexGrow={2}>{props.object.config.name}</Typography>
                <Typography variant="caption" fontWeight={800} flexGrow={2} color={value?"primary":"#aaa"}>{value ? 'Sprinklers on' : 'Sprinklers off'}</Typography>
                </Stack>
            </ButtonBase>
            <ButtonBase ><Box p={2} color="primary">0.65</Box></ButtonBase>
        </AppWidgetBox>
    }
    
}