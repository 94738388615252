import { CandidateObject, fabric, Lists, notEmpty } from '@grenton/gm-common';
import { evaluateOutletSelector } from '@grenton/gm-common';
import { exportOutletSelector, isAnonymousController, ProjectImpl, ProjectObjectImpl } from './model';

type Props = {
    project: ProjectImpl;
    onlyForObjects?: Set<string>;
    previousReferences?: ReferencesHolder;
};

/**
 * @param project
 * @param onlyForObjects - objects to calculate references for
 * @param previousReferences - previously calculated state. if provided, there's a good chance that it will be reused
 * @returns
 */
export function calculateProjectObjectReferences({ project, onlyForObjects, previousReferences }: Props) {
    let refsHolder = previousReferences ?? new ReferencesHolder({});

    const objectProvider = candidateProvider(project);
    const objects: ProjectObjectImpl[] = onlyForObjects
        ? Array.from(onlyForObjects).map(project.objectResolver).filter(notEmpty)
        : Object.values(project.objects).filter((o) => !isAnonymousController(o.uuid));

    // update references only for tracked objects
    // leave other calculated references untouched to avoid unnecessary mutation
    for (const object of objects) {
        Object.values(object.api.outlets).forEach((outlet) => {
            const init = object.init.outlets[outlet.id];
            if (init) {
                if (init.isStatic) {
                    refsHolder = refsHolder.withOutletRefs(object.uuid, outlet.id, { static: true, refs: init.staticRefs });
                } else {
                    let refs = evaluateOutletSelector({
                        objectProvider,
                        ownerTags: object.tags.selected,
                        outletProtocol: outlet.api.name,
                        selector: exportOutletSelector(init.dynamicRefs),
                    });
                    if (outlet.maxItems && outlet.maxItems > 0) {
                        refs = refs.slice(0, outlet.maxItems);
                    }
                    refsHolder = refsHolder.withOutletRefs(object.uuid, outlet.id, { refs });
                }
            }
        });
    }
    return refsHolder;
}

// we already have type ObjectReferences = Map<string, fabric.OutletRefs>;
export type ObjectReferences2 = { [objectId: string]: { [outletId: string]: fabric.OutletRefs } };

export class ReferencesHolder {
    static readonly empty = new ReferencesHolder({});

    constructor(readonly refs: ObjectReferences2) {}

    // avoid unnecessary mutations!
    withOutletRefs(objectId: string, outletId: string, outletRefs: fabric.OutletRefs) {
        const currentOutletRefs = this.refs[objectId]?.[outletId];
        if (currentOutletRefs) {
            if (currentOutletRefs.static && outletRefs.static) {
                return this;
            }
            if (Lists.areEqual(currentOutletRefs.refs, outletRefs.refs)) {
                return this;
            }
        }
        return new ReferencesHolder({ ...this.refs, [objectId]: { ...this.refs[objectId], [outletId]: outletRefs } });
    }
}

function toCandidateObject(obj: ProjectObjectImpl) {
    return {
        uuid: obj.uuid,
        config: {
            name: obj.label,
            device: obj.userType,
            tags: obj.tags.selected,
        },
        api: obj.api.api,
    } satisfies CandidateObject;
}

export function candidateProvider(p: ProjectImpl) {
    return {
        all: () => Object.values(p.objects).map(toCandidateObject),
        byId: (id: string) => {
            const obj = p.objects[id];
            return obj ? toCandidateObject(obj) : undefined;
        },
    };
}
