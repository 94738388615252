import { AppWidgetBox } from "./WidgetBox"
import { WidgetBasicObject } from "./basic"
import { prot } from "@grenton/gm-common"

export namespace WidgetSmartPanel {

    export const Type = "WidgetSmartPanel"

    export const Factory = WidgetBasicObject.factory(Type,{api:prot.SmartPanel})

    export const Renderer = WidgetBasicObject.renderer(Widget)

    function Widget(_props: WidgetBasicObject.BasicProps) {
       // const _executor = useContext(ObjectRPCExecutorContext)

        return <AppWidgetBox padding={0}>
            smart panel
        </AppWidgetBox>
    }


}

