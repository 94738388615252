import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';

// based on https://mui.com/material-ui/react-drawer/#system-MiniDrawer.tsx
// but we need to control precisely close width

type MiniDrawerProps = {
    openWidth: number
    closeWidth: number
} & DrawerProps;

const openedMixin = (theme: Theme, openWidth: number): CSSObject => ({
    width: openWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme, closeWidth: number): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: closeWidth, //`calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: closeWidth //`calc(${theme.spacing(8)} + 1px)`,
    },
});

export const GMiniDrawer = styled(MuiDrawer, 
    { shouldForwardProp: (prop) => prop !== 'openWidth' && prop !== 'closeWidth' })<MiniDrawerProps>(
    ({ theme, open, openWidth, closeWidth }) => ({
        width: openWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme, openWidth),
            '& .MuiDrawer-paper': openedMixin(theme, openWidth),
        }),
        ...(!open && {
            ...closedMixin(theme, closeWidth),
            '& .MuiDrawer-paper': closedMixin(theme, closeWidth),
        }),
    }),
);
