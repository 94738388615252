import { Maps, ObjectApi, ObjectOutlet, ObjectSelector, notEmpty, schema } from '@grenton/gm-common';
import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils/state';
import {
    FeatureImpl,
    FeatureConfigImpl,
    MethodImpl,
    EventImpl,
    OutletImpl,
    OutletConfigImpl,
    ProjectImpl,
    convertPropertyValueToStringIfNeeded,
    convertPropertyValueTypeIfNeeded,
} from '../model';

// array because one tab can be used to edit multiple objects (multiselection feature)
export type ObjectEditFormId = string[];

export type ObjectEditFormTab = 'general' | 'widget' | 'tags' | 'features' | 'methods' | 'events' | 'outlets' | 'runtime';

export type ObjectEditForm = {
    id: ObjectEditFormId;
    selectedTab: ObjectEditFormTab;
    disabled: {
        label?: boolean;
        tags?: boolean;
        functionalType?: boolean;
    };
    name: string;
    tags: string[];
    functionalType?: string;

    extendingProtocols: string[];

    apiItems: {
        features: Record<string, ObjectFeatureForm>;
        methods: Record<string, ObjectMethodForm>;
        events: Record<string, ObjectEventForm>;
        outlets: Record<string, ObjectOutletForm>;
    };

    editedFeature?: string;
    editedMethod?: string;
    editedOutlet?: string;
    editedEvent?: string;
    editedRuntime?: string;
};

export const UNMODIFIED_VALUE = Symbol('UNMODIFIED_VALUE');
/**
 * this essentially is internal data model of FeatureImpl and Feature,
 * but it is used as form model so it may be in invalid state.
 * that's why we cannot use FeatureImpl directly.
 */
export type ObjectFeatureForm = {
    inherited: boolean; // not needed?
    editable: boolean;
    spec: schema.Feature;
    config: {
        value?: schema.PropertyValue | typeof UNMODIFIED_VALUE;
    };
};

export const convertFeatureImplToForm = (mutableApi: boolean, feature: FeatureImpl, multiEdit: boolean, config?: FeatureConfigImpl): ObjectFeatureForm => ({
    inherited: feature.inherited,
    editable: mutableApi && !feature.inherited,
    spec: { ...feature.spec, label: feature.spec.label || feature.spec.id },
    config: multiEdit ? { value: UNMODIFIED_VALUE } : config ? { value: convertPropertyValueToStringIfNeeded(feature.spec.type, config.value) } : {},
});

export type ObjectMethodForm = {
    inherited: boolean;
    editable: boolean;
    spec: schema.Method;
};

export const convertMethodImplToForm = (mutableApi: boolean, method: MethodImpl): ObjectMethodForm => ({
    inherited: method.inherited,
    editable: mutableApi && !method.inherited && !method.spec.internal,
    spec: { ...method.spec, label: method.spec.label || method.spec.id },
});

export type ObjectEventForm = {
    inherited: boolean;
    editable: boolean;
    spec: schema.Method;
};

export const convertEventImplToForm = (mutableApi: boolean, event: EventImpl): ObjectEventForm => ({
    inherited: event.inherited,
    editable: mutableApi && !event.inherited,
    spec: { ...event.spec, label: event.spec.label || event.spec.id },
});

export type ObjectOutletForm = {
    // TODO having api:ObjectApi is unfortunate, form/command should be pure data object
    // it must be optional cuz new outlets are empty
    inherited: boolean;
    editable: boolean;
    spec: Omit<ObjectOutlet, 'api'> & { api?: ObjectApi };
    apiRef: string | null;
    config: {
        editable: boolean;
        selector: ObjectSelector;
    };
};

export const convertOutletImplToForm = (mutableApi: boolean, outlet: OutletImpl, multiEdit: boolean, config?: OutletConfigImpl): ObjectOutletForm => ({
    inherited: outlet.inherited,
    editable: mutableApi && !outlet.inherited,
    spec: { ...outlet.spec, label: outlet.spec.label || outlet.spec.id },
    apiRef: outlet.api.name,
    config: {
        editable: !multiEdit && !Boolean(config?.isStatic),
        selector:
            !multiEdit && config
                ? {
                      ids: Maps.reduceKeyMap(config.dynamicRefs.ids),
                      types: Maps.reduceKeyMap(config.dynamicRefs.types),
                      tags: Maps.reduceKeyMap(config.dynamicRefs.tags),
                      exclude: Maps.reduceKeyMap(config.dynamicRefs.exclude),
                  }
                : {
                      ids: [],
                      types: [],
                      tags: [],
                      exclude: [],
                  },
    },
});

export type ObjectFormFeatureInit = {
    id: string;
    defaultValue: schema.PropertyValue;
};

export class ObjectEditCommand implements Command {
    readonly type = 'ObjectEditCommand';
    constructor(readonly data: { form: ObjectEditForm }) {}
}

export class ObjectEditCommandHandler implements CommandHandler<ObjectEditCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'ObjectEditCommand';
    }

    execute(cmd: ObjectEditCommand) {
        return this.update((p) => {
            const multisel = cmd.data.form.id.length > 1;
            for (const uuid of cmd.data.form.id) {
                let object = p.getObjectById(uuid);
                if (object) {
                    if (!cmd.data.form.disabled.label) {
                        object = object.withLabel(cmd.data.form.name);
                    }
                    if (!cmd.data.form.disabled.tags) {
                        if (multisel && !cmd.data.form.tags.length) {
                            // skip erasing tags for multiselection
                        } else {
                            object = object.withTags(cmd.data.form.tags);
                        }
                    }
                    if (!cmd.data.form.disabled.functionalType) {
                        object = object.withUserType(cmd.data.form.functionalType);
                    }

                    Object.entries(cmd.data.form.apiItems.features).forEach(([id, featureForm]) => {
                        if (featureForm.config.value !== undefined && featureForm.config.value !== null && featureForm.config.value !== UNMODIFIED_VALUE) {
                            object = object!.withInit(
                                object!.init.withFeature(
                                    new FeatureConfigImpl(id, convertPropertyValueTypeIfNeeded(featureForm.spec.type, featureForm.config.value)),
                                ),
                            );
                        }
                    });

                    // configs for editable outlets recreate from the form, non-editable copy over from original object
                    const outletsConfigs: Record<string, OutletConfigImpl> = Maps.filterEmpty(
                        Maps.transform(cmd.data.form.apiItems.outlets, (id, outletForm) =>
                            outletForm.config.editable
                                ? OutletConfigImpl.from({ id, refs: { type: 'dynamic', value: outletForm.config.selector } })
                                : object?.init.outlets[id],
                        ),
                    );
                    object = object.withInit(object.init.withOutlets(outletsConfigs));

                    if (object.api.editable) {
                        function onlySelfItems(i: { inherited: boolean }) {
                            return !i.inherited;
                        }

                        let api = object.api.withoutAllItems();

                        // not sure...
                        cmd.data.form.extendingProtocols
                            .map((ref) => p.firmware.resolveApiRef({ ref }))
                            .filter(notEmpty)
                            .forEach((extending) => {
                                api = api.withExtending(extending);
                            });

                        const newApi = cmd.data.form.apiItems;

                        for (const item of Object.values(newApi.events).filter(onlySelfItems)) {
                            api = api.withEvent(EventImpl.from(item.spec, item.inherited));
                        }
                        for (const item of Object.values(newApi.methods).filter(onlySelfItems)) {
                            api = api.withMethod(MethodImpl.from(item.spec, item.inherited));
                        }
                        for (const item of Object.values(newApi.features).filter(onlySelfItems)) {
                            api = api.withFeature(FeatureImpl.from(item.spec, item.inherited));
                        }
                        for (const item of Object.values(newApi.outlets).filter(onlySelfItems)) {
                            // api should be
                            if (!item.apiRef) throw new Error(`outlet ${item.spec.id}: apiRef is required`);
                            const outletApi = p.firmware.resolveApiRef({ ref: item.apiRef });
                            if (!outletApi) throw new Error(`outlet ${item.spec.id}: no such api "${item.apiRef}"`);
                            api = api.withOutlet(OutletImpl.from({ ...item.spec, api: outletApi }, item.inherited));
                        }

                        object = object.withApi(api);
                    }
                    p = p.withObject(object);
                }
            }
            return p;
        });
    }
}
