import { RichTreeView, RichTreeViewProps } from "@mui/x-tree-view";
import { GRichTreeRendererContext } from "./context";
import { GRichTreeItemRenderable } from "./GRichTreeItemRenderable";
import { GTreeItemCheckboxState, GTreeItemClickHandler, GTreeItemRenderer } from "./types";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { useMemo } from "react";

type GRichTreeProps<R extends {}, Multiple extends boolean| undefined> = RichTreeViewProps<R, Multiple> & 
    {
        renderer: GTreeItemRenderer<R>
        onItemClick?: GTreeItemClickHandler<R>
        checkboxProps? : Record<string,GTreeItemCheckboxState>
    }

export function GRichTreeView<R extends {}, Multiple extends boolean| undefined>(props: GRichTreeProps<R, Multiple>) {
    const {renderer, onItemClick, checkboxProps, slots, ...other} = props

    const gSlots = useMemo(()=>({...slots, item:GRichTreeItemRenderable, collapseIcon: ArrowDropDown, expandIcon: ArrowRight}), [slots])
    const gContext = useMemo(()=>({renderer, onItemClick, checkboxProps}),[renderer, onItemClick, checkboxProps])

    return <GRichTreeRendererContext.Provider value={gContext}>
        <RichTreeView {...other} slots={gSlots}/>
    </GRichTreeRendererContext.Provider>
}