import { ObjectApi, isApiExtending, prot } from '@grenton/gm-common';
import { ActionTemplate } from '../../action-template';
import { ActionLedRGBSwitchGenerator } from './generator';
import { ActionLedRGBSwitchModel } from './model';
import { ActionLedRGBSwitchWidget } from './widget';

export const ActionLedRGBSwitch: ActionTemplate<ActionLedRGBSwitchModel> = {
    type: 'rgb-switch',
    label: 'Switch',
    order: 2,
    defaults: { time: 0, ramp: 0 },
    widget: ActionLedRGBSwitchWidget,
    generator: ActionLedRGBSwitchGenerator,
    isApplicable(api: ObjectApi) {
        return isApiExtending(api, prot.LedRGBW);
    },
};
