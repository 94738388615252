import { TheaterComedy } from "@mui/icons-material"
import { ButtonBase, Box, Stack, Typography } from "@mui/material"
import { SystemObject } from "@grenton/gm-logic"
import { AppWidgetBox } from "./WidgetBox"
import { SystemModel } from "@grenton/gm-logic"
import { AppWidget } from "@grenton/gm-logic"
import { ObjectRPCExecutorContext } from "../object-rpc-executor-context"
import { useContext } from "react"


export namespace WidgetScene {
    export const Type = "SceneWidgetType"

    export type Config = { uuid: string, method: string }

    export interface Props {
        widget: AppWidget<Config>
    }

    function Widget(props: Props) {
        const executor = useContext(ObjectRPCExecutorContext)
        return <AppWidgetBox padding={0}>
            <ButtonBase sx={{ flexGrow: 2, pt: 2, pb: 2, pr: 2, pl: 2 }} onClick={() => executor(props.widget.config.uuid, { method: props.widget.config.method })}>
                <Box pr={2}><TheaterComedy color="primary" /></Box>
                <Stack alignItems="start" direction="column" flexGrow={2} alignContent={"center"}>
                    <Typography variant="body1" flexGrow={2}>{props.widget.title}</Typography>
                </Stack>
            </ButtonBase>
        </AppWidgetBox>
    }

    // creates 'widget definition'
    export function Factory(object: SystemObject): AppWidget<Config>[] {
        const scenes = Object.values(object.api.flat.methods)
            .filter(m => m.scene)
        return scenes.map(scene => ({
            id: `${object.uuid}-${Type}-${scene.id}`,
            type: Type,
            title: scene.label || scene.id,
            config: {
                uuid: object.uuid,
                method: scene.label || scene.id,
            },
            descriptor: {
                tags : object.config.tags,
                device: object.config.device,
                scene:true
            }
        }))
    }

    export function Renderer(widget: AppWidget<Config>, _system: SystemModel<SystemObject>): [(props: Props) => JSX.Element, Props] {
        return [Widget, { widget }]
    }
}
