import { Components, Theme } from '@mui/material';
import { grentonColors } from '../colors';

export const MuiAccordion: Components<Omit<Theme, 'components'>>['MuiAccordion'] = {
    defaultProps: {
        elevation: 0,
        square: true,
    },
    styleOverrides: {
        root: () => ({
            borderRadius: 0,
            borderColor: grentonColors.Line_Menu,
            borderWidth: 1,
            borderStyle: 'solid',
            borderTopWidth: 0,

            '&::before': {
                backgroundColor: grentonColors.Line_Menu,
            },
            '&:first-of-type': {
                borderTopWidth: 1,
            },
            '&.Mui-expanded': {
                borderTopWidth: 1,
            },
        }),
    },
};
