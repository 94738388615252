import { ActionCall } from '@grenton/gm-common';
import { ActionLedRGBSetModel } from './model';
import { ResolvedPath } from '@grenton/gm-logic';

export function ActionLedRGBSetGenerator(model: ActionLedRGBSetModel, _: ResolvedPath) {
    const calls: ActionCall[] = [];

    if (model.on) {
        if (model.h?.set) {
            calls.push({ callable: 'setHue', params: { value: (model.h.value || 0) / 100 } });
        }

        if (model.s?.set) {
            calls.push({ callable: 'setSaturation', params: { value: (model.s.value || 0) / 100 } });
        }

        if (model.v?.set) {
            calls.push({ callable: 'setValue', params: { value: (model.v.value || 0) / 100 } });
        }
    }

    calls.push({ callable: model.on ? 'switchOn' : 'switchOff', params: { ramp: model.ramp } });

    return { calls };
}
