import { MethodInvocation, ObjectEmulator, ObjectEventEmitter, ObjectStateHolder, Scheduler } from './common';
import { fabric, schema } from '@grenton/gm-common';

export abstract class DimmableObjectEmulator implements ObjectEmulator {
    private previousHoldValueAction = { step: -0.05, actionTime: performance.now() };
    protected constructor(
        protected state: ObjectStateHolder,
        protected emitter: ObjectEventEmitter,
        protected scheduler: Scheduler,
    ) {}

    abstract execute(invocation: MethodInvocation): Promise<schema.PropertyValue>;

    onStateChange(curr: fabric.ObjectState, prev?: fabric.ObjectState) {
        //todo can prev really be undefined?
        const value = curr.features['value'] as number;
        if (prev && prev.features['value'] !== value) {
            //todo only value?
            const names: string[] = [];
            const state: fabric.ObjectState = { features: { value: value } };
            names.push('onValueChange');
            if (value === 1) {
                names.push('onSwitchOn');
            } else if (value === 0) {
                names.push('onSwitchOff');
            } else if (value > 1 || value < 0) {
                //todo is this possible?
                names.push('onOutOfRange');
            }

            if (value > (prev.features['value'] as number)) {
                names.push('onValueRise');
            } else {
                names.push('onValueLower');
            }

            this.emitter({ names, state });
        }
    }

    protected round(value: number): number {
        return Math.round(value * 100) / 100;
    }

    protected determineStep(): number {
        let result: number;
        const currTime = performance.now();
        const timeDiff = currTime - this.previousHoldValueAction.actionTime;
        if (timeDiff > 800) {
            result = this.previousHoldValueAction.step < 0 ? 0.05 : -0.05;
            this.previousHoldValueAction = { step: result, actionTime: currTime };
        } else {
            result = this.previousHoldValueAction.step;
            this.previousHoldValueAction.actionTime = currTime;
        }
        return result;
    }

    protected schedule(fn: () => void, timeInMs?: number) {
        if (timeInMs && timeInMs > 0) {
            this.scheduler.setTimeout(fn, timeInMs);
        }
    }

    protected set(featureName: string, targetValue: number, rampTimeInMs: number) {
        if (rampTimeInMs === 0) {
            this.state.set(featureName, targetValue);
            return;
        }
        const initialVal = (this.state.get(featureName) as number) || 0;
        const resolutionInMs = 100;
        const steps = Math.max(1, Math.ceil(rampTimeInMs / resolutionInMs));
        const incrementVal = (targetValue - initialVal) / steps;

        let value = initialVal;
        let stepCount = 0;

        const interval = setInterval(() => {
            value = this.round(value + incrementVal);
            stepCount++;
            this.state.set(featureName, value);
            if (stepCount >= steps) {
                if (value !== targetValue) {
                    //in case of float rounding inaccuracy
                    this.state.set(featureName, targetValue);
                }
                clearInterval(interval);
            }
        }, resolutionInMs);
    }
    protected hold(featureName: string) {
        const currentValue = this.state.get(featureName) as number;
        const step = this.determineStep();
        const valueToSet = this.round(Math.min(Math.max(currentValue + step, 0), 1));
        this.state.set(featureName, valueToSet);
    }

    protected switch(featureName: string, rampTimeInMs: number, timeInMs?: number) {
        const initialValue = this.state.get(featureName) as number;
        const targetValue = initialValue === 0 ? 1 : 0;
        this.set(featureName, targetValue, rampTimeInMs);

        this.schedule(() => this.set(featureName, initialValue, rampTimeInMs), timeInMs); //todo What happens if I click multiple times?
    }

    protected switchOn(featureName: string, rampTimeInMs: number, timeInMs?: number) {
        const initialValue = this.state.get(featureName) as number;
        const targetValue = 1;
        this.set(featureName, targetValue, rampTimeInMs);

        this.schedule(() => this.set(featureName, initialValue, rampTimeInMs), timeInMs);
    }

    protected switchOff(featureName: string, rampTimeInMs: number, timeInMs?: number) {
        const initialValue = this.state.get(featureName) as number;
        const targetValue = 0;
        this.set(featureName, targetValue, rampTimeInMs);

        this.schedule(() => this.set(featureName, initialValue, rampTimeInMs), timeInMs);
    }
}
