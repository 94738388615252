import { Check } from "@mui/icons-material";
import {Box} from "@mui/material";
import type {CSSProperties, ReactElement} from 'react';
import { sizeRem, sizes } from "../../../theme2/size";
import { grentonColors } from "../../../theme2";

export type TagLabelProps = {
    label: string | ReactElement,
    color?: string,
    blank?: boolean;
    style?: CSSProperties;
    borderRadius?: string;
    checked?:boolean;
}


export function TagLabel(props: TagLabelProps) {
    return <Box style={props.style} sx={{
        display:'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        gap:'0.5em',
        opacity: props.checked === true || props.checked === undefined ? 1 : 0.6,
        color: props.color && !props.blank ? grentonColors.Font_White : grentonColors.Font_Label, 
        minWidth: '4em', borderRadius: props?.borderRadius ? props.borderRadius : '1em',
        fontSize: sizeRem(sizes.S),
        fontWeight: 600,
        lineHeight: '1.75em', paddingLeft: '0.5em', paddingRight: '0.5em',
        backgroundColor: props.blank ? '#fff' : (props.color || 'transparent'),
        borderWidth: 1, 
        borderStyle: 'solid', 
        borderColor: props.color && !props.blank ? 'transparent' : grentonColors.Line_ButtonQuaternary,
        textAlign: 'center'
    }}>
        {props.checked && <Check/>}
        {props.label}
    </Box>
}
