export type SemanticVersion = string;

export type FQKey = {
    org: string;
    name: string;
    version: SemanticVersion;
};

export function serializeFQKey(key: FQKey): string {
    return `${key.org}/${key.name}/${key.version}`;
}
