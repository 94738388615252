import { Palette, PaletteColor, PaletteOptions } from '@mui/material';
import { grentonColors } from './colors';

declare module '@mui/material/styles' {
    interface Palette {
        white: Palette['primary'];
        greyish: Palette['primary'];
    }

    interface PaletteOptions {
        white?: PaletteOptions['primary'];
        greyish?: PaletteOptions['primary'];
    }
}

export const palette: PaletteOptions = {
    // TODO (I can't figure out how to use them)
    // action: {
    //     selected: grentonColors.black['100%'],
    //     hover: grentonColors.black['100%'],
    //     active: grentonColors.black['100%'],
    //     focusOpacity: 0.05,
    //     hoverOpacity: 0.05,
    //     activatedOpacity: 0.05,
    //     selectedOpacity: 0.05,
    //     focus: grentonColors.black['100%'],
    // },
    action: {
        // this does not work, see https://github.com/mui/material-ui/issues/28691
        // not sure why they use primary+opacity, instead of falling back to primary when this one is unset
        // selected:'#00ff00',
        // opacity applied to primary color to create 'selected' color
        selectedOpacity: 0.2,

        // self-explanatory; but hovering "selected" element does not change its color, it can change opacity of 'selected' color (via hoverOpacity)
        // hover: grentonColors.accent.hover,
        // hoverOpacity:0.5,

        /**
         * "active" in MUI means "available for interactions"
         * there's a lot of confusion around it, as can be seen here : https://github.com/mui/material-ui/issues/24941
         * generally, seems a bit useless and it is applied sporadically (to icons in list items for example...)
         * in our case it probably makes sense to set it to font color, but I'm not sure.
         */
        active: grentonColors.Font_Black,

        /**
         * focus should be subtle. it is mostly used for keyboard navigation
         */
        //focus: ...
    },
    text: {
        primary: grentonColors.black['100%'],
        secondary: grentonColors.black['90%'],
        disabled: grentonColors.black['50%'],
    },
    common: {
        black: grentonColors.black['100%'],
        white: grentonColors.white[100],
    },
    primary: {
        main: grentonColors.accent['100%'],
        dark: grentonColors.accent['hover'],
        contrastText: grentonColors.white[100],
    },
    secondary: {
        main: grentonColors.kris_blackish, //.black['100%'],
        dark: grentonColors.black['90%'],
        contrastText: grentonColors.white[100],
    },
    white: {
        main: grentonColors.white[100],
        light: grentonColors.white[100],
        dark: grentonColors.black['5%'],
        contrastText: grentonColors.black['100%'],
    },
    greyish: {
        main: grentonColors.grey[300],
        light: grentonColors.grey[300],
        dark: grentonColors.white[100],
        contrastText: grentonColors.black['100%'],
    },
};

export function getMatchingPaletteColor(palette: Palette, color: string | undefined): PaletteColor {
    const c = palette[color as keyof Palette];
    return (c as PaletteColor)?.main ? (c as PaletteColor) : palette.primary;
}
