import { ObjectApi, isApiExtending, prot } from '@grenton/gm-common';
import { ActionTemplate } from '../../action-template';
import { ActionLedRGBSetGenerator } from './generator';
import { ActionLedRGBSetModel } from './model';
import { ActionLedRGBSetWidget } from './widget';

export const ActionLedRGBSet: ActionTemplate<ActionLedRGBSetModel> = {
    type: 'rgb-set',
    label: 'Set',
    order: 1,
    defaults: { on: true, ramp: 0 },
    widget: ActionLedRGBSetWidget,
    generator: ActionLedRGBSetGenerator,
    isApplicable(api: ObjectApi) {
        return isApiExtending(api, prot.LedRGBW);
    },
};
