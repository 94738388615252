import { Components, Theme } from '@mui/material';
import { spacing } from '../size';
import { themeVariant } from '../../variant';

export const MuiDialogTitle: Components<Omit<Theme, 'components'>>['MuiDialogTitle'] = {
    defaultProps: {
        // design suggests "l" in standalone dialogs and "m" in side dialogs
        variant: 'l',
    },
    styleOverrides: {
        root: ({ theme }) =>
            themeVariant === 'kris'
                ? {
                      paddingLeft: spacing.XS3,
                      paddingRight: spacing.XS3,
                      paddingTop: spacing.XS,
                      paddingBottom: spacing.XS,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(1),

                      '&.MuiTypography-root+.MuiDialogContent-root': {
                          paddingTop: spacing.XS2,
                      },
                  }
                : {
                      paddingLeft: spacing.M,
                      paddingRight: spacing.M,
                      paddingTop: spacing.S,
                      paddingBottom: spacing.S,

                      '&.MuiTypography-root+.MuiDialogContent-root': {
                          paddingTop: spacing.M,
                      },
                  },
    },
};
