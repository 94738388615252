import { REF_SELF, OUTLET_OBJECT } from '@grenton/gm-common/src';
import { ObjectScriptRef, isAnonymousController, blocks } from '@grenton/gm-logic';
import { WorkspaceSvg } from 'blockly';
import { ToolboxItemInfo } from 'blockly/core/utils/toolbox';

export function toolboxGrentonCategory(getRef: () => ObjectScriptRef | undefined) {
    return function (_: WorkspaceSvg) {
        const ref = getRef();
        if (!ref) return [];
        const isAnonymous = isAnonymousController(ref?.objectId);
        const extraStateOfEntityBlock = { path: isAnonymous ? [REF_SELF, OUTLET_OBJECT] : [REF_SELF] };

        const content: ToolboxItemInfo[] = [];

        content.push({
            kind: 'block',
            type: blocks.ApiMethodInvoke.Type,
            inputs: {
                [blocks.ApiMethodInvoke.ENTITY_FIELD]: {
                    block: {
                        type: blocks.ApiEntityWithApiBlock.Type,
                        extraState: extraStateOfEntityBlock,
                    },
                },
            },
        });

        content.push({
            kind: 'block',
            type: blocks.ApiFeatureGet.Type,
            inputs: {
                [blocks.ApiMethodInvoke.ENTITY_FIELD]: {
                    block: {
                        type: blocks.ApiEntityWithApiBlock.Type,
                        extraState: extraStateOfEntityBlock,
                    },
                },
            },
        });

        content.push({
            kind: 'block',
            type: blocks.ApiFeatureSet.Type,
            inputs: {
                [blocks.ApiMethodInvoke.ENTITY_FIELD]: {
                    block: {
                        type: blocks.ApiEntityWithApiBlock.Type,
                        extraState: extraStateOfEntityBlock,
                    },
                },
            },
        });

        content.push({
            kind: 'block',
            type: blocks.ApiOutletGet.Type,
            inputs: {
                [blocks.ApiOutletGet.ENTITY_FIELD]: {
                    block: {
                        type: blocks.ApiEntityWithApiBlock.Type,
                        extraState: extraStateOfEntityBlock,
                    },
                },
            },
        });

        content.push({
            kind: 'block',
            type: blocks.TagBlock.Type,
        });
        content.push({
            kind: 'block',
            type: blocks.TypeBlock.Type,
        });
        content.push({
            kind: 'block',
            type: blocks.TagsByCategoryBlock.Type,
        });
        content.push({
            kind: 'block',
            type: blocks.FirstObjectBlock.Type,
        });
        // we should rather use regular get from list block
        // content.push({
        //     kind: 'block',
        //     type: blocks.NthObjectFromOutletBlock.Type,
        // });
        content.push({
            kind: 'block',
            type: blocks.ObjectSelectionAllBlock.Type,
        });
        content.push({
            kind: 'block',
            type: blocks.ObjectSelectionByApiBlock.Type,
        });
        content.push({
            kind: 'block',
            type: blocks.ObjectSelectionByTagBlock.Type,
        });
        content.push({
            kind: 'block',
            type: blocks.ObjectSelectionByTypeBlock.Type,
        });

        // iterate over outlet objects <experimental>
        // seems like too complex for blockly (and tricky to track variable type)
        // we'll add 'first-from-outlet' instead to extract an object
        // content.push({
        //     kind: "block",
        //     type: ForEachObject.Type
        // })

        // standalone controller only
        if (!isAnonymous) {
            // emit controller event
            content.push({
                kind: 'block',
                type: blocks.EmitEventBlock.Type,
            });
        }

        // method param. in context of event handler, it should contain "event_source"
        content.push({
            kind: 'block',
            type: blocks.MethodParamBlock.Type,
            fields: {
                [blocks.MethodParamBlock.PARAM_FIELD]: ref.scriptRef.type === 'event' ? blocks.MethodParamBlock.SOURCE_OPTION[1] : '',
            },
        });

        // controller's methods only (not event handlers)
        if (ref.scriptRef.type === 'method') {
            // return value from method
            content.push({
                kind: 'block',
                type: blocks.MethodReturn.Type,
            });
        }

        return content;
    };
}
