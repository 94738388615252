import { isConfigurationSchemaVersionSupported, SUPPORTED_CONFIGURATION_SCHEMA_VERSIONS } from '@grenton/contract';
import { Project } from '@grenton/gm-common/src';
import { PartialValidationResult, NO_ERRORS } from './helpers';
import { parseConfigurationSchemaVersion } from './parseConfigurationSchemaVersion';

export function validateVersion(project: Project): PartialValidationResult {
    const fullVersion = parseConfigurationSchemaVersion(project.$schema);

    if (fullVersion.isErr()) {
        return { errors: [{ type: 'schema', message: fullVersion.error }], fatal: true };
    }
    if (!isConfigurationSchemaVersionSupported(fullVersion.value)) {
        return {
            errors: [
                {
                    type: 'schema',
                    message: `Unsupported schema version ${fullVersion}, supported versions : ${SUPPORTED_CONFIGURATION_SCHEMA_VERSIONS.join(', ')}`,
                },
            ],
            fatal: true,
        };
    }
    return NO_ERRORS;
}
