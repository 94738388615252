import { schema } from '@grenton/gm-common';

export class FeatureConfigImpl {
    constructor(
        readonly id: string,
        readonly value: schema.PropertyValue,
    ) {}

    withValue(value: schema.PropertyValue) {
        return new FeatureConfigImpl(this.id, value);
    }
}

export class FeatureImpl {
    static from(spec: schema.Feature, inherited: boolean) {
        return new FeatureImpl({ spec, inherited });
    }

    /*
     * while editing methods and features it is better to rely on temporary uuid rather than on a name
     * that can change during edition.
     * we assign these uuids when project is imported and they are being thrown away when project is exported!
     */
    constructor(private data: { spec: schema.Feature; inherited: boolean }) {}

    get inherited() {
        return this.data.inherited;
    }

    get spec() {
        return this.data.spec;
    }

    get id() {
        return this.data.spec.id;
    }

    get name() {
        return this.data.spec.label || this.data.spec.id;
    }

    withSpec(spec: schema.Feature) {
        return new FeatureImpl({ ...this.data, spec });
    }

    withDefaultValue(defaultValue: schema.PropertyValue) {
        return this.withSpec({ ...this.data.spec, default: defaultValue });
    }

    withType(type: schema.PropertyValueType) {
        return this.withSpec({ ...this.data.spec, type });
    }

    withReadOnly(readOnly: boolean) {
        return this.withSpec({ ...this.data.spec, readOnly });
    }

    withName(label: string) {
        return this.withSpec({ ...this.data.spec, label });
    }

    ensureDefaultValueType(): FeatureImpl {
        let defaultValue = this.data.spec.default;
        switch (this.data.spec.type) {
            case 'boolean': {
                if (typeof defaultValue !== 'boolean') defaultValue = false;
                break;
            }
            case 'number': {
                if (typeof defaultValue !== 'number') defaultValue = 0;
                break;
            }
            case 'string': {
                if (typeof defaultValue !== 'string') defaultValue = '';
                break;
            }
            case schema.PROPERTY_TYPE_VOID: {
                defaultValue = undefined;
                break;
            }
            // TODO not sure - object probably will not be used as feature type
            case 'object': {
                if (typeof defaultValue !== 'object') defaultValue = {};
                break;
            }
        }
        return this.withSpec({ ...this.data.spec, default: defaultValue });
    }
}
