import { ANONYMOUS_CONTROLLER_SUFFIX, Lists, ObjectID, OUTLET_OBJECT, ProjectItemPath, REF_SELF, schema } from '@grenton/gm-common';
import { ProjectObjectImpl } from './object';
import { OutletImpl } from './object-outlet';
import { ObjectResolver } from './project';

export type ResolvedPathElem =
    | {
          type: 'object';
          id: string;
          name: string;
          entity: ProjectObjectImpl;
      }
    | {
          type: 'method';
          id: string;
          name: string;
          method: schema.Method;
      }
    | {
          type: 'event';
          id: string;
          name: string;
          event: schema.Event;
      }
    | {
          type: 'feature';
          id: string;
          name: string;
          feature: schema.Feature;
      }
    | {
          type: 'outlet';
          id: string;
          name: string;
          index?: number;
          entity: OutletImpl;
      };

function withIndex(self: boolean, baseName: string, elem: ResolvedPathElem) {
    if (self && elem.type === 'object') {
        return REF_SELF;
    } else if (elem.type === 'outlet' && elem.index !== undefined) {
        return `${baseName}[${elem.index}]`;
    } else {
        return baseName;
    }
}

export class ResolvedPath {
    static EMPTY = Object.freeze(new ResolvedPath(false, []));

    constructor(
        readonly self: boolean,
        readonly items: ResolvedPathElem[],
    ) {}

    push(item: ResolvedPathElem) {
        return new ResolvedPath(this.self, [...this.items, item]);
    }

    serialize(): ProjectItemPath {
        return this.items.map((item, i) => withIndex(this.self && i === 0, item.id, item));
    }

    serializeToString(): string {
        return this.serialize().join('.');
    }

    serializeNames(): ProjectItemPath {
        return this.items.map((item, i) => withIndex(this.self && i === 0, item.name, item));
    }

    withoutTail(): ResolvedPath {
        return this.empty ? this : new ResolvedPath(this.self, this.items.slice(0, this.items.length - 1));
    }

    withoutHead(): ResolvedPath {
        return this.empty ? this : new ResolvedPath(false, this.items.slice(1));
    }

    get empty() {
        return this.items.length === 0;
    }

    get head() {
        return this.empty ? undefined : this.items[0];
    }

    get tail() {
        return this.empty ? undefined : this.items[this.items.length - 1];
    }

    get length() {
        return this.items.length;
    }

    /**
     * object at the root of the path (if any)
     */
    get rootObject() {
        return this.head?.type === 'object' ? this.head.entity : undefined;
    }

    get outputApi() {
        for (let i = this.items.length - 1; i >= 0; i--) {
            const item = this.items[i]!;
            if (item.type === 'object' || item.type === 'outlet') {
                return item.entity.api.api;
            }
        }
        return null;
    }

    get relative() {
        const h = this.head;
        return h?.type === 'object' && h?.id === REF_SELF;
    }

    /**
     * if possible, turn absolute path into path relative to headID.
     * it may be possible in two scenarios: passed id is an object ID or anonymous controller for the object,
     * in the latter case we construct relative path via "object" outlet.
     * @param headID
     * @returns
     
    relativizeTo(root: ProjectObjectImpl): ResolvedPath {
        if (this.empty || this.relative) return this;
        const head = this.head!;
        if (head.type === 'object') {
            if (head.id === root.uuid) {
                return new ResolvedPath([
                    { type: 'object', id: REF_SELF, name: head.entity.label, entity: head.entity },
                    ...this.items.slice(1),
                ]);
            } else if (root.uuid === `${head.id}${ANONYMOUS_CONTROLLER_SUFFIX}`) {
                return new ResolvedPath([
                    {
                        type: 'object',
                        id: REF_SELF,
                        name: head.entity.label,
                        entity: head.entity,
                    },
                    {
                        type: 'outlet',
                        id: OUTLET_OBJECT,
                        name: OUTLET_OBJECT,
                        entity: head.entity.api.outlets[OUTLET_OBJECT]!,
                    },
                    ...this.items.slice(1),
                ]);
            }
        }
        return this;
    }*/

    isSame(path: ProjectItemPath) {
        return Lists.areEqual(this.serialize(), path);
    }
}

/**
 * recursively resolves given path starting from given entity (path must not include entity itself)
 * e.g.
 *
 * resolvePathStartingFrom(object, ['outlet1', 'feature1', 'method1'])
 * will create outlet1.feature1.method1 path where each element contain additional information about type, api etc.
 *
 * please note that raw "path" should be constructed based on IDs, not names!
 * TBD: I'd prefer to throw when path is invalid instead of leaving it partially resolved...
 */
function resolvePathStartingFrom(entity: ProjectObjectImpl | OutletImpl, path: string[]): ResolvedPath {
    const head = path[0];
    if (!head) return ResolvedPath.EMPTY;
    const rest = path.slice(1);
    const projectApi = entity.api;

    const feature = projectApi.features[head];
    if (feature) {
        return new ResolvedPath(false, [{ type: 'feature', id: head, name: feature.name, feature: feature.spec }]);
    }
    const method = projectApi.methods[head];
    if (method) {
        return new ResolvedPath(false, [{ type: 'method', id: head, name: method.name, method: method.spec }]);
    }
    const event = projectApi.events[head];
    if (event) {
        return new ResolvedPath(false, [{ type: 'event', id: head, name: event.name, event: event.spec }]);
    }

    const outlet = projectApi.outlets[head];
    if (outlet) {
        return new ResolvedPath(false, [
            {
                type: 'outlet',
                id: head,
                name: outlet.name,
                entity: outlet,
            },
            ...resolvePathStartingFrom(outlet, rest).items,
        ]);
    }

    const outletIndexed = head.match(/(.+)\[(\d+)\]/);
    if (outletIndexed) {
        const outletId = outletIndexed[1]!;
        const outlet = projectApi.outlets[outletId];
        if (outlet) {
            const index = parseInt(outletIndexed[2]!);
            if (index >= 1 && (outlet.spec.maxItems === undefined || index <= outlet.spec.maxItems)) {
                return new ResolvedPath(false, [
                    { type: 'outlet', id: outlet.id, name: outlet.name, index, entity: outlet },
                    ...resolvePathStartingFrom(outlet, rest).items,
                ]);
            }
        }
    }

    return ResolvedPath.EMPTY;
}

/*
 * resolve path inside object graph, e.g. {obj-a-id}.{outlet-x-id}.{outlet-y-id}[2].{method-z-id}
 *
 * input path is a list of strings, where each string is either object/outlet/event/method/feature ID.
 * we also allow path to start from "self" token, but to resolve it, substituteForSelf must be provided (otherwise we don't know where to start)
 *
 * resolved path must be
 * 1) valid, meaning you next item in the path appears in API of previous item. first elem must be object or outlet, and event/method/feature item may be only the last one.
 * 2) include 'self' marker which means it can be rendered as starting from "self" token instead of object ID/name.
 *
 * it must include all necessary protocol information
 *
 * if path is relative (starts with "self"), selfObject must be provided
 * if selfObject is provided we also try to transform path to start from this object, which means we search for a common ancestor.
 * it if is found, resulted path is modified to start from "selfObject" and path is marked as self=true. if not, path stays unchanged and self=false.
 *
 * use-cases:
 * 1. toolbox always uses "self" paths because it is always in context of a currently edited script
 * 2. context tree uses absolute paths because it is too complex to rewrite all paths every time user changes edited object
 * 3. external invokers will most likely use absolute paths
 * 4. actions store targets as relative paths (if possible ofc), e.g. "self.outlet1.feature1.method1"
 *
 * panelX -> buttons[2] -> onClick
 * self -> parent -> buttons[2] -> onClick
 */
export function resolvePath(path: ProjectItemPath, objectResolver: ObjectResolver, proposedRoot?: ObjectID): ResolvedPath {
    let head = path[0];
    if (!head) return ResolvedPath.EMPTY;
    let self = false;
    if (head === REF_SELF) {
        if (!proposedRoot) throw new Error(`path ${path.join('.')} is relative, but root object was not provided`);
        head = proposedRoot;
        self = true;
    }
    const object = head ? objectResolver(head) : undefined;
    if (object) {
        // resolve path starting from object
        const rest = resolvePathStartingFrom(object, path.slice(1));
        // now construct entire path incl object
        const absolutePath = new ResolvedPath(self, [
            {
                type: 'object',
                id: object.uuid,
                name: object.label,
                entity: object,
            },
            ...rest.items,
        ]);

        if (proposedRoot) {
            const root = objectResolver(proposedRoot);
            return root ? relativizeTo(absolutePath, root) : absolutePath;
        } else {
            return absolutePath;
        }
    } else {
        return ResolvedPath.EMPTY;
    }
}

/*
 * this is simple version, that handles only basic cases.
 * in more advanced we want to investigate root in two directions
 * - ascending, by checking its parent
 * - descending, by checking its subobjects
 *
 * find head of given path, and then concatenate these two paths.
 */
function relativizeTo(path: ResolvedPath, root: ProjectObjectImpl): ResolvedPath {
    if (path.empty || path.relative) return path;
    const head = path.head!;
    if (head.type === 'object') {
        if (head.id === root.uuid) {
            return new ResolvedPath(true, path.items);
        } else if (root.uuid === `${head.id}${ANONYMOUS_CONTROLLER_SUFFIX}`) {
            return new ResolvedPath(true, [
                {
                    type: 'object',
                    id: root.uuid,
                    name: root.label,
                    entity: root,
                },
                {
                    type: 'outlet',
                    id: OUTLET_OBJECT,
                    name: OUTLET_OBJECT,
                    entity: root.api.outlets[OUTLET_OBJECT]!,
                },
                ...path.items.slice(1),
            ]);
        }
    }
    return path;
}
