import { ApiItems, ObjectApi, ObjectOutlet } from './model';
import * as schema from './api';

export class ApiItemsImpl implements ApiItems {
    static EMPTY = new ApiItemsImpl({}, {}, {}, {});

    constructor(
        readonly events: Record<string, schema.Event>,
        readonly methods: Record<string, schema.Method>,
        readonly features: Record<string, schema.Feature>,
        readonly outlets: Record<string, ObjectOutlet>,
    ) {}

    withOutlet(outlet: ObjectOutlet): ApiItemsImpl {
        return new ApiItemsImpl(this.events, this.methods, this.features, { ...this.outlets, [outlet.id]: outlet });
    }

    withoutOutlet(id: string): ApiItemsImpl {
        const { [id]: _, ...outletsWithoutSpecifiedId } = this.outlets;
        return new ApiItemsImpl(this.events, this.methods, this.features, outletsWithoutSpecifiedId);
    }

    withMethod(method: schema.Method): ApiItemsImpl {
        return new ApiItemsImpl(this.events, { ...this.methods, [method.id]: method }, this.features, this.outlets);
    }

    withoutMethod(id: string): ApiItemsImpl {
        const { [id]: _, ...methodsWithoutSpecifiedId } = this.methods;
        return new ApiItemsImpl(this.events, methodsWithoutSpecifiedId, this.features, this.outlets);
    }

    withFeature(feature: schema.Feature): ApiItemsImpl {
        return new ApiItemsImpl(this.events, this.methods, { ...this.features, [feature.id]: feature }, this.outlets);
    }

    withoutFeature(id: string): ApiItemsImpl {
        const { [id]: _, ...featuresWithoutSpecifiedId } = this.features;
        return new ApiItemsImpl(this.events, this.methods, featuresWithoutSpecifiedId, this.outlets);
    }

    withEvent(event: schema.Event): ApiItemsImpl {
        return new ApiItemsImpl({ ...this.events, [event.id]: event }, this.methods, this.features, this.outlets);
    }

    withoutEvent(id: string): ApiItemsImpl {
        const { [id]: _, ...eventsWithoutSpecifiedId } = this.events;
        return new ApiItemsImpl(eventsWithoutSpecifiedId, this.methods, this.features, this.outlets);
    }
}

export class ObjectApiImpl implements ObjectApi {
    public readonly flat: ApiItemsImpl;

    constructor(
        public readonly id: string,
        public readonly meta: schema.ProtocolMeta,
        public readonly self: ApiItemsImpl,
        public readonly extending: ObjectApi[],
    ) {
        if (extending.length) {
            // TODO here we should ensure that self items do not override extended items
            // to avoid invalid API hierarchy!
            // so if A extends [B,C], B and C may define the same item-x, but A cannot define item-x.

            let all_events = { ...self.events };
            let all_methods = { ...self.methods };
            let all_features = { ...self.features };
            let all_outlets = { ...self.outlets };

            // TODO we must ensure that items are overridden in a valid way, e.g.
            // api can add params to a method but cannot change type of existing params
            extending.forEach((apiExt) => {
                all_events = { ...all_events, ...apiExt.flat.events };
                all_methods = { ...all_methods, ...apiExt.flat.methods };
                all_features = { ...all_features, ...apiExt.flat.features };
                all_outlets = { ...all_outlets, ...apiExt.flat.outlets };
            });

            this.flat = new ApiItemsImpl(all_events, all_methods, all_features, all_outlets);
        } else {
            this.flat = this.self;
        }
    }

    withFeature(feature: schema.Feature): ObjectApiImpl {
        return new ObjectApiImpl(this.id, this.meta, this.self.withFeature(feature), this.extending);
    }

    withoutFeature(id: string): ObjectApiImpl {
        return new ObjectApiImpl(this.id, this.meta, this.self.withoutFeature(id), this.extending);
    }

    withMethod(method: schema.Method): ObjectApiImpl {
        return new ObjectApiImpl(this.id, this.meta, this.self.withMethod(method), this.extending);
    }

    withoutMethod(id: string): ObjectApiImpl {
        return new ObjectApiImpl(this.id, this.meta, this.self.withoutMethod(id), this.extending);
    }

    withEvent(event: schema.Event): ObjectApiImpl {
        return new ObjectApiImpl(this.id, this.meta, this.self.withEvent(event), this.extending);
    }

    withoutEvent(id: string): ObjectApiImpl {
        return new ObjectApiImpl(this.id, this.meta, this.self.withoutEvent(id), this.extending);
    }

    withOutlet(outlet: ObjectOutlet): ObjectApiImpl {
        return new ObjectApiImpl(this.id, this.meta, this.self.withOutlet(outlet), this.extending);
    }

    withoutOutlet(id: string): ObjectApiImpl {
        return new ObjectApiImpl(this.id, this.meta, this.self.withoutOutlet(id), this.extending);
    }

    withoutAllItems(): ObjectApiImpl {
        return new ObjectApiImpl(this.id, this.meta, ApiItemsImpl.EMPTY, this.extending);
    }

    // setParent(parent: ObjectApi): void {
    //     this.self.setParent(parent);
    //     if (this.self !== this.flat) {
    //         this.flat.setParent(parent);
    //     }
    // }

    // not sure about this... we need a way to add new protocols to controllers though
    withExtending(api: ObjectApi) {
        if (this.extending.find((a) => a.id === api.id)) {
            return this;
        } else {
            return new ObjectApiImpl(this.id, this.meta, this.self, [...this.extending, api]);
        }
    }
}
