import { createElement } from "react"
import { AppWidget } from "@grenton/gm-logic"
import { AppWidgetInstance } from "@grenton/gm/my-grenton/app-layout-impl"
import { SystemModel } from "@grenton/gm-logic"
import { SystemObject } from "@grenton/gm-logic"

type Renderer<C,P> = (widget: AppWidget<C>, system:SystemModel<SystemObject>) => [(props: P) => JSX.Element, P]

class AppWidgetRenderer {

    private renderers: {[type:string]:Renderer<any,any>} = {}

    register<C,P>(type:string, renderer:Renderer<C,P>) {
        this.renderers[type] = renderer
    }

    render<C>(widget:AppWidgetInstance<C>, system:SystemModel<SystemObject>) : JSX.Element {
        const r = this.renderers[widget.widget.type]
        if (r) {
            const [cmp,props] = r(widget.widget, system)
            return createElement(cmp, props)
        } else {
            return <div>no renderer for widget "{widget.widget.type}"</div>
        }
    }
}

export const widgetRenderer = new AppWidgetRenderer()
