import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import { BehaviorSubject, Observable } from 'rxjs';
import log from 'loglevel'; //not sure which import will work where

type CachedData<T> = {
    data: T;
    etag: string | null;
};

type DataPollerConfig<T> = {
    url: string;
    intervalMs: number;
    afterSuccessfulPollIntervalMs?: number;
    afterErrorIntervalMs?: number;
    timeoutMs: number;
    logLevel?: log.LogLevelDesc;
    dataLoader?: DataLoader<T>;
    dataSaver?: DataSaver<T>;
    validator?: DataValidator<T>;
};

// Interface for data loading
export type DataLoader<T> = {
    load: () => Promise<CachedData<T> | null>;
};

// Interface for data saving
export type DataSaver<T> = {
    save: (data: CachedData<T>) => Promise<void>;
};

// Interface for data validator
export type DataValidator<T> = {
    validate: (data: T) => boolean;
};

export class DataPoller<T> {
    private config: DataPollerConfig<T>;
    private cachedData: CachedData<T> | null = null;
    private timeoutId: any = null;
    private isPolling = false;
    private cancelTokenSource: CancelTokenSource | null = null;
    private dataSubject: BehaviorSubject<T | null>;
    private logger: log.Logger;
    private consecutiveErrorCount = 0;

    constructor(config: DataPollerConfig<T>) {
        this.config = config;
        this.dataSubject = new BehaviorSubject<T | null>(null);
        this.logger = log.getLogger(`DataPoller:${this.config.url}`);
        this.logger.setLevel(this.config.logLevel || log.levels.INFO);
    }

    get data(): Observable<T | null> {
        return this.dataSubject.asObservable();
    }

    async start(): Promise<void> {
        this.logger.info('Starting data polling');
        await this.loadData();
        this.poll().catch(() => {});
    }

    stop(): void {
        this.logger.info('Stopping data polling');
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('Operation cancelled by user');
        }
        this.isPolling = false;
    }

    private scheduleNextPoll(wasSuccessful = true): void {
        let nextInterval: number;

        if (wasSuccessful && this.config.afterSuccessfulPollIntervalMs !== undefined) {
            nextInterval = this.config.afterSuccessfulPollIntervalMs;
            this.consecutiveErrorCount = 0;
        } else if (!wasSuccessful && this.config.afterErrorIntervalMs !== undefined) {
            nextInterval = this.config.afterErrorIntervalMs;
            this.consecutiveErrorCount++;
        } else {
            nextInterval = this.config.intervalMs;
        }

        this.logger.debug(`Scheduling next poll in ${nextInterval}ms`);
        this.timeoutId = setTimeout(() => {
            this.poll().catch(() => {});
        }, nextInterval);
    }

    private async loadData(): Promise<void> {
        if (this.config.dataLoader) {
            try {
                const loadedData = await this.config.dataLoader.load();
                if (loadedData && this.validateData(loadedData.data)) {
                    this.logger.info('Loaded data');
                    this.cachedData = loadedData;
                    this.dataSubject.next(loadedData.data);
                }
            } catch (error: unknown) {
                this.handleError('Error loading data:', error);
            }
        }
    }

    private async saveData(): Promise<void> {
        if (this.config.dataSaver && this.cachedData) {
            try {
                await this.config.dataSaver.save(this.cachedData);
                this.logger.debug('Data saved successfully');
            } catch (error: unknown) {
                this.handleError('Error saving data:', error);
            }
        }
    }

    private validateData(data: T): boolean {
        if (this.config.validator) {
            try {
                return this.config.validator.validate(data);
            } catch (error: unknown) {
                this.handleError('Error validating data:', error);
                return false;
            }
        }
        return true; // If no validator is provided, consider data valid
    }

    private async poll(): Promise<void> {
        if (this.isPolling) {
            this.logger.debug('Previous request still pending, skipping this poll');
            this.scheduleNextPoll();
            return;
        }

        this.isPolling = true;
        this.cancelTokenSource = axios.CancelToken.source();

        try {
            const headers: Record<string, string> = {};
            if (this.cachedData?.etag) {
                headers['If-None-Match'] = this.cachedData.etag;
            }

            const response: AxiosResponse<T> = await axios.get<T>(this.config.url, {
                headers,
                timeout: this.config.timeoutMs,
                cancelToken: this.cancelTokenSource.token,
                validateStatus: (status) => (status >= 200 && status < 300) || status === 304,
            });

            if (response.status === 304) {
                this.logger.debug('Data unchanged');
            } else if (response.status === 200) {
                if (this.validateData(response.data)) {
                    this.logger.info('New valid data received');
                    this.cachedData = {
                        data: response.data,
                        etag: (response.headers.etag as string) || null,
                    };
                    this.dataSubject.next(response.data);
                    await this.saveData();
                } else {
                    throw new Error('Data validation failed');
                }
            }

            this.scheduleNextPoll(true);
        } catch (error: unknown) {
            if (axios.isCancel(error)) {
                this.logger.info('Request cancelled:', (error as Error).message);
            } else if (axios.isAxiosError(error)) {
                this.handleAxiosError(error);
            } else {
                this.handleError('Error polling data:', error);
            }
            this.scheduleNextPoll(false);
        } finally {
            this.isPolling = false;
            this.cancelTokenSource = null;
        }
    }

    private handleAxiosError(error: AxiosError): void {
        if (error.code === 'ECONNABORTED') {
            this.logger.warn('Request timed out');
        } else if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            this.logger.error(`Request failed with status ${error.response.status}:`, error.response.data);
        } else if (error.request) {
            // The request was made but no response was received
            this.logger.error('No response received:', error.message);
        } else {
            // Something happened in setting up the request that triggered an Error
            this.logger.error('Error setting up request:', error.message);
        }
    }

    private handleError(message: string, error: unknown): void {
        if (error instanceof Error) {
            this.logger.error(message, error.message);
        } else {
            this.logger.error(message, String(error));
        }
    }
}
