import { ProjectHardware, HWConfigurationShim, HWCluShim, HWModuleShim, IdMap, Maps, Lists, ProjectCluster } from '@grenton/gm-common';

/**
 * for CLU only object-level mapping matters.
 * in GM however, we map modules.
 *
 * we'll need to expand module mapping to object mapping, but this may happen during export.
 *
 * hardware configuration should include last seen online snapshot + all missing modules.
 * missing and unused modules can be purged during export.
 */
export class ProjectHardwareImpl {
    static empty() {
        return new ProjectHardwareImpl({}, { clus: [] });
    }
    static from(h: ProjectHardware) {
        return new ProjectHardwareImpl(
            Lists.reduce(h.componentMapping, (record) => [record.virtual, record.physical]),
            h.configuration,
        );
    }

    private hardwareToProject: { [hwId: string]: string } = {};

    constructor(
        private projectToHardware: { [moduleId: string]: string },
        public readonly configuration: HWConfigurationShim,
        public readonly cluster?: ProjectCluster,
    ) {
        Object.entries(this.projectToHardware).map(([id, value]) => {
            this.hardwareToProject[value] = id;
        });
    }

    merge(h: ProjectHardwareImpl) {
        return new ProjectHardwareImpl({ ...h.projectToHardware, ...this.projectToHardware }, { clus: [...this.configuration.clus, ...h.configuration.clus] }); //TODO remove dups
    }

    withConfiguration(configuration: HWConfigurationShim) {
        return new ProjectHardwareImpl(this.projectToHardware, configuration);
    }

    withMapping(virtualToReal: { [moduleId: string]: string }) {
        let copy = { ...this.projectToHardware };

        // "unmap" any device that is mapped currently to these hardware objects
        for (const hwId of Object.values(virtualToReal)) {
            const moduleId = this.findVirtualMappedToHardware(hwId);
            if (moduleId) {
                delete copy[moduleId];
            }
        }

        copy = { ...copy, ...virtualToReal };
        return new ProjectHardwareImpl(copy, this.configuration);
    }

    withCluster(cluster: ProjectCluster) {
        return new ProjectHardwareImpl(this.projectToHardware, this.configuration, cluster);
    }

    withoutMapping(moduleId: string) {
        const copy = { ...this.projectToHardware };
        if (copy[moduleId]) {
            delete copy[moduleId];
        } else {
            const vid = this.findVirtualMappedToHardware(moduleId);
            if (vid) delete copy[vid];
        }
        return new ProjectHardwareImpl(copy, this.configuration);
    }

    findHardwareMappedToVirtual(deviceId: string): string | undefined {
        return this.projectToHardware[deviceId];
    }

    findVirtualMappedToHardware(hwId: string): string | undefined {
        return this.hardwareToProject[hwId];
    }

    export(): ProjectHardware {
        return {
            componentMapping: Object.entries(this.projectToHardware).map((e) => ({ virtual: e[0], physical: e[1] })),
            configuration: this.configuration,
            cluster: this.cluster,
        };
    }
}

// TODO find better place for it

export function mergeHWConfigurations(c1: HWConfigurationShim, c2: HWConfigurationShim) {
    if (c1 && c1 === c2) return c1;

    const mergeHWClus = (clu1?: HWCluShim, clu2?: HWCluShim): HWCluShim => {
        if (!clu1) {
            if (!clu2) throw Error('cannot merge empty configurations');
            return clu2;
        }
        if (!clu2) return clu1;
        const modules: IdMap<HWModuleShim> = {};
        clu1.modules.forEach((module) => {
            modules[module.id] = module;
        });
        clu2.modules.forEach((module) => {
            modules[module.id] = modules[module.id] || module;
        });
        return {
            id: clu1.id,
            name: clu1.name,
            modules: Maps.values(modules),
        };
    };

    const clus: IdMap<HWCluShim> = {};
    c1?.clus.forEach((clu) => (clus[clu.id] = clu));
    c2?.clus.forEach((clu) => (clus[clu.id] = mergeHWClus(clus[clu.id], clu)));
    return { clus: Maps.values(clus) };
}
