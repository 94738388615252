import { Project } from '@grenton/gm-common';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { ProjectExporter, ProjectImpl } from '../project';
import { PushConfigurationStatus } from './types';
import { NetworkView } from '@grenton/gm-logic';
import { StateProvider, StateUpdater } from '@grenton/gm-logic/src/utils/state';

export class ConfigurationUploader {
    readonly pushConfigurationStatus = new Subject<PushConfigurationStatus | null>();

    constructor(
        private projectExporter: ProjectExporter,
        private projectUpdater: StateUpdater<ProjectImpl>,
        private networkView: StateProvider<NetworkView>,
    ) {}

    resetPushConfigurationStatus() {
        this.pushConfigurationStatus.next(null);
    }

    public async uploadProject() {
        const exportedProject = await this.projectExporter.exportProject({ newVersion: true, validate: true });
        if (exportedProject.validation.errors.length) {
            throw new Error('project validation failed');
        } else {
            const project = exportedProject.project;
            this.uploadProjectToAllCLUs(project).subscribe((status) => {
                this.pushConfigurationStatus.next(status);
                switch (status.status) {
                    case 'ok':
                        this.projectUpdater((p) => p.withNewRevision(project.revisions[0]!).withHardware(p.hardware.withCluster(project.hardware.cluster!)));
                        //this.projectHolder.markAsSynced();
                        break;
                }
            });
        }
    }

    uploadProjectToAllCLUs(project: Project): Observable<PushConfigurationStatus> {
        const targets = project.hardware.configuration.clus.map((clu) => ({
            clu,
            hw: this.networkView.value.discovery.clus.find((target) => target.clu.id === clu.id),
        }));
        if (!targets.length || targets.find((t) => !t.hw?.url)) throw new Error('not all CLUs are accessible');

        const body = JSON.stringify({ configuration: project });

        const result = new BehaviorSubject<PushConfigurationStatus>({
            status: 'pending',
            clus: targets.reduce((acc, target) => ({ ...acc, [target.clu.id]: { status: 'pending' } }), {}),
        });

        const updateStatus = (cluId: string, status: 'ok' | 'error', message?: string) => {
            const updated: PushConfigurationStatus = { ...result.value, clus: { ...result.value.clus, [cluId]: { status, message } } };
            updated.status = Object.values(updated.clus).find((clu) => clu.status === 'pending')
                ? 'pending'
                : Object.values(updated.clus).find((clu) => clu.status === 'error')
                  ? 'error'
                  : 'ok';
            result.next(updated);
        };

        Promise.all(
            targets.map((target) => {
                return fetch(`${target.hw!.url}/configuration`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body,
                })
                    .then((response) => {
                        if (!response.ok) {
                            updateStatus(target.clu.id, 'error', `response code ${response.status}`);
                        } else {
                            updateStatus(target.clu.id, 'ok');
                        }
                        return;
                    })
                    .catch((e) => {
                        updateStatus(target.clu.id, 'error', e.message);
                    });
            }),
        ).finally(() => {
            result.complete();
        });
        return result;
    }
}
