import { styled, TextField } from "@mui/material";

export default styled(TextField)({
    width: "100%",
    maxWidth: "100%",

    '& label.Mui-focused': {
      color: 'rgb(44,44,44)',
    },
    '& .MuiInput-underline.Mui-focused:after': {
        borderBottomColor: 'rgb(44,44,44)',
      },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(44,44,44)',
      },
    },
  });