import { SwapHoriz } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { ObjectApi } from "@grenton/gm-common";

type ActionDigitalOutSwitchWidgetProps  = { api:ObjectApi, model:undefined, onUpdate:(model:undefined)=>void}

export function ActionDigitalOutSwitchWidget(_props:ActionDigitalOutSwitchWidgetProps) {
    return <Stack direction="row" padding={2} spacing={2} alignItems="center"><Typography>ON</Typography><SwapHoriz/><Typography>OFF</Typography></Stack>
}

