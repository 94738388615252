import { OUTLET_OBJECT, ObjectID } from '@grenton/gm-common';
import { ProjectObjectImpl, ProjectImpl, DEFAULT_EMPTY_SCRIPT, MethodImpl, OutletImpl, ScriptImpl } from '@grenton/gm-logic';

type ScriptRef = {
    // path to 'script' within object's api context, e.g.
    // 'outlet1.onClick' or 'method1'

    path: string;

    // in a few places it is necessary to know whether path refers to a method script or event handler

    type: 'method' | 'event';
};

// reference that identifies a single script within a project
export type ObjectScriptRef = {
    objectId: ObjectID;
    scriptRef: ScriptRef;
};

const sameScriptRef = (a: ScriptRef, b: ScriptRef) => {
    return a.type === b.type && a.path === b.path;
};

export const sameObjectScriptRef = (a?: ObjectScriptRef | null, b?: ObjectScriptRef | null) => {
    if (!a && !b) return true;
    if (!a || !b) return false;
    if (a.objectId !== b.objectId) return false;
    return sameScriptRef(a.scriptRef, b.scriptRef);
};

/**
 * ObjectScriptRef serves as a 'pointer',
 * but in editors we need to access tangible data, like script content, and to do so,
 * we need to retrieve ProjectObjectImpl from the project.
 *
 * this object is also passed through a blockly workspace updater.
 *
 * PLAN:
 * on top level we need a "controller" that will receive "ChangeEditingScript" as well as "CommitScriptChanges" commands from editors.
 * it will then create ScriptWithContext and keep it in a context.
 *
 * ChangeEditingScript = {objectId: '...', scriptRef: {path: '...', type: 'method'|'event'}}
 * CommitScriptChanges = {objectScriptRef:'', format: 'visual'|'actions'|'lua', content: '...', commitId:string}
 *
 * commitId will be added to ScriptWithContext
 *
 * editor will ignore ScriptWithContext if commitId is its own, otherwise, it will reset editor to the current content.
 * ---
 * when ActionEditor commits a change, the controller will update visual and lua editors as well. this is a simple approach.
 * more complex would be on-demand refresh, when visual or lua editor is opened, it could "request" such refresh from ScriptWithContext.
 *
 * in that way, all editors stay quite dummy - they edit content internally and do not need to refresh unless there's an external change,
 * they only dispatch "CommitScriptChanges".
 *
 * we can also easily preserve state of editors in the future when switch between top screens.
 */
export class ScriptWithContext {
    static from(project: ProjectImpl, ref: ObjectScriptRef) {
        const entity = project.getObjectById(ref.objectId);
        if (!entity) return null;
        return new ScriptWithContext(entity, ref.scriptRef);
    }

    readonly ref: ObjectScriptRef;

    constructor(
        public readonly entity: ProjectObjectImpl,
        public readonly scriptRef: ScriptRef,
    ) {
        this.ref = { objectId: entity.uuid, scriptRef };
    }

    /**
     * for anonymous controllers it should return object referenced by outlet "object",
     * for regular scriptable - itself.
     */
    getSelfObjectID() {
        return this.entity.init.outlets[OUTLET_OBJECT]?.firstStatic || this.entity.uuid;
    }

    // main source of script content
    // change of this item should trigger a re-render of editor content (unless this change was caused by an editor in the first place)
    getScript(): ScriptImpl {
        return this.entity.scripts.scripts[this.scriptRef.path] || DEFAULT_EMPTY_SCRIPT;
    }

    // used only by toolbar
    getOutlet(): OutletImpl | undefined {
        return this.scriptRef.type === 'event' ? this.entity.api.outlets[this.scriptRef.path.split('.')[0]!] : undefined;
    }

    // used by blockly code generator
    getMethod(): MethodImpl | undefined {
        return this.scriptRef.type === 'method' ? this.entity.api.methods[this.scriptRef.path] : undefined;
    }

    sameScriptInstance(e: ScriptWithContext) {
        return !!e && this.entity.uuid === e.entity.uuid && sameScriptRef(this.scriptRef, e.scriptRef);
    }
}
