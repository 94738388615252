import { schema } from '@grenton/gm-common';
import { ObjectEventEmitter, MethodInvocation, Scheduler, ObjectStateHolder } from './common';
import { DimmableObjectEmulator } from './dimmable-object-emulator';

export class Dimmer extends DimmableObjectEmulator {
    constructor(state: ObjectStateHolder, emitter: ObjectEventEmitter, scheduler: Scheduler) {
        super(state, emitter, scheduler);
    }

    async execute(request: MethodInvocation): Promise<schema.PropertyValue> {
        const params = request.params || {};
        let rampTime;
        if (params['ramp'] !== undefined) rampTime = params['ramp'] as number;
        else rampTime = (this.state.get('rampTime') as number) || 0;

        switch (request.method) {
            case 'setValue': {
                this.state.set('value', params['value']!);
                break;
            }
            case 'setRampTime': {
                this.state.set('rampTime', params['rampTime']!);
                break;
            }
            case 'setMinValue': {
                this.state.set('minValue', params['minValue']!);
                break;
            }
            case 'setMaxValue': {
                this.state.set('maxValue', params['maxValue']!);
                break;
            }
            case 'setStartLevel': {
                this.state.set('startLevel', params['startLevel']!);
                break;
            }
            case 'setDimmingEdge': {
                this.state.set('dimmingEdge', params['dimmingEdge']!);
                break;
            }
            case 'switch': {
                this.switch('value', rampTime, params['time'] as number);
                break;
            }
            case 'switchOn': {
                this.switchOn('value', rampTime, params['time'] as number);
                break;
            }
            case 'switchOff': {
                this.switchOff('value', rampTime, params['time'] as number);
                break;
            }
            case 'holdValue': {
                this.hold('value');
                break;
            }
        }
        return null;
    }
}
