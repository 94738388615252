import { CommandHandler } from '../../dispatcher';
import { CreateProjectImpl } from '../builders';
import { LoadNewProject } from '../loadNewProject';
import { generateSampleProject, generateLargeSampleProject } from '../sample-project';

export class LoadSampleProjectCommand {
    readonly type = 'LoadSampleProjectCommand';
    constructor(readonly id: 'small' | 'large') {}
}

export class LoadSampleProjectCommandHandler implements CommandHandler<LoadSampleProjectCommand> {
    constructor(
        private createProjectImpl: CreateProjectImpl,
        private projectLoader: LoadNewProject,
    ) {}

    supports(cmd: LoadSampleProjectCommand): boolean {
        return cmd.type === 'LoadSampleProjectCommand';
    }

    async execute(cmd: LoadSampleProjectCommand) {
        const sample = cmd.id === 'small' ? await generateSampleProject(this.createProjectImpl) : await generateLargeSampleProject(this.createProjectImpl);
        this.projectLoader(sample);
    }
}
