import { Components, Theme } from '@mui/material';
import { sizeRem, sizes } from '../size';

export const MuiButtonBase: Components<Omit<Theme, 'components'>>['MuiButtonBase'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: sizeRem(sizes.M),
            fontFamily: theme.typography.fontFamily,
        }),
    },
};
