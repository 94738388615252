import { CommandHandler, Command } from '../../dispatcher';
import { CreateProjectImpl } from '../builders';
import { LoadNewProject } from '../loadNewProject';
import { NewProjectCommand } from './new-project-command';

export class NewProjectCommandHandler implements CommandHandler<NewProjectCommand> {
    constructor(
        private projectLoader: LoadNewProject,
        private createProjectImpl: CreateProjectImpl,
    ) {}

    supports(cmd: Command) {
        return cmd.type === 'NewProjectCommand';
    }

    async execute(_: NewProjectCommand) {
        const p = await this.createProjectImpl();
        this.projectLoader(p);
    }
}
