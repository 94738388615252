import { Tags } from '@grenton/gm-common';

export class SelectedTagsImpl {
    static from(tags: Tags | string | undefined, multiple = true) {
        if (!tags) {
            return new SelectedTagsImpl({}, multiple);
        }
        if (typeof tags === 'string') {
            return new SelectedTagsImpl({ [tags]: true }, multiple);
        }
        return new SelectedTagsImpl(
            tags.reduce((acc, tag) => ({ ...acc, [tag]: true }), {}),
            multiple,
        );
    }

    constructor(
        private tagMap: { [tag: string]: boolean },
        public readonly multiple: boolean,
    ) {}

    get one(): string | undefined {
        return Object.keys(this.tagMap).find((tag) => this.tagMap[tag]);
    }

    asMultiple() {
        return new SelectedTagsImpl({ ...this.tagMap }, true);
    }

    withTag(tag: string, include = true) {
        tag = tag.trim();
        if (tag) {
            const tagMap = { ...this.tagMap };
            if (!this.multiple && include) {
                Object.keys(tagMap).forEach((key) => (tagMap[key] = false));
            }
            return new SelectedTagsImpl({ ...tagMap, [tag]: include }, this.multiple);
        } else {
            return this;
        }
    }

    toggle(tag: string) {
        return this.withTag(tag, this.tagMap[tag] !== true);
    }

    /**
     * if this tagset matches given matcher
     * matcher can be either a tag or "wildcard" tag.
     * @param tag
     * @returns
     */
    includes(matcher: string) {
        if (this.tagMap[matcher] === true) return true;
        if (matcher.endsWith('.*')) {
            const root = matcher.substring(0, matcher.length - 2);
            if (this.tagMap[root] === true) return true;
            const prefix = matcher.substring(0, matcher.length - 1);
            return Object.keys(this.tagMap).find((t) => this.tagMap[t] === true && t.startsWith(prefix)) !== undefined;
        }
        return false;
    }

    get empty() {
        return this.selected.length === 0;
    }

    export() {
        return this.empty ? undefined : this.selected;
    }

    get selected(): string[] {
        return Object.entries(this.tagMap)
            .filter(([_, value]) => value === true)
            .map(([tag]) => tag);
    }
}
