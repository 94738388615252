import { createTheme } from '@mui/material';
import './font';
import { breakpoints } from './breakpoints';
import { palette } from './palette';
import { typography } from './typography';
import * as components from './overrides';
import { size } from './size';
import { shadows } from './shadows';

export const theme2 = createTheme({
    spacing: 10, // (factor:number) => `${0.25 * factor}rem`,
    breakpoints,
    typography,
    palette,
    components,
    size,
    shadows,
});
