import { Components, Theme } from '@mui/material';
import { sizeRem, sizes } from '../size';
import { grentonColors } from '../colors';

export const MuiInputLabel: Components<Omit<Theme, 'components'>>['MuiInputLabel'] = {
    defaultProps: {
        color: 'secondary',
    },
    styleOverrides: {
        root: ({ theme }) => ({
            fontWeight: 600,
            fontSize: sizeRem(sizes.M),
            color: grentonColors.Font_Label,
            /**
             * "label" element is used also as a placeholder (when not shrunk to be fieldset label)
             * In that case we need to use greyish color
             */
            '&:not(.MuiInputLabel-shrink)': {
                color: grentonColors.Font_Placeholder,
            },

            '&.MuiInputLabel-shrink.Mui-error': {
                color: theme.palette.error.main,
            },

            '&.Mui-focused': {
                color: theme.palette.secondary.main,
            },
            '&.Mui-disabled': {
                color: grentonColors.Text_Disabled,
            },
            // this would have to go up
            // hover
            // '&:hover:not(.Mui-error):not(.Mui-disabled)': {
            //     color: theme.palette.secondary.main,
            // }
        }),
    },
};
