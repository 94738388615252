import { configurationValidator } from '@grenton/contract';
import { Project } from '@grenton/gm-common/src';
import { PartialValidationResult } from './helpers';

export function validateSchema(project: Project): PartialValidationResult {
    const result = configurationValidator(project.$schema)(project);
    return {
        errors: result.errors?.map((message) => ({ type: 'schema', message })) || [],
        fatal: !result.ok,
    };
}
