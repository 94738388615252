import { styled, Dialog, DrawerProps, SxProps, DialogProps } from "@mui/material"

export type GSideDialogProps = {
    children?: React.ReactNode
    open: boolean
    onClose?: DrawerProps['onClose']
    sx?:SxProps
    align?: 'start' | 'end' | 'center'
    width?: string|number
}

// 1200 is the default z-index for the drawer, 1300 for modal
// it seems that regular modal still seem to be on top of the side modal,
// e.g. adding new tag should be on top of the tag selector side modal, but I'm not sure if this 
// is always the case?
//const ZINDEX = 1201
//ts-ignore
export function GSideDialog({open, children, onClose, align, width}: GSideDialogProps) {
    return <AlignedDialog open={open} onClose={onClose} align={align} width={width}>{children}</AlignedDialog>
     
    //return <GDialog open={open} onClose={onClose} sx={{right: 'inherit', padding:'20px', ...sx, height:'100%', maxWidth:'75vw'}}>{children}</GDialog>
    // return <Drawer sx={{zIndex:ZINDEX}} PaperProps={{sx:{maxWidth:'70%',...sx}}} open={open} anchor="left" onClose={onClose} container={document.body}>
    //     {children}
    // </Drawer>
}

interface AlignedDialogProps extends DialogProps {
    align?: 'start'|'end'|'center';
    width?: string|number;
  }


const AlignedDialog = styled(Dialog, {
    shouldForwardProp:(prop)=>prop !== 'position',
    name: 'GSideDialog',
})<AlignedDialogProps>(({theme, align, width}) => ({
    '& .MuiDialog-container': {
     // alignItems: 'start',
      justifyContent: align || 'center',

      [theme.breakpoints.down('sm')]: {
        padding:0,
      }
    },
    '& .MuiDialog-paper': {
      margin: theme.spacing(4),
      width: width || 'auto',
      maxWidth: width || '75vw',
      height: `calc(100% - ${theme.spacing(8)})`,
      maxHeight: 'none',
      textAlign:'start',
      
      [theme.breakpoints.down('sm')]: {
        // this is how Dialog handles fullScreen prop
        margin:0,
        maxHeight:'none',
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        borderRadius: 0
      },
    },
  }));