import { Blocks, Block, FieldLabel, MenuOption } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, BlockWithDelegate, createDelegate, emptyOption, FieldDropdownModel, NOT_SELECTED, VALUE_BLOCK_STYLE } from './common';
import { FieldDropdownEx } from './field-dropdown-ex';

export namespace ApiOutletGet {
    export const Type = 'api-outlet-get';

    export const ENTITY_FIELD = 'ENTITY';
    export const OUTLET_FIELD = 'OUTLET';
    const EMPTY_OPTION = emptyOption('{outlet}');

    export class Delegate implements BlockDelegate {
        entityName?: string;
        entityType?: 'object' | 'outlet';
        outletName?: string;

        dropdownModel: FieldDropdownModel;

        constructor(private block: Block) {
            this.dropdownModel = new FieldDropdownModel(block, OUTLET_FIELD, [EMPTY_OPTION]);

            block.appendValueInput(ENTITY_FIELD);

            block.appendDummyInput().appendField(new FieldLabel('.')).appendField(new FieldDropdownEx(this.dropdownModel.generator), OUTLET_FIELD);

            this.updateBlockType(true);

            block.setInputsInline(true);
            block.setStyle(VALUE_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        updateBlockType(output: boolean) {
            this.block.setOutput(output, null);
            this.block.setPreviousStatement(!output, null);
            this.block.setNextStatement(!output, null);
        }

        onUpdate(context: BlockContext) {
            const entity = context.getInputType(ENTITY_FIELD);

            const outlets = entity?.api.flat.outlets || {};
            const options: MenuOption[] = Object.entries(outlets).map((m) => [m[1].label || m[1].id, m[0]]);
            options.unshift(EMPTY_OPTION);

            let outletId = this.block.getFieldValue(OUTLET_FIELD);
            let selectedOption = options.find((o) => o[1] === outletId) || EMPTY_OPTION;

            this.dropdownModel.setOptions(options, selectedOption[1]);
            this.outletName = selectedOption[1] ? selectedOption[0]?.toString() : undefined;

            const api = outlets[selectedOption[1]]?.api;
            if (api) {
                context.setReturnedType({ type: 'outlet', api });
            }
        }

        connectToEntity(block: Block, featureId?: string) {
            const input = this.block.getInput(ENTITY_FIELD)!;
            if (block.outputConnection) {
                input.connection!.connect(block.outputConnection);
            }
            this.block.setFieldValue(featureId || '', OUTLET_FIELD);
        }
    }
}

Blocks[ApiOutletGet.Type] = createDelegate((block) => new ApiOutletGet.Delegate(block));

luaGenerator.forBlock[ApiOutletGet.Type] = (block, generator) => {
    const _block = block as BlockWithDelegate<ApiOutletGet.Delegate>;
    const entityRef = generator.valueToCode(block, ApiOutletGet.ENTITY_FIELD, Order.ATOMIC) || NOT_SELECTED;
    const code = `${entityRef}.${_block.delegate.outletName || NOT_SELECTED}`;
    return [code, Order.ATOMIC];
};
