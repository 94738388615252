import axios, { AxiosError } from 'axios';
import { timer, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StateUpdater } from '../../utils/state';
import loglevel from 'loglevel';

export class NetworkStatusMonitor {
    private checkSubscription: Subscription | null = null;

    constructor(
        private url: string,
        private networkStatus: StateUpdater<boolean>,
        private checkInterval: number = 30000,
        private timeout: number = 5000,
    ) {}

    public start(): void {
        this.checkSubscription?.unsubscribe();
        this.checkSubscription = timer(0, this.checkInterval)
            .pipe(switchMap(() => this.checkRealOnlineStatus()))
            .subscribe((isOnline) => this.networkStatus(() => isOnline));
    }

    public stop(): void {
        this.checkSubscription?.unsubscribe();
        this.checkSubscription = null;
    }

    private checkRealOnlineStatus(): Promise<boolean> {
        return axios
            .get(this.url, {
                timeout: this.timeout,
                headers: {
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Expires: '0',
                },
            })
            .then(() => true)
            .catch((error: AxiosError) => {
                loglevel.debug('cannot confirm online status', error.message);
                return false;
            });
    }
}
