import { interval, Observable, of } from 'rxjs';
import { switchMap, catchError, timeout } from 'rxjs/operators';
import axios, { AxiosError } from 'axios';

export type PollResult<T> =
    | {
          type: 'data';
          data: T;
      }
    | {
          type: 'error';
          error: Error;
      };

// to start - subscribe, to stop - unsubscribe
export function pollEndpoint<T>(url: string, intervalMs: number, timeoutMs: number = 5000): Observable<PollResult<T>> {
    if (intervalMs <= timeoutMs) {
        throw new Error('Interval must be greater than http timeout');
    }
    return interval(intervalMs).pipe(
        switchMap(
            () =>
                new Observable<PollResult<T>>((observer) => {
                    axios
                        .get<T>(url, { timeout: timeoutMs })
                        .then((response) => {
                            observer.next({ type: 'data', data: response.data });
                            observer.complete();
                        })
                        .catch((error: AxiosError) => {
                            let errorMessage: string;
                            if (error.response) {
                                errorMessage = `Server responded with status ${error.response.status}`;
                            } else if (error.request) {
                                errorMessage = 'No response received from server';
                            } else {
                                errorMessage = error.message;
                            }
                            observer.next({ type: 'error', error: new Error(errorMessage) });
                            observer.complete();
                        });
                }),
        ),
        timeout(timeoutMs), // RxJS timeout
        catchError((error) => {
            if (error.name === 'TimeoutError') {
                return of({ type: 'error', error: new Error('Request timed out') } as PollResult<T>);
            }
            return of({ type: 'error', error: new Error('Unknown error occurred') } as PollResult<T>);
        }),
    );
}
