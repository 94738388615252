import { Command, CommandHandler, ProjectImpl, StateUpdater } from '@grenton/gm-logic';
import { ValidatorResult } from '../ui/form-validation';
import { ProjectUserImpl, TEMPORARY_DEFAULT_USER_ROLES, hashPassword } from '@grenton/gm-logic';
import { InputUser } from './inputUser';

export type UserEditForm = {
    existing: boolean;
    changePassword?: boolean;
} & Omit<InputUser, 'pwd' | 'pwdRepeat'> &
    Partial<Pick<InputUser, 'pwd' | 'pwdRepeat'>>;

export interface UserEditFormMeta {
    validator: (form: UserEditForm) => ValidatorResult;
}

export class UserEditCommand implements Command {
    readonly type = 'UserEditCommand';
    constructor(readonly form: UserEditForm) {}
}

export const userFormValidatorFactory = (hasUser: (name: string) => boolean, passwordValidator: (pwd: string) => string | null) => {
    return (form: UserEditForm): ValidatorResult => {
        let result = ValidatorResult.validResult;
        const { name, pwd, pwdRepeat, existing, changePassword } = form;
        const trimmedName = name.trim();
        const trimmedPwd = pwd?.trim();
        const trimmedPwdRepeat = pwdRepeat?.trim();

        if (!trimmedName) result = result.withError('name', 'Name is required');
        if (!existing && hasUser(trimmedName)) result = result.withError('name', 'User with this name already exists');

        if (!existing || changePassword) {
            if (!trimmedPwd) {
                result = result.withError('pwd', 'Password is required');
            } else {
                const pwdErr = passwordValidator(trimmedPwd);
                if (pwdErr) result = result.withError('pwd', pwdErr);
                if (trimmedPwd !== trimmedPwdRepeat) {
                    result = result.withError('pwdRepeat', 'Passwords do not match');
                }
            }
        }
        return result;
    };
};

export const PasswordValidator = (pwd: string) => {
    if (!pwd) return 'Password is required';
    const trimmedPwd = pwd.trim();
    if (trimmedPwd.length < 8) return 'Password is too short';
    return null;
};

export class UserEditCommandHandler implements CommandHandler<UserEditCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'UserEditCommand';
    }

    execute(cmd: UserEditCommand) {
        const { form } = cmd;
        return this.update(async (p) => {
            const validator = userFormValidatorFactory((name) => Boolean(p.security.getUserByName(name)), PasswordValidator);

            if (!validator(form).valid) return p;
            if (!form.existing) {
                return p.withSecurity(
                    p.security.withUser(
                        new ProjectUserImpl({
                            name: form.name.trim(),
                            disabled: form.disabled,
                            pwd: await hashPassword(form.pwd!.trim()),
                            roles: TEMPORARY_DEFAULT_USER_ROLES,
                        }),
                    ),
                );
            } else {
                let user = p.security.getUserByName(form.name.trim());
                if (!user) return p;
                user = user.withDisabled(form.disabled);
                if (form.changePassword) {
                    user = user.withPwd(await hashPassword(form.pwd!.trim()));
                }
                return p.withSecurity(p.security.withUser(user));
            }
        });
    }
}
