import {styled, } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import {AppBarProps} from "@mui/material/AppBar/AppBar";

const closedWidth = 70;

type Props = {
    open?: boolean
    drawerWidth: number
} & AppBarProps

export const DrawerAwareAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
})<Props>(({theme, open, drawerWidth}) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow:'none',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(!open && {
        marginLeft:closedWidth,
        width: `calc(100% - ${closedWidth}px)`,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))