import { schema } from "@grenton/gm-common"
import { Box, FormControlLabel, Switch, TextField } from "@mui/material"
import { EnumSelector } from "../enumSelector"

type Props = {
    label: string
    type : schema.PropertyValueType
    value?: schema.PropertyValue,
    oneOf?: Array<{ const: schema.PropertyValue; title: string }>
    onClick?: (e: React.MouseEvent) => void
    onChange: (value: schema.PropertyValue) => void
    required?: boolean
    minWidth?: number
    disabled?: boolean
}

/**
 * editor for typed values.
 * in case of booleans and enums, we can preserve types.
 * for free-hand edit values, we have to use strings and convert it at time of commit.
 * 
 * e.g. we cannot auto-convert to float, because value '0.' would be invalid and user couldn't type the subsequent digit.
 * @param param0 
 * @returns 
 */
export function PropertyValueField({onClick,label, onChange, type, value, oneOf, required, minWidth, disabled}:Props) {

    if (oneOf) {
        return <EnumSelector
            disabled={disabled}
            minWidth={minWidth}
            required={required}
            label={label}
            value={value}
            options={oneOf.map(o => ({name: o.title, value: o.const})) || []}
            onChange={onChange}
        />
    }

    switch (type) {
        case 'null':
            return null
        case 'boolean':
            return <FormControlLabel
            sx={{minWidth, px:1}}
            disabled={disabled}
            labelPlacement="end"
            label={label}
            control={
                <Box sx={{paddingRight:1}}><Switch required={required} disabled={disabled} onClick={onClick} checked={Boolean(value)} onChange={e=>onChange(e.target.checked)} /></Box>
            }/>
        default: 
            return <TextField 
                sx={{minWidth}}
                required={required}
                disabled={disabled}
                inputMode={type === 'integer' || type === 'number' ? 'numeric' : 'text'}
                onClick={onClick} 
                value={value||''}
                onChange={e=>onChange(e.target.value)}
                label={label} 
                size="small" 
                variant="outlined"
            />
    }


}