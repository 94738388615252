import { ProjectItemPath } from '@grenton/gm-common';
import { Block, BlockSvg, Workspace } from 'blockly';
import { blocks } from '@grenton/gm-logic';

export class BlockCreator {
    constructor(private workspace: Workspace) {}

    newBlock<T extends Block>(name: string, init?: (block: T) => void): T {
        const block = this.workspace.newBlock(name) as unknown as T;
        if (init) {
            init(block);
        }
        if (isBlockSvg(block)) {
            block.initSvg();
            block.render();
        }
        return block;
    }

    addEntity(ref: ProjectItemPath) {
        return this.newBlock(blocks.ApiEntityWithApiBlock.Type, (block: blocks.BlockWithDelegate<blocks.ApiEntityWithApiBlock.Delegate>) => {
            block.delegate.setPath(ref);
        });
    }
}

function isBlockSvg(block: Block): block is BlockSvg {
    return 'initSvg' in block;
}
