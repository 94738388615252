import { Project, Lists, ANONYMOUS_CONTROLLER_SUFFIX, createAnonymousCtrl } from '@grenton/gm-common/src';
import { FirmwareProvider } from '../firmwareProvider';
import {
    ProjectImpl,
    ProjectRevisionsImpl,
    ProjectI18nImpl,
    ProjectTagsImpl,
    ProjectHardwareImpl,
    ProjectDeviceModuleInstanceImpl,
    ProjectSecurityImpl,
} from '../model';
import { importObject } from './importObject';

export type ImportProjectImpl = ReturnType<typeof importProjectImpl>;

export function importProjectImpl(firmwareProvider: FirmwareProvider) {
    return function (project: Project): ProjectImpl {
        let revisions = new ProjectRevisionsImpl(project.revisions);
        if (revisions.all.length === 0) {
            revisions = revisions.withNewRevision(ProjectRevisionsImpl.revision('import'));
        }

        const firmwareImpl = firmwareProvider(project.firmware);
        if (!firmwareImpl) {
            throw new Error(`Firmware "${project.firmware}" is not supported`);
        }

        // add anonymous controllers
        const missingAnonControllers = project.objects
            .filter((o) => o.impl.type !== 'script')
            .filter((o) => !Boolean(project.objects.find((x) => x.id === `${o.id}${ANONYMOUS_CONTROLLER_SUFFIX}`)))
            .map((o) => createAnonymousCtrl(o));

        return new ProjectImpl({
            uuid: project.id,
            firmware: firmwareImpl,
            revisions: new ProjectRevisionsImpl(project.revisions),
            label: project.label,
            i18n: ProjectI18nImpl.from(project.i18n.langs),
            tags: ProjectTagsImpl.from(project.tags),
            hardware: ProjectHardwareImpl.from(project.hardware),
            objects: [...project.objects, ...missingAnonControllers]
                .map((p) => importObject(firmwareImpl, p))
                .reduce(
                    (all, c) => ({
                        ...all,
                        [c.uuid]: c,
                    }),
                    {},
                ),
            modules: Lists.reduce(project.components, (mod) => [mod.id, ProjectDeviceModuleInstanceImpl.from(mod)]),
            security: ProjectSecurityImpl.from(project.security),
        });
    };
}
