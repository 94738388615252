import { ObjectApi, isApiExtending, prot } from '@grenton/gm-common';
import { ActionTemplate } from '../../action-template';
import { ActionDigitalOutSwitchGenerator } from './generator';
import { ActionDigitalOutSwitchWidget } from './widget';

export const ActionDigitalOutSwitch: ActionTemplate<undefined> = {
    type: 'switch',
    label: 'Switch',
    order: 2,
    defaults: undefined,
    widget: ActionDigitalOutSwitchWidget,
    generator: ActionDigitalOutSwitchGenerator,
    isApplicable(api: ObjectApi) {
        return isApiExtending(api, prot.OnOff);
    },
};
