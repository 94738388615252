/*
 * To resolve an object api we may need bunch of object schemas, even if one is missing
 * this operation cannot be completed. ApiResolver must buffer all api resolution requests
 * simple implementation would be to create one behavior subject per requested api,
 * then every time when new schema is added iterate over empty subjects and check if we can resolve them.
 * then client can request resolution, and cancel it at any time
 *
 * const subscription = resolver.get('DigitalIN').once(api=>{
 *
 * })
 */

import { ObjectApi, notEmpty } from '@grenton/gm-common';
import { schema } from '@grenton/gm-common';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import log from 'loglevel';

export class FabricApiResolver {
    private subjects: Map<string, BehaviorSubject<ObjectApi | null>> = new Map();

    //private apiResolver;

    private registered = new Map<string, boolean>();
    private failed = new Map<string, schema.ProtocolSpec>();

    constructor() {
        // this.apiResolver = new ObjectApiRegistryImpl(/* 
            
        //     TODO: fix this when registry is rewritten
        //     {
        //     registerDefaults: false,
        //     fallbackResolver: (ref: schema.ProtocolID) => {
        //         return this.retryRegistration(ref);
        //     },
        // }*/);
    }

    private getSubjectForApi(apiId: string) {
        let subject = this.subjects.get(apiId);
        if (!subject) {
            subject = new BehaviorSubject<ObjectApi | null>(null);
            this.subjects.set(apiId, subject);
        }
        return subject;
    }

    resolve(objectId: string, refOrSchema: schema.ProtocolPtr): Observable<ObjectApi> {
        if (!schema.isApiRef(refOrSchema)) {
            this.add(objectId, refOrSchema.spec);
        }
        const subject = this.getSubjectForApi(schema.isApiRef(refOrSchema) ? refOrSchema.ref : objectId).pipe(filter(notEmpty));
        return subject;
    }

    // @ts-ignore
    private retryRegistration(apiID: schema.ProtocolID): ObjectApi | undefined {
        // const schema = this.failed.get(apiID);
        // if (schema) {
        //     log.debug(`attempt to re-register schema "${apiID}"`);
        //     const api = this.register(apiID, schema);
        //     this.failed.delete(apiID);

        //     // try to register children apis
        //     //Object.values(api.outlets).map(outlet=>this.resolve(outlet.api.name))

        //     return api;
        // }
        return
    }

    //@ts-ignore
    private register(apiId: string, spec: schema.ProtocolSpec) {
        // const api = this.apiResolver.register({ id: apiId, spec });
        // this.getSubjectForApi(api.id).next(api);

        // // register inline APIs that may have been registered as a side effect
        // Object.values(api.flat.outlets).map((outlet) => {
        //     this.getSubjectForApi(outlet.api.id).next(outlet.api);
        // });

       // return api;
    }

    add(apiId: string, schema: schema.ProtocolSpec) {
        //if (!this.registered.get(schema.id)) {
        log.debug(`add schema "${apiId}"`);
        this.registered.set(apiId, true);

        //setTimeout(()=>{
        try {
            log.debug(`attempt to register schema ${apiId}`);
            this.register(apiId, schema);

            // we have new API, retry failed ones
            // this step is necessary to not leave behind any api in failed state
            for (const apiID of this.failed.keys()) {
                try {
                    this.retryRegistration(apiID);
                } catch (e) {
                    // ignore
                }
            }
            log.debug(`we have ${this.failed.size} pending schemas`);
        } catch (e) {
            //log.error(`${this.id} cant register ${schema.name} atm`, e)
            this.failed.set(apiId, schema);
        }
    }
}
