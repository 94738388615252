import {
    QuestionMark,
    Lightbulb,
    DeviceThermostat,
    LightMode,
    Settings,
    CropFree,
    TouchApp,
    SettingsInputComponent,
    Workspaces,
    Code,
    LocalOfferOutlined,
    CompareArrows,
    AccountTree,
    BlindsClosed,
    AccessAlarm,
    Sensors,
    BrightnessMedium,
    CalendarMonth,
    Palette,
    EastOutlined,
    Apps,
    Memory,
    PlayArrow,
    WifiTethering,
    TheaterComedy,
    ElectricalServices,
    SwapVert,
    PowerSettingsNew,
    Speed,
    SettingsBackupRestore,
    WaterDrop,
    AcUnit,
    LocalFireDepartment
} from "@mui/icons-material";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { 
    GenericInputIcon, 
    GenericOutputIcon, 
    AnalogInputIcon, 
    AnalogOutputIcon, 
    FeatureIcon, 
    EventIcon, 
    DotIcon, 
    ModifiedIcon, 
    MethodIcon, 
    
    SoundWaveIcon, 
    MultisensorIcon, 
    Co2Icon, 
    EcoIcon,
    MeterIcon,
    HumidityIcon,
    HotspotIcon,
    SunIcon,
    PressureIcon,
    TemperatureIcon,
    SmartPanelIcon,
    PropertiesIcon,
    ButtonClickIcon,
    TouchPanelIcon,
    BlindsIcon,
    DownloadIcon,
    UploadShareIcon,
    SwitchIcon,
    PowerIcon,
    DimmerIcon,
    RgbIcon,
    LedIcon
} from "./customIcons";
import { ConnectorConnected } from "./ConnectorConnected";

export type IconConstructor = OverridableComponent<SvgIconTypeMap<{}, "svg">>

export const icons = {
    "unknown": QuestionMark,
    "on-off": PowerSettingsNew,
    "input": GenericInputIcon,
    "output": GenericOutputIcon,
    "analog-input-depricated": AnalogInputIcon,
    "analog-output-depricated": AnalogOutputIcon,
    "digital-input-depricated": GenericInputIcon,
    "digital-output-depricated": GenericOutputIcon,
    "sensor": Sensors,
    "light": Lightbulb,
    "light-sensor-depricated": LightMode,
    "light-sensor": SunIcon,
    "light-color": Palette,
    "brightness": BrightnessMedium,
    "meter": Speed,
    "valve": SettingsBackupRestore,
    "sound-sensor": SoundWaveIcon,
    "multisensor": MultisensorIcon,
    "air-co2-sensor": Co2Icon,
    "air-voc-sensor": EcoIcon,
    "voltage-meter": MeterIcon,
    "humidity-sensor": HumidityIcon,
    "ir-controller": HotspotIcon,
    "pressure-sensor": PressureIcon,
    "temperature-sensor": TemperatureIcon,
    "smart-panel": SmartPanelIcon,
    "smart-panel-page": PropertiesIcon,
    "switch": ButtonClickIcon,
    "touch-panel": TouchPanelIcon,
    "roller-shutter": BlindsIcon,
    "analog-input": DownloadIcon,
    "analog-output": UploadShareIcon,
    "digital-input": SwitchIcon,
    "digital-output": PowerIcon,
    "dimmer": DimmerIcon,
    "rgb": RgbIcon,
    "led": LedIcon,

    "temperature": DeviceThermostat,
    "water": WaterDrop,
    "panel": CropFree,
    "button": TouchApp,
    "up-down": SwapVert,
    "blinds": BlindsClosed,
    "cooling": AcUnit,
    "heating": LocalFireDepartment,

    "logic": AccountTree,
    "scheduler": CalendarMonth,
    "timer": AccessAlarm,

    "settings": Settings,
    "module": SettingsInputComponent,
    "workspaces": Workspaces,
    "script": Code,
    "tag": LocalOfferOutlined,
    "replace": CompareArrows,
    "project": Apps,
    "clu": Memory,
    "trigger": PlayArrow,
    "broadcast": WifiTethering,
    "move-right": EastOutlined,
    "connector": ConnectorConnected,
    "method": MethodIcon,
    "feature": FeatureIcon,
    "event": EventIcon,
    "dot": DotIcon,
    "scene": TheaterComedy,
    "outlet": ElectricalServices,
    "modified": ModifiedIcon,
} as const

export type GIconName = keyof typeof icons
