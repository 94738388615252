import { fabric, schema } from '@grenton/gm-common';
import { PartialObject, SystemModel, SystemModelEvents, SystemModelImpl } from './system-model';
import { FabricApiResolver } from './fabric-api-resolver';
import { Subject } from 'rxjs';

/**
 * based on messages received from fabric builds a system model (collection of all objects and meta-data)
 *
 * all components connected to fabric, incl engines, needs a similar mechanism.
 * however, each has slightly different requirements regarding object collection,
 *
 * e.g. hardware engine needs config + api, and accepts only hardware objects
 * controller engine needs config + api + logic, and then needs to sync state as well.
 * mygrenton needs config + api + state
 *
 * executor may be needed by mygrenton and controller-engine, but it does not need to be added here.
 *
 * to meet these requirements, system client allows to specify what has to be collected, what object considered to be "ready"
 * and exposes atomic change events
 */
export class SystemClient<T extends PartialObject> {
    private apiResolver = new FabricApiResolver();
    private _system;

    readonly events = new Subject<SystemModelEvents>();

    constructor(
        fn: (o?: PartialObject) => boolean,
        private options?: {
            logic?: boolean;
            state?: boolean;
        },
    ) {
        this._system = SystemModelImpl.empty<T>(fn);
        this._system.listener = (e: SystemModelEvents) => this.events.next(e);
    }

    get system(): SystemModel<T> {
        return this._system;
    }

    process(msg: fabric.FabricObjectMessage) {
        switch (msg.type) {
            case 'api': {
                this.apiResolver.add(msg.data.id, msg.data.spec);
                break;
            }
            case 'config-change': {
                this._system.withObjectConfig(msg.data.uuid, msg.data.config);
                this.apiResolver.resolve(msg.data.uuid, msg.data.config.api).subscribe((api) => {
                    if (
                        (schema.isApiRef(msg.data.config.api) && msg.data.config.api.ref !== api.id) ||
                        (!schema.isApiRef(msg.data.config.api) && msg.data.uuid !== api.id)
                    ) {
                        console.error('something is wrong!', msg.data.config.api, api.id);
                    }
                    this._system.withObjectApi(msg.data.uuid, api);
                });
                break;
            }
            case 'config-removed': {
                this._system.withoutObject(msg.data.uuid);
                break;
            }
            case 'state': {
                if (this.options?.state) {
                    this._system.withObjectState(msg.data.uuid, msg.data.state);
                }
                break;
            }
            case 'logic': {
                if (this.options?.logic) {
                    this._system.withObjectLogic(msg.data.uuid, msg.data.logic);
                }
                break;
            }
        }
    }
}
