import { ANONYMOUS_CONTROLLER_SUFFIX, LOC_PREFIX } from '@grenton/gm-common/src';
import { hashPassword } from '../security';
import { ProjectUserImpl, TEMPORARY_DEFAULT_USER_ROLES, ScriptImpl, ProjectObjectImpl } from './model';
import { createObjectsFromModuleComponent, CreateProjectImpl } from './builders';
import { createObjectsFromVirtualComponent } from './builders/createObjectsFromVirtualComponent';

const rooms_small = ['garden', 'garage', 'floor1', 'floor1.corridor', 'floor1.livingroom', 'floor1.bedroom', 'floor1.kitchen', 'floor2'];
const rooms_large = [
    'garden',
    'garage',
    'floor1',
    'floor1.corridor',
    'floor1.livingroom',
    'floor1.bedroom',
    'floor1.kitchen',
    'floor1.bathroom',
    'floor1.terrace',
    'floor2',
    'floor2.office',
    'floor2.kidsroom',
    'floor2.bathroom',
    'floor2.bedroom',
];

async function baseSampleProject(createProjectImpl: CreateProjectImpl, rooms: string[]) {
    async function user(name: string) {
        return ProjectUserImpl.from({ name, disabled: false, pwd: await hashPassword(name), roles: TEMPORARY_DEFAULT_USER_ROLES });
    }

    let p = await createProjectImpl();
    p = p.withSecurity(
        p.security
            .withUser(await user('admin'))
            .withUser(await user('family'))
            .withUser(await user('guest'))
            .withUser(await user('integration')),
    );
    rooms.forEach((room) => {
        p = p.withTag(`loc:${room}`);
    });

    p = p.withTagCategory('lights', '#f29b18', true, []).withTag('lights:main').withTag('lights:secondary').withTag('lights:night');
    return p;
}

export async function generateSampleProject(createProjectImpl: CreateProjectImpl) {
    let p = await baseSampleProject(createProjectImpl, rooms_small);

    const ioModuleImpl = createObjectsFromModuleComponent(p.firmware, '~/io-module-8-8/1.0.0', () => false);

    const relayModule1Impl = createObjectsFromModuleComponent(p.firmware, '~/relay-4hp/1.0.0', () => false);
    const relayModule2Impl = createObjectsFromModuleComponent(p.firmware, '~/relay-4hp/1.0.0', () => false);

    const lightsController = createObjectsFromVirtualComponent(p.firmware, '~/lights-controller/1.0.0', null, () => false, false, ['loc:floor1.livingroom']);
    const sceneController = createObjectsFromVirtualComponent(p.firmware, '~/scene-controller/1.0.0', null, () => false, false, ['loc:floor1.livingroom']);

    p = p
        .withComponentEntry(ioModuleImpl)
        .withComponentEntry(relayModule1Impl)
        .withComponentEntry(relayModule2Impl)
        .withComponentEntry(lightsController)
        .withComponentEntry(sceneController)
        // .withModule(smartPanelImpl)
        // .withModule(rollerShutterImpl)

        .withObject(ioModuleImpl.objects[0]!.object.withLabel('switch_L1').withUserType('switch').withTags(['loc:floor1.livingroom']))
        .withObject(ioModuleImpl.objects[1]!.object.withLabel('switch_L2').withUserType('switch').withTags(['loc:floor1.livingroom']))
        .withObject(ioModuleImpl.objects[2]!.object.withLabel('switch_B').withUserType('switch').withTags(['loc:floor1.bedroom']))
        .withObject(ioModuleImpl.objects[3]!.object.withLabel('switch_K').withUserType('switch').withTags(['loc:floor1.kitchen']))
        .withObject(ioModuleImpl.objects[4]!.object.withLabel('switch_C1').withUserType('switch').withTags(['loc:floor1.corridor']))
        .withObject(ioModuleImpl.objects[5]!.object.withLabel('switch_C2').withUserType('switch').withTags(['loc:floor1.corridor']))

        .withObject(relayModule1Impl.objects[0]!.object.withLabel('lamp_L1').withUserType('lamp').withTags(['loc:floor1.livingroom']))
        .withObject(relayModule1Impl.objects[1]!.object.withLabel('lamp_L2').withUserType('lamp').withTags(['loc:floor1.livingroom']))
        .withObject(relayModule1Impl.objects[2]!.object.withLabel('lamp_L3').withUserType('lamp').withTags(['loc:floor1.livingroom']))
        .withObject(relayModule1Impl.objects[3]!.object.withLabel('lamp_B1').withUserType('lamp').withTags(['loc:floor1.bedroom']))
        .withObject(relayModule2Impl.objects[0]!.object.withLabel('lamp_B2').withUserType('lamp').withTags(['loc:floor1.bedroom']))
        .withObject(relayModule2Impl.objects[1]!.object.withLabel('lamp_K1').withUserType('lamp').withTags(['loc:floor1.kitchen']))
        .withObject(relayModule2Impl.objects[2]!.object.withLabel('lamp_K2').withUserType('lamp').withTags(['loc:floor1.kitchen']))
        .withObject(relayModule2Impl.objects[3]!.object.withLabel('lamp_C1').withUserType('lamp').withTags(['loc:floor1.corridor']));

    // .withNewObjects(()=>rollerShutters.flat)
    // .withObject(rollerShutters.objects[0].withLabel("rollershutter_L1").withTags(["loc:floor1.livingroom"]))
    // .withObject(rollerShutters.objects[1].withLabel("rollershutter_L2").withTags(["loc:floor1.livingroom"]))
    // .withObject(rollerShutters.objects[2].withLabel("rollershutter_B1").withTags(["loc:floor1.bedroom"]))

    // .withNewObjects(()=>panel1.flat)
    // .withObject(panel1.objects[0].withLabel("panel_L"))

    const switch_L1 = p.getObjectByLabel('switch_L1')!;

    const switch_L1_ctrl = p.getObjectById(`${switch_L1.uuid}${ANONYMOUS_CONTROLLER_SUFFIX}`);

    p = p.withObject(switch_L1_ctrl!.withScripts(switch_L1_ctrl!.scripts.withScript('object.onClick', new ScriptImpl({ format: 'visual' }))));

    return p;
}

export async function generateLargeSampleProject(createProjectImpl: CreateProjectImpl) {
    let p = await baseSampleProject(createProjectImpl, rooms_large);

    const hasLabel = (label: string) => p.hasObjectWithLabel(label);

    p.tags.getCategory(LOC_PREFIX)?.values.all.forEach((tagValue) => {
        const tag = `${LOC_PREFIX}:${tagValue}`;

        const addTag = (o: ProjectObjectImpl) => (o.top ? o.withTags([tag]) : o);

        const io_objects = createObjectsFromModuleComponent(p.firmware, '~/io-module-8-8/1.0.0', hasLabel);
        p = p.withComponentEntry(io_objects, addTag);

        const relay_objects = createObjectsFromModuleComponent(p.firmware, '~/relay-4hp/1.0.0', hasLabel);
        p = p.withComponentEntry(relay_objects, addTag);

        const roller_shutter_objects = createObjectsFromModuleComponent(p.firmware, '~/roller-shutter/1.0.0', hasLabel);
        p = p.withComponentEntry(roller_shutter_objects, addTag);

        const smart_panel_objects = createObjectsFromModuleComponent(p.firmware, '~/smart-panel/1.0.0', hasLabel);
        p = p.withComponentEntry(smart_panel_objects, addTag);

        const lightsController = createObjectsFromVirtualComponent(p.firmware, '~/lights-controller/1.0.0', null, hasLabel, false, [tag]);
        p = p.withComponentEntry(lightsController, addTag);
    });

    return p;
}
