import { ResolvedPath } from '../project';

const reserved: { [keyword: string]: true } = [
    'and',
    'for',
    'or',
    'break',
    'function',
    'repeat',
    'do',
    'if',
    'return',
    'else',
    'in',
    'then',
    'elseif',
    'local',
    'true',
    'end',
    'nil',
    'until',
    'false',
    'not',
    'while',
].reduce((all, keyword) => ({ ...all, [keyword]: true }), {});

export function checkLuaName(value?: string): 'ok' | 'invalid' | 'empty' | 'reserved' {
    if (!value) return 'empty';
    if (reserved[value]) return 'reserved';
    return value.match(/^[_A-z][_0-9A-z]*$/) ? 'ok' : 'invalid';
}

export function lua_getMapField(mapName: string, fieldName: string) {
    return checkLuaName(fieldName) === 'ok' ? `${mapName}.${fieldName}` : `${mapName}["${fieldName}"]`;
}

export function lua_escapeField(fieldName: string) {
    return checkLuaName(fieldName) === 'ok' ? fieldName : `["${fieldName}"]`;
}

export function convertToLuaName(value: string, lowercase = false) {
    const n: string[] = [];
    for (let i = 0; i < value.length; i++) {
        const c = value.charAt(i);
        n.push(!(i === 0 && c.match(/[0-9]/)) && c.match(/[_0-9A-z]/) ? (lowercase ? c.toLocaleLowerCase() : c) : '_');
    }
    const name = n.join('');
    return reserved[name] ? name + '_' : name;
}

export function lua_pathToCode(path: ResolvedPath, skipNamespace = false) {
    if (path.self || !path.head || path.head?.type !== 'object') {
        return path.serializeNames().map(lua_escapeField).join('.');
    } else if (path.rootObject) {
        return [skipNamespace ? path.head.name : lua_getMapField('objects', path.head.name), ...path.withoutHead().serializeNames().map(lua_escapeField)].join(
            '.',
        );
    }
}
