import { schema } from '@grenton/gm-common';

export function convertPropertyValueTypeIfNeeded(type: schema.PropertyValueType, value: schema.PropertyValue): schema.PropertyValue {
    switch (type) {
        case 'number':
            return typeof value === 'string' ? parseFloat(value) : value;
        case 'integer':
            return typeof value === 'string' ? parseInt(value) : value;
        default:
            return value;
    }
}

export function convertPropertyValueToStringIfNeeded(type: schema.PropertyValueType, value: schema.PropertyValue): schema.PropertyValue | undefined {
    switch (type) {
        case 'number':
        case 'integer':
            return value?.toString();
        default:
            return value;
    }
}
