import { ObjectApi, isApiExtending, fabric, schema, prot } from '@grenton/gm-common';
import { ObjectEventEmitter, ObjectEmulator, MethodInvocation, Scheduler, ObjectStateHolder } from './modules/common';
import { DigitalINEmulator } from './modules/digital-in-emu';
import { DigitalOUTEmulator } from './modules/digital-out-emu';
import log from 'loglevel';
import { bufferCount } from 'rxjs';
import { Dimmer } from './modules/dimmer-emu';
import { LedRGB } from './modules/ledrgb-emu';
import { Subscriptions } from '@grenton/gm-common';
import { ProjectFirmwareImpl } from '@grenton/gm-logic';
import { isApiRef } from '@grenton/gm-common/dist/lib/model/objects/api';

/**
 * hardware engine manages hardware objects on CLU.
 * it detects a new object in project by listening to /object/{uuid}/config topics,
 * checks for its hardware mapping to match hardware module, and if this module is connected to its CLU,
 * it can initialize & update state, generate events and receive RPC requests.
 *
 * in emulation mode we do not need hardware mapping, the engine creates hardware emulator per each new
 * project object.
 */
export class HardwareEngineEmulator {
    private emulatedObjects: {
        [uuid: string]: {
            api: ObjectApi;
            emulator?: ObjectEmulator;
            scheduler: Scheduler;
            state: ObjectStateHolder;
        };
    } = {};

    private scheduler: Scheduler;
    private subs = new Subscriptions();
    private fabricPublisher?: fabric.FabricPublisher;
    public firmware?: ProjectFirmwareImpl;

    constructor() {
        this.scheduler = new Scheduler();
    }

    connect(fabricObservable: fabric.FabricObservable, fabricPublisher: fabric.FabricPublisher, fabricRpcServer: fabric.FabricRpcServer) {
        this.subs.dispose();
        this.fabricPublisher = fabricPublisher;
        this.subs.put(
            fabricObservable.subscribe((msg) => {
                // TODO use systemClient instead in a similar manner to controller-engine
                switch (msg.type) {
                    case 'config-change': {
                        const uuid = msg.data.uuid;
                        const r = this.add(uuid, msg.data.config);
                        const executor = r?.executor;
                        if (executor) {
                            fabricRpcServer.registerExecutor(uuid, (request: fabric.JsonRpcRequest) => {
                                return (
                                    executor(request)
                                        .then((result) =>
                                            request.id
                                                ? {
                                                      id: request.id,
                                                      result,
                                                  }
                                                : undefined,
                                        )

                                        // TODO here, besides runtime errors, we can expect some errors specific to invocation,
                                        // e.g. invalid parameters etc.
                                        // executor has to throw such error and we need to distinguish between runtime/invocation here
                                        .catch((e) =>
                                            request.id
                                                ? {
                                                      id: request.id,
                                                      error: {
                                                          code: 0,
                                                          message: e.toString(),
                                                      },
                                                  }
                                                : undefined,
                                        )
                                );
                            });
                        }
                        break;
                    }
                    case 'config-removed': {
                        const uuid = msg.data.uuid;
                        this.remove(uuid);
                        fabricRpcServer.removeExecutor(uuid);
                        break;
                    }
                }
            }),
        );
    }

    // TODO it should return executor
    private add(uuid: string, config: fabric.ObjectConfig): { executor?: (invocation: MethodInvocation) => Promise<schema.PropertyValue> } | undefined {
        if (!this.firmware) {
            throw new Error('firmware is not set');
        }
        if (config.impl.type !== 'module') return; // emulate only hardware

        const api = isApiRef(config.api) ? this.firmware.resolveApiRef(config.api) : this.firmware.resolveObjectApi(uuid, config.api);
        if (!api) {
            log.info(`cannot resolve api ${config.api}, ignore object`);
            return;
        }

        if (config.impl.type !== 'module') {
            log.debug(`not hardware object (${api.id}), ignore`);
            return;
        }

        const stateHolder = new ObjectStateHolder();

        // fill based on API
        Object.values(api.flat.features).forEach((f) => {
            stateHolder.set(f.id, f.default || null);
        });
        const emulator = this.getEmulator(api, stateHolder, (events) => {
            this.fabricPublisher?.publish({ type: 'event', data: { uuid, events } });
        });
        let executor: ((i: MethodInvocation) => Promise<schema.PropertyValue>) | undefined;
        if (emulator) {
            stateHolder.objectState.pipe(bufferCount(2, 1)).subscribe(([prev, curr]) => {
                if (curr) {
                    emulator.onStateChange(curr, prev);
                }
            });
            executor = (i: MethodInvocation) => emulator.execute(i);
        }
        stateHolder.objectState.subscribe((state) => {
            this.fabricPublisher?.publish({ type: 'state', data: { uuid, state } });
        });

        const scheduler = new Scheduler();

        this.emulatedObjects[uuid] = {
            api,
            state: stateHolder,
            emulator,
            scheduler,
        };
        return { executor };
    }

    private remove(uuid: string) {
        const o = this.emulatedObjects[uuid];
        if (o) {
            o.scheduler.cancel();
            delete this.emulatedObjects[uuid];
        }
    }

    private getEmulator(api: ObjectApi, state: ObjectStateHolder, emitter: ObjectEventEmitter) {
        if (isApiExtending(api, prot.DigitalIN)) return new DigitalINEmulator(state, emitter, this.scheduler);
        if (isApiExtending(api, prot.DigitalOUT)) return new DigitalOUTEmulator(state, emitter, this.scheduler);
        if (isApiExtending(api, prot.Dimm)) return new Dimmer(state, emitter, this.scheduler);
        if (isApiExtending(api, prot.LedRGBW)) return new LedRGB(state, emitter, this.scheduler);
    }
}
