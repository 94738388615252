import { NotificationReceiver } from '.';
import { CommandHandler } from '../dispatcher';
import { NotificationCommand } from './notificationCommand';

export class NotificationCommandHandler implements CommandHandler<NotificationCommand> {
    constructor(private notificationReceiver: NotificationReceiver) {}

    supports(cmd: NotificationCommand) {
        return cmd.type === 'notification';
    }
    async execute(cmd: NotificationCommand) {
        this.notificationReceiver(cmd.data);
    }
}
