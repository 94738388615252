import { TypographyOptions, Variant } from '@mui/material/styles/createTypography';
import { sizeRem, sizes } from './size';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        xl: React.CSSProperties;
        l: React.CSSProperties;
        m: React.CSSProperties;
        s: React.CSSProperties;
        s_regular: React.CSSProperties;
        s_bold: React.CSSProperties;
        s_info: React.CSSProperties;
        xs: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        xl?: React.CSSProperties;
        l?: React.CSSProperties;
        m?: React.CSSProperties;
        s?: React.CSSProperties;
        s_regular?: React.CSSProperties;
        s_bold?: React.CSSProperties;
        s_info?: React.CSSProperties;
        xs?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        xl: true;
        l: true;
        m: true;
        s: true;
        s_regular: true;
        s_bold: true;
        s_info: true;
        xs: true;
    }
}

export const grentonTypographyVariants: Variant[] = ['xl', 'l', 'm', 's', 's_regular', 's_bold', 's_info', 'xs'] as any[];

export const typography: TypographyOptions = {
    fontFamily: `"Manrope", sans-serif`,
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 600,
    fontWeightMedium: 800,
    xl: {
        fontSize: sizeRem(sizes.XL),
        fontWeight: 600,
    },
    l: {
        fontSize: sizeRem(sizes.L),
        fontWeight: 600,
    },
    m: {
        fontSize: sizeRem(sizes.M),
        fontWeight: 600,
    },
    s: {
        fontSize: sizeRem(sizes.S),
        fontWeight: 600,
    },
    s_regular: {
        fontSize: sizeRem(sizes.S),
        fontWeight: 400,
    },
    s_bold: {
        fontSize: sizeRem(sizes.S),
        fontWeight: 800,
    },
    s_info: {
        fontSize: sizeRem(sizes.S),
        fontWeight: 600,
    },
    xs: {
        fontSize: sizeRem(sizes.XS),
        fontWeight: 600,
    },
};
