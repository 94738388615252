import { Command, CommandHandler } from './command-handler';

export class CommandDispatcher {
    constructor(private handlers: CommandHandler<any>[]) {}

    async execute<T extends Command>(cmd: T): Promise<void> {
        const handler = this.handlers.find((h) => h.supports(cmd));
        if (!handler) {
            throw new Error(`command handler not found for ${cmd.type}`);
        }
        await handler.execute(cmd);
    }
}
