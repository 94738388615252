import type * as schema from './api';
import { ProtocolID } from './api';

export type ApiItems = {
    readonly events: Record<string, schema.Event>;
    readonly methods: Record<string, schema.Method>;
    readonly features: Record<string, schema.Feature>;
    readonly outlets: Record<string, ObjectOutlet>;
};

export const SPEC_API_PREFIX = '@';

// TODO add 'ref'/'spec' flag to ObjectApi instead ?
export function isInlinedApi(api: ObjectApi): boolean {
    return api.id.startsWith(SPEC_API_PREFIX);
}

export function isApiExtending(api: ObjectApi, parent: ProtocolID): boolean {
    if (api.id === parent) return true;
    for (const c of api.extending) {
        if (isApiExtending(c, parent)) return true;
    }
    return false;
}

export type ObjectApi = {
    id: schema.ProtocolID; // required
    meta: schema.ProtocolMeta;
    extending: ObjectApi[];

    readonly flat: ApiItems;
    readonly self: ApiItems;
};

export type ObjectOutlet = {
    id: string;
    label?: string;
    description?: string;
    readOnly?: boolean;
    api: ObjectApi;
    bidirectional?: number;
    maxItems?: number;
};
