import log from 'loglevel';
import { Block, Workspace } from 'blockly';
import { ProjectImpl } from '@grenton/gm-logic';
import { ScriptWithContext } from './script-with-context';
import { ObjectApi } from '@grenton/gm-common';

export type ReturnedType = { type: 'object' | 'outlet' | 'selection'; api: ObjectApi };

export interface BlockContext {
    readonly project: ProjectImpl;
    readonly editedScript: ScriptWithContext;

    getInputType(inputName: string): ReturnedType | undefined;
    setReturnedType(type: ReturnedType): void;

    // copy(name: string | undefined): BlockContext
    // setVarType(varId:string, type?:ReturnedType) : void
    // getVarType(varId:string) : ReturnedType | undefined
}

export interface InheritableBlockContext extends BlockContext {
    copy(name?: string): InheritableBlockContext;
    setVarType(varId: string, type?: ReturnedType): void;
    getVarType(varId: string): ReturnedType | undefined;
}

export class BlockContextImpl implements InheritableBlockContext {
    constructor(
        readonly project: ProjectImpl,
        readonly editedScript: ScriptWithContext,
        private varTypes: { [varId: string]: ReturnedType | undefined },
        readonly parent?: BlockContextImpl,
        private currentInput?: string,
    ) {}

    private inputTypes: { [input: string]: ReturnedType } = {};

    // TODO when going into nested statement, varTypes should be inherited but in readonly mode
    copy(inputName?: string) {
        return new BlockContextImpl(this.project, this.editedScript, this.varTypes, this, inputName);
    }

    setReturnedType(type: ReturnedType) {
        if (this.currentInput && this.parent) {
            this.parent.inputTypes[this.currentInput] = type;
        }
    }

    getInputType(inputName: string) {
        return this.inputTypes[inputName];
    }

    setVarType(varId: string, type?: ReturnedType): void {
        this.varTypes[varId] = type;
    }

    getVarType(varId: string): ReturnedType | undefined {
        return this.varTypes[varId];
    }
}

function notifyBlock(block: Block, handler: string, args: any[]) {
    const t = block as any;
    if (typeof t[handler] === 'function') {
        (t[handler] as Function).call(t, args);
    }
}

const mods: { [type: string]: (block: Block, context: InheritableBlockContext) => void } = {
    variables_set: (block: Block, context: InheritableBlockContext) => {
        const varId = block.getFieldValue('VAR');
        const varType = context.getInputType('VALUE');
        context.setVarType(varId, varType);
        //log.debug(`variable ${varId} set to "${varType?.type}"`);
    },
    variables_get: (block: Block, context: InheritableBlockContext) => {
        const varId = block.getFieldValue('VAR');
        const varType = context.getVarType(varId);
        //log.debug(`get variable ${varId} of type "${varType?.type}"`);
        if (varType) {
            context.setReturnedType(varType);
        }
    },
    controls_forEach: (block: Block, context: InheritableBlockContext) => {
        const varId = block.getFieldValue('VAR');
        const varType = context.getInputType('LIST');
        context.setVarType(varId, varType);
        //log.debug(`variable ${varId} set to "${varType?.type}"`);
    },
};

/*  I think the correct traversing order is:
 * - for all value inputs -> bottom-up
 * - then current block
 * - then all statement inputs
 * - then next statement
 */
function traverseBlockBranch(block: Block, context: InheritableBlockContext, level = 0) {
    if (block.isInsertionMarker()) return;
    // careful, returned list includes following statement!

    //const _indent = ''.padStart(level, '  ');
    //const _id = `"${block.type}" ["${block.id}"]`;
    //log.debug(`${_indent}-> traverse block ${_id}`);

    const next = block.getNextBlock();
    const children = block.getChildren(true);
    const children_value_blocks = children.filter((b) => Boolean(b.outputConnection?.isConnected()));
    const children_statement_blocks = children.filter((b) => !Boolean(b.outputConnection?.isConnected()) && b !== next);

    // value blocks
    for (const child of children_value_blocks) {
        const input = block.getInputWithBlock(child);
        //log.debug(`${_indent}--> follow input ${input?.name}`);
        traverseBlockBranch(child, context.copy(input?.name), level + 1);
    }

    // this block
    //log.debug(`${_indent}-> update ${_id}`);
    notifyBlock(block, 'onupdate', [context]);

    const mod = mods[block.type];
    if (mod) {
        mod(block, context);
    }

    // nested statement blocks
    for (const child of children_statement_blocks) {
        const input = block.getInputWithBlock(child);
        //log.debug(`${_indent}--> follow input ${input?.name}`);
        traverseBlockBranch(child, context.copy(input?.name), level + 1);
    }

    // next block
    if (next) {
        //log.debug(`${_indent}--> follow next`);
        traverseBlockBranch(next, context.copy('_next'));
    }
}

/*
 * here we want to traverse entire block tree and call onUpdate on each block so it could adjust itself, e.g. due to
 * changed input.
 * block behavior generally depends on its own fields and its value inputs, however some may also need "context" info,
 * e.g. if they want to know what value is assigned to variable (this assignment happens "before" current block, not inside its subtree)
 *
 * for now, to address value input issue, we traverse blocks bottom - up.
 * TODO:
 * I think the correct traversing order is:
 * - for all value inputs -> bottom-up
 * - then current block
 * - then all statement inputs
 * - then next statement
 *
 * in that way we can populate 'context' with variables' values and handle case of
 *
 *   for each {object} in {outlet} do
 *      {object}:setValue({})
 *   end
 *
 * because {object} variable will have known type inside the loop.
 */

export function traverseBlockGraph(workspace: Workspace, context: InheritableBlockContext) {
    log.debug(`-> traverse block graph`);
    for (const block of workspace.getTopBlocks(true)) {
        traverseBlockBranch(block, context.copy());
    }
}
