import { Components, Theme } from '@mui/material';
import { sizeRem, sizes } from '../size';

export const MuiFormControlLabel: Components<Omit<Theme, 'components'>>['MuiFormControlLabel'] = {
    styleOverrides: {
        root: {
            '& .MuiFormControlLabel-label': {
                fontSize: sizeRem(sizes.M),
            },
        },
    },
};
