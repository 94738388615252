/* eslint-disable camelcase -- I need this */
import { type ObjectApi } from './model';
import { type ObjectApiRegistry } from './api-registry';
import * as apiUtils from './api';
import { ObjectApiImpl } from './object-api-impl';
import { concatenateIt } from '../../collections';


export class ObjectApiRegistryImpl implements ObjectApiRegistry {
    private readonly apis = new Map<string, ObjectApiImpl>();

    constructor(private parent?:ObjectApiRegistryImpl) {}

    resolveRef(ref: apiUtils.ProtocolID ): ObjectApi | null {
        return this.parent?.resolveRef(ref) || this.apis.get(ref) || null;
    }

    store(api: ObjectApiImpl): void {
        // TBD!
        // we actually need to override dynamic APIs in runtime!
        // if (this.apis.get(api.name)) {
        //     throw new Error(`api "${api.name}" is already registered`)
        // }
        this.apis.set(api.id, api);
    }

    get all(): IterableIterator<ObjectApi> {
        return concatenateIt(this.parent?.all, this.apis.values());
    }
}