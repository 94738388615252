import { z } from 'zod';

const AppEnv = z.object({
    APP_VERSION: z.string(),
    AUTH0_DOMAIN: z.string(),
    AUTH0_CLIENT_ID: z.string(),
    AUTH0_GM_CLIENT_ID: z.string(),
    AUTH0_AUDIENCE: z.string(),
    CLOUD_MQTT_BROKER_URL: z.string(),
    CLOUD_MQTT_USERNAME: z.string(),
    CLOUD_MQTT_PASSWORD: z.string(),
    CLOUD_SYNC_ENABLED: z.boolean().default(true),
    CLOUD_SYNC_URL: z.string(),
    CLOUD_SYNC_PULL_STREAM_URL: z.string(),
    GRENTON_REPO_URL: z.string(),
    GRENTON_NETWORKS: z.string().optional(),
    GRENTON_NETWORK_STATUS_URL: z.string(),
    GRENTON_CERTIFICATE_VALIDATION_URL: z.string(),
    MODE: z.string(),
});

const appEnv = AppEnv.parse({
    // app version - it is substituted by Vite in vite.config.ts
    APP_VERSION: VITE_APP_VERSION,

    // built-in variables
    MODE: import.meta.env.MODE,
    // PROD: import.meta.env.PROD,
    // DEV: import.meta.env.DEV,

    // following variables are defined either in .env files or directly in env
    // all VITE_* variables have to be specified literally here!
    AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,

    AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
    AUTH0_GM_CLIENT_ID: import.meta.env.VITE_AUTH0_GM_CLIENT_ID,
    AUTH0_AUDIENCE: import.meta.env.VITE_AUTH0_AUDIENCE,

    CLOUD_MQTT_BROKER_URL: import.meta.env.VITE_CLOUD_MQTT_BROKER_URL,
    CLOUD_MQTT_USERNAME: import.meta.env.VITE_CLOUD_MQTT_USERNAME,
    CLOUD_MQTT_PASSWORD: import.meta.env.VITE_CLOUD_MQTT_PASSWORD,

    CLOUD_SYNC_ENABLED: import.meta.env.VITE_CLOUD_SYNC_ENABLED,
    CLOUD_SYNC_URL: import.meta.env.VITE_CLOUD_SYNC_URL,
    CLOUD_SYNC_PULL_STREAM_URL: import.meta.env.VITE_CLOUD_SYNC_PULL_STREAM_URL,

    // repository URL
    GRENTON_REPO_URL: import.meta.env.VITE_GRENTON_REPO_URL,

    // simulator URL
    GRENTON_NETWORKS: import.meta.env.VITE_GRENTON_NETWORKS,

    // network status URL
    GRENTON_NETWORK_STATUS_URL: import.meta.env.VITE_GRENTON_NETWORK_STATUS_URL,

    // certificate status URL
    GRENTON_CERTIFICATE_VALIDATION_URL: import.meta.env.VITE_GRENTON_CERTIFICATE_VALIDATION_URL,
});

export default appEnv;
