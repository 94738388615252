export const TAG_SEPARATOR = ':';
export const LOC_PREFIX = `loc${TAG_SEPARATOR}`;

export function parseTag(tag?: string) {
    const t = tag?.split(TAG_SEPARATOR);

    if (!t || t.length !== 2) {
        return { category: '', value: '' };
    } else {
        return { category: t[0]!.trim(), value: t[1]!.trim() };
    }
}
