import { type PropertyValue } from '../objects/api';
import { type ProjectItemPath } from './refs';

export const ScriptFormats = ['actions', 'visual', 'lua'] as const;
export type ScriptFormat = (typeof ScriptFormats)[number];

export type ActionScript = {
    items: ActionItem[];
};

/**
 * this model does not impose any action details, it can be anything.
 * in GM, action widgets translate its actions to Blockly (which then translates it to LUA).
 * However, this means that either myGrenton or CLU admin API would have to do the same thing (produce LUA code from action)
 * it may be problematic.
 *
 * if we assume that "Action" is simply list of method invocations on target, then we do not need LUA code to execute
 * such action, and we do not need any transpilation phase.
 */
export type ActionItem = {
    target: ProjectItemPath;
    action: Action;
    output: ActionOutput;
};

export type Action = {
    type: string;
    model: unknown;
};

export type ActionOutput = {
    calls: ActionCall[];
};

export type ActionCall = {
    callable: string;
    params?: Record<string, PropertyValue>;
};

// TODO refactor to use ExecutableActionScript and ExecutableLuaScript?
export type Script = {
    steps: ScriptStep[];
};

export type ScriptStep = {
    actions?: ActionScript;
    code?: string;
    visual?: string;
    format: ScriptFormat;
};

export function emptyScript(): Script {
    return { steps: [{ format: 'actions' }] };
}

export function emptyBasicScript(): ActionScript {
    return { items: [] };
}
