import { Components, Theme } from '@mui/material';

export const MuiFormControl: Components<Omit<Theme, 'components'>>['MuiFormControl'] = {
    styleOverrides: {
        root: {
            '& label:not(.MuiInputLabel-shrink)': {
                // 16px vertically is default, not sure why now we need to alter it...
                transform: 'translate(14px, 8px) scale(1)',
            },
        },
    },
};
