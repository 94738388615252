import {Stack, ToggleButtonGroup, ToggleButton, Slider, FormControlLabel, FormGroup, Box, Checkbox} from "@mui/material"
import GMTextField from "../../../../ui/controls/GMTextField"
import {ActionLedRGBSetModel} from "./model"
import {ObjectApi} from "@grenton/gm-common"

export function ActionLedRGBSetWidget(props: {
    api: ObjectApi,
    model: ActionLedRGBSetModel,
    onUpdate: (model: ActionLedRGBSetModel) => void
}) {

    const {model, onUpdate} = props
    return <Stack spacing={2} padding={2} direction="row">

        <ToggleButtonGroup
            sx={{alignItems: "start", marginRight: 4}}
            value={model.on}
            exclusive
            onChange={(_, on) => {
                props.onUpdate({...model, on})
            }}
        >
            <ToggleButton value={false} sx={{minWidth: 70}}>
                Off
            </ToggleButton>
            <ToggleButton color="primary" value={true} sx={{minWidth: 70}}>
                On
            </ToggleButton>
        </ToggleButtonGroup>

        <Stack minWidth={300}>
            <FormGroup>
                <Stack direction={"row"} alignItems="center">
                    <FormControlLabel
                        control={<Checkbox disabled={!model.on} checked={model.h?.set} onChange={(_, set) => {
                            onUpdate({...model, h: {...model.h, set}})
                        }}/>} label={<Box minWidth={100}>Hue</Box>}/>
                    <Slider
                        disabled={!model.on || !model.h?.set}
                        size="small"
                        value={model.h?.value || 0}
                        onChange={(_, value) => {
                            onUpdate({...model, h: {...model.h, value: value as number}})
                        }}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                    />
                </Stack>
                <Stack direction={"row"} alignItems="center">
                    <FormControlLabel
                        control={<Checkbox disabled={!model.on} checked={model.s?.set} onChange={(_, set) => {
                            onUpdate({...model, s: {...model.s, set}})
                        }}/>} label={<Box minWidth={100}>Saturation</Box>}/>
                    <Slider
                        disabled={!model.on || !model.s?.set}
                        onChange={(_, value) => {
                            onUpdate({...model, s: {...model.s, value: value as number}})
                        }}
                        size="small"
                        value={model.s?.value || 0}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                    />
                </Stack>
                <Stack direction={"row"} alignItems="center">
                    <FormControlLabel
                        control={<Checkbox disabled={!model.on} checked={model.v?.set} onChange={(_, set) => {
                            onUpdate({...model, v: {...model.v, set}})
                        }}/>} label={<Box minWidth={100}>Value</Box>}/>
                    <Slider
                        disabled={!model.on || !model.v?.set}
                        onChange={(_, value) => {
                            onUpdate({...model, v: {...model.v, value: value as number}})
                        }}
                        size="small"
                        value={model.v?.value || 0}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                    />
                </Stack>
            </FormGroup>
        </Stack>

        <Box sx={{flexGrow: 2}}/>
        <Stack direction="row" sx={{ml: 4}} alignItems="center" spacing={1}>
            <GMTextField value={model.ramp || ''} label="revert after (ms)" onChange={e => {
                const ramp = parseInt(e.target.value) || 0
                props.onUpdate({...model, ramp})
            }} size="small" type="number" sx={{maxWidth: 150}}></GMTextField>
        </Stack>
    </Stack>
}
