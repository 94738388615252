import { StateUpdater } from '../utils';
import { ProjectImpl } from './model';

export type LoadNewProject = ReturnType<typeof loadNewProject>;

export function loadNewProject(updater: StateUpdater<ProjectImpl | null>) {
    return function (p: ProjectImpl) {
        updater(() => p);
    };
}
