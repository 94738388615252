import { HWModuleShim, ObjectApi, createModuleObjectId, Component } from '@grenton/gm-common';
import { HWObjectImpl, HWModuleImpl } from '../project';

export function createModuleImpl(
    moduleShim: HWModuleShim,
    resolverModuleType: (type: string) => Component | undefined,
    resolverObjectApi: (api: { ref: string }) => ObjectApi | undefined,
    online = false,
) {
    const di = resolverModuleType(moduleShim.ref);
    if (!di || di.spec.type !== 'module') return;

    const objects: HWObjectImpl[] = [];
    di.spec.provides.objects.forEach((objentry) => {
        const api = resolverObjectApi(objentry.protocol);
        for (let i = 0; i < objentry.count; i++) {
            const cmpObjectId = createModuleObjectId(objentry, i + 1);
            const objectShim = moduleShim.objects.find((o) => o.port === cmpObjectId);
            if (api && objectShim) objects.push(new HWObjectImpl({ api, port: cmpObjectId, id: objectShim.id }));
        }
    });

    return HWModuleImpl.from(moduleShim.id, moduleShim.ref, objects, online);
}
