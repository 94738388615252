/*
# Password hash format

### Intro
Passwords must be kept securely in the configuration. 
A configuration leak cannot be synonymous with a password leak, so passwords are stored in hash form. 
For this purpose, we use the PBKDF2 algorithm. 
During the hash generation process, variable parameters are used that are needed on the server side, so it is necessary to save them in the configuration. 

### Format
This record will be in the following format:

```
$pbkdf2-sha512$[iterations]$[key length]$[salt][hash]
```

Example:
```
$pbkdf2-sha512$12345678$87654321$R9h/cIPz0gi.URNN/PgBkqquzi.Ss7KIUgO2t0jWMUW
\_____________/\______/ \______/ \_______________/\_____________________________/
      Alg     Iterations Keylen        Salt                    Hash
```

### Additional assumtions:
- `salt` is always of constant length and is 16 characters (12 bytes long when decoded),
- `pbkdf2-sha512` using `SHA512` hashing.
*/

const SALT_LENGTH_BYTES = 12;
const SALT_LENGTH_CHARS = 16;

export async function createPasswordHash(
    password: string,
    iterations: number = 100000,
    keyLengthInBits: number = 512,
    dangerouslySetSalt?: Uint8Array,
): Promise<string> {
    // Convert password to ArrayBuffer
    const encoder = new TextEncoder();
    const passwordBuffer = encoder.encode(password);

    // Generate a random salt
    const salt = dangerouslySetSalt || crypto.getRandomValues(new Uint8Array(SALT_LENGTH_BYTES));

    // Import the password as a key
    const passwordKey = await crypto.subtle.importKey('raw', passwordBuffer, { name: 'PBKDF2' }, false, ['deriveBits']);

    // Derive bits using PBKDF2
    const derivedBits = await crypto.subtle.deriveBits(
        {
            name: 'PBKDF2',
            salt: salt,
            iterations: iterations,
            hash: 'SHA-512',
        },
        passwordKey,
        keyLengthInBits,
    );

    // Convert derived bits to Base64
    const derivedKey = btoa(String.fromCharCode(...new Uint8Array(derivedBits)));

    // Convert salt to Base64
    const saltBase64 = btoa(String.fromCharCode(...salt));

    // Construct the final hash string
    const hashString = `$pbkdf2-sha512$${iterations}$${keyLengthInBits}$${saltBase64}${derivedKey}`;

    return hashString;
}

export async function verifyPasswordHash(password: string, hash: string): Promise<boolean> {
    // Parse the hash string
    const parts = hash.split('$');
    if (parts.length !== 5 || parts[1] !== 'pbkdf2-sha512') {
        throw new Error('Invalid hash format');
    }

    const iterations = parseInt(parts[2]!, 10);
    const keyLengthInBits = parseInt(parts[3]!, 10);
    const saltAndHash = parts[4]!;

    // Extract salt (first 16 characters of Base64, which is 12 bytes when decoded)
    const saltBase64 = saltAndHash.slice(0, SALT_LENGTH_CHARS);
    const salt = Uint8Array.from(atob(saltBase64), (c) => c.charCodeAt(0));

    // Convert password to ArrayBuffer
    const encoder = new TextEncoder();
    const passwordBuffer = encoder.encode(password);

    // Import the password as a key
    const passwordKey = await crypto.subtle.importKey('raw', passwordBuffer, { name: 'PBKDF2' }, false, ['deriveBits']);

    // Derive bits using PBKDF2 with the same parameters
    const derivedBits = await crypto.subtle.deriveBits(
        {
            name: 'PBKDF2',
            salt: salt,
            iterations: iterations,
            hash: 'SHA-512',
        },
        passwordKey,
        keyLengthInBits,
    );

    // Convert derived bits to Base64
    const derivedKey = btoa(String.fromCharCode(...new Uint8Array(derivedBits)));

    // Compare the derived key with the hash
    const expectedHash = saltAndHash.slice(SALT_LENGTH_CHARS); // Remove the salt part
    return derivedKey === expectedHash;
}
