import { Project } from '@grenton/gm-common/src';
import { ValidationContext, ResultBuilder, NO_ERRORS } from './helpers';
import { FirmwareProvider } from '../firmwareProvider';

export function validateFirmware(firmwareProvider: FirmwareProvider) {
    return (project: Project, context: ValidationContext) => {
        const result = new ResultBuilder();

        if (!project.firmware) {
            return result.fatalError('schema', 'Missing firmware version');
        }

        const firmware = firmwareProvider(project.firmware);
        if (!firmware) {
            return result.fatalError('schema', `Firmware "${project.firmware}" is not supported`);
        }

        context['firmware'] = firmware;

        return NO_ERRORS;
    };
}
