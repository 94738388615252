import { Subject, Observable } from 'rxjs';

export interface Doc<T = unknown> {
    id: string;
    dependencies: string[];
    data: T;
}

interface FailedDoc<T> {
    doc: Doc<T>;
    reason: string;
}

enum ProcessingState {
    WAITING,
    PROCESSING,
    COMPLETED,
    FAILED,
}

class Graph {
    private adjacencyList = new Map<string, Set<string>>();

    addNode(node: string) {
        if (!this.adjacencyList.has(node)) {
            this.adjacencyList.set(node, new Set());
        }
    }

    addEdge(from: string, to: string) {
        this.addNode(from);
        this.addNode(to);
        this.adjacencyList.get(from)!.add(to);
    }

    getNodes(): string[] {
        return Array.from(this.adjacencyList.keys());
    }

    getNeighbors(node: string): string[] {
        return Array.from(this.adjacencyList.get(node) || []);
    }
}

function tarjanSCC(graph: Graph): string[][] {
    const nodes = graph.getNodes();
    const index = new Map<string, number>();
    const lowLink = new Map<string, number>();
    const onStack = new Set<string>();
    const stack: string[] = [];
    let currentIndex = 0;
    const result: string[][] = [];

    function strongConnect(node: string) {
        index.set(node, currentIndex);
        lowLink.set(node, currentIndex);
        currentIndex++;
        stack.push(node);
        onStack.add(node);

        for (const neighbor of graph.getNeighbors(node)) {
            if (!index.has(neighbor)) {
                strongConnect(neighbor);
                lowLink.set(node, Math.min(lowLink.get(node)!, lowLink.get(neighbor)!));
            } else if (onStack.has(neighbor)) {
                lowLink.set(node, Math.min(lowLink.get(node)!, index.get(neighbor)!));
            }
        }

        if (lowLink.get(node) === index.get(node)) {
            const scc: string[] = [];
            let w: string;
            do {
                w = stack.pop()!;
                onStack.delete(w);
                scc.push(w);
            } while (w !== node);
            result.push(scc);
        }
    }

    for (const node of nodes) {
        if (!index.has(node)) {
            strongConnect(node);
        }
    }

    return result;
}

function topologicalSort(graph: Graph, scc: string[][]): string[][] {
    const visited = new Set<string>();
    const result: string[][] = [];

    function dfs(component: string[]) {
        if (visited.has(component.join('|'))) return;
        visited.add(component.join('|'));

        for (const node of component) {
            for (const neighbor of graph.getNeighbors(node)) {
                const neighborComponent = scc.find((comp) => comp.includes(neighbor));
                if (neighborComponent && !component.includes(neighbor)) {
                    dfs(neighborComponent);
                }
            }
        }

        result.push(component);
    }

    for (const component of scc.slice().reverse()) {
        dfs(component);
    }

    return result;
}

export class DependencyProcessor<T = unknown> {
    private docs = new Map<string, Doc<T>>();
    private processingState = new Map<string, ProcessingState>();
    private graph = new Graph();
    private processedDocs = new Subject<Doc<T>[]>();
    private failedDocs = new Subject<FailedDoc<T>>();
    private processCallback: (docs: Doc<T>[]) => void;

    constructor(processCallback: (docs: Doc<T>[]) => void) {
        this.processCallback = processCallback;
    }

    getProcessedDocs(): Observable<Doc<T>[]> {
        return this.processedDocs.asObservable();
    }

    getFailedDocs(): Observable<FailedDoc<T>> {
        return this.failedDocs.asObservable();
    }

    addDoc(doc: Doc<T>): void {
        this.addDocInternal(doc);
        this.processAllPossibleDocs();
    }

    addDocBatch(docs: Doc<T>[]): void {
        for (const doc of docs) {
            this.addDocInternal(doc);
        }
        this.processAllPossibleDocs();
    }

    private addDocInternal(doc: Doc<T>): void {
        if (this.docs.has(doc.id)) {
            throw new Error(`Document with id "${doc.id}" already exists`);
        }

        this.docs.set(doc.id, doc);
        this.processingState.set(doc.id, ProcessingState.WAITING);
        this.graph.addNode(doc.id);

        if (doc.dependencies.length === 0) {
            // For documents with no dependencies, add a self-loop
            // This ensures the node appears in the graph traversal
            this.graph.addEdge(doc.id, doc.id);
        } else {
            for (const depId of doc.dependencies) {
                this.graph.addEdge(doc.id, depId);
            }
        }
    }

    private processAllPossibleDocs(): void {
        const scc = tarjanSCC(this.graph);
        //console.log("SCC:", scc);
        const sortedComponents = topologicalSort(this.graph, scc);
        //console.log("SCC sorted:", sortedComponents);

        const processedNodes = new Set<string>();

        for (const component of sortedComponents) {
            const unprocessedNodes = component.filter((node) => !processedNodes.has(node));
            if (unprocessedNodes.length > 0 && this.canProcessComponent(unprocessedNodes)) {
                //console.log(`Processing component: ${unprocessedNodes}`);
                this.processComponent(unprocessedNodes);
                unprocessedNodes.forEach((node) => processedNodes.add(node));
            } else {
                //console.log(`Cannot process component: ${unprocessedNodes}`);
                // Instead of breaking, we'll continue to the next component
                continue;
            }
        }
    }

    private canProcessComponent(component: string[]): boolean {
        const processedDocs = new Set<string>();
        for (const docId of component) {
            if (this.processingState.get(docId) === ProcessingState.FAILED) {
                return false;
            }
            if (!this.canProcessDoc(docId, component, processedDocs)) {
                return false;
            }
        }
        return true;
    }

    private canProcessDoc(docId: string, component: string[], processedDocs: Set<string>, visitedDocs = new Set<string>()): boolean {
        if (visitedDocs.has(docId)) {
            return true; // We've encountered a cycle, but it's contained within the component
        }
        visitedDocs.add(docId);

        const doc = this.docs.get(docId);
        if (!doc) {
            return false; // If a document is not found, we can't process this component
        }
        for (const depId of doc.dependencies) {
            if (!component.includes(depId) && this.processingState.get(depId) !== ProcessingState.COMPLETED) {
                return false;
            }
            if (this.processingState.get(depId) === ProcessingState.FAILED) {
                return false;
            }
            if (component.includes(depId) && !processedDocs.has(depId)) {
                if (!this.canProcessDoc(depId, component, processedDocs, visitedDocs)) {
                    return false;
                }
            }
        }
        processedDocs.add(docId);
        return true;
    }

    private processComponent(component: string[]): void {
        const docsToProcess = component.map((id) => this.docs.get(id)!).filter((doc) => this.processingState.get(doc.id) === ProcessingState.WAITING);

        if (docsToProcess.length === 0) {
            return;
        }

        try {
            this.processCallback(docsToProcess);
            this.processedDocs.next(docsToProcess);
            for (const doc of docsToProcess) {
                this.processingState.set(doc.id, ProcessingState.COMPLETED);
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            this.markComponentAsFailed(component, errorMessage);
        }
    }

    private markComponentAsFailed(component: string[], errorMessage: string): void {
        const failedDocs = new Set<string>();
        const queue = [...component];

        while (queue.length > 0) {
            const docId = queue.shift()!;
            if (failedDocs.has(docId)) continue;

            failedDocs.add(docId);
            const doc = this.docs.get(docId)!;
            this.processingState.set(docId, ProcessingState.FAILED);
            this.failedDocs.next({ doc, reason: `Processing error: ${errorMessage}` });

            // Add dependents to the queue
            for (const [id, dependencies] of this.docs.entries()) {
                if (dependencies.dependencies.includes(docId) && !failedDocs.has(id)) {
                    queue.push(id);
                }
            }
        }
    }

    getUnprocessedDocs(): string[] {
        return Array.from(this.processingState.entries())
            .filter(([_, state]) => state === ProcessingState.WAITING)
            .map(([id, _]) => id);
    }

    getUnprocessableDocs(): string[] {
        return Array.from(this.processingState.entries())
            .filter(([_, state]) => state === ProcessingState.FAILED)
            .map(([id, _]) => id);
    }
}
