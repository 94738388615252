import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils/state';
import { ProjectImpl } from '../model';

export class ComponentDeleteCommand implements Command {
    readonly type = 'ComponentDeleteCommand';
    constructor(readonly componentId: string) {}
}

export class ComponentDeleteCommandHandler implements CommandHandler<ComponentDeleteCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'ComponentDeleteCommand';
    }

    execute(cmd: ComponentDeleteCommand) {
        return this.update((p) => {
            p = p.withoutComponent(cmd.componentId);
            return p;
        });
    }
}
