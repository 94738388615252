import { Box, Stack, Typography } from "@mui/material"
import { CallableApiItem } from "./types"
import { PropertyValue } from "@grenton/gm-common/src/model/objects/api"
import { MethodParamsForm } from "@grenton/gm/ui/components/methodParamsForm"
import { grentonColors } from "@grenton/design-system"

type Props = {
    item: CallableApiItem,
    values: Record<string, PropertyValue>,
    disabled: boolean,
    onValueChange: (id: string, value: PropertyValue) => void
}

export function CallableParamsForm({ item, values, onValueChange, disabled }: Props) {
    return <Stack p={2} spacing={2} sx={{ borderLeft: 'solid', borderColor: grentonColors.Line_Menu, flexGrow:1, alignItems:'start', overflow:'auto' }} 
        direction="column">

        <MethodParamsForm 
                    disabled={disabled}
                    params={item.params||[]} 
                    values={values} 
                    onValueChange={onValueChange}
                    />

        {!Boolean(item.params?.length) ? 
            <Box  sx={{flexGrow:1, width:'100%', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography variant="l" color="GrayText">method has no parameters</Typography></Box> : null}

    </Stack>
}