import { ProjectImpl, ObjectScriptRef, ScriptWithContext, traverseBlockGraph, BlockContextImpl } from '@grenton/gm-logic/src';
import { Workspace, Events } from 'blockly';
import log from 'loglevel';

export function updateBlocksToReflectProjectState(workspace: Workspace, project: ProjectImpl, scriptContextRef: ObjectScriptRef) {
    const scriptWithContext = ScriptWithContext.from(project, scriptContextRef);
    if (scriptWithContext) {
        log.info('update block context');
        Events.disable();
        try {
            traverseBlockGraph(workspace, new BlockContextImpl(project, scriptWithContext, {}));
        } finally {
            Events.enable();
        }
    }
}
