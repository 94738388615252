import { fabric } from '@grenton/gm-common';
import { Subject } from 'rxjs';

class LocalFabricPublisher implements fabric.FabricPublisher, fabric.FabricObservable {
    readonly retainedConfig: Map<string, fabric.ObjectConfigChangeMessage | fabric.ObjectConfigRemovedMessage> = new Map();
    readonly retainedState: Map<string, fabric.ObjectStateMessage> = new Map();
    readonly retainedLogic: Map<string, fabric.ObjectLogicMessage> = new Map();
    readonly retainedApi: Map<string, fabric.ObjectApiMessage> = new Map();

    readonly allMessages = new Subject<fabric.FabricObjectMessage>();

    subscribe(callback: (msg: fabric.FabricObjectMessage) => void): () => void {
        this.retainedConfig.forEach(callback);
        this.retainedLogic.forEach(callback);
        this.retainedState.forEach(callback);
        this.retainedApi.forEach(callback);
        const sub = this.allMessages.subscribe(callback);
        return () => sub.unsubscribe();
    }

    /**
     * we need to guarantee that some messages are retained and delivered to new subscribers
     */
    publish(msg: fabric.FabricObjectMessage): void {
        // retained
        switch (msg.type) {
            case 'config-change':
            case 'config-removed': {
                this.retainedConfig.set(msg.data.uuid, msg);
                break;
            }
            case 'logic': {
                this.retainedLogic.set(msg.data.uuid, msg);
                break;
            }
            case 'state': {
                this.retainedState.set(msg.data.uuid, msg);
                break;
            }
            case 'api': {
                this.retainedApi.set(msg.data.id, msg);
                break;
            }
        }
        this.allMessages.next(msg);
    }
}

// in case of local broker, publisher and observer are the same item
export class LocalFabricBroker {
    readonly publisher = new LocalFabricPublisher();
    readonly observable = this.publisher;
}
