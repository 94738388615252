import { type ProtocolID, type Outlet } from '../objects/api';

export type ObjectSelectorType = 'name' | 'tag' | 'type';

export type ObjectSelector = {
    // all devices with specified id
    ids?: string[];
    // plus all devices that matches tags AND types (TBD do we need OR)
    // for tags we need a special value to describe tag relative to controller, e.g. <ctrl:*>
    tags?: string[];
    types?: string[];
    exclude?: string[];
};

export type OutletConfig = {
    id: string;
    // static outlet references that reflects unbreakable links between related objects
    // e.g. parent objects will have all children listed here and children will hold a "parent" reference
    // anonymous controllers will hold an "object" reference
    refs:
        | {
              type: 'static';
              value: { ids: string[] };
          }
        | {
              type: 'dynamic';
              value: ObjectSelector;
          };
};

/*
 * outlet in anonymous controller referencing controlled object
 */
export const OUTLET_OBJECT = 'object';

/*
 * outlet in child object to hold a reverse relation to its parent
 */
export const OUTLET_PARENT = 'parent';

export function isParentOrObject(id: string): boolean {
    return id === OUTLET_OBJECT || id === OUTLET_PARENT;
}

export function createOwnerOutletInAnonymousController(selfId: string, apiRef: ProtocolID): { outlet: Outlet; instance: OutletConfig } {
    return {
        outlet: {
            id: OUTLET_OBJECT,
            readOnly: true,
            maxItems: 1,
            protocol: { type: 'ref', ref: apiRef },
        },
        instance: {
            id: OUTLET_OBJECT,
            refs: { type: 'static', value: { ids: [selfId] } },
        },
    };
}
