import { StateProvider } from '@grenton/gm-logic';
import { debounce, filter, interval } from 'rxjs';
import { ProjectImpl, ProjectRepository } from '@grenton/gm-logic';
import log from 'loglevel';
import { notEmpty } from '@grenton/gm-common';
import { UserHolder } from '@grenton/gm/auth/userholder';

export class ProjectAutoSave {
    constructor(
        private projectProvider: StateProvider<ProjectImpl | null>,
        private userHolder: UserHolder,
        private store: ProjectRepository,
    ) {}

    async init() {
        let last: ProjectImpl | undefined;
        this.projectProvider
            .pipe(
                filter((p) => p !== last),
                filter(notEmpty),
                debounce(() => interval(1000)),
            )
            .subscribe(async (p) => {
                const user = this.userHolder.user.value;
                if (user) {
                    log.info('project auto save');
                    last = p;
                    await this.store.store(user.sub, user.email, p.export());
                } else {
                    log.warn('project auto save skip (no user)');
                }
            });
    }
}
