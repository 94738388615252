import { BehaviorSubject, Observable } from 'rxjs';

export type StateProvider<T> = Observable<T> & { value: T };
export type StateUpdater<T> = (fn: (value: T) => T | Promise<T>) => Promise<void>;
export type StateSetter<T> = (value: T) => void;

export type StateStore<T> = {
    provider: StateProvider<T>;
    set: StateSetter<T>;
    updater: StateUpdater<T>;
};

export function State<T>(initial: T): StateStore<T> {
    const subject = new BehaviorSubject<T>(initial);

    const updater: StateUpdater<T> = async (fn: (value: T) => T | Promise<T>) => {
        const newState = fn(subject.value);
        if (newState instanceof Promise) {
            const value = await newState;
            if (value !== subject.value) {
                subject.next(value);
            }
        } else if (newState !== subject.value) {
            subject.next(newState);
        }
    };

    const set: StateSetter<T> = (value: T) => {
        subject.next(value);
    };

    return {
        provider: subject,
        set,
        updater,
    };
}
