import { RepoClient } from '@grenton/gm-common/src';
import { ProjectFirmwareImpl } from './model';

export type FirmwareProvider = (version?: string) => ProjectFirmwareImpl | null;

export function firmwareProviderFromRepo(repoClient: RepoClient) {
    return function (version?: string) {
        const v = version || repoClient.firmwares.value[0]?.version;
        if (v && repoClient.firmwares.value.find((fw) => fw.version === v)) {
            return ProjectFirmwareImpl.create(repoClient, v);
        } else {
            return null;
        }
    };
}
