import { ObjectApi } from '@grenton/gm-common';
import { GIconName, icons } from '../icons';

export function protocolIconResolver(api: ObjectApi): GIconName | null {
    const iconName = api?.meta?.icon as GIconName;
    const isIconDefined = Boolean(icons[iconName]);
    if (isIconDefined) {
        return iconName;
    } else {
        for (const ext of api.extending || []) {
            const iconName = protocolIconResolver(ext);
            if (iconName) return iconName;
        }
    }
    return 'unknown';
}
