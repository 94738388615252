import { Components, Theme } from '@mui/material';
import { sizeRem, sizes } from '../size';

export const MuiInputBase: Components<Omit<Theme, 'components'>>['MuiInputBase'] = {
    styleOverrides: {
        root: ({}) => ({
            fontWeight: 600,
            fontSize: sizeRem(sizes.M),
        }),
    },
};
