import { isApiExtending, schema } from '@grenton/gm-common';
import { SystemObject } from '@grenton/gm-logic';
import { SystemModel } from '@grenton/gm-logic';
import { AppWidget } from '@grenton/gm-logic';

export namespace WidgetBasicObject {
    export interface Config {
        uuid: string;
    }

    export interface Props<C extends Config> {
        widget: AppWidget<C>;
        object: SystemObject;
    }

    export type BasicProps = Props<Config>;

    export function factory(type: string, matcher: { api?: string; device?: string }) {
        return (object: SystemObject) =>
            (!matcher.api || isApiExtending(object.api, matcher.api)) && (!matcher.device || object.config.device === matcher.device)
                ? [
                      {
                          id: `${object.uuid}-${type}`,
                          type,
                          title: object.config.name,
                          config: {
                              uuid: object.uuid,
                          },
                          descriptor: {
                              tags: object.config.tags,
                              device: object.config.device,
                              api: schema.isApiRef(object.config.api) ? object.config.api.ref : matcher.api, //if api is dynamic, use matcher api as more generic
                          },
                      },
                  ]
                : [];
    }

    export function renderer<C extends Config, P extends Props<C>>(component: (props: P) => JSX.Element) {
        return function (widget: AppWidget<C>, system: SystemModel<SystemObject>): [(props: P) => JSX.Element, P] {
            const object = system.getObject(widget.config.uuid)!;
            return [component, { widget, object } as any];
        };
    }
}
