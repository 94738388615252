import { Components, Theme } from '@mui/material';

export const MuiMenu: Components<Omit<Theme, 'components'>>['MuiMenu'] = {
    defaultProps: {
        elevation: 4,
        slotProps: {
            paper: { style: { transform: 'translateY(5px)' } },
        },
    },
};
