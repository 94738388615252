import { KeyMap, Lists, Maps, TagsCategory } from '@grenton/gm-common';

export class ProjectTagsCategoryValues {
    static from(tags: string[]) {
        return new ProjectTagsCategoryValues(Lists.reduce(tags, (tag) => [tag, true]));
    }

    constructor(readonly values: KeyMap) {}

    withTag(tag: string, include = true) {
        return new ProjectTagsCategoryValues({ ...this.values, [tag]: include });
    }

    get all(): string[] {
        return Object.entries(this.values)
            .filter((e) => e[1])
            .map((e) => e[0]);
    }

    has(tag: string) {
        return this.values[tag] === true;
    }
}

export class ProjectTagsCategory {
    constructor(
        readonly data: {
            name: string;
            color: string;
            multiple: boolean;
            values: ProjectTagsCategoryValues;
        },
    ) {}

    get name() {
        return this.data.name;
    }

    get color() {
        return this.data.color;
    }

    get multiple() {
        return this.data.multiple;
    }

    get values() {
        return this.data.values;
    }

    get tags() {
        return this.values.all;
    }

    withValues(values: ProjectTagsCategoryValues) {
        return new ProjectTagsCategory({ ...this.data, values });
    }

    /**
     * based on allowed tag values and whether category is single/multi selection,
     * computes a new selection from currently selected tags and a new one
     * @param selected
     * @param tagValue
     * @param tagSelected
     * @returns
     */
    calculateNewSelection(selected: KeyMap, tagValue: string, tagSelected: boolean) {
        const selection: KeyMap = {};
        let hasSelection = false;
        if (tagSelected && this.values.has(tagValue)) {
            selection[tagValue] = true;
            hasSelection = true;
        }
        Maps.reduceKeyMap(selected)
            .filter((tag) => this.values.has(tag))
            .forEach((tag) => {
                selection[tag] = this.multiple || !hasSelection;
                hasSelection = true;
            });
        return selection;
    }

    export(): TagsCategory {
        return {
            name: this.data.name,
            color: this.data.color,
            multiple: this.data.multiple,
            values: Maps.reduceKeyMap(this.data.values.values),
        };
    }
}
