import { HWConfigurationShim } from '@grenton/gm-common';
import { ProjectFirmwareImpl, HWConfigurationImpl } from '../project';
import { createCluImpl } from './createCluFromShim';

export function createConfigurationFromShim(shim: HWConfigurationShim, firmware: ProjectFirmwareImpl): HWConfigurationImpl {
    let merged = HWConfigurationImpl.empty();
    for (const cluShim of shim.clus) {
        //clu.modules.map(m=>this.hardwareLibrary.getModule(m))
        const cluImpl = createCluImpl(
            cluShim,
            (type) => firmware.getComponent(type),
            (api) => firmware.resolveApiRef(api),
            true,
            false,
        );
        merged = merged.withClu(cluImpl);
    }
    return merged;
}
