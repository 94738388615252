import { Command, CommandHandler, ProjectImpl, StateUpdater } from '@grenton/gm-logic';

export class UserDeleteCommand implements Command {
    readonly type = 'UserDeleteCommand';

    constructor(readonly id: string) {}
}

export class UserDeleteCommandHandler implements CommandHandler<UserDeleteCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd instanceof UserDeleteCommand;
    }

    execute(cmd: UserDeleteCommand) {
        return this.update((p) => p.withSecurity(p.security.withoutUser(cmd.id)));
    }
}
