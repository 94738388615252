import { Blocks, Block, FieldImage, FieldLabel } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { ProjectItemPath } from '@grenton/gm-common';
import { BlockContext, resolvePath } from '@grenton/gm-logic';
import { BlockDelegate, BlockWithDelegate, createDelegate, NOT_SELECTED, SELF, VALUE_BLOCK_STYLE } from './common';
import { lua_pathToCode } from '@grenton/gm-logic';

interface State {
    path?: ProjectItemPath;
}

export namespace ApiEntityWithApiBlock {
    export const Type = 'api-entity-with-api';
    export const OutputType = 'ENTITY_WITH_API';

    export const ENTITY_FIELD = 'ENTITY';
    const ICON_FIELD = 'ICON';

    export class Delegate implements BlockDelegate<State> {
        code?: string = SELF;
        displayCode?: string = SELF;

        path: ProjectItemPath | undefined = [SELF];

        constructor(private block: Block) {
            block
                .appendDummyInput()
                .appendField(new FieldImage('icons/object.svg', 16, 16), ICON_FIELD)
                //  .appendField(new FieldLabel("ctrl"))
                .appendField(new FieldLabel(this.displayCode), ENTITY_FIELD);

            block.setOutput(true, OutputType);
            block.setInputsInline(false);
            block.setStyle(VALUE_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        setPath(entityRef: ProjectItemPath) {
            this.path = entityRef;
        }

        loadState(state: State): void {
            this.path = state.path;
            // this is only for proper displaying in toolbox
            // it will get overridden by onUpdate
            this.block.setFieldValue(this.path?.join('.'), ENTITY_FIELD);
        }
        saveState(): State {
            return {
                path: this.path,
            };
        }

        onUpdate(context: BlockContext) {
            if (!this.path) return;
            const selfID = context.editedScript.entity.uuid;
            const resolvedPath = resolvePath(this.path, context.project.objectResolver, selfID);

            const tail = resolvedPath.tail;
            if (!tail) return;
            const head = resolvedPath.head!;

            if (head.type !== 'object' || (tail.type !== 'object' && tail.type !== 'outlet')) return;
            switch (tail.type) {
                case 'object':
                    this.block.setFieldValue('icons/object.svg', ICON_FIELD);
                    break;
                case 'outlet':
                    this.block.setFieldValue('icons/outlet.svg', ICON_FIELD);
                    break;
            }

            if (tail.entity) {
                context.setReturnedType({ type: tail.type, api: tail.entity.api.api });
            }

            this.code = lua_pathToCode(resolvedPath);
            this.displayCode = lua_pathToCode(resolvedPath, true);
            this.block.setFieldValue(this.displayCode, ENTITY_FIELD);
        }
    }
}

Blocks[ApiEntityWithApiBlock.Type] = createDelegate((block) => new ApiEntityWithApiBlock.Delegate(block));

luaGenerator.forBlock[ApiEntityWithApiBlock.Type] = (block) => {
    const _block = block as BlockWithDelegate<ApiEntityWithApiBlock.Delegate>;
    return [_block.delegate.code || NOT_SELECTED, Order.ATOMIC];
};
