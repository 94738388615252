export type KeyMap = Record<string, boolean>;

// @deprecated use Record<string, T> instead
export type IdMap<T> = Record<string, T>;

export const Lists = {
    remove<O>(list: O[] | undefined, item: O): O[] {
        if (!list || !list.includes(item)) {
            return list ?? [];
        } else {
            return list.filter((i) => i !== item);
        }
    },
    reduce<O, V>(objects: O[] | undefined, func: (obj: O) => [string, V]): Record<string, V> {
        return (objects ?? []).reduce((all, obj) => {
            const t = func(obj);
            return { ...all, [t[0]]: t[1] };
        }, {});
    },
    group<O, V>(objects: O[] | undefined, func: (obj: O) => [string, V]): Record<string, V[]> {
        return (objects ?? []).reduce<Record<string, V[]>>((all, obj) => {
            const t = func(obj);
            const group = all[t[0]] ?? [];
            return { ...all, [t[0]]: [...group, t[1]] };
        }, {});
    },

    areEqual<V>(a: V[], b: V[], compare?: (v1: V, v2: V) => boolean) {
        const cmp = compare ? compare : (v1: V, v2: V) => v1 === v2;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- I need this
        return a.length === b.length && a.every((element, index) => cmp(element, b[index]!));
    },

    undefinedIfEmpty<V>(array: V[]) {
        return array.length ? array : undefined;
    },

    replaceOrPush<V>(array: V[], predicate: (v: V) => boolean, replaceWith: V) {
        const index = array.findIndex(predicate);
        if (index > -1) {
            const n = [...array];
            n[index] = replaceWith;
            return n;
        }
        return [...array, replaceWith];
    },

    with<V>(list: V[], i: number, func: (v: V) => void) {
        if (list.length > i) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- I need this
            func(list[i]!);
        }
    },

    withFirst<V>(list: V[], func: (v: V) => void) {
        Lists.with(list, 0, func);
    },

    withLast<V>(list: V[], func: (v: V) => void) {
        Lists.with(list, list.length - 1, func);
    },

    addIfNotPresent<V>(list: V[] | undefined, item: V): V[] {
        if (!list || !list.includes(item)) {
            return [...(list ?? []), item];
        }
        return list;
    },

    toggle<V>(list: V[], value: V, add: boolean) {
        if (add) {
            return list.find((i) => i === value) ? list : [...list, value];
        } else {
            return list.filter((i) => i !== value);
        }
    },
};

export const Maps = {
    keys<V>(map: Record<string, V> | undefined): string[] {
        return map ? Object.keys(map) : [];
    },

    values<V>(map: Record<string, V> | undefined): V[] {
        return map ? Object.values(map) : ([] as V[]);
    },

    mapValues<V, T>(map: Record<string, V> | undefined, callbackfn: (value: V, index: number, array: V[]) => T): T[] {
        return Maps.values(map).map(callbackfn);
    },

    transform<T1, T2>(map: Record<string, T1> | undefined, func: (key: string, value: T1) => T2): Record<string, T2> {
        return Object.entries(map ?? {}).reduce((all, e) => ({ ...all, [e[0]]: func(e[0], e[1]) }), {});
    },

    transformEntries<T1, T2>(map: Record<string, T1> | undefined, func: (key: string, value: T1 | undefined) => [string, T2]) {
        return Object.entries(map ?? {}).reduce((all, e) => {
            const entry = func(e[0], e[1]);
            return { ...all, [entry[0]]: entry[1] };
        }, {});
    },

    filterEmpty<T1>(map: Record<string, T1 | undefined>): Record<string, T1> {
        return Maps.keys(map).reduce((all, key) => (map[key] !== undefined ? { ...all, [key]: map[key] } : all), {});
    },

    filter<T1>(map: Record<string, T1>, func: (key: string, value: T1 | undefined) => boolean): Record<string, T1> {
        const m = map;
        return Maps.keys(m).reduce((all, key) => (func(key, m[key]) ? { ...all, [key]: map[key] } : all), {});
    },

    asKeyMap(keys: string[] | undefined): KeyMap {
        return (keys ?? []).reduce((all, key) => ({ ...all, [key]: true }), {});
    },

    reduceKeyMap(map: KeyMap): string[] {
        return Object.entries(map)
            .filter((e) => e[1])
            .map((e) => e[0]);
    },

    areEqual<K, V>(a: Map<K, V>, b: Map<K, V>, compare?: (v1: V, v2: V) => boolean): boolean {
        const cmp = compare ? compare : (v1: V, v2: V) => v1 === v2;
        return (
            a.size === b.size &&
            Array.from(a.keys()).every((key) => {
                const v1 = a.get(key);
                const v2 = b.get(key);
                if (v1 === undefined && v2 === undefined) return true;
                if (v1 === undefined || v2 === undefined) return false;
                return cmp(v1, v2);
            })
        );
    },
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

class ConcatenatedIterableIterator<T> implements IterableIterator<T> {
    private isFirst = true;

    constructor(
        private first: IterableIterator<T> | undefined,
        private second: IterableIterator<T>,
    ) {}

    [Symbol.iterator](): IterableIterator<T> {
        return this;
    }

    next(): IteratorResult<T> {
        if (this.isFirst) {
            if (this.first) {
                const result = this.first.next();
                if (!result.done) {
                    return result;
                }
            }
            this.isFirst = false;
        }
        return this.second.next();
    }
}

export function concatenateIt<T>(first: IterableIterator<T> | undefined, second: IterableIterator<T>): IterableIterator<T> {
    return new ConcatenatedIterableIterator(first, second);
}

export function* filterIt<T>(iterator: IterableIterator<T>, predicate: (value: T) => boolean): IterableIterator<T> {
    for (const item of iterator) {
        if (predicate(item)) {
            yield item;
        }
    }
}

export function* mapIt<T, U>(iterator: IterableIterator<T>, transform: (value: T) => U): IterableIterator<U> {
    for (const item of iterator) {
        yield transform(item);
    }
}

export function findIt<T>(iterator: IterableIterator<T>, predicate: (value: T) => boolean): T | undefined {
    for (const item of iterator) {
        if (predicate(item)) {
            return item;
        }
    }
    return undefined;
}
