const G = '~';
const V1 = '1.0.0';
export const LedRGBW = `${G}/led-output/${V1}`;
export const DigitalOUT = `${G}/digital-output/${V1}`;
export const OnOff = `${G}/on-off/${V1}`;
export const DigitalIN = `${G}/digital-input/${V1}`;
export const Dimm = `${G}/dimmable-output/${V1}`;
export const SmartPanel = `${G}/object-smart-panel/${V1}`;
export const Scheduler = `${G}/scheduler/${V1}`;
export const RollerShutter = `${G}/object-roller-shutter/${V1}`;
