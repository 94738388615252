import { schema } from '@grenton/gm-common';

export class MethodImpl {
    static from(spec: schema.Method, inherited: boolean) {
        return new MethodImpl({ inherited, spec });
    }

    constructor(private data: { inherited: boolean; spec: schema.Method }) {}

    get inherited() {
        return this.data.inherited;
    }

    get id() {
        return this.data.spec.id;
    }

    get spec() {
        return this.data.spec;
    }

    withSpec(spec: schema.Method) {
        return new MethodImpl({ ...this.data, spec });
    }

    get name() {
        return this.data.spec.label || this.data.spec.id;
    }

    withScene(scene: boolean) {
        return this.withSpec({ ...this.spec, scene });
    }

    withName(label: string) {
        return this.withSpec({ ...this.spec, label });
    }

    withReturnType(type: schema.PropertyValueType) {
        return this.withSpec({ ...this.spec, result: { type } });
    }

    withParams(params: schema.MethodParam[]) {
        return this.withSpec({ ...this.spec, params });
    }
}
