import { Block, Blocks } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { BlockContext } from '@grenton/gm-logic';
import { BlockDelegate } from './common';

export namespace MethodReturn {
    export const Type = 'method_return';

    export interface Block {}

    export class Logic implements BlockDelegate<void> {
        onUpdate(_: BlockContext) {}
    }
}

const DEFAULT_VALUE_INPUT = 'DEFAULT_VALUE';

Blocks[MethodReturn.Type] = {
    init() {
        this.appendValueInput(DEFAULT_VALUE_INPUT).setCheck(null).appendField('return');
        this.setInputsInline(false);
        this.setPreviousStatement(true);
        this.setOutput(false, null);
        this.setColour(230);
        this.setTooltip('');
        this.setHelpUrl('');
    },
};

luaGenerator.forBlock[MethodReturn.Type] = (block: Block, generator) => {
    const defaultValue = generator.valueToCode(block, DEFAULT_VALUE_INPUT, Order.ATOMIC);
    return 'return ' + defaultValue;
};
