import { schema } from '@grenton/gm-common';

export class EventImpl {
    static from(spec: schema.Event, inherited: boolean) {
        return new EventImpl({ spec, inherited });
    }

    constructor(private data: { spec: schema.Event; inherited: boolean }) {}

    get inherited() {
        return this.data.inherited;
    }

    get id() {
        return this.data.spec.id;
    }

    get name() {
        return this.data.spec.label || this.data.spec.id;
    }

    get spec() {
        return this.data.spec;
    }

    withSpec(spec: schema.Event) {
        return new EventImpl({ ...this.data, spec });
    }

    withName(label: string) {
        return this.withSpec({ ...this.data.spec, label });
    }
}
