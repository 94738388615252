import { uuid } from '@grenton/gm-common';
import { StateUpdater } from '../utils/state';
import loglevel from 'loglevel';
import { NotificationState, NotificationReceiver, GNotification, NotificationWrapper } from './types';

// we might want to customize it per notification type, level etc
const DEFAULT_AUTO_HIDE_DURATION = 2000;

export class NotificationCentre {
    constructor(private update: StateUpdater<NotificationState>) {}

    get receiver(): NotificationReceiver {
        return this.notify.bind(this);
    }

    notify(notification: GNotification) {
        this.update((state) => {
            loglevel.info(`Notification`, notification);
            // Filter out notifications with the same collapseId if it's provided
            const filteredNotifications = notification.collapseId
                ? state.notifications.filter((n) => n.data.collapseId !== notification.collapseId)
                : state.notifications;

            const notifications: NotificationWrapper[] = [
                ...filteredNotifications,
                {
                    id: uuid.short(),
                    autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
                    data: notification,
                },
            ];

            return { notifications };
        });
    }

    clear() {
        this.update((_) => ({ notifications: [] }));
    }
}
