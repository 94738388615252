import { Block, Blocks, FieldLabel, MenuOption, inputs } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { BlockContext } from '@grenton/gm-logic';
import {
    BlockDelegate,
    createDelegate,
    emptyOption,
    FieldDropdownModel,
    getSortedApis,
    NOT_SELECTED,
    SELECTION_OUTPUT_TYPES,
    VALUE_BLOCK_STYLE,
} from './common';
import { FieldDropdownEx } from './field-dropdown-ex';

export namespace ObjectSelectionByApiBlock {
    export const Type = 'object-sel-by-api';

    export const INPUT_SELECTION = 'objects';
    export const API_FIELD = 'api';

    const EMPTY_OPTION = emptyOption('{api}');

    export class Delegate implements BlockDelegate {
        inputIsSelection = false;

        dropdownModel: FieldDropdownModel;

        constructor(private block: Block) {
            this.dropdownModel = new FieldDropdownModel(block, API_FIELD, [EMPTY_OPTION]);

            block.setOutput(true, SELECTION_OUTPUT_TYPES);

            block.appendValueInput(INPUT_SELECTION).setAlign(inputs.Align.RIGHT).appendField(new FieldLabel('filter objects'));

            block
                .appendDummyInput()
                .setAlign(inputs.Align.RIGHT)
                .appendField(new FieldLabel('by api'))
                .appendField(new FieldDropdownEx(this.dropdownModel.generator), API_FIELD);

            block.setInputsInline(false);
            block.setStyle(VALUE_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        onUpdate(context: BlockContext) {
            const selectedApiName = this.block.getFieldValue(API_FIELD);
            const all = getSortedApis(context);
            const api = all.find((a) => a.id === selectedApiName);

            // to make it happen, we need to return ObjectApiImpl + reference, not entire entity
            if (api) {
                context.setReturnedType({ type: 'selection', api });
            }

            const options: MenuOption[] = all.map((api) => [api.id, api.id]);
            options.unshift(EMPTY_OPTION);
            this.dropdownModel.setOptions(options, selectedApiName);
        }
    }
}

Blocks[ObjectSelectionByApiBlock.Type] = createDelegate((block) => new ObjectSelectionByApiBlock.Delegate(block));

luaGenerator.forBlock[ObjectSelectionByApiBlock.Type] = (block, generator) => {
    const input = generator.valueToCode(block, ObjectSelectionByApiBlock.INPUT_SELECTION, Order.NONE) || NOT_SELECTED;
    const api = block.getFieldValue(ObjectSelectionByApiBlock.API_FIELD) || NOT_SELECTED;
    const code = `${input.trim()}._withApi("${api}")`;
    return [code, Order.ATOMIC];
};
