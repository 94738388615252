import { IdMap, ObjectApiImpl, Maps, ObjectApi } from '@grenton/gm-common';
import { FeatureImpl } from './object-feature';
import { MethodImpl } from './object-method';
import { EventImpl } from './object-event';
import { OutletImpl } from './object-outlet';

function isNamed<T extends { id: string; label?: string }>(name: string) {
    return (item: T, _index: number, _values: T[]) => name === item.id || name === item.label;
}

/**
 * the reason for this wrapper is twofold
 * 1) we need some utility features around regular api interface (this is done via ***InstanceImpl wrappers)
 * 2) some objects have mutable API and this wrapper helps to orchestrate that
 *
 * TODO revisit and decide whether it stays or we merge it with ObjectApiImpl
 */
export class ProjectObjectApi {
    readonly features: IdMap<FeatureImpl>;
    readonly events: IdMap<EventImpl>;
    private _outlets: IdMap<OutletImpl> | undefined;
    readonly methods: IdMap<MethodImpl>;

    constructor(
        readonly api: ObjectApiImpl,
        readonly editable: boolean,
    ) {
        this.features = Maps.transform(this.api.flat.features, (_, f) => new FeatureImpl({ spec: f, inherited: !this.api.self.features[f.id] }));
        this.events = Maps.transform(this.api.flat.events, (_, f) => new EventImpl({ spec: f, inherited: !this.api.self.events[f.id] }));
        this.methods = Maps.transform(this.api.flat.methods, (_, f) => new MethodImpl({ spec: f, inherited: !this.api.self.methods[f.id] }));
    }

    get outlets() {
        // has to be evaluated in a lazy way otherwise this causes eternal loop for parent->child->parent outlets
        if (!this._outlets) {
            this._outlets = Maps.transform(this.api.flat.outlets, (_, o) => new OutletImpl({ spec: o, inherited: !this.api.self.outlets[o.id] }));
        }
        return this._outlets!;
    }

    get name() {
        return this.api.id;
    }

    getMethodByName(name: string) {
        return Object.values(this.api.flat.methods)
            .filter(isNamed(name))
            .map((m) => new MethodImpl({ spec: m, inherited: !this.api.self.methods[m.id] }))[0];
    }

    hasMethodNamed(name: string) {
        return Boolean(Object.values(this.api.flat.methods).find(isNamed(name)));
    }
    hasEventNamed(name: string) {
        return Boolean(Object.values(this.api.flat.events).find(isNamed(name)));
    }
    hasFeatureNamed(name: string) {
        return Boolean(Object.values(this.api.flat.features).find(isNamed(name)));
    }
    hasOutletNamed(name: string) {
        return Boolean(Object.values(this.api.flat.outlets).find(isNamed(name)));
    }
    hasItemNamed(name: string) {
        return Boolean(this.hasMethodNamed(name) || this.hasFeatureNamed(name) || this.hasOutletNamed(name) || this.hasEventNamed(name));
    }

    withFeature(feature: FeatureImpl) {
        return this.editable ? new ProjectObjectApi(this.api.withFeature(feature.spec), this.editable) : this;
    }

    withFeatures(features: FeatureImpl[]) {
        let p: ProjectObjectApi = this;
        features.forEach((f) => {
            p = p.withFeature(f);
        });
        return p;
    }

    withMethod(method: MethodImpl) {
        return this.editable ? new ProjectObjectApi(this.api.withMethod(method.spec), this.editable) : this;
    }

    withoutMethod(id: string) {
        return this.editable ? new ProjectObjectApi(this.api.withoutMethod(id), this.editable) : this;
    }

    withMethods(methods: MethodImpl[]) {
        let p: ProjectObjectApi = this;
        methods.forEach((f) => {
            p = p.withMethod(f);
        });
        return p;
    }

    withOutlet(outlet: OutletImpl) {
        return this.editable ? new ProjectObjectApi(this.api.withOutlet(outlet.spec), this.editable) : this;
    }

    withOutlets(outlets: OutletImpl[]) {
        let p: ProjectObjectApi = this;
        outlets.forEach((f) => {
            p = p.withOutlet(f);
        });
        return p;
    }

    withoutOutlet(id: string): ProjectObjectApi {
        return this.editable ? new ProjectObjectApi(this.api.withoutOutlet(id), this.editable) : this;
    }

    withEvent(event: EventImpl) {
        return this.editable ? new ProjectObjectApi(this.api.withEvent(event.spec), this.editable) : this;
    }

    withEvents(events: EventImpl[]) {
        let p: ProjectObjectApi = this;
        events.forEach((f) => {
            p = p.withEvent(f);
        });
        return p;
    }

    withoutAllItems(): ProjectObjectApi {
        return this.editable ? new ProjectObjectApi(this.api.withoutAllItems(), this.editable) : this;
    }

    withExtending(api: ObjectApi) {
        return this.editable ? new ProjectObjectApi(this.api.withExtending(api), this.editable) : this;
    }
}
