import { TabsProps, styled } from "@mui/material";
import { GTabs } from "./GTabs";
import { grentonColors } from "../colors";

//@ts-ignore
export const GOutlinedTabs = styled(GTabs)<TabsProps>(({ theme }) => ({

    "& .MuiTabs-flexContainer": { 
        gap: theme.spacing(0.5),
        paddingTop:8
    },
    "& .MuiTabs-flexContainerVertical": {
        alignItems: 'end',
        paddingLeft: theme.spacing(1),
        "& .MuiTab-root": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0,
            minWidth: 0,
            maxWidth: '100%',
            width: '100%',
        },
        "& .MuiTab-g-label" : {
            textAlign: 'left'
        }
    },
    "& .MuiTab-root": { 

        background:grentonColors.grey[300],
        // outline
        borderColor:grentonColors.grey[300],
        borderWidth:1,
        borderStyle:'solid',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        // here horizontal padding is ok because we have no indicator
        //padding: '0 1em',
        

        "&.Mui-selected": {
            background:grentonColors.white[100]
        },

        "& .MuiTab-g-label-root" : {
          
            width:'100%',
            flexDirection:'row',
            alignItems:'center',
            padding:'0 1em',  //horizontal padding makes indicator to be wider than the label. 
            "&.MuiTab-g-label-root-withClose" : {
                paddingRight:0,
            },       
            "& .MuiIconButton-root" : {
            },
            "& .MuiTab-g-label" : {

            },
        },
        "& .MuiBadge-badge.MuiBadge-anchorOriginTopRight" : {
            top: 0,
            right: 5,
            "&.MuiBadge-dot" : {
                top:6,
                right:6
            }
        }
    },
    "& .MuiTabs-indicator" : { 
        display: 'none'
    }
}));