import { ProjectItemPath, ScriptFormat } from '@grenton/gm-common';
import log from 'loglevel';
import { BehaviorSubject } from 'rxjs';
import { ProjectImpl, ProjectObjectImpl, resolvePath, ScriptImpl, StateProvider } from '@grenton/gm-logic';
import { ActionModeController } from '../actions/action-editor-controller';
import { CodeModeController } from '../code/code-editor-controller';
import { ScriptWithContext, sameObjectScriptRef } from '@grenton/gm-logic';
import { ObjectScriptRef } from '@grenton/gm-logic';
import { VisualModeController } from '../visual/visual-editor-controller';

export class ScriptEditorController {
    readonly editMode = new BehaviorSubject<{ viewed: ScriptFormat; edited: ScriptFormat }>({
        viewed: 'visual',
        edited: 'visual',
    });
    private _scriptContextRef = new BehaviorSubject<ObjectScriptRef | null>(null);
    readonly scriptContextRef = this._scriptContextRef.asObservable();

    readonly actions: ActionModeController; //ModeController<ActionScriptImpl>;
    readonly visual: VisualModeController; //ModeController<Record<string,any>>;
    readonly code: CodeModeController; // ModeController<string>;

    constructor(
        private project: StateProvider<ProjectImpl | null>,
        private onUpdate: (entity: ProjectObjectImpl) => void,
    ) {
        // each of three editors triggers "onSave" event with an editor content
        // onSave event besides updating object with a new script content also triggers cascade updates
        // to low-level editors.
        // it is done in an inefficient way - object is updated multiple times.

        const updateScript = (format: ScriptFormat, fn: (script: ScriptImpl) => ScriptImpl) => {
            // update project ONLY when this format is edited, otherwise derived (always changing) content will cause infinite loop,
            if (format !== this.editMode.value.edited) return;
            if (!this._scriptContextRef.value || !project.value) return;
            const object = project.value.getObjectById(this._scriptContextRef.value.objectId);
            if (object) {
                onUpdate(object.withScripts(object.scripts.withScriptModifier(this._scriptContextRef.value.scriptRef.path, fn)));
                this.updateDerivedModes();
            }
        };

        this.actions = new ActionModeController(project, (content) => updateScript('actions', (script) => script.withActions(content)));
        this.visual = new VisualModeController(project, (content) => updateScript('visual', (script) => script.withVisual(content)));
        this.code = new CodeModeController((content) => updateScript('lua', (script) => script.withCode(content)));
    }

    private updateDerivedModes() {
        // because updating derived modes, especially when action is edited and we need to update both blockly/lua
        // can be costly, we can delay it until user previews them.
        // if user never previews derived modes, they will be out-of-sync and corrected only prior to exporting project
        // which is fine.
        const content = this.generateDerivedContent(this.editMode.value.edited, this.editMode.value.viewed);
        if (content?.visual !== undefined) {
            this.visual.setContent(content.visual);
        }
        if (content?.lua !== undefined) {
            this.code.setContent(content.lua);
        }
    }

    private generateDerivedContent(source: ScriptFormat, target: ScriptFormat): undefined | { visual?: Record<string, any> | null; lua?: string | null } {
        // because updating derived modes, especially when action is edited and we need to update both blockly/lua
        // can be costly, we can delay it until user previews them.
        // if user never previews derived modes, they will be out-of-sync and corrected only prior to exporting project
        // which is fine.

        if (source === target) return;
        if (source === 'actions') {
            return this.actions.generateDerived();
        }
        if (source === 'visual') {
            return { lua: this.visual.generateDerived() };
        }
    }

    onPreviewMode(viewed: ScriptFormat) {
        this.editMode.next({ ...this.editMode.value, viewed });
        this.updateDerivedModes();
    }

    onSwitchMode(newFormat: ScriptFormat) {
        if (!this._scriptContextRef.value || !this.project.value) return;
        const object = this.project.value.getObjectById(this._scriptContextRef.value.objectId);
        if (!object) return;
        if (this.editMode.value.edited === newFormat) return;
        const content = this.generateDerivedContent(this.editMode.value.edited, newFormat);
        const scripts = object.scripts.withScriptModifier(this._scriptContextRef.value.scriptRef.path, (script) => {
            script = script.withEditMode(newFormat);
            if (newFormat === 'lua')
                script = script
                    .withActions(null)
                    .withVisual(null)
                    .withCode(content?.lua || null);
            if (newFormat === 'visual')
                script = script
                    .withActions(null)
                    .withVisual(content?.visual ? JSON.stringify(content.visual) : null)
                    .withCode(content?.lua || null);
            return script;
        });

        this.onUpdate(object.withScripts(scripts));
        this.editMode.next({ ...this.editMode.value, edited: newFormat });
    }

    setEditedScript(scriptContextRef: ObjectScriptRef | null) {
        if (sameObjectScriptRef(this._scriptContextRef.value, scriptContextRef) || !this.project.value) {
            log.debug('blockly - the same script, ignore edit event');
            return;
        } else {
            this._scriptContextRef.next(scriptContextRef);
        }
        if (!this._scriptContextRef) {
            return;
        }

        const scriptContext = scriptContextRef ? ScriptWithContext.from(this.project.value, scriptContextRef) : null;
        if (!scriptContext) return;
        const script = scriptContext.getScript();
        if (!script) throw new Error('script not defined for path ' + this._scriptContextRef.value?.scriptRef);
        this.editMode.next({
            viewed: script.format,
            edited: script.format,
        });

        this.actions.onScriptChange(scriptContext);
        this.visual.onScriptChange(scriptContext);
        this.code.onScriptChange(scriptContext);
    }

    private getEditModeController() {
        switch (this.editMode.value.edited) {
            case 'visual':
                return this.visual;
            case 'lua':
                return this.code;
            case 'actions':
                return this.actions;
        }
    }

    addScriptlet(path: ProjectItemPath, opts?: { set?: boolean }) {
        if (this.project.value && this._scriptContextRef.value) {
            const relativePath = resolvePath(path, this.project.value.objectResolver, this._scriptContextRef.value.objectId);
            this.getEditModeController()?.addScriptlet(relativePath, opts);
        }
    }
}
