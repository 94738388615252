import { SystemModel, SystemObject, sortTags, AppLayout, AppPage, AppWidget } from "@grenton/gm-logic"
import { LOC_PREFIX, prot, IdMap} from "@grenton/gm-common"

export type AppWidgetFactory = (object:SystemObject) => AppWidget<any>[]


const defaultSections:{[section:string]:{order:number, types:Array<{api:string}|string>}} = {
    "lights"  : {order:1, types:["lamp",{api:prot.Dimm},{api:prot.LedRGBW}, {api:"#light-controller"}]},
    "windows" : {order:2, types:[{api:prot.RollerShutter},"window","window sensor"]},
    "doors & gates" : {order:3, types:["door","gate","door sensor","gate sensor"]},
    "controls" : {order:4, types:["switch", "valve", {api:prot.SmartPanel}]},
    "sensors" : {order:5, types:["light sensor", "humidity sensor","CO2 sensor", "air sensor", "wind sensor"]},
}

class LayoutGenerator {

    private factories: AppWidgetFactory[] = []

    private deviceToSection:{[device:string]:string} = {}
    private apiToSection:{[device:string]:string} = {}

    constructor() {
        Object.entries(defaultSections).forEach(s=>{
            s[1].types.forEach(d=>{
                if (typeof d === "string") {
                    this.deviceToSection[d] = s[0]
                } else {
                    this.apiToSection[d.api] = s[0]
                }
            })
        })
    }

    register(factory:AppWidgetFactory) {
        this.factories.push(factory)
    }

    layout(system:SystemModel<SystemObject>) : AppLayout {

        const all : {[t:string]:boolean} = {}
        system.objects.forEach(object=>{
            object.config.tags.forEach(tag=>{
                all[tag] = true
            })
        })

        const tags = [...Object.keys(all),""].filter(tag=>tag.startsWith(LOC_PREFIX)).sort(sortTags)

        const pages:AppPage[] = []
        const allWidgets: IdMap<AppWidget<any>> = {}
        tags.forEach((tag,_)=>{
                const objectsOnPage = system.objects.filter(mod=>(tag ? mod.config.tags?.indexOf(tag) !== -1 : !mod.config.tags?.length))
                const widgetOnPage = objectsOnPage.map((obj)=>this.getAppWidgets(obj)).flat()
                widgetOnPage.forEach(w=>allWidgets[w.id] = w)
                const widgetsByDevice:{[device:string]:AppWidget<any>[]} = {}

                widgetOnPage.forEach(w=>{

                    let section
                    if (w.descriptor.scene) {
                        section = 'scenes'
                    }

                    if (!section && w.descriptor.device) {
                        section = this.deviceToSection[w.descriptor.device]
                    }
                    if (!section && w.descriptor.api) {
                        section = this.apiToSection[w.descriptor.api]
                    }
                    if (!section) {
                        section = 'misc'
                    }


                    widgetsByDevice[section] = [...(widgetsByDevice[section] || []), w]
                })

                function sortSections(s1:[string,any],s2:[string,any]) {
                    const i1 = defaultSections[s1[0]]?.order || 1000
                    const i2 = defaultSections[s2[0]]?.order || 1000
                    return i1-i2
                }

                const page:AppPage = {
                    id: `auto-${tag}`,
                    title: tag.substring(LOC_PREFIX.length).replace("."," - "),
                    auto: {
                        selector: {
                            tag
                        }
                    },
                    sections: Object.entries(widgetsByDevice)
                        .sort(sortSections)
                        .map(s=>({
                        id:`auto-${tag}-${s[0]||'nodevice'}`,
                        title:s[0],
                        widgets:s[1].map(w=>w.id)
                    }))
                }
                if (page.sections.length) {
                    pages.push(page) // add only pages with widgets (fixme)
                }
            })

        return {
            id: 'auto',
            name: 'auto',
            widgets: Object.values(allWidgets),
            pages
        }
    }

    private getAppWidgets(obj:SystemObject) : AppWidget<any>[] {
        for (const factory of this.factories) {
            const w = factory(obj)
            if (w.length) return w
        }
        return []
    }

}

export const layoutGenerator = new LayoutGenerator()


