import { alpha } from '@mui/material';

export const grentonColors = {
    grey: {
        400: '#dadada',
        300: '#e5e5e5',
        200: '#f0f0f0',
        100: '#fafafa',
    },
    black: {
        '100%': '#222222',
        '90%': convertRGBAtoRGB('#222222E5'),
        '50%': convertRGBAtoRGB('#22222280'),
        '5%': convertRGBAtoRGB('#2222220D'),
    },
    white: {
        100: '#fff',
    },
    accent: {
        '100%': '#ED2F48',
        hover: '#D52A41', //grenton-red
        '30%': convertRGBAtoRGB('#ED2F484D'),
        '20%': convertRGBAtoRGB('#ED2F4833'),
        '10%': convertRGBAtoRGB('#ED2F481A'),
        '5%': convertRGBAtoRGB('#ED2F480D'),
    },
    kris_blackish: convertRGBAtoRGB('#222222E5'),
    kris_blackish2: '#333344', //purple hint
    backgrounds_menu: '#fafafa',
    backgrounds_workspace: '#f0f0f0',
    backgrounds_treepanes: '#fafafa',
    Line_ButtonQuaternary: alpha('#222222', 0.15),
    Font_Label: alpha('#222222', 0.7),
    Font_White: '#ffffff',
    Font_Black: '#222222',
    Font_Placeholder: alpha('#222222', 0.4),
    Font_40: alpha('#222222', 0.4),
    Line_Menu: '#e5e5e5',
    Line_Input: '#dadada',
    Buttons_GreyOrHover: alpha('#222222', 0.05),
    LabelGreyBackground: alpha('#222222', 0.05),
    Error: '#ff0000',
    Text_Disabled: alpha('#000000', 0.38),
};

// some colors from extrahut contains alpha channel, we want to get rid of that
function convertRGBAtoRGB(hex: string): string {
    // Validate hex format
    if (!/^#([A-Fa-f0-9]{8}|[A-Fa-f0-9]{4})$/.test(hex)) {
        throw new Error('Invalid hex color format. Use #RRGGBBAA or #RGBA.');
    }

    let r: number, g: number, b: number, a: number;

    if (hex.length === 9) {
        // Format #RRGGBBAA
        r = parseInt(hex.slice(1, 3), 16);
        g = parseInt(hex.slice(3, 5), 16);
        b = parseInt(hex.slice(5, 7), 16);
        a = parseInt(hex.slice(7, 9), 16) / 255;
    } else if (hex.length === 5) {
        // Format #RGBA
        r = parseInt(hex[1]! + hex[1], 16);
        g = parseInt(hex[2]! + hex[2], 16);
        b = parseInt(hex[3]! + hex[3], 16);
        a = parseInt(hex[4]! + hex[4], 16) / 255;
    } else {
        throw new Error('Invalid hex color format. Use #RRGGBBAA or #RGBA.');
    }

    // Blend each color component with white
    r = Math.round(a * r + (1 - a) * 255);
    g = Math.round(a * g + (1 - a) * 255);
    b = Math.round(a * b + (1 - a) * 255);

    // Convert to hex and return RGB (no alpha)
    const toHex = (x: number) => x.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
