import { ProjectImpl, StateStore } from '@grenton/gm-logic';
import { BehaviorSubject } from 'rxjs';

export class ProjectHolder {
    constructor(private _state: StateStore<ProjectImpl | null>) {}

    public get project() {
        // we rely on behavior in many places...
        return this._state.provider as BehaviorSubject<ProjectImpl | null>;
    }

    public get currentProject() {
        return this._state.provider.value;
    }

    public set(project: ProjectImpl) {
        this._state.set(project);
    }

    public update(fn: (project: ProjectImpl) => ProjectImpl) {
        this._state.updater((old) => {
            if (!old) throw new Error('project not loaded');
            return fn(old);
        });
    }
}
