import { BrightnessHigh, BrightnessLow, BrightnessMedium, KeyboardArrowDown, KeyboardArrowUp, Tune } from "@mui/icons-material"
import { ButtonBase, Box, Stack, Typography, Button, IconButton } from "@mui/material"
import { useRef, useState } from "react"
import { AppWidgetBox } from "./WidgetBox"
import useLongPress from "../../ui/hooks/useLongPress"
import { WidgetBasicObject } from "./basic"

export namespace WidgetLightController {

    export const Type = "WidgetLightController"

    export const Factory = WidgetBasicObject.factory(Type,{api:"#light-controller"})

    export const Renderer = WidgetBasicObject.renderer(Widget)

    function Widget(props: WidgetBasicObject.BasicProps) {
        const [value, setValue] = useState(0)
        const timeout = useRef<{timer:any, value:number, dir:1|-1}>()
    
        function icon() {
            switch (value) {
                case 0 : return <BrightnessLow color="disabled"/>
                case 1 : return <BrightnessHigh color="primary"/>
                default:
                    return <BrightnessMedium color="primary"/>
            }
        }
    
        function label() {
            switch (value) {
                case 0 : return "Off"
                default:
                    return `${Math.round(value*100)}%`
            }
        }
    
        function clear() {
            clearInterval(timeout.current?.timer)
        }
    
        const holdValue = () => {
            if (timeout.current) {
                timeout.current.value = Math.min(1, Math.max(0,(timeout.current.value||0) + (timeout.current.dir*0.1)))
                setValue(timeout.current.value)
            }
        }
    
        const holdUp = useLongPress(()=>{
            timeout.current = {
                value,
                dir : 1,
                timer: setInterval(()=>holdValue(), 500)
            }
        },
        ()=>{
            clear()
        },
        ()=>{
            clear()
            setValue(Math.min(1, value+0.1))
        }, {delay:500})
    
        const holdDown = useLongPress(()=>{
            timeout.current = {
                value,
                dir : -1,
                timer: setInterval(()=>holdValue(), 500)
            }
        },
        ()=>{
            clear()
        },
        ()=>{
            clear()
            setValue(Math.max(0, value-0.1))
        }, {delay:500})
    
        return <AppWidgetBox padding={0}>
            <ButtonBase sx={{flexGrow:2,pt:1,pb:1,pr:2,pl:2}} onClick={()=>{setValue(value > 0 ? 0 : 1)}}>
            <Box pr={2}>{icon()}</Box>
                <Stack alignItems="start" direction="column" flexGrow={2} alignContent={"center"}>
                <Typography variant="body1" flexGrow={2}>{props.object.config.name}</Typography>
                <Typography variant="caption" fontWeight={800} flexGrow={2} color={value?"primary":"#aaa"}>{label()}</Typography>
                </Stack>
            </ButtonBase>
            <Stack>
                <Button sx={{p:0}} disabled={value===1} {...holdUp as any}><KeyboardArrowUp/></Button>
                <Button sx={{p:0}} disabled={value===0} {...holdDown as any}><KeyboardArrowDown/></Button>
            </Stack>
            <IconButton color="primary"><Tune/></IconButton>
        </AppWidgetBox>
    }


}
