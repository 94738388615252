import { SvgIconProps, SvgIcon } from "@mui/material";
import { ReactElement } from "react";


export function AnalogOutputIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon viewBox="0 0 24 24" fontSize="inherit" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <g stroke="#000" fill="none" strokeWidth={2} strokeLinecap="round" >
                <path id="analog" d="M4.348,11.488C4.348,11.448 5.068,8.887 6.257,8.887C8.285,8.887 8.732,15.26 10.444,15.253C11.842,15.247 12.475,11.52 12.475,11.52" />
                <path d="M15.75,4.435C15.75,3.048 14.94,1.921 13.943,1.921L2.963,1.921C1.966,1.921 1.156,3.048 1.156,4.435L1.156,19.705C1.156,21.092 1.966,22.218 2.963,22.218L13.943,22.218C14.94,22.218 15.75,21.092 15.75,19.705" />
                <g id="output">
                    <path d="M15.75,11.976C16.501,11.976 21.838,11.987 21.838,11.987" />
                    <path d="M19.555,8.017L22.838,11.973L19.555,15.969" />
                </g>
            </g>
        </SvgIcon>
    );
}

export function AnalogInputIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon viewBox="0 0 24 24" fontSize="inherit" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <g stroke="#000" fill="none" strokeWidth={2} strokeLinecap="round" >
                <path id="analog" d="M4.348,11.488C4.348,11.448 5.068,8.887 6.257,8.887C8.285,8.887 8.732,15.26 10.444,15.253C11.842,15.247 12.475,11.52 12.475,11.52" />
                <path d="M15.75,4.435C15.75,3.048 14.94,1.921 13.943,1.921L2.963,1.921C1.966,1.921 1.156,3.048 1.156,4.435L1.156,19.705C1.156,21.092 1.966,22.218 2.963,22.218L13.943,22.218C14.94,22.218 15.75,21.092 15.75,19.705" />
                <g id="input">
                    <path d="M22.838,12.01C22.086,12.01 16.75,11.999 16.75,11.999" />
                    <path d="M19.033,15.969L15.75,12.013L19.033,8.017" />
                </g>
            </g>
        </SvgIcon>
    );
}

export function GenericOutputIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon viewBox="0 0 24 24" fontSize="inherit" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <g stroke="#000" fill="none" strokeWidth={2} strokeLinecap="round" >
                <path d="M15.75,4.435C15.75,3.048 14.94,1.921 13.943,1.921L2.963,1.921C1.966,1.921 1.156,3.048 1.156,4.435L1.156,19.705C1.156,21.092 1.966,22.218 2.963,22.218L13.943,22.218C14.94,22.218 15.75,21.092 15.75,19.705" />
                <g id="output">
                    <path d="M15.75,11.976C16.501,11.976 21.838,11.987 21.838,11.987" />
                    <path d="M19.555,8.017L22.838,11.973L19.555,15.969" />
                </g>
            </g>
        </SvgIcon>
    );
}

export function GenericInputIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon viewBox="0 0 24 24" fontSize="inherit" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <g stroke="#000" fill="none" strokeWidth={2} strokeLinecap="round" >
                <path d="M15.75,4.435C15.75,3.048 14.94,1.921 13.943,1.921L2.963,1.921C1.966,1.921 1.156,3.048 1.156,4.435L1.156,19.705C1.156,21.092 1.966,22.218 2.963,22.218L13.943,22.218C14.94,22.218 15.75,21.092 15.75,19.705" />
                <g id="input">
                    <path d="M22.838,12.01C22.086,12.01 16.75,11.999 16.75,11.999" />
                    <path d="M19.033,15.969L15.75,12.013L19.033,8.017" />
                </g>
            </g>
        </SvgIcon>
    );
}


export function MethodIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon viewBox="0 0 256 256" fontSize="inherit" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M72,40S32,64,32,128s40,88,40,88" fill="none" stroke="#000" strokeLinecap="round"
                strokeLinejoin="round" strokeWidth="16" />
            <path d="M184,40s40,24,40,88-40,88-40,88" fill="none" stroke="#000" strokeLinecap="round"
                strokeLinejoin="round" strokeWidth="16" />
        </SvgIcon>
    );
}

export function FeatureIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon viewBox="0 0 24 24" fontSize="inherit" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M10.9848 3.16464C11.0756 2.61988 10.7076 2.10462 10.1629 2.01378C9.6181 1.92293 9.10284 2.29091 9.012 2.83567L8.15118 7.99772L3.99937 8.00016C3.44708 8.00049 2.99963 8.44847 2.99996 9.00075C3.00028 9.55304 3.44826 10.0005 4.00055 10.0002L7.81763 9.99791L7.15053 13.9983L2.99955 14.0002C2.44727 14.0004 1.99975 14.4483 2 15.0006C2.00025 15.5529 2.44816 16.0004 3.00045 16.0002L6.81699 15.9985L6.01034 20.8357C5.91949 21.3804 6.28747 21.8957 6.83223 21.9865C7.37699 22.0774 7.89225 21.7094 7.9831 21.1646L8.84475 15.9976L13.8203 15.9953L13.0136 20.8358C12.9228 21.3805 13.2908 21.8958 13.8356 21.9865C14.3803 22.0773 14.8956 21.7093 14.9863 21.1646L15.848 15.9944L20.0004 15.9926C20.5527 15.9923 21.0002 15.5444 21 14.9921C20.9998 14.4399 20.5518 13.9923 19.9996 13.9926L16.1814 13.9943L16.8483 9.9926L21.0005 9.99015C21.5528 9.98983 22.0003 9.54185 22 8.98956C21.9996 8.43728 21.5517 7.98983 20.9994 7.99015L17.1817 7.9924L17.9863 3.16456C18.0771 2.61978 17.7091 2.10456 17.1644 2.01376C16.6196 1.92297 16.1044 2.29099 16.0136 2.83576L15.1539 7.99359L10.179 7.99652L10.9848 3.16464ZM9.84545 9.99672L14.8206 9.99379L14.1537 13.9952L9.1783 13.9974L9.84545 9.99672Z"
                fill="#212121" />
        </SvgIcon>
    );
}

export function ModifiedIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon viewBox="0 0 24 24" fontSize="inherit" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
        </SvgIcon>
    );
}

export function DotIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M8 6C8.27614 6 8.5 6.22386 8.5 6.5C8.5 6.74546 8.32312 6.94961 8.08988 6.99194L8 7H6C4.34315 7 3 8.34315 3 10C3 11.5906 4.23784 12.892 5.80275 12.9936L6 13H8C8.27614 13 8.5 13.2239 8.5 13.5C8.5 13.7455 8.32312 13.9496 8.08988 13.9919L8 14H6C3.79086 14 2 12.2091 2 10C2 7.8645 3.67346 6.11986 5.78053 6.00592L6 6H8ZM14 6C16.2091 6 18 7.79086 18 10C18 12.1355 16.3265 13.8801 14.2195 13.9941L14 14H12C11.7239 14 11.5 13.7761 11.5 13.5C11.5 13.2545 11.6769 13.0504 11.9101 13.0081L12 13H14C15.6569 13 17 11.6569 17 10C17 8.40942 15.7622 7.10795 14.1973 7.00638L14 7H12C11.7239 7 11.5 6.77614 11.5 6.5C11.5 6.25454 11.6769 6.05039 11.9101 6.00806L12 6H14ZM6 9.5H14C14.2761 9.5 14.5 9.72386 14.5 10C14.5 10.2455 14.3231 10.4496 14.0899 10.4919L14 10.5H6C5.72386 10.5 5.5 10.2761 5.5 10C5.5 9.75454 5.67688 9.55039 5.91012 9.50806L6 9.5H14H6Z"
                fill="#212121" />
        </SvgIcon>
    )
}

export function EventIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon viewBox="0 0 512 512" fontSize="inherit" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <g>
                <path
                    d="M75.817,155.005C50.101,182.482,36,218.195,36,256.129c0,37.775,14.1,73.396,39.817,100.866l20.789-20.789    c-20.063-21.811-31.051-50.051-31.051-80.077c0-30.184,10.989-58.516,31.052-80.335L75.817,155.005z" />
                <path
                    d="M256,195.453c-32.574,0-59.074,26.5-59.074,59.074s26.5,59.075,59.074,59.075c32.573,0,59.074-26.501,59.074-59.075    S288.573,195.453,256,195.453z" />
                <path
                    d="M357.833,220.664c5.511,10.963,8.407,23.139,8.407,35.465c0,13.958-3.675,27.527-10.666,39.516l22.88,19.084    c11.191-17.423,17.08-37.568,17.08-58.6c0-19.711-5.151-38.736-14.946-55.375L357.833,220.664z" />
                <path
                    d="M154.167,220.664l-22.754-19.91c-9.794,16.638-14.946,35.663-14.946,55.375c0,21.033,5.888,41.178,17.08,58.6    l22.879-19.084c-6.991-11.989-10.666-25.56-10.666-39.516C145.76,243.803,148.656,231.626,154.167,220.664z" />
                <path
                    d="M436.183,155.005l-20.788,20.788c20.062,21.82,31.051,50.152,31.051,80.336c0,30.026-10.988,58.267-31.051,80.077    l20.788,20.789C461.899,329.525,476,293.904,476,256.129C476,218.195,461.899,182.482,436.183,155.005z" />
            </g>

        </SvgIcon>
    )
}

export function SoundWaveIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 10v12M11 7v18M25 10v12M21 7v18M16 4v24M3 12v8M29 12v8"
            />
        </SvgIcon>
    );
}

export function MultisensorIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 10v12.5"
            />
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 10v12.5M26.083 5H5.917C5.41 5 5 5.616 5 6.375v19.25c0 .76.41 1.375.917 1.375h20.166c.507 0 .917-.616.917-1.375V6.375C27 5.615 26.59 5 26.083 5"
            />
            <path
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 21a1 1 0 1 0 0 2 1 1 0 0 0 0-2M16 17a1 1 0 1 0 0 2 1 1 0 0 0 0-2M16 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
            />
        </SvgIcon>
    );
}

export function Co2Icon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11.77 19.4v1.055c-.04.944-.418 1.833-1.052 2.472-.634.638-1.474.974-2.333.933-.86.04-1.7-.295-2.334-.933S5.04 21.399 5 20.455V10.91c.039-.945.417-1.833 1.051-2.472.635-.638 1.474-.974 2.334-.934.86-.04 1.699.296 2.333.934s1.013 1.527 1.051 2.472v1.055M21.026 22.927c.634-.639 1.012-1.528 1.05-2.472V10.91c-.038-.945-.416-1.833-1.05-2.472-.635-.638-1.474-.974-2.334-.934-.86-.04-1.699.296-2.333.934s-1.013 1.527-1.051 2.472v9.546c.038.944.416 1.833 1.05 2.472.635.638 1.475.974 2.334.933.86.04 1.7-.295 2.334-.933"
            />
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeWidth={1.5}
              d="m25 19.59.274-.746c.46-1.248 2.195-1.33 2.77-.13v0c.213.447.193.97-.053 1.399l-2.905 5.055a.1.1 0 0 0 .087.15h3.212"
            />
        </SvgIcon>
    );
}

export function EcoIcon(props: SvgIconProps): ReactElement {
    return (
       <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11.25 27.587a8.15 8.15 0 0 0 9.966-3.538c2.252-3.9 3.534-17.766 3.534-17.766s-13.5 0-17.65 9.616a8.15 8.15 0 0 0 0 8.15"
            />
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeWidth={2}
              d="M11.25 27.283c.333-2.334 3.175-7.88 4.5-10s-4.5 3.666-8.5 7"
            />
        </SvgIcon>
    );
}

export function MeterIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              fill="#000"
              fillRule="evenodd"
              d="M23.1 13.87c.282-.45-.24-.972-.69-.69l-7.83 4.916a2.615 2.615 0 1 0 3.605 3.605zm-7.855 7.118a1.082 1.082 0 1 0 1.53-1.531 1.082 1.082 0 0 0-1.53 1.53"
              clipRule="evenodd"
            />
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 20.2a10 10 0 0 1 14-9.165m6 9.165c0-1.39-.29-2.75-.835-4"
            />
        </SvgIcon>
    );
}

export function HumidityIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 12.73A3.4 3.4 0 0 1 11.5 16 3.4 3.4 0 0 1 8 12.73C8 10.92 11.5 4 11.5 4s3.5 6.92 3.5 8.73M24 19a3 3 0 0 1-6 0c0-1.66 3-8 3-8s3 6.34 3 8M16 25.82A2.1 2.1 0 0 1 14 28a2.1 2.1 0 0 1-2-2.18c0-1.21 2-5.82 2-5.82s2 4.61 2 5.82"
            />
        </SvgIcon>
    );
}

export function HotspotIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 28V14M20 7.34a8 8 0 0 1 0 11.32M24 4.51a12 12 0 0 1 0 17M12 18.66a8 8 0 0 1 0-11.32M8 21.49a12 12 0 0 1 0-17"
            />
        </SvgIcon>
    );
}

export function SunIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 22a6 6 0 1 0 0-12 6 6 0 0 0 0 12M16 4v3M16 25v3M28 16h-3M24.56 24.06l-2.12-2.12M24.63 8.02l-2.26 1.96M7 16H4M9.56 21.94l-2.12 2.12M9.56 10.06 7.44 7.94"
            />
        </SvgIcon>
    );
}

export function PressureIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <circle cx={16} cy={8} r={2} stroke="#000" strokeWidth={2} fill="none"/>
            <circle cx={9} cy={20} r={2} stroke="#000" strokeWidth={2} fill="none"/>
            <circle cx={23} cy={20} r={2} stroke="#000" strokeWidth={2} fill="none"/>
            <circle cx={16} cy={16.5} r={1.5} fill="#000" />
            <circle cx={23.5} cy={11.5} r={1.5} fill="#000" />
            <circle cx={8.5} cy={11.5} r={1.5} fill="#000" />
            <circle cx={16} cy={25.5} r={1.5} fill="#000" />
        </SvgIcon>
    );
}

export function TemperatureIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 19V7a3 3 0 0 0-6 0v12a5 5 0 1 0 6 0"
            />
            <path
              fill="#000"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 25a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
            />
            <path
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 21v-8"
            />
        </SvgIcon>
    );
}

export function SmartPanelIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 16h4"
            />
            <path
              fill="#000"
              fillRule="evenodd"
              d="M17 5a1 1 0 1 0-2 0v7.5h2zm0 14.5h-2V27a1 1 0 1 0 2 0zM10.5 15H5a1 1 0 1 0 0 2h5.5zm11 2H27a1 1 0 1 0 0-2h-5.5z"
              clipRule="evenodd"
            />
            <rect width={8} height={4} x={12} y={14} fill="#000" rx={0.5} />
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M26.083 5H5.917C5.41 5 5 5.616 5 6.375v19.25c0 .76.41 1.375.917 1.375h20.166c.507 0 .917-.616.917-1.375V6.375C27 5.615 26.59 5 26.083 5"
            />
        </SvgIcon>
    );
}

export function PropertiesIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M18.92 4H7v24h18V10h-6zM19 4l6 6"
            />
        </SvgIcon>
    );
}

export function ButtonClickIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeWidth={2}
              d="M15.94 18.513v-8.5a1.5 1.5 0 1 0-3 0v10.5"
            />
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="m12.94 20.391-3.76-3.759a1.583 1.583 0 0 0-2.239 0v0c-.57.57-.62 1.478-.117 2.108l6.615 8.273"
            />
            <path
              stroke="#000"
              fill="none"
              strokeWidth={2}
              d="M18.94 18.513v-3.5a1.5 1.5 0 1 0-3 0v3.5M21.94 18.513v-3.5a1.5 1.5 0 1 0-3 0v3.5M24.94 20.513v-5.5a1.5 1.5 0 1 0-3 0v3.166"
            />
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M24.94 19.513v7.5h-11.5"
            />
            <path stroke="#000" strokeWidth={2} d="M9.94 10.513c0-7 9-7 9 0" />
        </SvgIcon>
    );
}

export function TouchPanelIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 9v14M9 16h14M26.083 5H5.917C5.41 5 5 5.616 5 6.375v19.25c0 .76.41 1.375.917 1.375h20.166c.507 0 .917-.616.917-1.375V6.375C27 5.615 26.59 5 26.083 5"
            />
        </SvgIcon>
    );
}

export function BlindsIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M25 4H7v24h18zM16 16v12M7 16h18M7 13h18M7 10h18M7 7h18"
            />
        </SvgIcon>
    );
}

export function DownloadIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M26 16v10H6V16"
            />
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="m11 14.333 5 5 5-5M16 19.333V6"
            />
        </SvgIcon>
    );
}

export function UploadShareIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M26 16v10H6V16M21 11l-5-5-5 5M16 6v13.333"
            />
        </SvgIcon>
    );
}

export function SwitchIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M24 2.5H8v27h16z"
            />
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M18 19h-4v6.5h4z"
            />
            <path
              fill="#000"
              fillRule="evenodd"
              d="M11 4.5a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-10a1 1 0 0 0-1-1zm3 2a1 1 0 1 0 0 2h1v5a1 1 0 1 0 2 0v-6a1 1 0 0 0-1-1z"
              clipRule="evenodd"
            />
        </SvgIcon>
    );
}

export function PowerIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19.56 8.51a10.09 10.09 0 1 1-7.26 0M16 5v7"
            />
        </SvgIcon>
    );
}

export function DimmerIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M26 4H6a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2"
            />
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 24a8 8 0 1 0 0-16 8 8 0 0 0 0 16"
            />
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 12a3 3 0 0 1 3 3"
            />
        </SvgIcon>
    );
}

export function RgbIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M20.5 20a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15"
            />
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 28a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15"
            />
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11.5 20a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15"
            />
        </SvgIcon>
    );
}

export function LedIcon(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
              stroke="#000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="m28.02 11.048-7.07-7.071-16.97 16.97 7.07 7.071zM9.247 9.247 7.832 7.833M12.075 6.008V4M6.008 12.075H4M17.59 13.34l.7.71M20.83 10.1l.71.71M13.34 17.59l.71.7M10.1 20.83l.71.71"
            />
        </SvgIcon>
    );
}
