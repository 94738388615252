import { newApiItemId } from '../id';
import { MethodImpl } from '../model/object-method';

export function createMethodImpl(): MethodImpl {
    return MethodImpl.from(
        {
            id: newApiItemId(),
        },
        false,
    );
}
