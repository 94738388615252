import { Block, Blocks, FieldLabel, inputs } from 'blockly';
import { Order, luaGenerator } from 'blockly/lua';
import { BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, BlockWithDelegate, createDelegate, SELECTION_OUTPUT_TYPES, VALUE_BLOCK_STYLE } from './common';

export namespace ObjectSelectionByTypeBlock {
    export const Type = 'object-sel-by-type';

    export const TYPES = 'types';
    export const INPUT_SELECTION = 'objects';

    export class Delegate implements BlockDelegate {
        inputIsSelection = false;

        // @ts-ignore
        constructor(private block: Block) {
            // block.appendDummyInput()
            //     .appendField(new FieldImage("icons/object.svg",16,16))

            block.setOutput(true, SELECTION_OUTPUT_TYPES);

            block.appendValueInput(INPUT_SELECTION).setAlign(inputs.Align.RIGHT).appendField(new FieldLabel('filter objects'));
            // .appendField(new FieldImage("icons/object.svg",16,16))

            block.appendValueInput(TYPES).setAlign(inputs.Align.RIGHT).appendField(new FieldLabel('by type'));

            block.setInputsInline(false);
            block.setStyle(VALUE_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        onUpdate(context: BlockContext) {
            const input = context.getInputType(INPUT_SELECTION);
            this.inputIsSelection = input?.type === 'outlet' || input?.type === 'selection';
            if (input && this.inputIsSelection) {
                context.setReturnedType(input);
            }
        }
    }
}

Blocks[ObjectSelectionByTypeBlock.Type] = createDelegate((block) => new ObjectSelectionByTypeBlock.Delegate(block));

luaGenerator.forBlock[ObjectSelectionByTypeBlock.Type] = (block, generator) => {
    let code: string;
    const _block = block as BlockWithDelegate<ObjectSelectionByTypeBlock.Delegate>;
    if (_block.delegate.inputIsSelection) {
        const input = generator.valueToCode(block, ObjectSelectionByTypeBlock.INPUT_SELECTION, Order.ATOMIC);
        const tags = generator.valueToCode(block, ObjectSelectionByTypeBlock.TYPES, Order.ATOMIC);
        code = `${input?.trim()}._withType(${tags?.trim()})`;
    } else {
        code = `{input must be an object selection}`;
    }
    return [code, Order.ATOMIC];
};
