import { Lists, Maps, ObjectSelector, ObjectApi, KeyMap, ObjectOutlet, ObjectApiImpl, OutletConfig } from '@grenton/gm-common';
import { ProjectObjectApi } from './object-api';

export interface ObjectSelectorImpl {
    tags: KeyMap;
    types: KeyMap;
    ids: KeyMap;
    exclude: KeyMap;
}

export const EMPTY_SELECTOR: ObjectSelectorImpl = {
    tags: {},
    types: {},
    ids: {},
    exclude: {},
};

export function convertOutletSelector(sel: ObjectSelector): ObjectSelectorImpl {
    return {
        ids: Lists.reduce(sel.ids, (value) => [value, true]),
        types: Lists.reduce(sel.types, (value) => [value, true]),
        tags: Lists.reduce(sel.tags, (value) => [value, true]),
        exclude: Lists.reduce(sel.exclude, (value) => [value, true]),
    };
}

export function exportOutletSelector(sel: ObjectSelectorImpl): ObjectSelector {
    return Maps.filterEmpty({
        ids: Lists.undefinedIfEmpty(Maps.reduceKeyMap(sel.ids)),
        types: Lists.undefinedIfEmpty(Maps.reduceKeyMap(sel.types)),
        tags: Lists.undefinedIfEmpty(Maps.reduceKeyMap(sel.tags)),
        exclude: Lists.undefinedIfEmpty(Maps.reduceKeyMap(sel.exclude)),
    });
}

export class OutletImpl {
    public readonly type = 'outlet';

    readonly api: ProjectObjectApi;

    static from(spec: ObjectOutlet, inherited: boolean) {
        return new OutletImpl({ spec, inherited });
    }

    private data: { spec: ObjectOutlet; inherited: boolean };

    constructor(data: { spec: ObjectOutlet; inherited: boolean }) {
        // ensure that bidirectional outlets are readOnly and have maxItems=bidirectional
        this.data = data.spec.bidirectional !== undefined ? { ...data, spec: { ...data.spec, maxItems: data.spec.bidirectional, readOnly: true } } : data;
        this.api = new ProjectObjectApi(data.spec.api as ObjectApiImpl, false);
    }

    // this is not spec (schema.Outlet) but postprocessed spec (ObjectOutlet)
    get spec() {
        return this.data.spec;
    }

    // just to be compatible with other EntityWithApi types. TODO rename all to id.
    get uuid() {
        return this.data.spec.id;
    }

    get inherited() {
        return this.data.inherited;
    }

    get id() {
        return this.data.spec.id;
    }

    get label() {
        return this.data.spec.label;
    }

    get name() {
        return this.label || this.uuid;
    }

    get bidirectional() {
        return this.data.spec.bidirectional;
    }

    get maxItems() {
        return this.data.spec.maxItems;
    }

    get readOnly() {
        return this.data.spec.readOnly;
    }

    withBidirectional(bidirectional: number | undefined) {
        return new OutletImpl({ ...this.data, spec: { ...this.data.spec, bidirectional } });
    }

    withMaxItems(maxItems: number | undefined) {
        return new OutletImpl({ ...this.data, spec: { ...this.data.spec, maxItems } });
    }

    withReadonly(readOnly: boolean) {
        return new OutletImpl({ ...this.data, spec: { ...this.data.spec, readOnly } });
    }

    withLabel(label: string) {
        return new OutletImpl({ ...this.data, spec: { ...this.data.spec, label } });
    }

    withApi(api: ObjectApi) {
        return new OutletImpl({ ...this.data, spec: { ...this.data.spec, api } });
    }

    export(): ObjectOutlet {
        return this.data.spec;
    }
}

export class OutletConfigImpl {
    static from(outletConfig: OutletConfig) {
        const type = outletConfig.refs.type;
        return new OutletConfigImpl({
            id: outletConfig.id,
            type: type,
            staticRefs: type === 'static' ? outletConfig.refs.value.ids || [] : [],
            dynamicRefs: type === 'dynamic' ? convertOutletSelector(outletConfig.refs.value || {}) : EMPTY_SELECTOR,
        });
    }

    constructor(private data: { id: string; type: 'static' | 'dynamic'; staticRefs: string[]; dynamicRefs: ObjectSelectorImpl }) {}

    get id() {
        return this.data.id;
    }

    get isStatic() {
        return this.data.type === 'static';
    }

    // this is useful for retrieving child->parent relation
    get firstStatic() {
        return this.isStatic ? this.staticRefs[0] : undefined;
    }

    get staticRefs() {
        return this.data.staticRefs;
    }

    get dynamicRefs() {
        return this.data.dynamicRefs;
    }

    withSelector(dynamicRefs: ObjectSelectorImpl) {
        return new OutletConfigImpl({ ...this.data, type: 'dynamic', staticRefs: [], dynamicRefs });
    }

    export(): OutletConfig {
        return this.isStatic
            ? {
                  id: this.data.id,
                  refs: {
                      type: 'static',
                      value: {
                          ids: this.data.staticRefs,
                      },
                  },
              }
            : {
                  id: this.data.id,
                  refs: {
                      type: 'dynamic',
                      value: exportOutletSelector(this.data.dynamicRefs),
                  },
              };
    }
}
