import { SwapHoriz } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import GMTextField from "../../../../ui/controls/GMTextField";
import { ActionLedRGBSwitchModel } from "./model";
import { ObjectApi } from "@grenton/gm-common";

export function ActionLedRGBSwitchWidget(props:{api:ObjectApi, model:ActionLedRGBSwitchModel, onUpdate:(model:ActionLedRGBSwitchModel)=>void}) {
    const {model} = props
    return <Stack direction="row" padding={2} spacing={2} alignItems="center">
        <Typography>ON</Typography><SwapHoriz/><Typography>OFF</Typography>
        <Box flexGrow={2}/>
        <GMTextField value={model.time||''} label="revert after (ms)" onChange={e=>{
            const time = parseInt(e.target.value) || 0
            props.onUpdate({...model, time})
        }} size="small" type="number" sx={{maxWidth:150}}></GMTextField>
                <GMTextField value={model.ramp||''} label="ramp (ms)" onChange={e=>{
            const ramp = parseInt(e.target.value) || 0
            props.onUpdate({...model, ramp})
        }} size="small" type="number" sx={{maxWidth:150}}></GMTextField>
    </Stack>
}

