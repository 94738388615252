import { Events, serialization, Workspace, WorkspaceSvg } from 'blockly';

import { BehaviorSubject, debounceTime, filter, share, Subject } from 'rxjs';
import { deepEqual } from 'fast-equals';
import { AbstractEventJson } from 'blockly/core/events/events';

export interface WorkspaceChangeEvent {
    type: string;
    recordUndo?: boolean;
    [key: string]: any;
}

export class BlocklyWorkspaceRef {
    private _changeEvent = new Subject<WorkspaceChangeEvent>();
    private _workspace = new BehaviorSubject<WorkspaceSvg | null>(null);
    private _lastContent: any;

    readonly changeEvent = this._changeEvent.pipe(
        filter((e) => {
            const w = this._workspace.value;
            if (w) {
                // preliminary filter events to avoid unnecessary serialization
                if (e.type === FORCE_WORKSPACE_CHANGE || e.recordUndo) {
                    // and now compare if content has changed - fortunately serialization seems to be stable
                    const content = serialization.workspaces.save(w);
                    if (!deepEqual(content, this._lastContent)) {
                        this._lastContent = content;
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            return false;
        }),
        // small debounce is still valuable because some operations (e.g. collapsing blocks) triggers multiple events
        // times > 5 adds noticeable delay in refreshing UI
        debounceTime(5),
        share(),
    );

    private listener = (e: WorkspaceChangeEvent) => this._changeEvent.next(e);

    get workspace() {
        return this._workspace;
    }

    withWorkspace<T>(callback: (workspace: WorkspaceSvg) => T, silentEvents = false): T | undefined {
        if (this._workspace.value) {
            if (silentEvents) Events.disable();
            try {
                return callback(this._workspace.value);
            } finally {
                if (silentEvents) Events.enable();
            }
        }
    }

    attachWorkspace(workspace: WorkspaceSvg) {
        this._workspace.next(workspace);
        workspace.addChangeListener(this.listener);
    }

    detachWorkspace() {
        this._workspace.value?.removeChangeListener(this.listener);
        this._workspace.next(null);
        this._lastContent = undefined;
    }
}

const FORCE_WORKSPACE_CHANGE = 'forceWorkspaceChange';

export function triggerWorkspaceChange(workspace: Workspace) {
    workspace.fireChangeListener({
        type: FORCE_WORKSPACE_CHANGE,
        isBlank: false,
        group: '',
        recordUndo: false,
        isUiEvent: false,
        toJson: function (): AbstractEventJson {
            return { type: 'projectChange', group: '' };
        },
        isNull: () => false,
        run: function (_forward: boolean): void {},
        getEventWorkspace_: function (): Workspace {
            return workspace;
        },
    });
}
