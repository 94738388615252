import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils/state';
import { createObjectsFromModuleComponent } from '../builders';
import { ProjectImpl } from '../model';

export class AddModuleCommand implements Command {
    readonly type = 'AddModuleCommand';
    constructor(readonly data: { cmpId: string; tag?: string }) {}
}

export class AddModuleCommandHandler implements CommandHandler<AddModuleCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'AddModuleCommand';
    }

    execute(cmd: AddModuleCommand) {
        return this.update((project) => {
            return project.withComponentEntry(
                createObjectsFromModuleComponent(
                    project.firmware,
                    cmd.data.cmpId,
                    (label) => project.hasObjectWithLabel(label),
                    cmd.data.tag ? [cmd.data.tag] : [],
                ),
            );
        });
    }
}
