import { fabric, schema } from '@grenton/gm-common';
import { BehaviorSubject } from 'rxjs';

export interface MethodInvocation {
    method: string;
    params?: { [name: string]: schema.PropertyValue };
}

export class ObjectStateHolder {
    private _state = new BehaviorSubject<fabric.ObjectState>({
        features: {},
    });

    constructor() {}

    get objectState() {
        return this._state;
    }

    set(feature: string, value: schema.PropertyValue) {
        this._state.next({ features: { ...this._state.value.features, [feature]: value } });
    }
    get(feature: string): schema.PropertyValue {
        return this._state.value.features[feature] || null;
    }
}

export type ObjectEventEmitter = (event: fabric.ObjectEvents) => void;

export class Scheduler {
    setTimeout(fn: () => void, time: number) {
        setTimeout(fn, time);
    }

    cancel() {}
}

export interface ObjectEmulator {
    onStateChange(curr: fabric.ObjectState, prev?: fabric.ObjectState): void;
    execute: MethodExecutor;
}

export type MethodExecutor = (invocation: MethodInvocation) => Promise<schema.PropertyValue>;
