import { TreeViewBaseItem } from '@mui/x-tree-view';

export type GTreeViewBaseItem<R extends {}> = TreeViewBaseItem<R>; // & { checkbox?: GTreeItemCheckboxState };

export type GTreeItemRendererProps<R extends {}> = {
    item: GTreeViewBaseItem<R>;
    onClick: (e: React.MouseEvent, eventData?: any) => void;
    rootClassName?: string;
};

export type GTreeItemRenderer<R extends {}> = (props: GTreeItemRendererProps<R>) => React.ReactNode;

export type GTreeItemClickHandler<R extends {}> = (e: React.MouseEvent, item: R, data?: any) => void;

export enum GTreeItemCheckboxState {
    HIDDEN = 0,
    DISABLED = 1,
    ENABLED = 2,
}
