import {Stack, ToggleButtonGroup, ToggleButton} from "@mui/material"
import GMTextField from "../../../../ui/controls/GMTextField"
import {ActionSetModel} from "./model"

export function ActionDigitalOutSetWidget(props: { model: ActionSetModel, onUpdate: (model: ActionSetModel) => void }) {

    const {model} = props
    return <Stack spacing={2} padding={2} direction="row">

        <ToggleButtonGroup
            sx={{flexGrow: 2}}
            value={model.value}
            exclusive
            onChange={(_, value) => {
                props.onUpdate({...model, value})
            }}
        >
            <ToggleButton value={0} sx={{minWidth: 70}}>
                Off
            </ToggleButton>
            <ToggleButton color="primary" value={1} sx={{minWidth: 70}}>
                On
            </ToggleButton>
        </ToggleButtonGroup>

        <Stack direction="row" sx={{ml: 4}} alignItems="center" spacing={1}>
            <GMTextField value={model.revertAfter || ''} label="revert after (ms)" onChange={e => {
                const revertAfter = parseInt(e.target.value) || 0
                props.onUpdate({...model, revertAfter})
            }} size="small" type="number" sx={{maxWidth: 150}}></GMTextField>
        </Stack>
    </Stack>
}
