import { err, ok, Result } from 'neverthrow';
import semver from 'semver';

export function parseConfigurationSchemaVersion(schemaVersion: any): Result<string, string> {
    if (!schemaVersion) {
        return err(`missing schema version`);
    }
    if (typeof schemaVersion !== 'string') {
        return err(`schema version must be string`);
    }
    const parsedSchemaVersion = semver.parse(schemaVersion);
    if (!parsedSchemaVersion) {
        return err(`malformed schema version "${schemaVersion}"`);
    }
    return ok(parsedSchemaVersion.format());
}
