/* eslint-disable */
import ace from 'ace-builds/src-noconflict/ace';

import modeLuaUrl from 'ace-builds/src-noconflict/mode-lua.js?url';
ace.config.setModuleUrl('ace/mode/lua', modeLuaUrl);
import snippetsLua from 'ace-builds/src-noconflict/snippets/lua.js?url';
ace.config.setModuleUrl('ace/snippets/lua', snippetsLua);
import modeLuaWorker from 'ace-builds/src-noconflict/worker-lua.js?url';
ace.config.setModuleUrl('ace/mode/lua_worker', modeLuaWorker);
import extLanguageTools from 'ace-builds/src-noconflict/ext-language_tools.js?url';
ace.config.setModuleUrl('ace/ext/language_tools', extLanguageTools);
import themeGithub from 'ace-builds/src-noconflict/theme-github.js?url';
ace.config.setModuleUrl('ace/theme/github', themeGithub);
