import { Components, Theme } from '@mui/material';
import { spacing } from '../size';
import { themeVariant } from '../../variant';

export const MuiDialogContent: Components<Omit<Theme, 'components'>>['MuiDialogContent'] = {
    defaultProps: {},
    styleOverrides: {
        //@ts-ignore
        root: ({ _ownerState, _theme }) =>
            themeVariant === 'kris'
                ? {
                      padding: spacing.XS2,
                  }
                : {
                      padding: spacing.M,
                  },
    },
};
