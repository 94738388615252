import { ProjectComponentInstance, ProjectComponentType } from '@grenton/gm-common';

// TODO this probably is redundant since we do not modify it
export class ProjectDeviceModuleInstanceImpl {
    static from(cmp: ProjectComponentInstance) {
        return new ProjectDeviceModuleInstanceImpl({ id: cmp.id, ref: cmp.ref, type: cmp.type });
    }

    constructor(
        private data: {
            id: string;
            ref: string;
            type: ProjectComponentType;
        },
    ) {}

    get type() {
        return this.data.type;
    }

    get uuid() {
        return this.data.id;
    }

    get ref() {
        return this.data.ref;
    }

    export(): ProjectComponentInstance {
        return {
            type: this.data.type,
            id: this.data.id,
            ref: this.data.ref,
        };
    }
}
