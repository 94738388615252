import { Tabs, TabsProps, styled } from "@mui/material";

export const GTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
    minHeight:'3em', // 2.5em is too short and cause tabs to change height 
   
    "& .MuiTabs-flexContainer": { 
        gap: theme.spacing(1.2),
        paddingTop: 10, // leave space for badges
    },
    "& .MuiTab-root": { 
        textTransform: 'none',
        fontWeight: 600,
        padding: 0, //we use gap instead. padding makes indicator to be wider than the label.
        minWidth: 48,
        maxWidth: 150,
        minHeight: '2.5em',
        flexDirection: 'row',
        overflow:'visible', //to show badges,
        "& .MuiTab-g-label-root" : {
            width:'100%',
            flexDirection:'row',
            alignItems:'center',
            "& .MuiIconButton-root" : {
                fontSize:'1.25em'
            },
            "& .MuiTab-g-label" : {
                padding:'8px 0',  //horizontal padding makes indicator to be wider than the label.    
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                flexGrow:1,
                fontWeight: 600
            },
        },
        "& .MuiBadge-root": {
            flexGrow:1, // we need to take it entire width of the tab
        },
        "& .MuiBadge-badge.MuiBadge-anchorOriginTopRight" : {
            top: -1,
            right: -5,
            "&.MuiBadge-dot" : {
                top:4,
                right:-2
            }
        }
    },
    "& .MuiTabs-indicator" : { 
        backgroundColor: theme.palette.primary.main,
        bottom:'0.5em',
        height:'2px'
    }
}));