/*
for worker loading issues see
https://github.com/securingsincity/react-ace/issues/725

"What is the status of this issue? Although importing webpack-resolver works, it makes compilation super-slow, so cannot be considered a permanent solution."

"Adding webpack-resolver creating 100+ js files when building the project #1168"
*/

import AceEditor from "react-ace";
import './ace-thin-webpack-resolver'
import 'ace-builds/src-noconflict/ext-language_tools'; // required for auto-completion
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/worker-lua";

import { useEffect, useRef } from "react";
import { useResizeDetector } from "react-resize-detector";
import { IAceEditor } from "react-ace/lib/types";
import { SilentBehaviorSubject } from "@grenton/gm-common/src";


export class LuaCodeEditorController {

  editor?: IAceEditor
  private _code = new SilentBehaviorSubject<string|null>(null)
  readonly code = this._code.asObservable()

  insert(code: string) {
    this.editor?.insert(code)
  }

  getCodeValue() {
    return this._code.value
  }

  onCodeChange(code:string) : void {
    if (code !== this._code.value) {
      this._code.next(code)
    }
  }

  setContent(code:string) {
    // it is important to avoid generating a change event while setting the value
    // otherwise it may trigger infinite loop
    if (code !== this._code.value) {
      this._code.silentNext(code)
      // 2nd arg is cursor position: -1=start, 0=select all, 1=end
      this.editor?.setValue(code, 1)
    }
  }
}

interface LuaCodeEditorProps {
  controller?: LuaCodeEditorController
}

export default function LuaCodeEditor(props:LuaCodeEditorProps) {

  const aceEditor = useRef<AceEditor>(null);

  useEffect(() => {
    const ctrl = props.controller
    if (ctrl) {
      ctrl.editor = aceEditor.current?.editor
      return ()=>{
        ctrl.editor=undefined
      }
    }
  }, [props.controller]);

  useResizeDetector({targetRef: {current:aceEditor.current?.refEditor||null}, onResize: () => {
    aceEditor.current?.editor.resize()
  }})

    return (
        <AceEditor
          style={{}}
          ref={aceEditor as any}
          
          mode="lua"
          theme="github"
          onChange={(code)=>props.controller?.onCodeChange(code)}
          value={props.controller?.getCodeValue()||''}
          width="auto"
          height="100%"
          editorProps={{ $blockScrolling: true }}

          setOptions={{
            useWorker: true,
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
            }}
        />
    );
  }
