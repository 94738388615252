import { Project } from '@grenton/gm-common';
import { PartialValidator } from './helpers';
import { validateVersion } from './validateVersion';
import { validateSchema } from './validateSchema';
import { ProjectValidationResult, ProjectValidator } from './types';
import { validateFirmware } from './validateFirmware';
import { validateObjectDefinition } from './validateObjectDefinition';
import { FirmwareProvider } from '../firmwareProvider';

export function projectValidator(firmwareProvider: FirmwareProvider): ProjectValidator {
    const validators: PartialValidator[] = [
        // is project version even supported? if not, we do not have a schema to validate it and it is a fatal error
        validateVersion,
        // validate schema. Here we should choose proper schema for the project version. If schema is broken it is a fatal error
        validateSchema,
        validateFirmware(firmwareProvider),
        validateObjectDefinition(),
    ];

    return (project: Project) => {
        const result: ProjectValidationResult = {
            errors: [],
            warnings: [],
            validated: true,
        };

        const context = {};

        for (const validator of validators) {
            const partial = validator(project, context);
            result.errors.push(...(partial.errors || []));
            result.warnings.push(...(partial.warnings || []));
            if (partial.fatal) {
                break;
            }
        }

        // 0. check version
        //  . firmware?
        // 1. check for anonymous controllers w/o owners
        // 2. check for naming
        // 2.1 PORT ids "PORT_x"
        // 2.2 OBJECT ids
        // 2.3 API ITEM ids
        // 3. errors in hardware mapping

        return result;
    };
}

export const ProjectValidationResultNone: ProjectValidationResult = Object.freeze({
    validated: false,
    errors: [],
    warnings: [],
});
