import { FieldDropdown } from 'blockly';

/*
 * dropdown field that allows any value
 *
 * because...
 * state is used to serialize invisible values that ARE NOT STORED IN FIELDS!
 * normally, it would be empty, but in both deserialization and marker creation:
 * 1. loadState is called prior to setting fields values
 * 2. dropdown complains when value is not listed in MenuOptions
 * 3. at this point we cannot generate full list of options yet.
 *
 * Ideally, dropdown would simply accept any value and render empty option.
 */
export class FieldDropdownEx extends FieldDropdown {
    // protected override doValueUpdate_(newValue: MenuOption[1]) {
    //     this.selectedOption_
    // }
    /*
     * this works but to render anything, FieldGenerator needs matching MenuOption.
     * otherwise it renders nothing
     */
    // this is messing with generics now...?
    protected override doClassValidation_(opt_newValue: string): string | null {
        return opt_newValue === undefined ? null : opt_newValue || null;
    }
}
