import { uuid } from '@grenton/gm-common';

const getRandomLetterAtoF = () => String.fromCharCode(97 + Math.floor(Math.random() * 6));

const shortUUIDstartingWithALetter = () => `${getRandomLetterAtoF()}${uuid.short()}`;

export function newProjectId() {
    return uuid.v4();
}

export const newApiItemId = shortUUIDstartingWithALetter;

export const newObjectId = shortUUIDstartingWithALetter;

/**
 * TBD: x was used by 2.0 as a prefix to - I believe - be lua friendly,
 * because this id was used as a prefix for object id.
 *
 * so it depends how we construct the object id:
 * if we start from module name (which makes more sense) followed by module id and object slot,
 * we do not need 'x'
 *
 * @returns
 */
export const newComponentId = shortUUIDstartingWithALetter;
