import { schema } from "@grenton/gm-common";
import { PropertyValueField } from "../propertyValueField";

type Props = {
    params:schema.MethodParam[],
    values:Record<string,schema.PropertyValue>,
    onValueChange:(id:string, value:schema.PropertyValue)=>void,
    disabled?: boolean
}

export function MethodParamsForm({params, values, onValueChange, disabled}:Props) {
    return <> {params.map((param) => 
        <PropertyValueField 
            disabled={disabled}
            minWidth={param.type === 'number' || param.type === 'integer' ? 80 : 300}
            key={param.id} 
            type={param.type} 
            value={values[param.id]} 
            label={param.label||param.id} 
            onChange={(v) => onValueChange(param.id, v) }/>
        )} </>
}