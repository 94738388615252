import { Subscription, filter, map, pairwise } from 'rxjs';
import { NotificationReceiver } from '../../notifications';
import { StateProvider } from '../../utils/state';
import { NetworkView } from './networkView';

export function networkViewNotifier(networkViewProvider: StateProvider<NetworkView>, notificationReceiver: NotificationReceiver): Subscription {
    return networkViewProvider
        .pipe(
            pairwise(),
            filter(([prev, curr]) => prev.discovery.connectedClu !== curr.discovery.connectedClu),
            map(([prev, curr]) => ({ previous: prev, current: curr })),

            //skip(1),
            //distinctUntilChanged((prev, curr) => prev.discovery.connectedClu === curr.discovery.connectedClu),
        )
        .subscribe(({ previous, current }) => {
            if (current.discovery.connectedClu) {
                notificationReceiver({
                    level: 'info',
                    title: `Found ${current.discovery.clus.length} CLU(s)`,
                    details: `Connected to CLU at ${current.discovery.connectedClu}`,
                });
            } else if (previous.discovery.connectedClu !== null) {
                notificationReceiver({
                    level: 'info',
                    title: 'CLU disconnected',
                    details: 'CLU disconnected',
                });
            }
        });
}
