import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { HWCluInfo, Lists, Maps, notEmpty, ProjectUserCredentials } from '@grenton/gm-common';
import { ProjectImpl, ProjectImporter, StateProvider } from '@grenton/gm-logic';
import { HWCluImpl, HWConfigurationImpl, Cluster } from '@grenton/gm-logic';
import { ClusterClient } from '@grenton/gm-logic';
import { CommandDispatcher } from '@grenton/gm-logic/src/dispatcher/dispatcher';
import { LinkProjectModuleToHardwareCommand } from '@grenton/gm-logic';
import { StoreHardwareConfigurationCommand } from '@grenton/gm-logic';
import { RemoveCluCommand } from '@grenton/gm-logic';
import { MoveObjectsBetweenModulesCommand } from '@grenton/gm-logic';
import { RemoveProjectModuleLinkToHardwareCommand } from '@grenton/gm-logic';

import { z } from 'zod';
import { AuthorizationError } from '@grenton/gm-logic';
import { SelectedEntity } from './types';
import { createModuleImpl } from './createModuleFromShim';
import { NetworkView } from '@grenton/gm-logic';
import { createConfigurationFromShim } from './createConfigurationFromShim';
import { importProjectFromCLU } from './importProjectFromCLU';

const ProjectUserCredentialsZod = z
    .object({
        username: z.string(),
        password: z.string(),
    })
    .required();

function findOnlineProjectCLUUrl(
    loadedProjectProvider: StateProvider<ProjectImpl | null>,
    networkViewObservable: Observable<NetworkView>,
): Observable<string | undefined> {
    return combineLatest([loadedProjectProvider, networkViewObservable]).pipe(
        // we kick off monitoring only when user edits a project
        filter(([project]) => project !== null),
        map(([project, networkView]) => {
            const anyProjectCluOnline = project!.hardware.configuration.clus
                // map CLUs from the project to CLUs from the network view
                .map((projectClu) => networkView.discovery.clus.find((remoteClu) => remoteClu.clu.id === projectClu.id))
                // get first online CLU
                .find((remoteClu) => remoteClu && remoteClu.url);
            return anyProjectCluOnline;
        }),
        map((clu) => clu?.url),
        // notify observers only when CLU url changes (since url is pseudo-static per CLU, this means we got different CLU)
        distinctUntilChanged(),
    );
}

function createDeployedClusterView(clus: HWCluInfo[]): Cluster[] {
    return Maps.values(Lists.group(clus, (cluInfo) => [cluInfo.cluster.id, cluInfo])).map((clusByClusterId) => {
        let configuration = HWConfigurationImpl.empty();
        const first = clusByClusterId[0]!;
        clusByClusterId.forEach(
            (cluInfo) =>
                (configuration = configuration.withClu(
                    new HWCluImpl({
                        id: cluInfo.clu.id,
                        name: cluInfo.clu.id,
                        url: cluInfo.url,
                        modules: {},
                        imported: false,
                        online: true,
                    }),
                )),
        );

        return new Cluster({
            local: false,
            clusterId: first.cluster.id,
            projectId: first.project.id,
            projectRevision: first.project.revision,
            configuration,
        });
    });
}

/**
 * here we create a view of clusters that overlays existing mapping (that may not be deployed yet)
 * onto the real network view.
 * All CLUs that have been added to the local project are removed from their existing clusters
 * TODO - this may be a bit misleading on UI, as this mixes reality with non applied changes.
 * we may want to display such CLUs in both clusters, and indicate that they are being removed from old clusters
 */
function createMergedClusterView(deployedClus: HWCluInfo[], localCluster: Cluster | null): Cluster[] {
    const clusters: Cluster[] = [];
    const deployedClusters = createDeployedClusterView(deployedClus);

    if (localCluster) {
        clusters.push(localCluster);
        deployedClusters.forEach((deployedCluster) => {
            localCluster.configuration.clus.forEach((acquiredCLU) => {
                deployedCluster = deployedCluster.withConfiguration(deployedCluster.configuration.withoutClu(acquiredCLU.id));
            });
            clusters.push(deployedCluster);
        });
    } else {
        clusters.push(...deployedClusters);
    }

    return clusters;
}

/*
 * TODO we have to split this into UI part (selecting modules etc)
 * and backend part (discovery/live updates etc)
 */
export class HardwareController {
    // this set will be updated in runtime
    // but on UI we need to keep displaying all the hardware used by the project
    // even if it is not currently online
    // if we keep storing only hardware SNs in the project, we cannot recreate a full tree in case of offline hardware piece
    // does it mean that we need to store full spec of the hardware once it is mapped? I think so.
    private _visibleClusters = new BehaviorSubject<Cluster[]>([]);

    private readonly _selectedEntity = new BehaviorSubject<SelectedEntity | undefined>(undefined);

    private allSeenOnline = HWConfigurationImpl.empty();

    private _unlinkedModulesCount: Observable<number>;

    constructor(
        private dispatcher: CommandDispatcher,
        private projectProvider: StateProvider<ProjectImpl | null>,
        private projectImporter: ProjectImporter,
        private networkViewObservable: Observable<NetworkView>,
        private cluCurrentConfigurationMonitor: ClusterClient,
        private importProjectFromCLU: importProjectFromCLU,
    ) {
        /*
         * for any project that contains hardware:
         * 1. combine with cluNetworkLookup.clus
         * 2. find CLU that
         *    - belong to the project
         *    - is online
         *    - we're authorized for
         * 3. grab its endpoint and set to cluProjectMonitor
         * 4. the result from cluProjectMonitor is our online hardware configuration
         */

        findOnlineProjectCLUUrl(projectProvider, networkViewObservable).subscribe((cluURL) => this.cluCurrentConfigurationMonitor.setCLUEndpoint(cluURL));

        // what's going on here
        combineLatest([this.projectProvider, this.cluCurrentConfigurationMonitor.project, this.networkViewObservable])
            .pipe(
                map(([localProject, remoteProject, networkView]) => {
                    if (!localProject) return [];

                    // cluster related to currently edited, local project
                    let localCluster: Cluster | null = null;

                    // create cluster for the local project
                    if (localProject.hardware.configuration.clus.length) {
                        localCluster = new Cluster({
                            local: true,
                            clusterId: '', //localProject.hardware.cluster?.id,
                            projectId: localProject.uuid,
                            projectRevision: localProject.revisions.head.tag,
                            configuration: createConfigurationFromShim(localProject.hardware.configuration, localProject.firmware),
                        });
                    }

                    // update CLUs from connected cluster with up-to-date data from CLI
                    if (localCluster) {
                        const firmware = localProject.firmware;

                        for (const cluInfo of networkView.discovery.clus) {
                            let clu = localCluster.configuration.getCluById(cluInfo.clu.id);
                            if (clu) {
                                clu = clu.withOnline(true);

                                // this we need to replace, ideally clu-network-lookup would return full map of components visible online
                                if (remoteProject) {
                                    const remoteClu = remoteProject.configuration.clus.find((c) => c.id === clu?.id);
                                    const localModules = clu.modules;

                                    if (remoteClu) {
                                        // update online status for given module (we do not remove modules, we just mark them offline)
                                        localModules.forEach((localModule) => {
                                            clu = clu?.withModule(
                                                localModule.withOnline(Boolean(remoteClu.modules.find((remoteModule) => remoteModule.id === localModule.id))),
                                            );
                                        });
                                        // add missing modules from the local project
                                        remoteClu.modules.forEach((remoteModule) => {
                                            if (!localModules.find((localModule) => remoteModule.id === localModule.id)) {
                                                const module = createModuleImpl(
                                                    remoteModule,
                                                    (type) => firmware.getComponent(type),
                                                    (api) => firmware.resolveApiRef(api),
                                                    true,
                                                );
                                                if (module) {
                                                    clu = clu?.withModule(module);
                                                }
                                            }
                                        });
                                    }
                                }

                                localCluster = localCluster.withConfiguration(localCluster.configuration.withClu(clu));
                            }
                        }
                    }

                    return createMergedClusterView(networkView.discovery.clus, localCluster);

                    /*
            // merge with online config with all previous online clus we've seen so far
            this.allSeenOnline = this.allSeenOnline.withOnlineAll(false)
            for (const clu of val[1]) {

                const newClu = createCluImpl(clu, (type)=>this.hardwareLibrary.getModule(type), (api)=>this.objectApiRegistry.find(api), false, true)
                let existingClu = this.allSeenOnline.getCluById(clu.id)
                if (existingClu) {
                    existingClu = existingClu.withOnline(true)
                    for (const module of newClu.modules) {
                        existingClu = existingClu.withModule(module)
                    }
                }

                this.allSeenOnline = this.allSeenOnline.withClu(existingClu || newClu)
            }

            return this.combineProjectAndOnlineConfigurations(
                val[0],
                this.allSeenOnline
            )
            */
                }),
            )
            .subscribe((c: Cluster[]) => {
                // even with distinct above it is hard to avoid loops, when source is being instantly modified here
                // projectHolder.update(p=>p.withHardware(p.hardware.withConfiguration(c)))
                this._visibleClusters.next(c);
            });

        this._unlinkedModulesCount = this.projectProvider.pipe(
            filter(notEmpty),
            map((p) => {
                return p.modules.reduce((sum, mod) => (p.hardware.findHardwareMappedToVirtual(mod.uuid) ? sum : sum + 1), 0);
            }),
        );
    }

    /*
     * TODO - we want to display components that are currently online/available and also components that are present in
     * current project configuration, but may be temporarily or indefinitely unavailable
     * when project is synced to CPU, we can remove all unmapped/unavailable components and trim the list so it does not
     * grow with time
     */
    // private combineProjectAndOnlineConfigurations(projectConfig: HWConfigurationShim, currentConfig: HWConfigurationImpl): HWConfigurationImpl {
    //     let merged = HWConfigurationImpl.empty()

    //     for (const clu of projectConfig.clus) {
    //         //clu.modules.map(m=>this.hardwareLibrary.getModule(m))
    //         const cluimpl = createCluImpl(clu, (type) => this.deviceModuleLibrary.getModule(type), (api) => this.objectApiRegistry.resolve(api), true, false)
    //         merged = merged.withClu(cluimpl)
    //     }

    //     for (const clu of currentConfig.clus) {
    //         let c = merged.getCluById(clu.id)
    //         if (c) {
    //             for (const mod of clu.modules) {
    //                 c = c.withModule(mod)
    //             }
    //             c = c.withOnline(clu.online)
    //         } else {
    //             c = clu
    //         }
    //         merged = merged.withClu(c)
    //     }

    //     return merged
    // }

    get visibleClusters() {
        return this._visibleClusters;
    }

    get editedCluster() {
        const health = (cluster: Cluster) => {
            const info: { severity: 'error' | 'info' | 'warning'; message: string }[] = [];

            if (!cluster.configuration.clus.length) {
                info.push({ severity: 'error', message: 'At least one CLU is required' });
            }
            for (const clu of cluster.configuration.clus) {
                if (!clu.online) {
                    info.push({ severity: 'error', message: `CLU ${clu.id} is offline` });
                }
            }

            const pushReady = info.find((i) => i.severity === 'error') === undefined;

            return {
                pushReady,
                info,
            };
        };

        return this._visibleClusters.pipe(
            map((all) => all.find((c) => c.local)),
            filter(notEmpty),
            map((cluster) => ({
                cluster,
                health: health(cluster),
            })),
        );
    }

    get selectedEntity() {
        return this._selectedEntity;
    }

    get unlinkedModulesCount() {
        return this._unlinkedModulesCount;
    }

    onSelectEntity(mod?: SelectedEntity) {
        const current = this._selectedEntity.value;

        if (mod?.selection === 'module' && current?.selection === 'module') {
            if (mod.belongsTo === current.belongsTo && mod.uuid === current.uuid) {
                this._selectedEntity.next(undefined);
                return;
            }
            if (mod.belongsTo !== current.belongsTo) {
                const mods: [SelectedEntity, SelectedEntity] = current.belongsTo === 'project' ? [current, mod] : [mod, current];
                const currentProj = this.visibleClusters.value.find((p) => p.projectId === this.projectProvider.value?.uuid);
                if (currentProj) {
                    this.dispatcher.execute(
                        new StoreHardwareConfigurationCommand({
                            configuration: currentProj.configuration.export(),
                        }),
                    );
                }
                this.dispatcher.execute(new LinkProjectModuleToHardwareCommand({ mapping: { [mods[0].uuid]: mods[1].uuid } }));
                this._selectedEntity.next(undefined);
                return;
            }
        }

        if (mod?.selection === 'object' && current?.selection === 'object') {
            if (mod.uuid !== current.uuid) {
                this.dispatcher.execute(new MoveObjectsBetweenModulesCommand({ sourceObjectId: current.uuid, targetObjectId: mod.uuid }));
            }
            this._selectedEntity.next(undefined);
            return;
        }

        this._selectedEntity.next(mod);
    }

    onDeleteMapping(uuid: string) {
        this.dispatcher.execute(new RemoveProjectModuleLinkToHardwareCommand({ moduleId: uuid }));
    }

    onRemoveClu(cluId: string) {
        this.allSeenOnline = this.allSeenOnline.withoutClu(cluId);
        this.dispatcher.execute(new RemoveCluCommand({ cluId }));
    }

    getProjectForClu(cluID: string) {
        return this.visibleClusters.value.find((p) => Boolean(p.configuration.clus.find((clu) => clu.id === cluID)));
    }

    findVisibleClusterWithCLU(cluId: string): { cluster: Cluster; clu: HWCluImpl } | undefined {
        const cluster = this._visibleClusters.value.find((cluster) => Boolean(cluster.configuration.clus.find((c) => c.id === cluId)));
        return cluster
            ? {
                  cluster,
                  clu: cluster.configuration.clus.find((c) => c.id === cluId)!,
              }
            : undefined;
    }

    getStoredProjectCredentials(projectId: string): ProjectUserCredentials | null {
        try {
            return ProjectUserCredentialsZod.parse(JSON.parse(localStorage.getItem(`project-creds-${projectId}`) || ''));
        } catch (e) {
            return null;
        }
    }

    setCluAuthorization(cluId: string, creds: ProjectUserCredentials) {
        const projectId = this.findVisibleClusterWithCLU(cluId)?.cluster.projectId;
        if (!projectId) return;
        localStorage.setItem(`project-creds-${projectId}`, JSON.stringify(creds)); // TODO encrypt!
    }

    /**
     *
     * @param cluId
     * @param withModules if true, will also import all modules from the remote project
     * @param creds if not provided, will try to use stored credentials
     * @throws AuthorizationError
     * @returns
     */
    async importAndMerge(cluId: string, withModules: boolean) {
        const { cluster, clu } = this.findVisibleClusterWithCLU(cluId) || {};
        if (!cluster || !clu) throw new Error('CLU not found');
        if (!clu.url) throw new Error('CLU does not define pull URL');

        let projectCredentials = this.getStoredProjectCredentials(cluster.projectId);
        if (!projectCredentials) {
            throw new AuthorizationError(`No credentials for configuration "${cluster.projectId}".`);
        }

        // inject fake error
        // return new Promise<void>((_, reject) => {
        //     setTimeout(()=>{
        //         reject(new AuthorizationError("ZONK"));
        //     }, 1000)
        // })

        const newProject = await this.projectImporter(clu.url, projectCredentials);
        this.importProjectFromCLU(cluId, newProject, withModules);
    }
}
