import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils/state';
import { ProjectImpl } from '../model';

export type ObjectQuickEditForm = {
    id: string;
    label: string;
    tags: string[];
    functionalType?: string;
};

export class ObjectQuickEditCommand implements Command {
    readonly type = 'ObjectQuickEditCommand';
    constructor(readonly data: { form: ObjectQuickEditForm }) {}
}

export class ObjectQuickEditCommandHandler implements CommandHandler<ObjectQuickEditCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'ObjectQuickEditCommand';
    }

    async execute(cmd: ObjectQuickEditCommand) {
        await this.update((p) => {
            let object = p.getObjectById(cmd.data.form.id);
            if (object) {
                object = object.withLabel(cmd.data.form.label).withTags(cmd.data.form.tags).withUserType(cmd.data.form.functionalType);

                p = p.withObject(object);
            }
            return p;
        });
    }
}
