import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { Services } from "../framework";

type Props = PropsWithChildren<{}>

export const ServicesContext = createContext<Services>({} as Services)

export function ServicesProvider({children}:Props) {
    const [beans, setBeans] = useState<Services|null>(null);

    useEffect(() => {
        const beans = new Services();

        beans.init().then(() => {
            setBeans(beans);
        }).catch(error => {
            console.error("Failed to initialize beans:", error);
        });
    }, []);

    if (!beans) {
        return <div>Loading...</div>;
    }

    return (<ServicesContext.Provider value={beans}>{children}</ServicesContext.Provider>)
}