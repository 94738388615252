import { Project } from '@grenton/gm-common/src';
import { createObjectsFromModuleComponent } from './createObjectsFromModuleComponent';
import { CreateProjectImpl } from './createProjectImpl';

export type ImportSingleCLU = ReturnType<typeof createProjectOfSingleCLU>;

/**
 * create a project that contains only this part (hardware modules) that are linked to given CLU.
 * this is used to add (merge) CLU with its modules to existing project.
 * @param createProjectImpl
 * @returns
 */
export function createProjectOfSingleCLU(createProjectImpl: CreateProjectImpl) {
    return async function (project: Project, cluToImport: string, withModules: boolean) {
        let p = await createProjectImpl();
        const clu = project.hardware.configuration.clus.find((c) => c.id === cluToImport);
        if (!clu) return p;
        p = p.withHardware(p.hardware.withConfiguration({ clus: [clu] }));
        if (withModules) {
            clu.modules.forEach((m) => {
                const compEntry = createObjectsFromModuleComponent(p.firmware, m.ref, (label) => p.hasObjectWithLabel(label));
                p = p.withComponentEntry(compEntry).withHardware(p.hardware.withMapping({ [compEntry.component.uuid]: m.id }));
            });
        }
        return p;
    };
}
