import { Theme } from '@emotion/react';
import { Components } from '@mui/material';

export const MuiTabs: Components<Omit<Theme, 'components'>>['MuiTabs'] = {
    defaultProps: {
        color: 'secondary',
        textColor: 'secondary',
        indicatorColor: 'secondary',
    },
    styleOverrides: {
        root: {
            '.MuiBadge-root': {
                maxWidth: '100%',
            },
        },
    },
};
