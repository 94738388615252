import { ObjectConfigPublisher } from '../object-config-publisher';
import { HardwareEngineEmulator } from '../hardware-engine-emulator';
import { ProjectImpl, StateProvider, StateUpdater, SystemClient } from '@grenton/gm-logic';
import { LocalFabricBroker } from './local-fabric-broker';
import { LocalFabricRpc } from './local-fabric-rpc';
import { ControllerEngineEmulator } from '../controller-engine-emulator';
import { SystemObject, isSystemObject } from '@grenton/gm-logic/src/fabric-client/system-object';
import { AdminApiEmulator } from '../admin-api-emulator';
import { ObjectRPCExecutor } from '@grenton/gm-logic/src/fabric-client/system-model';
import { fabricMethodExecutor } from '@grenton/gm-logic/src/fabric-client/remote/fabric-method-executor';
import log from 'loglevel';
import { distinct, map, filter } from 'rxjs';
import { notEmpty } from '@grenton/gm-common';

export class LocalFabric {
    readonly localBroker = new LocalFabricBroker();
    readonly objectRPC = new LocalFabricRpc();
    readonly executor: ObjectRPCExecutor;

    private systemClient = new SystemClient<SystemObject>(isSystemObject, { logic: true, state: true });
    readonly adminApiEmulator: AdminApiEmulator;

    constructor(
        private projectProvider: StateProvider<ProjectImpl | null>,
        projectUpdater: StateUpdater<ProjectImpl>,
    ) {
        this.adminApiEmulator = new AdminApiEmulator(this.projectProvider, projectUpdater);
        this.executor = fabricMethodExecutor(this.objectRPC.client);
    }

    get localSystemClient() {
        return this.systemClient;
    }

    async init(): Promise<any> {
        const hardwareEngine = new HardwareEngineEmulator();

        // republish all schemas when firmware changes
        this.projectProvider
            .pipe(
                filter(notEmpty),
                map((p) => p.firmware),
                distinct(),
            )
            .forEach((firmware) => {
                hardwareEngine.firmware = firmware;
                // TODO figure out something better.
                // for (let api of firmware.schemas) {
                //     this.localBroker.publisher.publish({
                //         type: 'api',
                //         data: api,
                //     });
                // }
            });

        hardwareEngine.connect(this.localBroker.observable, this.localBroker.publisher, this.objectRPC.server);
        const controllerEngine = new ControllerEngineEmulator();
        controllerEngine.connect(this.localBroker.observable, this.localBroker.publisher, this.objectRPC.server);

        const configObserver = new ObjectConfigPublisher(this.projectProvider);
        configObserver.pub.subscribe((msg) => this.localBroker.publisher.publish(msg));

        this.localBroker.observable.subscribe((msg) => {
            log.debug('fabric broker', msg);
        });
        this.localBroker.observable.subscribe((msg) => this.systemClient.process(msg));
        return;
    }
}
export { SystemClient as SystemModelSync };
