import log from 'loglevel';
import { debounce, Subscription, timer } from 'rxjs';
import { lua_pathToCode } from '@grenton/gm-logic';
import { LuaCodeEditorController } from './LuaCodeEditor';
import { ScriptWithContext } from '@grenton/gm-logic';
import { ModeController } from '../backend/abstractModeController';
import { ResolvedPath } from '@grenton/gm-logic';
import { blocks } from '@grenton/gm-logic';

export class CodeModeController implements ModeController<string> {
    readonly editorController = new LuaCodeEditorController();
    private sub?: Subscription;

    //private scriptContextRef?: ObjectScriptRef;

    constructor(private onSave: (code: string | null) => void) {}

    generateDerived(): void | null {
        throw new Error('unsupported');
    }

    addScriptlet(resolvedPath: ResolvedPath, opts?: { set?: boolean }) {
        const tail = resolvedPath.tail;
        switch (tail?.type) {
            case 'method': {
                const target = [lua_pathToCode(resolvedPath.withoutTail())];
                const method = tail.method;
                const code = blocks.lua_invokeObjectMethod(target.join('.'), method, (param, _) => {
                    return param.optional === true ? undefined : param.default?.toString();
                });
                this.editorController.insert(code);
                break;
            }
            case 'feature': {
                const code = lua_pathToCode(resolvedPath);
                if (code) {
                    const suffix = opts?.set ? ' = ' : '';
                    const prefix = opts?.set ? '' : 'local _ = ';
                    this.editorController.insert(prefix + code + suffix);
                }
                break;
            }
            default: {
                const code = lua_pathToCode(resolvedPath);
                if (code) {
                    this.editorController.insert(code);
                }
            }
        }
    }

    onScriptChange(scriptContext: ScriptWithContext) {
        this.setContent(scriptContext?.getScript()?.code || null);
        this.sub?.unsubscribe();

        this.sub = this.editorController.code.pipe(debounce(() => timer(100))).subscribe((code) => {
            log.debug('lua change', code);
            this.onSave(code);
        });
        //this.scriptContextRef = scriptContext.ref;
    }

    setContent(code: string | null) {
        this.editorController.setContent(code || '');
    }

    onBeforeScriptChange() {}
}
