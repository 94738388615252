import { FieldError } from "@grenton/gm/ui/form-validation";
import {
    Box,
    Stack
} from "@mui/material";

export type FieldErrorMessagesProps = {
    errors: FieldError[];
};

export function FieldErrorMessages({errors}: FieldErrorMessagesProps) {
    if (errors.length === 0) {
        return null
    } else {
        return (
            <Stack>
                {errors.map((error, index) => <Box sx={{color: 'red'}} key={index}>{error}</Box>)}
            </Stack>
        )
    }
}