/* eslint-disable camelcase -- I need this */
import { uuid } from '../../uuid';
import { type HWConfigurationShim } from '../hardware/system';
import { type FeatureConfig, type OutletConfig, type Script } from '../logic';
import { type ProtocolPtr } from '../objects/api';
import { type ProjectSecurity } from './security';
import { type TagsCategory } from '.';

/**
 * IMPORTANT.
 *
 * Treat this as API.
 *
 * prefer TABLES instead of MAPS here.
 * in 99% cases, entity must include its own ID
 * it is unnatural to use ID as a map key, because this may lead to more annomalies
 * than using TABLES (here we can only have problems with duplicates).
 *
 * PERFORMANCE aspect does not matter here. Whole structure will be transformed into performance-optimised
 * object anyway!
 */

/**
 * to create a hierarchy in project and allow grouping of entities, we use tags.
 * tags can be used to mark a location of an entity, e.g.
 *
 * location.floor1.bedroom1
 * location.floor1.bedroom2
 *
 * or any other property assigned to an entity, e.g.
 *
 * main-light-button
 *
 * that's why it seems that they need to be assigned to both module and object?
 * this makes sense for complex modules like smart panel.
 *
 * for simple modules, that are at the same time an object, tags should be common.
 */
export type Tags = string[];

/**
 * Project has to leverage abstraction, not be focused on concrete hardware implementation.
 * During planning, scripting and testing it completely does not matter weather it is implemented using one or two CLUs,
 * or if lamps are connected to Digital IO with 4 or 8 outputs - these are "implementation" details.
 *
 * The whole setup should be testable w/o a single real hardware piece installed.
 *
 * So the idea is to focus on "devices" here, like "lamp", "sensor" or "smart panel".
 * The device is backed by one of Grenton's "objects" (IO interface) and when device is created, we need to know the
 * exact type of the object and the firmware version, because it has an impact on available API.
 *
 * These devices generally are one of two types: "input" (controllers) or "output" (controlled), but usually they all
 * emit events, have properties (features) and methods.
 *
 * Devices have also physical location (e.g. "floor1 / room2").
 *
 * Some devices may be virtual, e.g. timers, but the only difference is that purely virtual devices have no location.
 *
 * All devices have to be identifiable, so when created, immutable uuid is generated for each of them.
 *
 * Once project design and testing is completed, devices can be mapped to a specific hardware.
 * This is created by linking a device to available (discovered) hardware "objects". There are two approaches:
 *
 * 1) map devices to hardware objects prior to wiring - this can be done automatically.
 * This makes sense if all wires are properly marked and installer will have no problem
 * in connecting specific wire to a specific port. The wireless hardware is more problematic, because it is usually installed first and cannot
 * be re-located. We may want to skip auto-mapping for wireless stuff.
 *
 * 2) first wiring, then mapping. It cannot be done automatically, but may be more practical. The only help for installers we need to provide is the ability
 * of identifying already connected devices. For example for each output port we trigger "switch" action, for each input we display which port received a signal.
 */
export type Project = {
    $schema: string;
    id: string;
    firmware: string;
    revisions: ProjectRevision[];
    label: string;
    i18n: I18n;
    components: ProjectComponentInstance[];
    objects: ProjectObject[];
    tags: ProjectTags;
    hardware: ProjectHardware;
    security: ProjectSecurity;
};

export type ProjectRevision = {
    tag: string;
    author: string;
    ts: string;
    note?: string;
};

export function createRevision(author: string, note?: string): ProjectRevision {
    return { tag: uuid.short(), author, ts: new Date().toISOString(), note };
}

export type ProjectTags = TagsCategory[];

export type ProjectHardware = {
    // project moduleId to real hardware moduleId
    componentMapping: ComponentMapping[];
    configuration: HWConfigurationShim;
    // when uploaded, cluster if formed for this project
    cluster?: ProjectCluster;
};

export type ProjectCluster = {
    id: string;
    secret: string;
};

export type ComponentMapping = {
    virtual: string;
    physical: string;
};

/*
 * represents a HARDWARE module.
 * this is a module "instance", and it holds a reference to DeviceModule
 * that describes objects it implements
 *
 * on project level, on modules are "virtual", uuid does not map to real SN of hardware.
 *
 * do we need it for virtual/scripted objects?
 *
 * virtual objects has to be backed implementation as well, but this implementation has no
 * physical representation, we only need its "type".
 * scripted objects does not even require "type" per se, because they are self-contained.
 */
export type ProjectComponentType = 'module' | 'system' | 'script' | 'external';

export type ProjectComponentInstance = {
    id: string;
    ref: string;
    type: ProjectComponentType; // must match type in component spec referenced by ref
};

export type ProjectObjectInit = {
    // default features' values
    features?: FeatureConfig[];
    // static and dynamic refs
    outlets?: OutletConfig[];
};

export type ProjectObject = {
    // immutable ID
    id: string;
    // user-friendly editable name
    label: string;

    // ObjectAPI that defines this object capabilities
    // it can be a direct reference to predefined APIs or inline custom API
    api: ProtocolPtr;

    // api alias that describes functionality - has to be compatible with api
    deviceType?: string;

    // user-assigned tags
    tags?: Tags;

    // implementation
    impl: ObjectImplementation;

    // initial object configuration
    init?: ProjectObjectInit;

    // for scriptable objects
    scripts?: { path: string; script: Script }[];
};

export enum I18nLangCodeEnum {
    EN = 'en',
    PL = 'pl_PL',
}

export type I18nLang = {
    code: I18nLangCodeEnum;
    isDefault: boolean;
};

export type I18n = {
    langs: I18nLang[];
};

export type ComponentObjectRef = {
    componentId: string;
};

export type ModuleObjectRef = {
    componentId: string;

    // "hardware port" within component that this object is linked to, e.g. "IN_1"
    objectId: string;
};

export type ObjectImplementation =
    | {
          type: 'module';
          componentRef: ModuleObjectRef;
      }
    | {
          type: 'system';
          componentRef: ComponentObjectRef;
      }
    | {
          type: 'script';
          componentRef: ComponentObjectRef;
      }
    | {
          type: 'external';
          componentRef: ComponentObjectRef;
      };
