import { ActionDigitalOutSet } from './digital-out/set';

import { ActionTemplate } from './action-template';
import { ActionDigitalOutSwitch } from './digital-out/switch';
import { ActionApiTemplate } from './generic/api';
import { ObjectApi } from '@grenton/gm-common';
import { ActionLedRGBSwitch } from './ledrgb/switch';
import { ActionLedRGBSet } from './ledrgb/set';
import { ResolvedPath } from '@grenton/gm-logic';

export interface ActionType {
    type: string;
    label: string;
    defaults?: any;
}

class ActionRepo {
    private templates: { [type: string]: ActionTemplate<any> } = {};

    public add(action: ActionTemplate<any>) {
        this.templates[action.type] = action;
    }

    public action(type: string) {
        return this.templates[type];
    }

    types(path: ResolvedPath) {
        return path.empty ? [] : this.typesForApi(path.outputApi!);
    }

    typesForApi(api: ObjectApi): ActionType[] {
        const tmpls = [...Object.values(this.templates).filter((tmpl) => tmpl.isApplicable(api))];
        tmpls.sort((t1, t2) => (t1.order || 1000) - (t2.order || 1000));
        return tmpls.map((tmpl) => ({
            type: tmpl.type,
            label: tmpl.label,
            defaults: tmpl.defaults,
        }));
    }
}

export const actionRepo = new ActionRepo();

actionRepo.add(ActionDigitalOutSet);
actionRepo.add(ActionDigitalOutSwitch);
actionRepo.add(ActionApiTemplate);
actionRepo.add(ActionLedRGBSwitch);
actionRepo.add(ActionLedRGBSet);
