import { schema, fabric } from '@grenton/gm-common';
import { ObjectEventEmitter, ObjectEmulator, MethodInvocation, Scheduler, ObjectStateHolder } from './common';

const VALUE = 'value';
const TIME = 'time';

export class DigitalOUTEmulator implements ObjectEmulator {
    constructor(
        private state: ObjectStateHolder,
        private emitter: ObjectEventEmitter,
        private scheduler: Scheduler,
    ) {}

    private schedule(fn: () => void, time?: number) {
        if (time && time > 0) {
            this.scheduler.setTimeout(fn, time);
        }
    }

    onStateChange(curr: fabric.ObjectState, prev?: fabric.ObjectState) {
        if (prev) {
            if (prev.features[VALUE] !== curr.features[VALUE]) {
                const names: string[] = [];
                const state: fabric.ObjectState = { features: { VALUE: curr.features[VALUE]! } };
                names.push('onValueChange');
                if (curr.features[VALUE] === 1) {
                    names.push('onSwitchOn');
                } else {
                    names.push('onSwitchOff');
                }
                this.emitter({ names, state });
            }
        }
    }

    async execute(request: MethodInvocation): Promise<schema.PropertyValue> {
        const params = request.params || {};
        switch (request.method) {
            case 'setValue':
                this.state.set(VALUE, params[VALUE]!);
                break;
            case 'switchOn': {
                this.state.set(VALUE, 1);
                this.schedule(() => this.state.set(VALUE, 0), params[TIME] as number);
                break;
            }
            case 'switchOff': {
                this.state.set(VALUE, 0);
                this.schedule(() => this.state.set(VALUE, 1), params[TIME] as number);
                break;
            }
            case 'switch': {
                const value = this.state.get(VALUE);
                this.state.set(VALUE, value === 1 ? 0 : 1);
                this.schedule(() => this.state.set(VALUE, value), params[TIME] as number);
                break;
            }
        }
        return null;
    }
}
