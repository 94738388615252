import { Observable, map } from 'rxjs';
import { ProjectImpl, StateProvider } from '@grenton/gm-logic';
import { ProjectSecurityImpl } from '@grenton/gm-logic';

export class SecurityController {
    readonly security: Observable<ProjectSecurityImpl | null>;

    constructor(private projectProvider: StateProvider<ProjectImpl | null>) {
        this.security = projectProvider.pipe(map((p) => p?.data.security || null));
    }

    getUserByName(name: string) {
        return this.projectProvider.value!.security.getUserByName(name);
    }

    getUsers() {
        return this.projectProvider.pipe(map((p) => p!.security.users));
    }
}
