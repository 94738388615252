declare module '@mui/material/styles' {
    interface Theme {
        size?: (number: number) => number;
    }
    interface ThemeOptions {
        size?: (number: number) => number;
    }
}

/**
 * this is default font size in modern browsers.
 * we don't want to override it, because it will prevent user from using their preferred font size in browser settings
 * instead, we want to scale our font styles to be relative to this size by using REM units
 * e.g. typography variant M is supposed to be 14px, so it will be 14/16 = 0.875rem
 */
const ROOT_FONT_SIZE = 16;

export const size = (number: number) => number / ROOT_FONT_SIZE;
export const sizeRem = (number: number) => `${size(number)}rem`;

export const sizes = {
    XL: 22,
    L: 16,
    M: 14,
    S: 12,
    XS: 10,
};

export const spacing = {
    XS3: 5,
    XS2: 10,
    XS: 15,
    S: 20,
    M: 30,
    L: 60,
    XL: 80,
    XL2: 120,
    XL3: 200,
};

export const icons = {
    small: '1.2rem',
    medium: '1.5rem',
    large: '1.75rem',
};

export const px = (...values: number[]) => values.map((v) => `${v}px`).join(' ');
