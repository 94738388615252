import { ResolvedPath } from '@grenton/gm-logic';
import { ActionApiModel } from './model';

export function ActionApiGenerator(model: ActionApiModel, _: ResolvedPath) {
    return {
        calls: Object.entries(model.callables)
            .filter((e) => e[1].enabled)
            .map((e) => ({ callable: e[0], params: e[1].params })),
    };
}
