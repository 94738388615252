import { schema } from "@grenton/gm-common";
import { MenuItem, TextField } from "@mui/material";

export type Props = {
    disabled?: boolean;
    required?: boolean;
    label: string;
    value: any;
    options: schema.FeatureValueOption<any>[];
    onChange: (value: any) => void;
    hint?: string;
    minWidth?: number;
};

export function EnumSelector({disabled, required, hint, label, value, options, onChange,minWidth}: Props) {
    return (
            <TextField
                select
                required={required}
                helperText={hint}
                disabled={disabled}
                size="small"
                sx={{minWidth: minWidth || 150}}
                value={value}
                label={label}
                onChange={(e) => onChange(e.target.value)}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>))}
            </TextField>
    )
}