import { List, ListItemButton, ListItemIcon, Checkbox, ListItemText } from "@mui/material"
import { ActionApiModel } from "../model"
import { CallableApiItem } from "./types"

type Props = {
    items: CallableApiItem[],
    selectedCallable?: string,
    onSelectedCallable: (id: string) => void,
    onToggleCallable: (id: string, enabled: boolean) => void,
    model: ActionApiModel
}

export function CallableItemList({ items, model, selectedCallable, onSelectedCallable, onToggleCallable }: Props) {
    return <List sx={{ width: 300, overflow: 'auto' }}>
        {items.map(item => {
            const checked = Boolean(model.callables[item.id]?.enabled)
            return <ListItemButton 
                        key={item.id} 
                        selected={selectedCallable === item.id} 
                        onClick={() => onSelectedCallable(item.id)} dense>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={checked}
                        tabIndex={-1}
                        disableRipple
                        onChange={(_, enabled) => onToggleCallable(item.id, enabled)}
                    />
                </ListItemIcon>
                <ListItemText sx={{ opacity: checked ? 1 : 0.5 }} primary={item.name} />
            </ListItemButton>
        })}
    </List>
}