import { ObjectRPCExecutor } from '../system-model';
import { IdMap, fabric, schema, uuid } from '@grenton/gm-common';
import { RpcError } from './json-mqtt-rpc-client';

export function fabricMethodExecutor(fabricRpcClient: fabric.FabricRpcClient): ObjectRPCExecutor {
    return async (id: string, request: { method: string; params?: IdMap<schema.PropertyValue> }) => {
        const rid = uuid.v4();
        const msg: fabric.RpcRequestMessage = {
            type: 'rpc-request',
            data: {
                uuid: id,
                request: {
                    id: rid,
                    ...request,
                },
            },
        };
        const rsp = await fabricRpcClient.execute(msg);
        const error = rsp?.data.response.error;
        if (error) {
            throw new RpcError(error);
        } else {
            return rsp?.data.response.result || null;
        }
    };
}
