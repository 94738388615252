import { SvgIconProps, SvgIcon } from "@mui/material";
import { ReactElement } from "react";

export function ConnectorConnected(props: SvgIconProps): ReactElement {

    return <SvgIcon viewBox="0 0 24 24" {...props} fontSize="inherit">
    {/* <g id="connected">
        <path d="M4.71,20.71L7.11,18.31C8.596,19.179 10.496,18.931 11.71,17.71L14.41,15L13.41,14L14.7,12.71L13.29,11.3L12,12.59L11.41,12L12.7,10.71L11.29,9.3L10,10.59L9,9.59L6.29,12.29C5.079,13.509 4.832,15.401 5.69,16.89L3.29,19.29L4.71,20.71ZM7.71,13.71L9,12.41L11.59,15L10.29,16.29C9.947,16.633 9.481,16.826 8.995,16.826C7.99,16.826 7.164,16 7.164,14.995C7.164,14.509 7.357,14.043 7.7,13.7L7.71,13.71Z"/>
        <path d="M16.89,5.69C15.401,4.832 13.509,5.079 12.29,6.29L9.59,9L15,14.41L17.71,11.7C18.921,10.481 19.168,8.589 18.31,7.1L20.71,4.7L19.29,3.29L16.89,5.69ZM16.29,10.29L15,11.59L12.41,9L13.7,7.71C14.043,7.367 14.509,7.174 14.995,7.174C16,7.174 16.826,8 16.826,9.005C16.826,9.491 16.633,9.957 16.29,10.3L16.29,10.29Z"/>
    </g> */}
    <g id="connected_fully">
        <path d="M5.71,19.71L8.11,17.31C9.596,18.179 11.496,17.931 12.71,16.71L15.41,14L10,8.59L7.29,11.29C6.079,12.509 5.832,14.401 6.69,15.89L4.29,18.29L5.71,19.71ZM8.71,12.71L10,11.41L12.59,14L11.29,15.29C10.947,15.633 10.481,15.826 9.995,15.826C8.99,15.826 8.164,15 8.164,13.995C8.164,13.509 8.357,13.043 8.7,12.7L8.71,12.71Z"/>
        <path d="M15.89,6.69C14.401,5.832 12.509,6.079 11.29,7.29L8.59,10L14,15.41L16.71,12.7C17.921,11.481 18.168,9.589 17.31,8.1L19.71,5.7L18.29,4.29L15.89,6.69ZM15.29,11.29L14,12.59L11.41,10L12.7,8.71C13.043,8.367 13.509,8.174 13.995,8.174C15,8.174 15.826,9 15.826,10.005C15.826,10.491 15.633,10.957 15.29,11.3L15.29,11.29Z"/>
    </g>
    </SvgIcon>
}