import { Components, Theme } from '@mui/material';

export const MuiRadio: Components<Omit<Theme, 'components'>>['MuiRadio'] = {
    defaultProps: {
        color: 'secondary',
    },
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            '& .MuiSvgIcon-root': {
                color: ownerState.disabled ? 'inherit' : theme.palette.secondary.main,
            },
        }),
    },
};
