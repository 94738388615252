import { Project, ProjectObject, ProjectComponentInstance, ModuleObjectRef } from '@grenton/gm-common/src';
import { ValidationContext, ResultBuilder } from './helpers';

export function validateObjectDefinition() {
    return (project: Project, context: ValidationContext) => {
        const firmware = context.firmware!;
        if (!firmware) {
            throw new Error('invalid validation context');
        }

        const result = new ResultBuilder();

        project.objects.forEach(validateObject);

        function validateObject(object: ProjectObject) {
            if (!object.impl) {
                return result.error('logic', `Object "${object.id}" has no implementation`);
            }
            validateModuleObject(object);
        }

        function validateModuleObject(object: ProjectObject) {
            if (object.impl.type !== 'module') {
                return;
            }
            const { componentRef } = object.impl;
            if (!componentRef) {
                return result.fatalError('logic', `Object "${object.id}" is not assigned to a component`);
            }

            const componentEntry = project.components.find((component) => component.id === componentRef.componentId);
            if (!componentEntry) {
                return result.fatalError('logic', `Object "${object.id}" maps to component ${componentRef.componentId} that is missing`);
            }

            validateComponentSupport(componentEntry, object, componentRef);
        }

        function validateComponentSupport(componentEntry: ProjectComponentInstance, object: ProjectObject, componentRef: ModuleObjectRef) {
            const cmp = firmware.getComponent(componentEntry.ref);
            if (!cmp) {
                return result.fatalError('logic', `Component "${componentEntry.ref}" is not supported by firmware`);
            }

            if (cmp.spec.type !== 'module') {
                return result.fatalError('logic', `Component "${componentEntry.ref}" is not a module`);
            }

            if (!componentRef.objectId) {
                return result.fatalError('logic', `Object "${object.id}" misses componentRef.objectId`);
            }

            // TODO compare componentRef.objectId with componentSpec.provides.objects (incl counter)
        }

        return result;
    };
}
