import { fabric } from '@grenton/gm-common';
import { ActionScriptImpl, ProjectImpl, resolvePath, StateProvider, StateUpdater } from '@grenton/gm-logic';
import { checkLuaName } from '@grenton/gm-logic';
import { ProjectUserImpl, TEMPORARY_DEFAULT_USER_ROLES } from '@grenton/gm-logic';
import { createMethodImpl, createObjectsFromVirtualComponent } from '../project/builders';

/*
 * simulates CLU Admin API
 */
export class AdminApiEmulator implements fabric.admin.Api {
    constructor(
        private projectProvider: StateProvider<ProjectImpl | null>,
        private projectUpdater: StateUpdater<ProjectImpl>,
    ) {}

    async userCreate(request: fabric.admin.userCreate.Request): Promise<fabric.admin.userCreate.Response> {
        const user = ProjectUserImpl.from({
            name: request.id,
            pwd: request.data.pwd, //TODO
            disabled: request.data.disabled,
            roles: TEMPORARY_DEFAULT_USER_ROLES,
        });
        this.projectUpdater((p) => {
            const existing = p.security.getUserByName(request.id);
            if (existing) {
                throw new Error('duplicated user name');
            }
            return p.withSecurity(p.security.withUser(user));
        });

        return {
            id: user.name,
        };
    }

    async userRead(request: fabric.admin.userRead.Request): Promise<fabric.admin.userRead.Response> {
        const user = this.projectProvider.value?.security.getUserByName(request.id);
        if (user) {
            return {
                id: user.name,
                data: {
                    disabled: user.disabled,
                },
            };
        } else {
            throw new Error('no such user');
        }
    }

    async userUpdate(request: fabric.admin.userUpdate.Request): Promise<fabric.admin.userUpdate.Response> {
        let user = this.projectProvider.value?.security.getUserByName(request.id);
        if (!user) {
            throw new Error('no such user');
        }
        user = user.withDisabled(request.data.disabled);
        if (request.data.pwd) {
            user = user.withPwd(request.data.pwd); //TODO
        }

        this.projectUpdater((p) => {
            return p.withSecurity(p.security.withUser(user!));
        });

        return {};
    }

    async userDelete(request: fabric.admin.userDelete.Request): Promise<fabric.admin.userDelete.Response> {
        const user = this.projectProvider.value?.security.getUserByName(request.id);
        if (!user) {
            throw new Error('no such user');
        }
        this.projectUpdater((p) => {
            return p.withSecurity(p.security.withoutUser(user.name));
        });
        return {};
    }

    private getScriptFromObject(uuid: string, path: string[]) {
        const ref = this.projectProvider.value ? resolvePath([uuid, ...path], this.projectProvider.value.objectResolver) : null;
        if (!ref || ref.length === 0) throw new Error('no such object');
        const ctrl = ref.rootObject!;
        if (ctrl.impl.type !== 'script') throw new Error('non scriptable object');
        const scriptPath = ref.withoutHead();
        // only two paths are allowed here
        // ctrl -> outlet -> event
        // ctrl -> method
        if (scriptPath.length > 2) throw new Error(`no such script ${scriptPath.serializeToString()}`);
        return { ctrl, scriptPath: scriptPath };
    }

    async scriptRead(request: fabric.admin.scriptRead.Request): Promise<fabric.admin.scriptRead.Response> {
        const { ctrl, scriptPath } = this.getScriptFromObject(request.id.object, request.id.path.split('.'));
        if (!scriptPath.length) throw new Error('script does not exist');
        const pathStr = scriptPath.serializeToString();
        const script = ctrl.scripts.scripts[pathStr];

        if (script && script?.format !== 'actions') throw new Error('unsupported script format');

        return {
            id: {
                object: ctrl.uuid,
                path: pathStr,
            },
            data: {
                label: scriptPath.tail!.name,
                script: {
                    type: 'actions',
                    script: {
                        items: script?.actions?.export()?.items || [],
                    },
                },
                // enabled only for non-inherited methods
                labelEditable: Boolean(scriptPath.outputApi!.self.methods[scriptPath.tail!.id]),
                methodRemovable: Boolean(ctrl.api.api.self.methods[pathStr]),
            },
        };
    }

    async scriptDelete(request: fabric.admin.scriptDelete.Request): Promise<fabric.admin.scriptDelete.Response> {
        let { ctrl, scriptPath } = this.getScriptFromObject(request.id.object, request.id.path.split('.'));
        if (!scriptPath.length) throw new Error('script does not exist');
        const pathStr = scriptPath.serializeToString();

        const method = ctrl.api.api.self.methods[pathStr];
        if (!method) throw new Error('no such removable method');

        this.projectUpdater((p) => {
            return p.withObject((ctrl = ctrl.withApi(ctrl.api.withoutMethod(pathStr)).withScripts(ctrl.scripts.withoutScript(pathStr))));
        });

        return {};
    }

    async scriptableCreate(request: fabric.admin.scriptableCreate.Request): Promise<fabric.admin.scriptableCreate.Response> {
        const project = this.projectProvider.value!;
        const created = createObjectsFromVirtualComponent(
            project.firmware,
            'Custom Controller',
            'mg_scene_controller',
            (label) => Boolean(project.getObjectByLabel(label)),
            false,
            request.data.tags,
        );
        const methodId = 'script_1'; // custom controller has a single empty method called as such

        this.projectUpdater((p) => {
            if (!checkLuaName(request.data.label)) throw new Error('invalid script name');

            return p.withComponentEntry(created, (ctrl) => {
                if (ctrl.api.hasItemNamed(request.data.label)) throw new Error('duplicate name in this object API');
                const method = ctrl.api.methods[methodId];
                if (!method) return ctrl;
                return ctrl.withApi(ctrl.api.withMethod(method.withName(request.data.label))).withScripts(
                    ctrl.scripts.withScriptModifier(methodId, (script) => {
                        return script.withActions(ActionScriptImpl.from(request.data.script.script));
                    }),
                );
            });
        });

        return {
            id: {
                object: created.objects[0]!.object.uuid,
                path: methodId,
            },
        };
    }

    async scriptCreate(request: fabric.admin.scriptCreate.Request): Promise<fabric.admin.scriptCreate.Response> {
        const method = createMethodImpl().withName(request.data.label);
        let { ctrl } = this.getScriptFromObject(request.id.object, []);
        this.projectUpdater((p) => {
            if (!checkLuaName(request.data.label)) throw new Error('invalid script name');
            if (ctrl.api.hasItemNamed(request.data.label)) throw new Error('duplicate name in this object API');

            ctrl = ctrl.withApi(ctrl.api.withMethod(method));
            return p.withObject(
                ctrl.withScripts(
                    ctrl.scripts.withScriptModifier(method.id, (script) => {
                        return script.withActions(ActionScriptImpl.from(request.data.script.script));
                    }),
                ),
            );
        });

        return {
            id: {
                object: ctrl.uuid,
                path: method.id,
            },
        };
    }

    async scriptUpdate(request: fabric.admin.scriptUpdate.Request): Promise<fabric.admin.scriptUpdate.Response> {
        this.projectUpdater((p) => {
            let { ctrl, scriptPath } = this.getScriptFromObject(request.id.object, request.id.path.split('.'));
            const pathStr = scriptPath.serializeToString();

            // allow method renaming only for self methods
            if (request.data.label && scriptPath.tail?.type === 'method') {
                const method = ctrl.api.methods[scriptPath.tail!.id];
                if (!method) return p;
                if (!method.inherited) {
                    if (request.data.label !== method.name) {
                        if (!checkLuaName(request.data.label)) throw new Error('invalid script name');
                        if (request.data.label !== method.id && ctrl.api.hasItemNamed(request.data.label)) throw new Error('duplicate name in this object API');
                        ctrl = ctrl.withApi(ctrl.api.withMethod(method.withName(request.data.label)));
                    }
                }
            }
            return p.withObject(
                ctrl.withScripts(
                    ctrl.scripts.withScriptModifier(pathStr, (script) => {
                        return script.withActions(ActionScriptImpl.from(request.data.script.script));
                    }),
                ),
            );
        });
        return {};
    }

    async objectRead(request: fabric.admin.objectRead.Request): Promise<fabric.admin.objectRead.Response> {
        const project = this.projectProvider.value!;
        const object = project.getObjectById(request.id);
        if (!object) throw new Error(`no such entity ${request.id}`);
        return {
            id: object.uuid,
            data: {
                label: object.label,
                device: object.userType,
                api: object.api.name,
                tags: object.tags.selected,
                topObject: object.top,
                links: {
                    from: [],
                    to: [],
                },
            },
            meta: {
                deviceTypes: project.firmware.getFunctionalTypes(object.api.api).map((u) => u.id),
                tagCategories: project.tags.export(),
            },
        };
    }

    async objectUpdate(request: fabric.admin.objectUpdate.Request): Promise<fabric.admin.objectUpdate.Response> {
        this.projectUpdater((p) => {
            const entity = p.getObjectById(request.id);
            if (!entity) throw new Error(`no such entity ${request.id}`);
            let updated = entity.withLabel(request.data.label).withTags(request.data.tags).withUserType(request.data.device);

            return p.withObject(updated);
        });
        return {};
    }

    async userList(_: fabric.admin.userList.Request): Promise<fabric.admin.userList.Response> {
        return {
            users:
                this.projectProvider.value?.security.users.map((u) => ({
                    id: u.name,
                    data: {
                        disabled: u.disabled,
                    },
                })) || [],
        };
    }
}
