import {red} from "@mui/material/colors";

export const colors = {
    primary: {
        main: '#EC2E47',
    },
    secondary: {
        main: '#0288D1',
    },
    error: {
        main: red.A400,
    },
    action: {
        selected:'#0288D1',
        selectedOpacity:0.12,
    },
};
