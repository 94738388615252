import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { TreeDemoItem, TreeDemoProps } from "./types";
import { GRichTreeView, GTreeItemCheckboxState } from "../../components";

const Renderer = function({ item }: { item:TreeDemoItem }) {
    return <div>{item.data.type}</div>;
}

const checkboxes = {
    
    "1" : GTreeItemCheckboxState.DISABLED,
    "2" : GTreeItemCheckboxState.ENABLED,
    "3" : GTreeItemCheckboxState.DISABLED,
    "4" : GTreeItemCheckboxState.ENABLED,
}

export function RichTreeViewDemo({ items, multiSelect, expanded, onItemExpansionToggle}: TreeDemoProps) {

    const onToggle = useCallback((_e:React.SyntheticEvent<Element,Event>,itemId:string,isExpanded:boolean) => onItemExpansionToggle(itemId,isExpanded), [onItemExpansionToggle])

    return <Box sx={{ minHeight: 300, height: 300, width: 350, border: '1px solid black', overflow: 'auto' }}>
        <GRichTreeView
            renderer={Renderer}
            expandedItems={expanded}
            checkboxProps={checkboxes}
            onItemExpansionToggle={onToggle}
            checkboxSelection={multiSelect}
            multiSelect={true}
            items={items}/>
    </Box>
}
