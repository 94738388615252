import { fabric } from '@grenton/gm-common';

class Client implements fabric.FabricRpcClient {
    constructor(private executors: Map<string, fabric.JsonRpcExecutor>) {}

    private simulateRemoteError(id: string, message: string): fabric.RpcResponseMessage {
        return {
            type: 'rpc-response',
            data: {
                response: {
                    id,
                    error: {
                        code: -1,
                        message,
                    },
                },
            },
        };
    }

    // FabricRpcClient is just a transport, so it is ok to throw regular errors
    // but with "local" we're trying to simulate "remote" behavior,
    // so some errors that with "remote" implementation occurs on the server side we wrap into RPC message
    async execute(request: fabric.RpcRequestMessage): Promise<fabric.RpcResponseMessage | undefined> {
        const uuid = request.data.uuid;
        const id = request.data.request.id;

        const executor = this.executors.get(uuid);

        if (!executor) {
            return id ? this.simulateRemoteError(id, `executor not found for ${request.data.uuid}`) : undefined;
        }

        try {
            const result = executor(request.data.request);
            return id
                ? {
                      type: 'rpc-response',
                      data: {
                          response: {
                              id,
                              result,
                          },
                      },
                  }
                : undefined;
        } catch (e: any) {
            return id ? this.simulateRemoteError(id, e.message || 'unknown reason') : undefined;
        }
    }
}

class Server implements fabric.FabricRpcServer {
    constructor(private executors: Map<string, fabric.JsonRpcExecutor>) {}

    registerExecutor(uuid: string, executor: fabric.JsonRpcExecutor) {
        this.executors.set(uuid, executor);
    }

    removeExecutor(uuid: string) {
        this.executors.delete(uuid);
    }
}

export class LocalFabricRpc {
    private executors = new Map<string, fabric.JsonRpcExecutor>();
    readonly client = new Client(this.executors);
    readonly server = new Server(this.executors);
}
