import { HWCluShim, HWConfigurationShim, HWModuleShim, IdMap, Lists, Maps, ObjectApi } from '@grenton/gm-common';

export class Cluster {
    constructor(
        private readonly data: {
            projectId: string;
            projectRevision: string;
            clusterId: string;
            configuration: HWConfigurationImpl;
            local: boolean;
        },
    ) {}

    get clusterId() {
        return this.data.clusterId;
    }

    get local() {
        return this.data.local;
    }

    get projectId() {
        return this.data.projectId;
    }

    get projectRevision() {
        return this.data.projectRevision;
    }

    get configuration() {
        return this.data.configuration;
    }

    withConfiguration(configuration: HWConfigurationImpl) {
        return new Cluster({ ...this.data, configuration });
    }
}

export class HWConfigurationImpl {
    static empty() {
        return new HWConfigurationImpl({ clus: {} });
    }

    // static from(config: HWConfigurationShim, online = false) {
    //     let c = HWConfigurationImpl.empty()
    //     for (const clu of config.clus) {
    //         c = c.withClu(HWCluImpl.from(clu, online))
    //     }
    //     return c
    // }

    constructor(private data: { clus: IdMap<HWCluImpl> }) {}

    getCluById(id: string) {
        return this.data.clus[id];
    }

    get clus() {
        return Maps.values(this.data.clus);
    }

    withClu(clu: HWCluImpl) {
        return new HWConfigurationImpl({ clus: { ...this.data.clus, [clu.id]: clu } });
    }

    withoutClu(cluId: string) {
        const copy: IdMap<HWCluImpl> = { ...this.data.clus };
        delete copy[cluId];
        return new HWConfigurationImpl({ clus: copy });
    }

    withOnlineAll(online: boolean) {
        let c = HWConfigurationImpl.empty();
        for (const clu of this.clus) {
            c = c.withClu(clu.withOnlineAll(online));
        }
        return c;
    }

    export(): HWConfigurationShim {
        return {
            clus: this.clus.map((c) => c.export()),
        };
    }
}

export class HWCluImpl {
    // static from(clu: HWCluShim, online = false) {
    //     return new HWCluImpl({id:clu.id, name:clu.name, online, modules: Lists.reduce(clu.modules, m=>[m.id,HWModuleImpl.from(m, online)])})
    // }

    constructor(private data: { id: string; name: string; url?: string; modules: IdMap<HWModuleImpl>; imported: boolean; online: boolean }) {}

    get name() {
        return this.data.name;
    }

    get url() {
        return this.data.url;
    }

    get id() {
        return this.data.id;
    }

    get imported() {
        return this.data.imported;
    }

    get modules() {
        return Maps.values(this.data.modules);
    }

    get online() {
        return this.data.online;
    }

    withModule(module: HWModuleImpl) {
        return new HWCluImpl({ ...this.data, modules: { ...this.data.modules, [module.id]: module } });
    }

    withOnlineAll(online: boolean) {
        // @ts-ignore
        return new HWCluImpl({ ...this.data, online, modules: Maps.transform(this.data.modules, (id, mod) => mod.withOnline(online)) });
    }

    withOnline(online: boolean) {
        return new HWCluImpl({ ...this.data, online });
    }

    export(): HWCluShim {
        return {
            id: this.id,
            name: this.name,
            modules: this.modules.map((m) => m.export()),
        };
    }
}

export class HWModuleImpl {
    static from(id: string, ref: string, objects: HWObjectImpl[], online = false) {
        return new HWModuleImpl({
            id,
            ref,
            objects: Lists.reduce(objects, (o) => [o.id, new HWObjectImpl(o)]),
            online,
        });
    }

    constructor(private data: { id: string; ref: string; objects: IdMap<HWObjectImpl>; online: boolean }) {}

    get id() {
        return this.data.id;
    }

    get ref() {
        return this.data.ref;
    }

    get objects() {
        return this.data.objects;
    }

    get online() {
        return this.data.online;
    }

    withOnline(online: boolean) {
        return new HWModuleImpl({ ...this.data, online });
    }

    export(): HWModuleShim {
        return {
            id: this.id,
            ref: this.ref,
            objects: Maps.values(this.objects),
        };
    }
}

export class HWObjectImpl {
    constructor(private data: { id: string; port: string; api: ObjectApi }) {}

    get io() {
        return 'output'; // TODOX
    }

    get id() {
        return this.data.id;
    }

    get port() {
        return this.data.port;
    }

    get api() {
        return this.data.api;
    }
}
