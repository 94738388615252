import { Components, Theme } from '@mui/material';
import { spacing } from '../size';
import { themeVariant } from '../../variant';

export const MuiDialogContentText: Components<Omit<Theme, 'components'>>['MuiDialogContentText'] = {
    defaultProps: {
        variant: themeVariant === 'kris' ? 'l' : 'm',
    },
    styleOverrides: {
        //@ts-ignore
        root: ({ _ownerState, _theme }) =>
            themeVariant === 'kris'
                ? {
                      padding: spacing.XS,
                      alignContent: 'center',
                      minWidth: '100px',
                  }
                : {},
    },
};
