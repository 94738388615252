import { Components, Theme, alpha } from '@mui/material';
import { grentonColors } from '../colors';
import { icons, px, sizeRem, sizes, spacing } from '../size';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        dashed: true;
    }
    interface ButtonPropsColorOverrides {
        white: true;
        greyish: true;
    }
}

export const MuiButton: Components<Omit<Theme, 'components'>>['MuiButton'] = {
    variants: [
        {
            props: { variant: 'dashed' },
            style: {
                textTransform: 'none',
                border: `2px dashed blue`,
            },
        },
        {
            props: { variant: 'dashed', color: 'secondary' },
            style: {
                border: `4px dashed red`,
            },
        },
    ],
    defaultProps: {
        variant: 'contained',
        color: 'white',
    },
    styleOverrides: {
        root: ({ theme }) => ({
            boxShadow: 'none',
            borderRadius: '2em',
            textTransform: 'none',
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: sizeRem(sizes.M),
            // it is better to scale spacing here with font
            //padding: '0.7em 1em 0.7em 1em',
            padding: px(spacing.XS2, spacing.XS, spacing.XS2, spacing.XS),

            // to avoid diff in height between buttons with/without border, use transparent border by default
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'transparent',

            '&.MuiButton-sizeSmall': {
                //padding: '0.35em 0.7em 0.35em 0.7em'
                padding: px(spacing.XS3, spacing.XS2, spacing.XS3, spacing.XS2),
            },
            '&.MuiButton-containedWhite': {
                borderColor: grentonColors.grey[300],
            },
            '&.MuiButton-containedGreyish': {
                borderColor: grentonColors.grey[300],
            },
            '&:hover': {
                boxShadow: 'none',
            },
        }),
        endIcon: ({ ownerState, theme }) => ({
            borderLeftWidth: 1,
            borderLeftStyle: 'solid',
            borderLeftColor: alpha(
                ownerState.color !== undefined && ownerState.color !== 'inherit' ? theme.palette[ownerState.color].contrastText : '#fff',
                0.2,
            ),
            marginLeft: px(10),
            marginRight: px(-4),
            paddingLeft: px(8),
            '>*:nth-of-type(1)': {
                fontSize: icons.medium,
            },
            '&.MuiButton-iconSizeSmall': {
                marginLeft: px(8),
                marginRight: px(0),
                paddingLeft: px(6),
            },
            '&.MuiButton-iconSizeSmall>*:nth-of-type(1)': {
                fontSize: icons.small,
            },
        }),
    },
};
