import { map, merge, Observable, Subject } from 'rxjs';
import * as apiUtils from './api';
import { createApiForSpec, OutletApiPlaceholder } from './api-factory';
import { DependencyProcessor, Doc } from './dependencyProcessor3';
import { ObjectApi } from './model';
import { ObjectApiImpl } from './object-api-impl';

export type ProtocolLoaderError = {
    id: string;
    reason: string;
};

export class ProtocolLoader {
    private dependencyProcessor;
    private localErrors = new Subject<ProtocolLoaderError>();

    constructor(
        private resolver: (apiId: string) => ObjectApi | null,
        private registrar: (api: ObjectApiImpl) => void,
    ) {
        this.dependencyProcessor = new DependencyProcessor<apiUtils.NamedApiSpec>((docs) => {
            this.processRelatedApis(docs);
        });
    }

    get errors(): Observable<ProtocolLoaderError> {
        return merge(this.localErrors, this.dependencyProcessor.getFailedDocs().pipe(map((d) => ({ id: d.doc.data.id, reason: d.reason }))));
    }

    get pending() {
        return this.dependencyProcessor.getUnprocessedDocs();
    }

    markPendingAsErrors() {
        for (const id of this.pending) {
            this.localErrors.next({ id, reason: 'protocol unprocessed due to unresolved dependencies' });
        }
    }

    load(schemas: apiUtils.NamedApiSpec[]) {
        const docs = schemas.map((schema) => {
            const dependencies = [
                // API depends on extended APIs
                ...(schema.spec.extending || []),
                // API depends on outlets APIs
                ...(schema.spec.outlets?.filter((o) => o.protocol.type === 'ref').map((o) => (o.protocol as any).ref as string) || []),
            ]
                // some dependencies may be already resolved so do not take them under consideration
                .filter((id) => !this.resolver(id));

            const doc: Doc<apiUtils.NamedApiSpec> = {
                id: schema.id,
                data: schema,
                dependencies,
            };
            return doc;
        });
        this.dependencyProcessor.addDocBatch(docs);
    }

    private processRelatedApis(docs: Doc<apiUtils.NamedApiSpec>[]) {
        const processed = new Map<string, ObjectApiImpl>();
        const processing = new Set<string>();
        const unresolvedOutlets: OutletApiPlaceholder[] = [];

        const createApi = (schema: apiUtils.NamedApiSpec): ObjectApiImpl => {
            const result = createApiForSpec(schema, (apiId) => processed.get(apiId) || this.resolver(apiId) || null);
            if (result.status === 'unresolved-extending') {
                throw new Error(`Failed to create API ${schema.id}: ${result.status}`);
            } else if (result.status === 'unresolved-outlets') {
                unresolvedOutlets.push(...result.unresolved);
            }
            return result.api;
        };

        const processApi = (doc: Doc<apiUtils.NamedApiSpec>) => {
            if (processed.has(doc.id)) return;
            if (processing.has(doc.id)) {
                throw new Error(`Circular dependency detected in API extensions: ${doc.id}`);
            }

            processing.add(doc.id);

            // Process extended APIs first
            for (const extendedApiId of doc.data.spec.extending || []) {
                const extendedDoc = docs.find((d) => d.id === extendedApiId);
                if (extendedDoc) {
                    processApi(extendedDoc);
                } else if (!this.resolver(extendedApiId)) {
                    throw new Error(`Extended API ${extendedApiId} not found for ${doc.id}`);
                }
            }

            // Create and register the API
            const api = createApi(doc.data);
            processing.delete(doc.id);
            processed.set(doc.id, api);
        };

        // Process all docs
        for (const doc of docs) {
            processApi(doc);
        }

        for (const outlet of unresolvedOutlets) {
            const api = processed.get(outlet.requireApi);
            if (!api) {
                // this generally should not happen because dependencies have been already validated!
                throw new Error(`Failed to resolve cyclic outlet API ${outlet.requireApi} in outlet ${outlet.outlet.id}`);
            }
            outlet.outlet.api = api;
        }

        // now when we are sure all are processed we can register them
        for (const api of processed.values()) {
            this.registrar(api);
        }
    }
}
